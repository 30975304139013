import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import { User } from '../entities/entities';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css'],
})
export class AddUserComponent implements OnInit {
  user: User = new User();
  myform: UntypedFormGroup;
  isBusy: boolean = false;
  isExistsUser: boolean = false;
  isDuplicateIsraelID: boolean = false;
  isDuplicateLastFirstPhone: boolean = false;

  showPass: boolean = false;
  showAsk: boolean = false;
  isPersonalShadchan: boolean = false;
  byShadchan: boolean;
  msg: string = '';
  fix: boolean = true;
  showGender: boolean;
  emailTemp: string = '';
  idPerson: number;

  constructor(
    private titleService: Title,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private globals: Globals,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('שידוכים כהלכה');
    this.byShadchan = this.globals.isDefined(this.route.snapshot.params.user);
    if (this.byShadchan) {
      this.user = JSON.parse(this.route.snapshot.params.user);
      this.idPerson = this.user.id;
      console.log(this.idPerson);
    }
    console.log(this.user);

    this.emailTemp = this.user.email;
    this.showGender = this.user.gender == 0;
    console.log(this.emailTemp);
    this.initForm();
    console.log(this.user);
  }

  initForm() {
    this.userService.id = 0;
    this.userService.loginFirstName = undefined;
    this.userService.loginLastName = undefined;
    console.log(this.user.firstName);
    this.myform = new UntypedFormGroup({
      firstName: new UntypedFormControl(
        this.user.firstName,
        Validators.required
      ),
      lastName: new UntypedFormControl(this.user.lastName, Validators.required),
      gender: new UntypedFormControl(
        this.user.gender || undefined,
        Validators.required
      ),
      email: new UntypedFormControl(this.user.email, [
        Validators.required,
        Validators.pattern(/^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,15}\s*$/),
      ]),
      phone: new UntypedFormControl(this.user.phone, [
        Validators.required,
        Validators.pattern('0[0-9]{8,9}$'),
      ]),
      password: new UntypedFormControl(this.user.password, [
        Validators.required,
        Validators.pattern(this.globals.passwordPattern),
      ]),
    });
  }

  submit() {
    if (this.myform.valid) {
      this.isBusy = true;
      this.user = this.myform.value;
      this.user.isShadchan = false;
      this.user.isSystem = false;
      this.user.isLocked = false;
      this.user.permission = 0;

      this.user.email = this.user.email.toLowerCase().trim();
      console.log(this.user);
      if (this.emailTemp == this.user.email) this.addUserToExistsPerson();
      // else{
      //   if(this.byShadchan){
      //     // this.editEmailToPerson();
      //     this.addUserToExistsPerson();
      //   }
      else this.addUser();
      // }
    } else this.fix = false;
  }

  validateNo(e): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  addUser() {
    console.log('add-user addUser()');

    console.log('addUser this.user - ' + this.user);
    this.http
      .post(
        this.globals.apiURL +
          `actions/add?className=db.Users&userIDRequested="${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}"`,
        JSON.stringify(this.user),
        {
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        }
      )
      .subscribe(
        (response) => {
          console.log('response - ' + response.toString());

          this.user.id = parseInt(response.toString());
          console.log('addUser() this.user.id - ' + this.user.id);
          if (this.user.id == -1) {
            this.showAsk = true;
            this.user.id = null;
          } else if (this.user.id == 0) {
            this.isExistsUser = true;
          } else if (this.user.id == -2) {
            this.isDuplicateIsraelID = true;
          } else if (this.user.id == -3) {
            this.isDuplicateLastFirstPhone = true;
          } else if (this.user.id > 0) {
            this.sendEmail(this.user);
            this.router.navigate(['/user-unavailable/new']);
          }
          this.isBusy = false;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.isBusy = false;
        }
      );
  }

  addNewUser() {
    console.log('add-user addNewUser()');

    this.http
      .post(
        this.globals.apiURL +
          `users/addUser?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}&ans=0`,
        JSON.stringify(this.user),
        {
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        }
      )

      .subscribe(
        (response) => {
          console.log(response);
          // this.userService.initUser(this.user);
          //  console.log(this.userService);
          //this.router.navigate(['register-by-steps', 0]);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  addUserToExistsPerson() {
    console.log('add-user addUserToExistsPerson()');

    console.log(this.user);
    this.http
      .post(
        this.globals.apiURL +
          `users/addUser?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}&ans=1`,
        JSON.stringify(this.user),
        {
          // headers: { 'Content-Type': 'application/json;charset=UTF-8' }
        }
      )

      .subscribe(
        (response) => {
          if (this.byShadchan) {
            this.msg =
              'המשתמש נוסף בהצלחה!' +
              '\n' +
              (this.user.email ? 'אמייל עם הסיסמה נשלח למשתמש' : '');
            this.sendEmail(this.user);
          } else {
            this.userService.initUser(response);
            if (this.userService.getIsLocked()) {
              this.router.navigate(['/user-unavailable/locked']);
              return;
            }
            sessionStorage.setItem(
              'currentUser',
              JSON.stringify(this.userService)
            );
            if (this.userService.registerLevel == 3)
              this.router.navigate(['/user-folder/offer/system']);
            else
              this.router.navigate([
                '/register-by-steps',
                this.userService.registerLevel,
              ]);
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  sendEmail(user: User) {
    this.http
      .post(
        this.globals.apiURL +
          `shadchan/get/sendEmail?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        {
          from: '',
          to: user.email,
          subject: 'נוצר משתמש חדש עבורכם: שידוכים כהלכה',
          body:
            '<p>אנו שמחים לעדכן אותך</p>' +
            '<p>שכעת אתם רשומים במערכת שידוכים כהלכה</p>' +
            '<p>באפשרותכם להיכנס ולצפות</p>' +
            `<p>שם משתמש  ${user.email}</p>` +
            ` <p>סיסמה  ${user.password}</p>` +
            `<p> לאישור והמשך ההרשמה לחץ כאן</p>` +
            `<p><a href='[link]/login?user={"email":"${user.email}","password":"${user.password}"}'>להמשך הרשמה</a></p>` +
            '<p>&nbsp;</p>',
        },
        {
          responseType: 'text' as 'json',
          headers: {},
        }
      )
      .toPromise();
  }

  // editEmailToPerson() { console.log(this.idPerson);
  //   this.http.put(this.globals.apiURL +(this.user.gender==1?'man':'woman')+ `/editEmail?id=${this.idPerson}&email=${this.user.email}` , {
  //     headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  //   }).subscribe(response => {
  //     console.log(response);
  //   }, (err: HttpErrorResponse) => {
  //     console.log(err);
  //   });
  // }
}
