//#region imports
import { Component, OnInit, Injectable, ElementRef, ViewChild, Input, TemplateRef } from '@angular/core';
import { Globals } from '../common/globals';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; import { Person, PersonPref, Men, Women, MenPref, WomenPref, Recommend, OffersStatus, Pair,  AlertedPerson, Alerts } from '../entities/entities';
import { Routes, RouterModule, Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router'
import { UserService } from '../common/user.service';
//#endregion

@Component({
  selector: 'app-alerts-user',
  templateUrl: './alerts-user.component.html',
  styleUrls: ['./alerts-user.component.css']
})
export class AlertsUserComponent implements OnInit {
  alert:Alerts=new Alerts();
  state:string;
  validDate:boolean=true;
  today=this.globals.dateToString(new Date());
  addMsg: boolean = false;
  mzlt: boolean = false;
  msg: string;
  gender:number;

  constructor(private http: HttpClient, private globals: Globals, private modalService: BsModalService,
    private router: Router, private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit(): void {
    this.gender=this.userService.getGender();
    this.getAlert();

  }

  getAlert() {
    this.http.get<Alerts>(this.globals.apiURL + `shadchan/get/userAlert?shadchan=${this.userService.getId()}&id=${this.userService.personId}&gender=${this.gender}`+`&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, { responseType: 'json' })
      .subscribe(data => {
        if(data==null){
          this.mzlt=true;
          return;
        }
        this.alert = data;
        console.log(this.alert);
        this.state=this.alert.id==null?'add':'edit';
        console.log(this.state);
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  isFutureDate(std:string) {
    let d=new Date(std);
    if(d==null || !this.globals.isDefined(d))
      return false;
    return d>new Date() || std==this.today;
  }

  ok(id: number){
    this.validDate=this.isFutureDate(this.alert.date);
    if(this.validDate){
      if(this.state=='add'){
        this.alert.idShadchan=this.userService.getId();
        this.alert.idPerson=this.userService.personId;
        this.alert.gender=this.gender;
      }
      this.alert.insertDate=this.today;
      this.http.post(this.globals.apiURL + `shadchan/set/alert`+`?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, JSON.stringify(this.alert), {
        headers: { 'Content-Type': 'application/json' }
       }).subscribe(response => {
         console.log(this.alert);
         console.log(this.state);
            this.alert.id=parseInt(response.toString());
            console.log(this.alert.id);
            this.addMsg=true;
            this.msg="התזכורת נשמרה";
            this.state='edit';
          }, (err: HttpErrorResponse) => {
            console.log(err);
        });
    }
  }

  close(id: number) {
    this.http.post(this.globals.apiURL + `shadchan/set/closeAlert/${id}`+`?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, {})
      .subscribe(response => {
        if(response.toString()=="1"){
          this.addMsg=true;
          this.msg="התזכורת הוסרה";
          this.alert=new Alerts();
          this.state='add';
          this.alert.id=null;
        }
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

}
