<div *ngIf="!onlyShow">
  <p class="pink-color">התמונה תוצג לשדכניות בלבד!</p>
  <input type="file" accept=".jpg, .jpeg"  class="file-input" (change)="addImg($event)" #fileUpload>
  <div>
    <a *ngIf="action=='replace'" [href]="getImgUrl()" target="_blank" title="פתח בלשונית חדשה"
      style="margin-bottom: 15px; display: table;">
        <div *ngIf="showOnLoad" class="showOnLoad d-block mx-auto">
        <div class="d-block mx-auto" style="width: 55px; margin-top: 25px;">
          <app-fulfilling-bouncing-circle-spinner [animationDuration]="1500" [size]="50" [color]="'var(--blue)'">
          </app-fulfilling-bouncing-circle-spinner>
        </div>
      </div>
      <cl-image [public-id]="imgPath" style="height: 102px;">
        <cl-transformation width="133" height="102" crop="thumb" gravity="face">
        </cl-transformation>
      </cl-image>
    </a>
  </div>
  <div class="file-upload d-flex">
    <button class="btn btn-blue" (click)="msg=''; fileUpload.click();">
      <div *ngIf="action=='add'">
        <fa name="plus left-space"></fa>התמונה שלך
      </div>
      <div *ngIf="action=='replace'">
        <fa name="image left-space"></fa>החלפה
      </div>
    </button>&nbsp;
    <button *ngIf="action=='replace'" class="btn btn-danger" (click)="delImg()" style="padding-left: 7px;">
      <fa name="trash left-space"></fa>
    </button>
    <label *ngIf="action=='add'" style="margin: 0px 10px; padding-top: 5px;">{{fileName}}</label>
    <div *ngIf="loadIcon" class="d-block" style="width: 30px; padding-top: 3px; margin-right: 13px;">
      <app-fulfilling-bouncing-circle-spinner [animationDuration]="1500" [size]="30" [color]="'var(--blue)'">
      </app-fulfilling-bouncing-circle-spinner>
    </div>
  </div>
  <p class="pink-color" style="margin-top: 10px;" [ngClass]="{'note':action=='replace'||(action=='add'&&prevImg!=null)}">
    {{msg}}
    <span class="link-top" *ngIf="msg.split(' ').pop()=='על'" (click)="scrollTop()">עדכן פרטים</span>
    <!-- <fa *ngIf="msg.split(' ').pop()=='פרטים.'" name="chevron-up" (click)="scrollTop()"></fa> -->
  </p>
  <!-- <br>
<button class="btn btn-blue" (click)="delAll()">dell all</button>   -->
</div>
<a *ngIf="onlyShow && userImg!=null" [href]="getUserImgUrl()" target="_blank" title="פתח בלשונית חדשה"
  style="margin-bottom: 15px; display: table;">
  <cl-image *ngIf="!isMobile" [public-id]="'pepoleImg/'+ this.userImg+'.jpg'" style="border-radius: 200px;">
    <cl-transformation width="85" height="85" gravity="face" crop="thumb" rounding="max"></cl-transformation>
  </cl-image>
  <cl-image *ngIf="isMobile" [public-id]="'pepoleImg/'+ this.userImg+'.jpg'" style="border-radius: 200px;">
    <cl-transformation width="45" height="45" gravity="face" crop="thumb" rounding="max"></cl-transformation>
  </cl-image>
</a>