<accordion *ngIf="isLoaded | async">
  <accordion-group
    heading="שדכנית ברירת מחדל"
    isOpen="true"
    panelClass="text-info"
  >
    (מטפלת במשתמשים שנרשמו לבד ולאחר זמן מחקו את המשתמש/ המייל שלהם וביקשו לעבור
    לטיפול שדכנית )
    <br />
    <alert
      *ngIf="addMsg2"
      type="info alert-top"
      dismissible="true"
      (onClosed)="addMsg2 = false"
    >
      <p class="text-center">העדכון בוצע בהצלחה!</p>
    </alert>
    <br />
    <select autocomplete="on" class="btn" [(ngModel)]="selected2">
      <option value="0" selected style="color: var(--gray)">
        {{ sDefault.lastName }} {{ sDefault.firstName }}
      </option>
      <option [value]="s.id" *ngFor="let s of shadchanList">
        {{ s.lastName }} {{ s.firstName }}
      </option>
    </select>
    &nbsp;
    <button class="btn btn-blue" (click)="updateSDefault(selected2)">
      עדכן
    </button>
  </accordion-group>

  <accordion-group
    heading="שדכניות שיטפלו בשידוכי מערכת"
    isOpen="true"
    panelClass="text-info"
  >
    <alert
      *ngIf="addMsg1"
      type="info alert-top"
      dismissible="true"
      (onClosed)="addMsg1 = false"
    >
      <p class="text-center">העדכון בוצע בהצלחה!</p>
    </alert>
    <select
      autocomplete="on"
      class="btn"
      [(ngModel)]="selected1"
      placeholder=" הוסף שדכנ/ית"
    >
      <option value="0" selected style="color: var(--gray)">בחר שדכנ/ית</option>
      <option
        [value]="s.id"
        *ngFor="let s of shadchanList | filterSort : shadchanSystemList"
      >
        {{ s.lastName }} {{ s.firstName }}
      </option>
    </select>
    &nbsp;
    <button
      id="btnAdd1"
      class="btn btn-blue"
      [ngClass]="{ 'red-focus': notic }"
      (click)="addShadchan(selected1)"
    >
      הוסף
    </button>
    <br /><br />
    <div *ngFor="let s of shadchanSystemList" class="d-flex">
      <fa
        name="times circled del"
        (click)="delShadchan(s.id)"
        style="margin-left: 20px; cursor: pointer"
      ></fa>
      <p>{{ s.lastName }} {{ s.firstName }}</p>
    </div>
    <br />
    <button
      class="btn btn-blue btn-round d-block btn-ok"
      style="width: 230px"
      (click)="saveShadchanSystemList()"
    >
      <fa name="check left-space"></fa>אישור
    </button>
  </accordion-group>

  <accordion-group
    heading="הוספת שדכניות לצפיה בדוחות "
    isOpen="true"
    panelClass="text-info"
  >
    <alert
      *ngIf="addMsg3"
      type="info alert-top"
      dismissible="true"
      (onClosed)="addMsg3 = false"
    >
      <p class="text-center">העדכון בוצע בהצלחה!</p>
    </alert>
    <select
      autocomplete="on"
      class="btn"
      [(ngModel)]="selected1"
      placeholder=" הוסף שדכנ/ית"
    >
      <option value="0" selected style="color: var(--gray)">בחר שדכנ/ית</option>
      <option
        [value]="s.id"
        *ngFor="let s of shadchanList | filterSort : shadchanReportedList"
      >
        {{ s.lastName }} {{ s.firstName }}
      </option>
    </select>
    &nbsp;
    <button
      id="btnAdd1"
      class="btn btn-blue"
      [ngClass]="{ 'red-focus': notic }"
      (click)="addShadchaToReport(selected1)"
    >
      הוסף
    </button>
    <br /><br />
    <div *ngFor="let s of shadchanReportedList" class="d-flex">
      <fa
        name="times circled del"
        (click)="addShadchaToReport(s.id, false)"
        style="margin-left: 20px; cursor: pointer"
      ></fa>
      <p>{{ s.lastName }} {{ s.firstName }}</p>
    </div>
    <br />
  </accordion-group>
</accordion>
