<!-- <div *ngIf="!noResults && offersType!='search'">
    <app-filter [pairsListRoot]="pairsListRoot" (filterClick)="updatepairsList($event)"></app-filter>
</div>
<app-free-search *ngIf="offersType=='search'" (searchClick)="getpairsList($event)"></app-free-search> -->

<div *ngIf="noResults" class="mx-auto" class="div-noResults">
    <h1 class="text-center pink-color">אין התראות.</h1>
</div>
<div class="offer-box" *ngFor="let pair of pairsList">
    <div class="d-flex" [ngStyle]="{'flex-direction': pair.offer.openedBy==2?'row-reverse':'row'}">
        <div class="man-container d-flex">
            <div class="profile-container">
                <div class="male-img">
                    <app-add-img *ngIf="pair.man != null && pair.man.img!=null" [onlyShow]="true" [userImg]="pair.man.img"></app-add-img>
                </div>               
            </div>
            <div class="inner-container d-flex">
                <div *ngIf="pair.man != null" class="profile-title">
                    {{pair.man.firstName}} {{pair.man.lastName}}, {{pair.man.age}}<br>
                    {{pair.man.religiousLevel}} | {{pair.man.familyStatus}}<br>{{pair.man.city}}
                </div>
                <button (click)="goToUserFolder(pair.man,1,pair.offer.openedBy==2?'receive#p'+pair.woman.id:'profile')" class="btn btn-round btn-blue">
                     {{pair.offer.openedBy==2?'להצעות ששלחו לי':'לפרופיל המלא'}}
                </button>
            </div>
        </div>
        <i class="fa fa-chevron-left" style="-webkit-text-stroke: 3px #fff;font-size: 24pt;margin-top: 55px;"></i>
        <div class="woman-container d-flex">
            <div class="profile-container">
                <div class="female-img">
                    <app-add-img *ngIf="pair.woman != null && pair.woman.img!=null" [onlyShow]="true" [userImg]="pair.woman.img"></app-add-img>
                </div>                
            </div>
            <div class="inner-container d-flex">
                <div *ngIf="pair.woman != null" class="profile-title">
                    {{pair.woman.firstName}} {{pair.woman.lastName}}, {{pair.woman.age}}<br>
                    {{pair.woman.religiousLevel}} | {{pair.woman.familyStatus}}<br>{{pair.woman.city}}
                </div>
                <button (click)="goToUserFolder(pair.woman,2,pair.offer.openedBy==2?'profile':'receive#p'+pair.man.id)" class="btn btn-round btn-blue">
                    {{pair.offer.openedBy==2?'לפרופיל המלא':'להצעות ששלחו לי'}}
                </button>
            </div>
        </div>
    </div>
</div>  