<link
  href="https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700"
  rel="stylesheet"
/>
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
/>
<div class="div-search" (keydown.enter)="search()">
  <div class="row-flex-between" style="justify-content: start">
    <div>
      <table *ngIf="adminStatistics" style="margin-bottom: 100" class="table">
        <thead>
          <tr>
            <th>מספר חסומים</th>
            <th>מספר נשים</th>
            <th>מספר גברים</th>
            <th>מספר שדכניות</th>
            <th>מספר משתמשים</th>
            <th>מספר גברים שהצטרפו היום</th>
            <th>מספר נשים שהצטרפו היום</th>
            <th>מספר הלא רשומים</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ adminStatistics.TotalBlocked }}</td>
            <td>{{ adminStatistics.TotalWomen }}</td>
            <td>{{ adminStatistics.TotalMen }}</td>
            <td>{{ adminStatistics.TotalMatchmakers }}</td>
            <td>{{ adminStatistics.TotalUsers }}</td>
            <td>{{ adminStatistics.TotalMensJoinToday }}</td>
            <td>{{ adminStatistics.TotalWoMensJoinToday }}</td>
            <td>{{ adminStatistics.TotalUnregisterUsers }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w-100" style="height: 5px"></div>

    <h5
      class="text-info text-center"
      style="margin-top: 3px; margin-bottom: 0px"
    >
      <fa name="search left-space"></fa>חיפוש משתמשים
    </h5>

    <div class="flex row-flow-between px-5 mt-1">
      <input
        [defaultChecked]="true"
        type="checkbox"
        [(ngModel)]="usersFilter.managed"
        (change)="changeType()"
      />
      <label class="text-info px-1">רשומים</label>
    </div>

    <div class="w-100" style="height: 20px"></div>

    <div>
      הצג&nbsp;
      <div class="btn-group" dropdown>
        <button dropdownToggle type="button" class="btn dropdown-toggle">
          {{ usersFilter.type }}
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li *ngFor="let t of types" role="menuitem">
            <a class="dropdown-item" (click)="usersFilter.type = t">{{ t }}</a>
          </li>
        </ul>
      </div>
      &nbsp;בלבד
    </div>
    &nbsp;&nbsp;
    <input
      class="form-control"
      type="text"
      [(ngModel)]="usersFilter.firstName"
      placeholder="שם פרטי"
    />
    <input
      class="form-control"
      type="text"
      [(ngModel)]="usersFilter.lastName"
      placeholder="שם משפחה"
    />
    <input
      class="form-control"
      type="text"
      [(ngModel)]="usersFilter.phone"
      placeholder="טלפון"
    />
    <button class="btn btn-blue" (click)="search()">
      <!-- סינון<fa name="filter right-space"></fa> -->
      <fa name="search left-space"></fa>חיפוש
    </button>
    <button class="btn btn-blue" (click)="clear()">
      <!-- סינון<fa name="filter right-space"></fa> -->
      <fa name="times left-space"></fa>ניקוי
    </button>
  </div>
</div>
<!-- ------------------------------------------------------------------------ -->
<div style="margin-left: 20px">
  <div *ngIf="noResults" class="mx-auto" class="div-noResults">
    <h1 class="text-center pink-color">לא נמצאו תוצאות.</h1>
  </div>
  <alert
    *ngIf="addMsg"
    type="info alert-top"
    dismissible="true"
    (onClosed)="addMsg = false"
  >
    <p class="text-center">{{ msg }}</p>
  </alert>
  <table *ngIf="searched && !noResults" class="table table-striped">
    <thead>
      <tr>
        <th>שם משפחה</th>
        <th>שם פרטי</th>
        <th>טלפון</th>
        <th>דוא"ל</th>
        <th *ngIf="usersFilter.managed">חסימת/ שחרור משתמש</th>
        <th
          *ngIf="usersFilter.type.includes('חסומים') || usersFilter.type == ''"
        >
          שדכן?
        </th>
        <th>נוצר</th>
        <th>צפיה בפרופיל</th>
        <th>מחיקת משתמש</th>
        <th *ngIf="usersFilter.managed">שינוי/איפוס ססמת משתמש</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let u of usersList" [ngClass]="{ 'table-info': u.isLocked }">
        <td>{{ u.lastName }}</td>
        <td>{{ u.firstName }}</td>
        <td>{{ usersFilter.managed ? u.phone : u?.tel1 || u?.tel2 }}</td>
        <td>{{ u.email }}</td>
        <td *ngIf="usersFilter.managed">
          <div class="d-flex flex-column">
            <fa
              [name]="u.isLocked ? 'lock' : 'unlock-alt'"
              class="circled"
              (click)="lock(u.id)"
              [tooltip]="u.isLocked ? 'בטל חסימה' : 'חסום משתמש'"
              placement="right"
            ></fa>
            <a
              *ngIf="u.isLocked"
              placement="bottom"
              class="text-info pointer"
              [tooltip]="u.lockedReason"
              >סיבת חסימה?</a
            >
          </div>
        </td>
        <td
          *ngIf="usersFilter.type.includes('חסומים') || usersFilter.type == ''"
        >
          {{ u.isShadchan ? "שדכן" : "" }}
        </td>

        <th>
          <div class="d-flex flex-column" style="width: 200px">
            <div>{{ format(u.createdAt) }}</div>
            <div>{{ getBy(u.id) }}</div>
          </div>
        </th>

        <td>
          <button
            class="btn btn-round btn-blue"
            (click)="goToUserFolder(u, 'user-details')"
          >
            לפרופיל המלא<fa name="address-card right-space"></fa>
          </button>
        </td>
        <td>
          <button class="btn btn-round btn-blue" (click)="removeUser(u.id)">
            מחיקת משתמש<fa name="right-space"></fa>
          </button>
        </td>
        <td *ngIf="usersFilter.managed">
          <div>
            <ul>
              <li>
                <div>
                  <div class="input-icons">
                    <i
                      id="iconPassword_{{ u.id }}"
                      (click)="showHidePassword(u.id)"
                      style="margin-top: 15px"
                      class="fa fa-eye-slash"
                    ></i>
                    <input
                      id="psswd_{{ u.id }}"
                      class="input-field"
                      type="password"
                      [(ngModel)]="u.password"
                      placeholder="{{ u.password }}"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <button
                    class="btn btn-round btn-blue"
                    (click)="updatePassword(u)"
                  >
                    <fa name="left-space"></fa>עדכן ססמא
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
