<div>
  <div>
    <input
      class="form-control"
      placeholder="בחר תאריך"
      bsDatepicker
      #d
      [bsConfig]="{
        containerClass: 'theme-blue',
        startView: 'year',
        dateInputFormat: 'DD/MM/YYYY',
        showWeekNumbers: false,
      }"
      [bsValue]="bsValue"
      (bsValueChange)="ok($event)"
    />
    <fa name="calendar-o" class="btn-calendar" (click)="d.click()"></fa>
  </div>
  <input
    type="text"
    class="form-control"
    [(ngModel)]="dateFull.hebD"
    placeholder="תאריך עברי"
    disabled="disabled"
    style="margin-top: 10px"
  />
</div>
