import {Injectable} from '@angular/core';
import {environment as env} from '../../environments/environment';
import { UserService } from './user.service';

@Injectable()
export class Globals {
  apiURL: string = env.baseUrl;
  mainTitle: string = "";
  ADMIN_ID: number = 123;
  passwordPattern: string=this.apiURL.includes('localhost:8080')?'':'^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9@#%?!&+$]{6,}$';
  isMobile=window.screen.width<=430;
  // passwordPattern: string="^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9@#%?!&+$]{6,}$"; //important!!!! not delete!!!!
  // passwordPattern: string=""; //important!!!! not delete!!!!
  loginFirstName: string= "";
  loginLastName: string= "";

  isDefined(x: any) {
    return (typeof x !== 'undefined');
    // return (typeof x !== 'undefined') && x !== null;
  }

  isEmpty(x: any) {
    if(typeof x=='boolean')
      return false;
    return (x== "" || x== null || !this.isDefined(x));
  }


  public getfirstName()
  {
    return this.loginFirstName;
  }

  public getlastName()
  {
    return this.loginLastName;
  }



  setCurrentUserName(firstName: string , lastName: string){
    this.loginFirstName = firstName;
    this.loginLastName = lastName;
  }




  setCounter(userService: UserService, count:number, tab:string){
    if(count<userService.tabMap[tab]){
      userService.tabMap[tab]=count;
      sessionStorage.setItem('currentUser', JSON.stringify(userService));
    }
  }

  dateToString(date:Date):string{
    return new Date(Date.UTC(date.getFullYear(),date.getMonth(),date.getDate())).toISOString().slice(0,10);
    // (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString();
  }

  timeToString(date:Date):string{
    return (new Date(Date.now() - date.getTimezoneOffset() * 60000)).toISOString().slice(0,16).replace('T',' ');
  }

}
