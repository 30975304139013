<div *ngIf="!isMobile && isLoaded" id="sidebar" class="sidebar">
  <div class="above">
    <div [class]="gender == 'man' ? 'male-img' : 'female-img'">
      <app-add-img
        *ngIf="img != ''"
        [onlyShow]="true"
        [userImg]="img"
      ></app-add-img>
    </div>
    <p><label *ngIf="!isShadchan && !isAdmin">שלום</label> {{ userName }}</p>
    <div style="height: 10px"></div>
  </div>
  <ul>
    <li routerLink="offer/system" routerLinkActive="active">
      הצעות מערכת <fa name="wrench"></fa>
    </li>
    <li routerLink="offer/sent" routerLinkActive="active">
      הצעות ששלחתי
      <span class="badge" *ngIf="tabMap['sent'] > 0">{{ tabMap["sent"] }}</span>
      <fa name="arrow-circle-left"></fa>
    </li>
    <li routerLink="offer/receive" routerLinkActive="active">
      הצעות ששלחו לי
      <span class="badge" *ngIf="tabMap['receive'] > 0">{{
        tabMap["receive"]
      }}</span>
      <fa name="arrow-circle-right"></fa>
    </li>
    <li routerLink="offer/search" routerLinkActive="active">
      חיפוש חופשי <fa name="search"></fa>
    </li>
    <li routerLink="offer/favorites" routerLinkActive="active">
      המועדפים שלי <fa name="star"></fa>
    </li>
    <li routerLink="user-details" routerLinkActive="active">
      הפרטים שלי - צפיה<fa name="eye"></fa>
    </li>
    <li routerLink="register/edit" routerLinkActive="active">
      הפרטים שלי - עריכה<fa name="pencil"></fa>
    </li>
    <li
      *ngIf="isShadchan || isAdmin"
      routerLink="shadchan-notes"
      routerLinkActive="active"
    >
      הערות שדכנ/ית<fa name="file"> </fa>
    </li>
    <li *ngIf="isShadchan" routerLink="alerts-user" routerLinkActive="active">
      תזכורות <fa name="bell"></fa>
    </li>
    <li routerLink="offer/check" routerLinkActive="active">
      הצעות בבירורים<fa name="question"></fa>
    </li>
    <li routerLink="offer/confirm" routerLinkActive="active">
      הצעות שאישרתי<fa name="check"></fa>
    </li>
    <li routerLink="offer/refuse" routerLinkActive="active">
      הצעות ששללתי<fa name="times"></fa>
    </li>
    <li routerLink="offer/finallyNo" routerLinkActive="active">
      בסוף לא התאים<fa name="trash"></fa>
    </li>
    <li
      *ngIf="!isShadchan && !isAdmin"
      routerLink="chat/isPersonChat"
      routerLinkActive="active"
    >
      צאט עם שדכנ/ית<fa name="comments-o"></fa>
      <span class="badge bg-danger chat-badge" *ngIf="chat.unreadAll() > 0">{{
        chat.unreadAll()
      }}</span>
    </li>
    <li
      *ngIf="isShadchan"
      routerLink="/shadchan-folder"
      routerLinkActive="active"
    >
      חזרה לדף שדכן <fa name="backward"> </fa>
    </li>
  </ul>
</div>
<router-outlet *ngIf="!isMobile" class="pull-left"></router-outlet>

<!-- ----------------------------------------------------------------------------------- -->
<div *ngIf="isMobile && isLoaded" id="sidebarMobile" class="sidebar-mobile">
  <div class="above d-flex">
    <div [class]="gender == 'man' ? 'male-img' : 'female-img'">
      <app-add-img
        *ngIf="img != ''"
        [onlyShow]="true"
        [userImg]="img"
      ></app-add-img>
    </div>
    <p><label *ngIf="!isShadchan && !isAdmin">שלום</label> {{ userName }}</p>
  </div>
  <ul class="main-ul d-flex">
    <li routerLink="offer/system" routerLinkActive="active">
      הצעות מערכת
      <!-- <fa name="wrench"></fa> -->
    </li>
    <div class="hr"></div>
    <li routerLink="offer/sent" routerLinkActive="active">
      הצעות ששלחתי
      <span class="badge" *ngIf="tabMap['sent'] > 0">{{ tabMap["sent"] }}</span>
      <!-- <fa name="arrow-circle-left"></fa> -->
    </li>
    <div class="hr"></div>
    <li routerLink="offer/receive" routerLinkActive="active">
      הצעות ששלחו לי
      <span class="badge" *ngIf="tabMap['receive'] > 0">{{
        tabMap["receive"]
      }}</span>
      <!-- <fa name="arrow-circle-right"></fa> -->
    </li>
    <div class="hr"></div>
    <li routerLink="offer/search" routerLinkActive="active">
      <fa name="search"></fa>
    </li>
    <div class="hr"></div>
    <li #btnMenu (click)="isMenuOpen = !isMenuOpen">
      <fa name="bars"></fa>
    </li>
  </ul>
  <div *ngIf="isMenuOpen" #menu (click)="hideMenu()">
    <ul class="menu-nav">
      <li routerLink="offer/favorites" routerLinkActive="active">
        המועדפים שלי <fa name="star"></fa>
      </li>
      <li routerLink="user-details" routerLinkActive="active">
        הפרטים שלי - צפיה<fa name="eye"></fa>
      </li>
      <li
        *ngIf="isYourShadchan"
        routerLink="register/edit"
        routerLinkActive="active"
      >
        הפרטים שלי - עריכה<fa name="pencil"></fa>
      </li>
      <li
        *ngIf="isShadchan || isAdmin"
        routerLink="shadchan-notes"
        routerLinkActive="active"
      >
        הערות שדכנ/ית<fa name="file"> </fa>
      </li>
      <li
        *ngIf="!isYourShadchan"
        routerLink="edit-notes-status"
        routerLinkActive="active"
      >
        עריכה<fa name="pencil"></fa>
      </li>
      <li *ngIf="isShadchan" routerLink="alerts-user" routerLinkActive="active">
        תזכורות <fa name="bell"></fa>
      </li>
      <li routerLink="offer/check" routerLinkActive="active">
        הצעות בבירורים<fa name="question"></fa>
      </li>
      <li routerLink="offer/confirm" routerLinkActive="active">
        הצעות שאישרתי<fa name="check"></fa>
      </li>
      <li routerLink="offer/refuse" routerLinkActive="active">
        הצעות ששללתי<fa name="times"></fa>
      </li>
      <li routerLink="offer/finallyNo" routerLinkActive="active">
        בסוף לא התאים<fa name="trash"></fa>
      </li>
      <li routerLink="/login" routerLinkActive="active" (click)="logout()">
        יציאה<fa name="sign-out"></fa>
      </li>
    </ul>
  </div>
</div>
<router-outlet *ngIf="isMobile"></router-outlet>
