<div class="wrap-alert">
  <alert *ngIf="addMsg" type="info alert-top" dismissible="true" (onClosed)="addMsg=false">
    <p class="text-center">העדכון בוצע בהצלחה!</p>
  </alert>
</div>
<div class="container text-center">
  <label>הערות שדכנ/ית</label>
  <textarea class="form-control notes" rows="3" [(ngModel)]="person['notes']"></textarea>
  <label  class="value-label mr-4 ">{{person.notesInfo}}</label>
</div>
<div class="container text-center">
  <label>הסטטוס שלי</label>&nbsp;&nbsp;
  <div class="btn-group" dropdown>
    <button dropdownToggle type="button" class="btn dropdown-toggle">{{statusLbl[person.status]}}&nbsp;&nbsp;<span
        class="caret"></span></button>
    <ul *dropdownMenu class="dropdown-menu">
      <li><a class="dropdown-item" (click)="changeStatus(1)">פעיל</a></li>
      <li><a class="dropdown-item" (click)="changeStatus(0)">לא פעיל</a></li>
      <li><a class="dropdown-item" (click)="changeStatus(2)">התארסתי</a></li>
    </ul>
  </div>
  <label *ngIf="person.status==2" class="mzlt-label">מזל טוב!!!</label>
</div>
<button class="btn btn-blue d-block mx-auto" (click)="save()">
  <fa name="check left-space"></fa>שמירה
</button>
