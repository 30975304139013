import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Globals } from '../common/globals';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { User } from '../entities/entities';
import {
  Routes,
  RouterModule,
  Router,
  Params,
  ActivatedRoute,
} from '@angular/router';
import { UserService } from '../common/user.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  user: User = new User();
  myform: UntypedFormGroup;
  isExistsUser: boolean = true;
  showPass: boolean = false;
  returnUrl: string = '';
  elementRef: any;
  onClick: any;
  byUrl: boolean;

  constructor(
    private titleService: Title,
    private http: HttpClient,
    private globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    sessionStorage.setItem('isAdmin', 'false');
    this.titleService.setTitle('שידוכים כהלכה');
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
    this.continueIfTokenExists();
    console.log(this.returnUrl);
    sessionStorage.removeItem('currentUser');
    // if(sessionStorage.getItem('currentUser')!=null)
    //   this.router.navigateByUrl(this.returnUrl);
    this.user.isShadchan = false;
    this.byUrl = this.globals.isDefined(this.route.snapshot.queryParams.user);

    if (this.byUrl) {
      this.user = JSON.parse(this.route.snapshot.queryParams.user);
    }
    this.initForm();

    if (this.byUrl) {
      this.submit();
    }
  }

  continueIfTokenExists() {
    let token;
    if (this.returnUrl.includes('&&&')) {
      token = this.returnUrl.split('&&&')[1];
      this.returnUrl = this.returnUrl.split('&&&')[0];
    }

    this.http
      .get<User>(
        this.globals.apiURL + `users/getUserIfTokenExists?token=${token}`,
        {
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        }
      )
      .subscribe(
        (data) => {
          if (data == null) return;
          this.user.email = data.email;
          this.user.password = data.password;
          this.submitToServer();
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          return;
        }
      );
  }

  initForm() {
    this.myform = new UntypedFormGroup({
      email: new UntypedFormControl(this.user.email, [
        Validators.required,
        Validators.pattern(/^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,15}\s*$/),
      ]),
      password: new UntypedFormControl(this.user.password, [
        Validators.required,
        Validators.pattern(this.globals.passwordPattern),
      ]),
    });
  }

  submit() {
    this.isExistsUser = true;
    if (this.myform.valid) {
      this.user = this.myform.value;
      console.log(this.user);
      this.submitToServer();
    }
  }

  submitToServer() {
    console.log('submitToServer login.component');
    this.http
      .get(
        this.globals.apiURL +
          `users/login/` +
          this.user.email.trim() +
          '/' +
          this.user.password,
        {
          headers: {
            'Content-Type': 'text/plain',
            'Access-Control-Allow-Credentials': '*',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .subscribe({
        next: (response) => {
          if (response == null) {
            this.isExistsUser = false;
            this.myform.reset();
          } else {
            sessionStorage.clear();
            console.log('response - after login - ' + response);

            this.userService.initUser(response);
            this.userService.initLoginUser();
            sessionStorage.setItem(
              'loginUser',
              JSON.stringify(this.userService.loginUser)
            );

            this.globals.setCurrentUserName(
              this.userService.firstName,
              this.userService.lastName
            );
            // console.log(this.globals.loginFirstName+"... login ");
            if (this.userService.getIsLocked()) {
              this.router.navigate(['/user-unavailable/locked']);
              return;
            }
            sessionStorage.setItem(
              'currentUser',
              JSON.stringify(this.userService)
            );
            if (this.userService.getIsShadchan()) {
              sessionStorage.setItem(
                'shadchanName',
                `${this.userService.getFirstName()} ${this.userService.getLastName()}`
              );
              sessionStorage.setItem(
                'isSystem',
                this.userService.getIsSystem() + ''
              );
              if (this.returnUrl != '') {
                if (this.returnUrl.includes('user-folder'))
                  this.router.navigate(['/**']);
                else this.router.navigateByUrl(this.returnUrl);
                return;
              }
              this.router.navigate(['/shadchan-folder/offer/search']);
            } else if (this.userService.getId() == this.globals.ADMIN_ID) {
              sessionStorage.setItem('isAdmin', 'true');
              this.router.navigate(['/admin-folder/users-manage']);
            } else if (this.userService.getId() == 3000) {
              sessionStorage.setItem('isMultiAddUser', 'true');
              this.router.navigate(['/add-multi-user-meshudach-folder']);
            } else {
              if (this.userService.personId > 0) {
                if (this.userService.registerLevel == 3) {
                  if (this.returnUrl != '') {
                    if (this.returnUrl.includes('shadchan-folder'))
                      this.router.navigate(['/**']);
                    else this.router.navigateByUrl(this.returnUrl);
                    return;
                  }
                  this.router.navigate(['/user-folder/offer/system']);
                } else {
                  sessionStorage.setItem(
                    'person',
                    //@ts-ignore
                    JSON.stringify(response?.person || {})
                  );
                  sessionStorage.setItem(
                    'preferences',
                    //@ts-ignore
                    JSON.stringify(response?.preferences || {})
                  );
                  this.router.navigate(
                    ['/register-by-steps', this.userService.registerLevel],
                    {
                      queryParams: {
                        //@ts-ignore
                        askIndex: response.person.askIndex,
                      },
                    }
                  );
                }
              } else this.router.navigate(['/register-by-steps', 0]);
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          alert('שגיאת בכניסה, אנא פנה למנהל האתר.');
        },
      });
  }
}
