//#region imports
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { getRandomPassword } from 'src/utils/stringUtils';
import { AddImgComponent } from '../add-img/add-img.component';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import { Answers, AskAnswer, Asks, PrefAsks } from '../entities/AskAnswer';
import {
  DateFull,
  Men,
  MenPref,
  Person,
  PersonPref,
  Recommend,
  User,
  Women,
  WomenPref,
} from '../entities/entities';
import { FamilyStatusAsksComponent } from '../family-status-asks/family-status-asks.component';

import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
//#endregion

//#region @Component
@Component({
  selector: 'app-add-man',
  templateUrl: './add-man.component.html',
  styleUrls: ['./add-man.component.css'],
})
//#endregion
export class AddManComponent implements OnInit {
  createNewUser() {
    this.openModal(this.addUser);
  }
  //#region variables
  modalRef: BsModalRef;
  @ViewChild('addUser') addUser;
  @ViewChild('duplicatePersons') duplicatePersons;

  @ViewChild(FamilyStatusAsksComponent) familyDetails;
  @ViewChild(AddImgComponent) addImg;
  @ViewChild(TabsetComponent) tabset;
  registerMsg: string;
  //canToCreaetUser: boolean;
  //arrays
  arrNum(n: number): any[] {
    return Array(n);
  }

  duplicates: Array<Person | User>;

  asks: Array<Asks>;
  subAsks: Array<Asks>;
  prefAsks: Array<PrefAsks>;
  answers: Array<Answers>;
  answersAll: Array<Answers>;
  recommendList: Array<Recommend> = new Array<Recommend>();
  // otherArr: Array<string>;
  ansHasSub: Array<boolean>;
  formTitle: Array<string>;
  statusLbl: Array<string> = ['לא פעיל', 'פעיל', 'התארסתי'];
  numKidsIrrelevant: Array<string> = [
    'רווקה',
    'רווקה;16',
    ';16',
    '',
    'רווק',
    'רווק;16',
  ];
  isUpdateAll: Array<number> = [0, 0, 0];
  //maps
  ansMap: Map<number, Array<Answers>>;
  ansPrefMap: Map<number, Array<Answers>>;
  asksMap: Map<string, Array<Asks>>;
  asksPrefMap: Map<string, Array<Asks>>;
  asksIdMap: Map<number, Array<Asks>>;
  validationMap: Map<string, boolean> = new Map();
  //primitives
  hasntEmail: boolean = false;
  askfamilyStatus: boolean = false;
  familyStatusFlag: boolean = false;
  validFlag: boolean = true;
  completed: boolean = false;
  ansDelUser: boolean = false;
  imgLoaded: boolean = false;
  imgLoading: boolean = false;
  duplicateEmail: boolean = false;
  duplicateIsraelID: boolean = false;
  isShadchan: boolean = false;
  isAdmin: boolean = false;
  isPersonalShadchan: boolean = false;
  tabsLoaded: Array<boolean> = [false, false, false];
  startFill: boolean = false;
  showDatepicker: boolean = false;
  getAlerts: boolean;
  loadIcon: boolean = false;
  currentTitle: number = 0;
  familyStatusI: number;
  maxNumKids: number;
  countRecommends: number = 2;
  personId: number;
  gender: string;
  otherGender: string;
  Gender: string;
  canByGender: string = 'תוכל';
  userName: string;
  state: string;
  today: string;
  updateAllMsg: Array<string> = [];
  recommendFields = {
    name: 'שם',
    descrip: 'הקשר ביננו',
    phone: 'טלפון',
    phone2: 'טלפון נוסף',
  };
  //entities
  prevPerson: Person;
  person: Person;
  personTemp: Person;
  personPref: PersonPref;
  recommend: Recommend = new Recommend();
  personPrev: Person;
  personPrefPrev: PersonPref;
  recommendListPrev: Array<Recommend> = new Array<Recommend>();
  isMobile = this.globals.isMobile;
  draft: object;
  hasDraft = false;
  fromRecover = false;
  draftMessage = 'שחזר טיוטה';

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router,
    public userService: UserService,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) {}
  //#endregion

  //#region init firstly
  ngOnInit() {
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true';
    this.today = this.globals.dateToString(new Date());
    this.state = this.route.snapshot.params.state;

    this.initUserData();
    this.familyStatusI = 29; //(this.gender == 'man' ? 23 : 25);
    let st = 'מה אני מחפש' + (this.gender == 'woman' ? 'ת' : '');
    this.formTitle = ['הפרטים שלי', 'ממליצים עלי', st, 'סיום'];
    this.callToServices();

    let draftPerson = localStorage.getItem('draftPerson');
    if (draftPerson && this.isShadchan) {
      const draftPerson = JSON.parse(localStorage.getItem('draftPerson'));
      if (draftPerson.gender === this.gender) {
        this.hasDraft = true;
      }
      if (draftPerson.personId) {
        this.draftMessage = 'שחזר משתמש שלא סיימת איתו רישום';
      }
    }
  }

  recoverDraft() {
    const draftPerson = JSON.parse(localStorage.getItem('draftPerson'));
    if (draftPerson) {
      this.hasDraft = false;
      const id = draftPerson.personId;
      if (id) {
        this.personId = id;
        this.userService.personId = id;
        this.getPersonDetails();
        this.state = 'edit';
        this.fromRecover = true;
      } else {
        this.personTemp = draftPerson?.person;
        this.recoverPerson();
      }
    }
  }

  format(str: string) {
    const m = moment(new Date(str));
    if (m.isValid()) {
      return moment(new Date(str)).format('DD/MM/yy HH:mm');
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg',
      backdrop: 'static',
    });
  }

  validateNo(e): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  initUserData() {
    if (this.userService.gender == 1) {
      this.gender = 'man';
      this.otherGender = 'woman';
      this.Gender = 'Men';
      this.person = new Men();
      this.personTemp = new Men();
      this.personPref = new MenPref();
      this.personPrev = new Men();
      this.personPrefPrev = new MenPref();
    } else {
      this.gender = 'woman';
      this.otherGender = 'man';
      this.Gender = 'Women';
      this.person = new Women();
      this.personTemp = new Women();
      this.personPref = new WomenPref();
      this.personPrev = new Women();
      this.personPrefPrev = new WomenPref();
      this.canByGender += 'י';
    }
    this.isShadchan = this.userService.isShadchan;
    this.isPersonalShadchan =
      this.isShadchan && this.userService.permission < 3;
    this.userName = this.userService.firstName;
    this.getAlerts = this.userService.getAlerts;
    if (this.state == 'add') this.personId = this.userService.personId = 0;
    else this.personId = this.userService.personId;
  }

  initKnownUserData() {
    this.personTemp.email = this.userService.email;
    this.personTemp.tel1 = this.userService.phone;
    this.personTemp.firstName = this.userService.firstName;
    this.personTemp.lastName = this.userService.lastName;
    this.personTemp.israelID = this.userService.israelID;
  }

  callToServices() {
    console.log('callToServices add-man.component');

    this.http
      .get<AskAnswer>(
        this.globals.apiURL +
          `actions/getAsks?userIDRequested=${this.userService.firstName}-${this.userService.lastName}`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        this.asks = data.askList.concat(data.subAskList);
        this.asks = this.asks.filter((o) => o.name != 'age');
        this.answers = data.ansList;
        this.prefAsks = data.prefAskList.filter((o) => o[this.gender] == true);
        this.initDependentParams(this.asks, this.answers);
        if (!this.userService.isShadchan) this.initKnownUserData();
        if (this.state == 'edit') this.getPersonDetails();
        else {
          this.recommendList.push(new Recommend());
          this.recommendList.push(new Recommend());
          this.tabsLoaded[1] = true;
        }
      });
  }

  initDependentParams(asks, answers) {
    asks = asks.sort((a, b) => (a.orderAll > b.orderAll ? 1 : -1));
    this.asksIdMap = asks.reduce(function (r, a) {
      r[a.id] = r[a.id] || [];
      r[a.id].push(a);
      return r;
    }, Object.create(null));
    this.asksPrefMap = asks.reduce(function (r, a) {
      r[a.name] = r[a.name] || [];
      r[a.name].push(a);
      return r;
    }, Object.create(null));
    this.answersAll = JSON.parse(JSON.stringify(answers)); //copy arr
    answers = answers.filter((o) => o[this.gender] !== '0');
    asks = asks.filter((o) => o[this.gender] !== '0');
    this.ansHasSub = new Array(answers.length).fill(false);
    this.ansMap = answers.reduce(function (r, a) {
      r[a.idAsk] = r[a.idAsk] || [];
      r[a.idAsk].push(a);
      return r;
    }, Object.create(null));
    this.ansPrefMap = this.answersAll
      .filter((o) => o[this.gender] !== 'אחר')
      .reduce(function (r, a) {
        r[a.idAsk] = r[a.idAsk] || [];
        r[a.idAsk].push(a);
        return r;
      }, Object.create(null));
    this.asksMap = asks.reduce(function (r, a) {
      r[a.name] = r[a.name] || [];
      r[a.name].push(a);
      return r;
    }, Object.create(null));

    if (!this.isShadchan && this.state != 'edit')
      asks = asks.filter((o) => o['name'] != 'email');
    for (let ask of asks) this.personTemp[ask.name] = '';
    this.asks = asks;
  }
  //#endregion

  //#region functions for html
  forShadchan() {
    this.isShadchan = true;
  }

  ifCurrentValid(ask: Asks) {
    //for remove invalid class online when touched
    if (ask.typeInput == 3 || ask.typeInput == 6 || ask.typeInput == 7)
      return true;
    else if (this.personTemp[ask.name].trim() == '') return true;
    return false;
  }

  emptyEmail() {
    setTimeout(() => {
      if (!this.hasntEmail) return;
      if (this.state == 'add') this.personTemp['email'] = '';
      else if (
        confirm(
          "מחיקת המייל תגרום למחיקת המשתמש שלך, \nלא תוכל לקבל התראות ולא להכנס למערכת. \nהאם למחוק את המשתמש שלך?\n(א''כ לחץ בסיום על עדכון פרטים)"
        )
      ) {
        this.ansDelUser = confirm(
          'האם ברצונך ששדכנית תטפל בהצעות שלך?\n(אם לא לחץ על ביטול, ואז תהפוך ללא פעיל)'
        );
        if (this.ansDelUser) this.personTemp['email'] = '';
        else this.personTemp.status = 0;
      } else this.hasntEmail = false;
    });
  }

  changeStatus(s: number) {
    if (confirm('האם הנך בטוח/ה שברצונך לשנות סטטוס?'))
      this.personTemp.status = s;
  }

  initAns(answer: string, ask: Asks) {
    for (let ans of this.ansMap[ask.id]) this.ansHasSub[ans.id] = false;
    let ansNamesArr = answer.split(';');

    for (let ans of ansNamesArr) {
      const profession = this.asks.find(
        (a) => (a.id == 61 && ans === '7') || (a.id == 67 && ans === '8')
      );
      if (profession) {
        if (this.personTemp['workLearn'].split(';').includes('20')) {
          //this.ansHasSub[ans] = true;
          profession.hidden = false;
        } else {
          profession.hidden = true;
        }
      }
      this.ansHasSub[ans] = true;
    }
    this.treatFamilyStatusFlag(answer, ask);
  }

  updateAns(answer: string, ask: Asks, i: number) {
    for (let ans of this.ansMap[ask.id]) this.ansHasSub[ans.id] = false;
    let ansNamesArr = answer.split(';');

    for (let ans of ansNamesArr) {
      const profession = this.asks.find(
        (a) => (a.id == 61 && ans === '7') || (a.id == 67 && ans === '8')
      );
      if (profession) {
        if (this.personTemp['workLearn'].split(';').includes('20')) {
          //this.ansHasSub[ans] = true;
          profession.hidden = false;
        } else {
          profession.hidden = true;
        }
      }
      this.ansHasSub[ans] = true;
    }

    //empty sub that irrelevant now
    for (let ans of this.ansMap[ask.id]) {
      let subs = this.asks.filter(({ idParentAns }) => ans.id == idParentAns);
      for (let sub of subs)
        if (!this.ansHasSub[sub.idParentAns]) this.personTemp[sub.name] = '';
    }
    this.treatFamilyStatusFlag(answer, ask);

    this.bluerDartPerson();
  }

  treatFamilyStatusFlag(answer: string, ask: Asks) {
    if (ask.name == 'familyStatus' && (answer == '17' || answer == '18')) {
      this.personTemp['familyStatus'] = answer;
      this.familyStatusFlag = true;
    } else if (answer == '16') this.familyStatusFlag = false;
  }

  addMultiAns(checkedAns: string, askName: string) {
    if (this.personTemp[askName].includes(checkedAns))
      this.personTemp[askName] = this.personTemp[askName].replace(
        ';' + checkedAns,
        ''
      );
    else this.personTemp[askName] = this.personTemp[askName] + ';' + checkedAns;
  }

  addMultiPref(checkedAns: string, askName: string) {
    if (checkedAns == ';0') this.personPref[askName] = checkedAns;
    else if (checkedAns != ';0' && this.personPref[askName] == ';0')
      this.personPref[askName] = this.personPref[askName].replace(
        ';0',
        ';' + checkedAns
      );
    else if (this.personPref[askName].includes(checkedAns))
      this.personPref[askName] = this.personPref[askName].replace(
        ';' + checkedAns,
        ''
      );
    else this.personPref[askName] = this.personPref[askName] + ';' + checkedAns;
  }

  setImgLoading() {
    this.imgLoading = true;
  }

  setImgLoaded() {
    this.imgLoading = false;
    this.imgLoaded = true;
    this.bluerDartPerson();
  }

  addRecommend() {
    this.countRecommends++;
    this.recommendList.push(new Recommend());
  }

  goToNext() {
    if (this.state == 'add') {
      this.currentTitle++;
      if (this.currentTitle <= 3) {
        this.tabset.tabs[this.currentTitle].active = true;
        window.scroll(0, 0);
        console.log(this.personPref);
        if (this.currentTitle == 3) this.addUserToExistsPerson();
        if (this.currentTitle == 2 && this.checkPrefValidation())
          this.getPref();
        if (this.currentTitle == 1 && this.checkValidation())
          this.getPersonDetails();
      }
    }
  }

  tryToSwitch(e, desiredTab) {
    if (this.state == 'add') {
      if (desiredTab > this.currentTitle) {
        e.stopPropagation();
        document
          .querySelector(`#btnSave${this.currentTitle}`)
          .scrollIntoView({ behavior: 'smooth', block: 'start' });
        document.getElementById(`btnSave${this.currentTitle}`).focus();
      } else if (desiredTab == 2) this.getPref();
      else if (desiredTab == 0) this.validFlag = true;
    } else {
      //state='edit'
      if (!this.tabsLoaded[desiredTab]) {
        if (desiredTab == 1) this.getRecommends();
        else this.getPref();
        // else{
        //   if(this.checkPrefValidation())
        //     this.getPref();
        //   else
        //     this.tabsLoaded[2] = true;
        //     // this.personPref=this.gender=="man"? new MenPref():new WomenPref();
        // }
      }
    }
  }
  //#endregion

  //#region validate func
  getAge(birthdate: string) {
    return new Date().getFullYear() - parseInt(birthdate.split('-')[0]);
  }

  bluerDartPerson() {
    if (this.personId || !this.isShadchan) {
      return;
    }
    console.log('update draft');
    this.hasDraft = false;
    this.draft = {
      person: this.personTemp,
      gender: this.gender,
    };
    localStorage.setItem('draftPerson', JSON.stringify(this.draft));
  }

  blurTel1($event: any) {
    this.bluerDartPerson();
    if (/0[0-9]{8,9}$/.test(this.personTemp['tel1']?.trim())) {
      this.checkDuplicatePerson();
    }
  }

  blurEmail(e: Event) {
    this.bluerDartPerson();
    if (
      !this.hasntEmail &&
      this.validateEmail(this.personTemp['email']?.trim())
    ) {
      this.checkDuplicatePerson();
    }
  }

  checkDuplicatePerson() {
    const sameTel1 = this?.prevPerson?.tel1 === this.personTemp?.tel1?.trim();
    const sameEmail =
      this?.prevPerson?.email === this.personTemp?.email?.trim();

    forkJoin({
      persons: this.http.get<Person[]>(
        this.globals.apiURL +
          `${this.gender}/isDuplicateEmailPhone?email=${
            (!sameEmail && this.personTemp?.email?.trim?.()) || 'none'
          }&phone=${(!sameTel1 && this.personTemp?.tel1?.trim?.()) || 'none'}`,
        { responseType: 'json' }
      ),
      users: this.http.get<User[]>(
        this.globals.apiURL +
          `users/isDuplicateEmailPhone?email=${
            (!sameEmail && this.personTemp?.email?.trim?.()) || 'none'
          }&phone=${(!sameTel1 && this.personTemp?.tel1?.trim?.()) || 'none'}`,
        { responseType: 'json' }
      ),
    }).subscribe(({ persons, users }) => {
      this.duplicates = [];
      if (persons.length) {
        this.duplicates.push(...persons);
      }
      if (users.length) {
        this.duplicates.push(
          ...users
            .map((u) => {
              return { ...u, isUser: true };
            })
            .filter((u) => {
              return !persons.map((p) => p.idUser).includes(u.id);
            })
        );
      }
      if (this.duplicates.length) {
        if (this.isAdmin || this.isShadchan) {
          this.openModal(this.duplicatePersons);
        } else {
          if (sameEmail) {
            alert('טלפון זה כבר קיים במערכת');
          } else if (sameTel1) {
            alert(' אמייל זה כבר קיים במערכת');
          } else {
            alert('טלפון ו אמייל זה כבר קיימים במערכת');
          }
        }
      }
    });
  }

  checkValidation(): boolean {
    this.personTemp.tel1 = this.personTemp.tel1.replace(/-/g, '');
    this.personTemp.tel2 = this.personTemp.tel2.replace(/-/g, '');
    this.personTemp.email =
      this.personTemp['email'] == undefined
        ? ''
        : this.personTemp['email'].toLowerCase();
    let height: string = this.personTemp['height'] + '';
    if (height.indexOf('.') == -1)
      this.personTemp['height'] = parseFloat(
        height.substring(0, 1) + '.' + height.substring(1)
      );
    this.validationMap = new Map();
    let requiredNames: Array<string> = this.asks
      .filter(({ required }) => required)
      .map((o) => o.name);
    for (let name of requiredNames) {
      if ((this.state == 'edit' && name != 'israelID') || this.state == 'add') {
        this.validationMap[name] =
          this.globals.isDefined(this.personTemp[name]) &&
          ('' + this.personTemp[name]).trim() != '';
      }
    }
    this.validationMap['tel1'] =
      this.validationMap['tel1'] &&
      /0[0-9]{8,9}$/.test(this.personTemp['tel1']);

    this.validationMap['birthdate'] =
      this.validationMap['birthdate'] &&
      this.getAge(this.personTemp['birthdate']) < 100 &&
      this.getAge(this.personTemp['birthdate']) > 15;
    this.validationMap['height'] =
      this.validationMap['height'] &&
      !isNaN(this.personTemp['height']) &&
      this.personTemp['height'] > 0.5 &&
      this.personTemp['height'] < 2.5;

    if (!this.hasntEmail)
      this.validationMap['email'] = this.validateEmail(
        this.personTemp['email']
      );
    else this.validationMap['email'] = true;

    if (this.state == 'add')
      this.validationMap['israelID'] =
        this.validationMap['israelID'] &&
        /^\d{9}$/.test(this.personTemp['israelID']);

    for (let key in this.validationMap)
      if (!this.validationMap[key]) {
        console.log(key);
        return false;
      }

    return true;
  }

  checkPrefValidation(): boolean {
    // console.log(this.personPref);
    this.validationMap = new Map();
    let requiredNames: Array<string> = this.prefAsks.map((o) => o.name);
    for (let name of requiredNames)
      this.validationMap[name] =
        this.personPref[name] != '' && this.personPref[name] != null;
    this.validationMap['minAge'] =
      !isNaN(this.personPref['minAge']) &&
      this.personPref['minAge'] < 90 &&
      this.personPref['minAge'] > 15;
    this.validationMap['maxAge'] =
      !isNaN(this.personPref['maxAge']) &&
      this.personPref['maxAge'] < 90 &&
      this.personPref['maxAge'] > this.personPref['minAge'];
    if (![';16', 'רווק', 'רווקה'].includes(this.personPref['familyStatus']))
      this.validationMap['numKids'] =
        this.personPref['numKids'] != null &&
        !isNaN(this.personPref['numKids']);
    for (let key in this.validationMap)
      if (!this.validationMap[key]) return false;
    return true;
  }

  validateEmail(email): boolean {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  //#endregion

  //#region saveData
  setBirthdate(date: DateFull) {
    this.personTemp.birthdate = date.d;
    this.personTemp.birthdateHeb = date.hebD;
    this.bluerDartPerson();
  }

  hasChanges() {
    if (this.state == 'edit') {
      //if something changed
      if (JSON.stringify(this.personPrev) === JSON.stringify(this.personTemp)) {
        if (this.hasntEmail && this.personTemp.email != '') return true;
        if (
          !this.hasntEmail &&
          this.userService.getGetAlerts() != this.getAlerts
        )
          return true;
        if (this.personTemp['img'] != this.addImg.getImg()) return true;
        return false;
      }
      return true;
    }
    if (this.state == 'add')
      //if you started but not finished
      // return !(Object.values(this.personTemp).every(x => (x === null || x === ''))) && !this.completed;
      return (
        (!Object.values(this.personTemp).every((x) => x === null || x === '') ||
          this.personTemp['img'] != this.addImg.getImg()) &&
        !this.completed
      );
  }

  saveMen() {
    return new Promise((resolve, reject) => {
      if (!this.hasChanges()) {
        resolve(true);
        this.goToNext();
        console.log('לא בוצעו שינויים בפרטים אישיים');
        this.isUpdateAll[0] = 0;
        if (
          !this.hasntEmail &&
          this.userService.getGetAlerts() != this.getAlerts
        ) {
          console.log('התראות עודכנו');
          this.editAlerts();
          this.isUpdateAll[0] = 1;
        }
        return;
      }

      this.duplicateEmail = false;
      this.duplicateIsraelID = false;
      this.validFlag = this.checkValidation();
      if (this.validFlag) {
        let a: Asks;
        this.personTemp['img'] = this.addImg.getImg();
        this.person = JSON.parse(JSON.stringify(this.personTemp)); //copy
        for (let i in this.person) {
          if (
            i == 'familyStatus' &&
            parseInt(this.person[i]) > 16 &&
            this.state == 'add'
          ) {
            this.person['familyStatusDetails'] = this.familyDetails
              .getFamilyStatusDetails()
              .trim();
            this.person['numKids'] = this.familyDetails.getNumKids();
            if (this.person['numKids'] == null) {
              this.validFlag = false;
              window.scroll(0, 0);
              return;
            }
          }
          if (i == 'familyStatusDetails' || i == 'numKids') continue;
          else if (this.globals.isDefined(this.asksMap[i])) {
            let ansIdArr: Array<string>, ansNamesArr: Array<string>;
            a = this.asksMap[i][0];
            if (
              a.typeInput < 3 &&
              (/^\d+$/.test(this.person[i]) || this.person[i].includes(';'))
            ) {
              ansIdArr = this.person[i].split(';');
              ansNamesArr = this.answersAll
                .filter(({ id }) => ansIdArr.includes(id + ''))
                .map((o) => o[this.gender]);
              this.person[i] = ansNamesArr.join(';');
            } else if (i != 'moneySum' && this.person[i] != null)
              this.person[i] = ('' + this.person[i]).trim();
          }
        }

        if (this.userService.personId > 0) {
          console.log('go to edit');
          this.editMen().then(() => resolve(true));
        } else {
          console.log('go to init');
          this.initMen();
          console.log(this.person);

          this.http
            .post(
              this.globals.apiURL +
                `actions/add?className=db.${this.Gender}&userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
              JSON.stringify(this.person),
              {
                headers: { 'Content-Type': 'application/json' },
              }
            )
            .subscribe(
              (response) => {
                resolve(true);
                this.person.id =
                  this.personId =
                  this.userService.personId =
                    parseInt(response.toString());
                // duplicateEmail
                if (this.personId == 0) {
                  this.setDuplicateEmail();
                  return;
                }
                if (this.personId == -2) {
                  this.setDuplicateIsraelID();
                  return;
                }
                console.log('added, id person: ' + response.toString());
                this.isUpdateAll[0] = 1;
                this.personPrev = JSON.parse(JSON.stringify(this.personTemp));
                if (!this.isShadchan) {
                  this.getAlerts = !this.hasntEmail;
                  this.editAlerts();
                } else {
                  localStorage.setItem(
                    'draftPerson',
                    JSON.stringify({
                      gender: this.gender,
                      personId: this.personId,
                    })
                  );
                }

                this.goToNext();
              },
              (err: HttpErrorResponse) => {
                console.log(err);
                this.isUpdateAll[0] = 3;
                resolve(true);
              }
            );
        }

        this.addImg.delPrevImgIfNeed();
        this.addImg.updateUserImg();
      } //end of validFlag
      else {
        this.isUpdateAll[0] = 2;
        resolve(true);
      }
      window.scroll(0, 0);
    });
  }

  initMen(): void {
    //man.image=fd or send it like class type
    this.userService = JSON.parse(sessionStorage.getItem('currentUser'));
    this.person.phoneShadchan = this.isShadchan
      ? sessionStorage.getItem('shadchanName') + ' - ' + this.userService.phone
      : '0722733744';
    this.person.relatedShadchanID = this.userService.isShadchan
      ? this.userService.personId
      : 99999999;
    this.person.payModel = this.isShadchan ? 'דמי שידוך' : 'חינם';
    this.person.joinDate = this.today;
    this.person.lastEditDate = this.today;
    this.person.status = 1;
    this.person.permission =
      this.userService.permission == 1 || this.userService.permission == 2
        ? this.userService.permission
        : 0;
    if (!this.globals.isDefined(this.person['numKids']))
      this.person.numKids = null;
    this.person.insertedBy = this.userService.id;
    this.person.idUser = this.isShadchan ? 0 : this.person['insertedBy'];
    if (this.gender == 'man' && !this.globals.isDefined(this.person['smoker']))
      this.person['smoker'] = false; //not need because its must, remove after do it
    if (!this.globals.isDefined(this.person['familyStatusDetails']))
      this.person['familyStatusDetails'] = '';
  }

  editAlerts() {
    this.http
      .put(
        this.globals.apiURL +
          `users/setAlerts?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}&id=${this.userService.id}&getAlerts=${this.getAlerts}`,
        {}
      )
      .subscribe(
        (response) => {
          console.log(
            response.toString() == '1'
              ? 'קבלת התראות לא עודכן'
              : 'קבלת התראות עודכן'
          );
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.isUpdateAll[0] = 3;
        }
      );
    this.userService.getAlerts = this.getAlerts;
  }

  setDuplicateEmail() {
    this.validFlag = this.validationMap['email'] = false;
    this.duplicateEmail = true;
    this.isUpdateAll[0] = 2;
    window.scroll(0, 0);
  }

  setDuplicateIsraelID() {
    this.validFlag = this.validationMap['israelID'] = false;
    this.duplicateIsraelID = true;
    this.isUpdateAll[0] = 2;
    window.scroll(0, 0);
  }

  editMen() {
    if (this.state == 'edit') {
      this.person['joinDate'] = this.person['joinDate'].slice(0, 10);
      this.person['lastEditDate'] = this.today;
    }
    if (
      this.state == 'edit' &&
      !this.isShadchan &&
      !this.hasntEmail &&
      this.userService.getGetAlerts() != this.getAlerts
    )
      this.editAlerts();
    if (!this.globals.isDefined(this.person['numKids']))
      this.person['numKids'] = null;
    if (!this.globals.isDefined(this.person['familyStatusDetails']))
      this.person['familyStatusDetails'] = '';
    this.person['id'] = this.userService.personId;
    if (this.state != 'edit') this.initMen();
    return new Promise((resolve, reject) => {
      this.http
        .put(
          this.globals.apiURL +
            `actions/edit?className=db.${this.Gender}&userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
          JSON.stringify(this.person),
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .subscribe(
          (response) => {
            // duplicateEmail
            if (response?.toString?.() == '0') {
              this.setDuplicateEmail();
              return;
            }
            resolve(true);
            console.log('edited, id person: ' + response?.toString());
            this.isUpdateAll[0] = 1;
            this.emailChecks().then(() => {
              this.personPrev = JSON.parse(JSON.stringify(this.personTemp)); //person
              this.prevPerson = { ...this.personTemp };
              this.goToNext();
            });
          },
          (err: HttpErrorResponse) => {
            console.log(err);
            this.isUpdateAll[0] = 3;
            resolve(true);
          }
        );
      this.userService.getAlerts = this.getAlerts;
    });
  }

  emailChecks() {
    console.log(this.hasntEmail);
    console.log(this.personPrev.email);
    return new Promise((resolve, reject) => {
      if (this.personPrev.email == '' && this.person.email != '') {
        //add
        this.openModal(this.addUser);
        resolve(true);
      } else if (this.personPrev.email != '' && this.hasntEmail) {
        //del
        if (this.state == 'add') {
          alert(
            'לא ניתן למחוק את המשתמש שלך תוך כדי הוספה. \n ניתן למחוק בתום ההוספה בעמוד עריכת פרטים.'
          );
          resolve(true);
          return;
        }
        let yourShadchan =
          this.person.idUser == this.person.insertedBy
            ? 'שדכנית מהמערכת'
            : 'השדכנית שרשמה אותך';
        this.http
          .post(
            this.globals.apiURL +
              `users/delete?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}&ans=${this.ansDelUser}&id=${this.person.idUser}&justUser=true`,
            {
              headers: { 'Content-Type': 'application/json' },
            }
          )
          .subscribe(
            (response) => {
              resolve(true);
              console.log('deleted: ' + response.toString());
              alert(
                this.ansDelUser
                  ? 'המשתמש שלך נמחק בהצלחה.\nמעתה השידוכים שלך יטופלו ע"י ' +
                      yourShadchan
                  : 'המשתמש שלך עבר לסטטוס לא פעיל.'
              );
            },
            (err: HttpErrorResponse) => {
              console.log(err);
            }
          );
      } else if (this.personPrev.email != this.person.email) {
        //change
        alert(
          this.gender == 'man'
            ? 'שים לב! שינוי כתובת המייל משפיע גם על המייל איתו אתה נכנס למערכת.'
            : 'שימי לב! שינוי כתובת המייל משפיע גם על המייל איתו את נכנסת למערכת.'
        );

        this.http
          .get<User>(
            this.globals.apiURL + `users/findById/${this.person.idUser}`
          )
          .subscribe((user: User) => {
            this.sendEmail(
              user,
              'כתובת המייל עודכנה: שידוכים כהלכה',
              '<p>כתובת המייל שלכם עודכנה לכניסה למערכת</p>' +
                '<p>באפשרותכם להיכנס למערכת </p>' +
                '<p>לינק למערכת [link]</p>' +
                `<p>שם משתמש</p>` +
                `<p>${user.email}</p>` +
                ` <p>סיסמה</p>` +
                ` <p>${user.password}</p>` +
                '<p>&nbsp;</p>'
            );
          });

        resolve(true);
      } else resolve(true);
    });
  }

  savePrefAsks() {
    return new Promise((resolve, reject) => {
      if (
        this.state == 'edit' &&
        JSON.stringify(this.personPrefPrev) === JSON.stringify(this.personPref)
      ) {
        resolve(true);
        console.log('לא בוצעו שינויים בהעדפות');
        this.isUpdateAll[2] = 0;
        this.goToNext();
        return;
      }
      let a: Asks;
      let unchangedPref = ['minAge', 'maxAge', 'numKids', 'idPrefer'];
      if (this.gender == 'woman') unchangedPref.push('smoker');
      this.validFlag = this.checkPrefValidation();
      if (this.validFlag) {
        for (let i in this.personPref) {
          if (this.personPref[i] == ';0') this.personPref[i] = 'לא משנה';
          else if (!unchangedPref.includes(i)) {
            // console.log(i);
            if (this.asksPrefMap[i] == undefined) continue;
            a = this.asksPrefMap[i][0];
            let ansIdArr: Array<string>, ansNamesArr: Array<string>;
            ansIdArr = this.personPref[i].split(';');
            ansNamesArr = this.answersAll
              .filter(({ id }) => ansIdArr.includes(id + ''))
              .map((o) => o[this.otherGender]);
            this.personPref[i] = ansNamesArr.join(';');
          } else if (i == 'numKids') this.maxNumKids = null;
          if (this.personPref['familyStatus'] == ';16')
            this.personPref['numKids'] = null;
        }
        if (this.globals.isDefined(this.personPref['idPrefer'])) {
          this.editPref().then(() => resolve(true));
          return;
        }
        this.personPref['idPrefer'] = this.userService.personId;
        this.http
          .post(
            this.globals.apiURL +
              `actions/add?className=db.${this.Gender}Preferences&userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
            JSON.stringify(this.personPref),
            {
              headers: { 'Content-Type': 'application/json' },
            }
          )
          .subscribe(
            (response) => {
              resolve(true);
              console.log('added, idPrefer: ' + response.toString());
              this.isUpdateAll[2] = 1;
              this.personPrefPrev = JSON.parse(JSON.stringify(this.personPref));
              if (this.fromRecover) {
                localStorage.removeItem('draftPerson');
                this.currentTitle = 3;
                this.state = 'add';
                this.person = { ...this.personTemp };
                this.addUserToExistsPerson();
                return;
              }
              this.goToNext();
            },
            (err: HttpErrorResponse) => {
              resolve(true);
              console.log(err);
              this.isUpdateAll[2] = 3;
            }
          );
      } else {
        this.isUpdateAll[2] = 2;
        resolve(true);
      }
    });
  }

  addUserToExistsPerson() {
    if (this.isShadchan && !this.hasntEmail) {
      const user: User = this.generateUser();
      user.password = getRandomPassword();
      user.isShadchan = false;
      this.http
        .post(
          this.globals.apiURL +
            `users/addUser?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}&ans=1`,
          JSON.stringify(user),
          {}
        )
        .pipe(
          tap({
            next: async (val) => {
              await this.sendEmail(
                user,
                'נוצר משתמש חדש עבורכם: שידוכים כהלכה',
                '<p>אנו שמחים לעדכן אותך</p>' +
                  '<p>שכעת אתם רשומים במערכת שידוכים כהלכה</p>' +
                  '<p>באפשרותכם להיכנס ולצפות</p>' +
                  '<p>לינק למערכת [link]</p>' +
                  `<p>שם משתמש  ${user.email}</p>` +
                  ` <p>סיסמה  ${user.password}</p>` +
                  '<p>&nbsp;</p>'
              );
            },
            error: (err) => {
              console.log(err);
            },
            complete: () => console.log('on complete'),
          })
        )
        .subscribe(
          (response) => {
            console.log(response);
            this.registerMsg =
              ' המשתמש נוסף בהצלחה!' +
              '\n ' +
              (user.email ? 'אמייל עם הסיסמה נשלח למשתמש' : '');
          },
          (err: HttpErrorResponse) => {
            this.registerMsg = 'כישלון ביצירת המשתמש - פנה למנהל מערכת';
            console.log(err);
          }
        );
    }
  }

  sendEmail(user: User, subject: string, body: string) {
    this.http
      .post(
        this.globals.apiURL +
          `shadchan/get/sendEmail?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        {
          from: '',
          to: user.email,
          subject,
          body,
        },
        {
          responseType: 'text' as 'json',
          headers: {},
        }
      )
      .toPromise();
  }

  editPref() {
    return new Promise((resolve, reject) => {
      this.http
        .put(
          this.globals.apiURL +
            `actions/edit?className=db.${this.Gender}Preferences&userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
          JSON.stringify(this.personPref),
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .subscribe(
          (response) => {
            resolve(true);
            console.log('edited, idPrefer: ' + response.toString());
            this.isUpdateAll[2] = 1;
            this.personPrefPrev = JSON.parse(JSON.stringify(this.personPref));
            this.goToNext();
          },
          (err: HttpErrorResponse) => {
            console.log(err);
            this.isUpdateAll[2] = 3;
            resolve(true);
          }
        );
    });
  }

  saveRecommends() {
    return new Promise((resolve, reject) => {
      if (
        JSON.stringify(this.recommendListPrev) ===
        JSON.stringify(this.recommendList)
      ) {
        resolve(true);
        console.log('לא בוצעו שינויים בממליצים');
        this.isUpdateAll[1] = 0;
        this.goToNext();
        return;
      }
      let recommendFlag: boolean = false;
      this.recommendList.forEach((r, i) => {
        if (
          this.globals.isDefined(r.phone) &&
          /0[0-9]{8,9}$/.test(r.phone.replace(/-/g, ''))
        ) {
          r.idWhom = this.userService.personId;
          r.whomGender = this.userService.gender;
          recommendFlag = true;
        } else {
          this.recommendList.splice(i, 1);
          this.countRecommends--;
        }
      });

      if (recommendFlag) {
        this.http
          .post(
            this.globals.apiURL +
              `actions/add?className=RecommendsList&userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
            JSON.stringify(this.recommendList),
            {
              headers: { 'Content-Type': 'application/json' },
            }
          )
          .subscribe(
            (response) => {
              resolve(true);
              console.log('done, idWhomRecomended: ' + response.toString());
              this.isUpdateAll[1] = 1;
              this.recommendListPrev = JSON.parse(
                JSON.stringify(this.recommendList)
              );
            },
            (err: HttpErrorResponse) => {
              console.log(err);
              this.isUpdateAll[1] = 3;
              resolve(true);
            }
          );
      } else resolve(true);
      this.goToNext();
    });
  }

  delRecommend(i: number) {
    this.countRecommends--;
    this.recommendList.splice(i, 1);
  }

  updateAll() {
    this.loadIcon = true;
    this.updateAllMsg = [];
    this.saveMen()
      .then(() => this.saveRecommends())
      .then(() => this.savePrefAsks())
      .then(() => {
        let names = ['פרטיך האישיים', 'פרטי הממליצים', 'ההעדפות שלך'];
        let results = [
          'עודכנו בהצלחה',
          'לא עודכנו עקב פרטים חסרים/ לא תקינים',
          'לא עודכנו עקב תקלה טכנית, אנא נסו מאוחר יותר',
        ];
        this.loadIcon = false;
        if (
          this.isUpdateAll[0] == 1 &&
          this.isUpdateAll[1] == 1 &&
          this.isUpdateAll[2] == 1
        )
          this.updateAllMsg[3] = 'כל הפרטים עודכנו בהצלחה!';
        else
          for (let i in this.isUpdateAll)
            if (this.globals.isDefined(results[this.isUpdateAll[i] - 1]))
              this.updateAllMsg[i] =
                names[i] + ' ' + results[this.isUpdateAll[i] - 1];
        if (this.isUpdateAll[2] == 1) this.getPref();
      });
  }

  goToUserFolder(p: any, type: string) {
    let u: UserService = new UserService(this.globals);
    u.setLoginFirstName(u.getFirstName());
    u.setLoginLastName(u.getLastName());
    u.setFirstName(p.firstName);
    u.setLastName(p.lastName);

    if (p.isUser) {
      this.http
        .get<number>(
          this.globals.apiURL +
            `users/getPersonId/${p.id}/${p.gender}?userIDRequested=${u.id}-${u.loginFirstName}-${u.loginLastName}`
        )
        .subscribe((data) => {
          let personId = data;
          u.personId = personId;
          sessionStorage.setItem('currentUser', JSON.stringify(u));
          // console.log(sessionStorage.getItem('currentUser'));
          let url = this.router.serializeUrl(
            this.router.createUrlTree([`user-folder/user-details`])
          );
          window.open('/#' + url, '_blank');
        }); //add throwError
      return;
    }

    u.setGender('zakan' in p ? 1 : 2);
    u.personId = p.id;
    sessionStorage.setItem('currentUser', JSON.stringify(u));
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`user-folder/${type}`], {
        queryParams: {
          params1: this.userService.loginFirstName,
          params2: this.userService.loginLastName,
        },
      })
    );

    //const url = this.router.serializeUrl(this.router.createUrlTree([`user-folder/${type}`]));
    // this.router.navigateByUrl(url);
    window.open('/#' + url, '_blank');
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
  }

  // saveShadchanSetting() {
  //   this.userService.setLoginFirstName(this.userService.firstName);
  //   this.userService.setLoginLastName(this.userService.lastName);
  //   this.userService.setFirstName(this.personTemp.firstName);
  //   this.userService.setLastName(this.personTemp.lastName);
  //   this.editMen();
  //   this.completed = true;
  //   sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
  // }

  complete() {
    this.completed = true;
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
  }

  //#endregion
  //#region state=edit
  treatContactDetails(person: Person) {
    // if (
    //   this.isShadchan &&
    //   !this.userService.members.includes(person.insertedBy)
    // ) {
    //   // not yourself && not your shadchan
    //   this.asks = this.asks.filter(
    //     (o) => !['email', 'tel1', 'tel2'].includes(o.name)
    //   );
    // }
  }

  getPersonDetails() {
    this.http
      .get<Person>(
        this.globals.apiURL +
          `${this.gender}/getById/${this.userService.personId}?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        { responseType: 'json' }
      )
      .subscribe(
        (data) => {
          this.personTemp = data;
          this.prevPerson = { ...this.personTemp };

          // this.http
          //   .get<User>(
          //     this.globals.apiURL + `users/findById/${this.prevPerson.idUser}`
          //   )
          //   .subscribe((user) => {
          //     if (!user) {
          //       this.canToCreaetUser = true;
          //     }
          //   });

          this.recoverForm();
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  recoverPerson() {
    this.asks.forEach((ask) => {
      if (ask.typeInput == 1 || ask.typeInput == 2) {
        const lasAnswer = this.personTemp[ask.name];
        if (lasAnswer) {
          this.initAns(lasAnswer, ask);
        }
      }
    });
  }

  recoverForm() {
    this.hasntEmail = this.personTemp.email == '';
    this.treatContactDetails(this.personTemp);
    let a: Asks;
    for (let i in this.personTemp) {
      //pass over the answers
      if (this.globals.isDefined(this.asksMap[i])) {
        a = this.asksMap[i][0]; //[0] - only for know typeInput
        let ansIdArr: Array<number>, ansNamesArr: Array<string>;
        if (a.typeInput < 3) {
          ansNamesArr = this.personTemp[i].split(';');
          ansIdArr = this.answers
            .filter(({ idAsk }) => a.id == idAsk)
            .filter((o) => ansNamesArr.includes(o[this.gender]))
            .map((o) => o.id);
          //---------------------------------
          // this.ansMap[ask.id]
          if (Array.isArray(ansIdArr) && ansIdArr.length)
            for (let ans of ansIdArr) {
              //if this ans cause to sub- turn up ansHasSub
              let subs = this.asks.filter(
                ({ idParentAns }) => ans == idParentAns
              );
              if (Array.isArray(subs) && subs.length)
                this.ansHasSub[ans] = true;
            }
          //---------------------------------
          this.personTemp[i] = ansIdArr.join(';');
          if (a.typeInput == 2) this.personTemp[i] = ';' + this.personTemp[i];
        } else if (a.typeInput == 5) {
          this.personTemp[i] = this.personTemp[i] ? 'true' : 'false';
        }
      }
    }
    this.familyStatusFlag = !this.globals.isEmpty(
      this.personTemp['familyStatusDetails']
    );
    this.tabsLoaded[0] = true;
    this.personPrev = JSON.parse(JSON.stringify(this.personTemp));
  }

  getRecommends() {
    this.http
      .get<Recommend[]>(
        this.globals.apiURL +
          `recommends/getRecommends/${this.userService.personId}/${
            this.gender == 'man' ? 1 : 2
          }?userIDRequested=${this.userService.id}-${
            this.userService.lastName
          }-${this.userService.lastName}`,
        { responseType: 'json' }
      )
      .subscribe(
        (data) => {
          this.recommendList = data;
          this.countRecommends = this.recommendList.length;
          this.tabsLoaded[1] = true;
          this.recommendListPrev = JSON.parse(
            JSON.stringify(this.recommendList)
          );
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  getPref() {
    console.log('get pref');
    this.http
      .get<PersonPref>(
        this.globals.apiURL +
          `${this.gender}/getPref/${this.userService.personId}?userIDRequested=${this.userService.id}-${this.userService.lastName}-${this.userService.lastName}`,
        { responseType: 'json' }
      )
      .subscribe(
        (data) => {
          this.personPref = data;
          console.log(data);
          if (data == null) {
            this.personPref =
              this.gender == 'man' ? new MenPref() : new WomenPref();
            this.tabsLoaded[2] = true;
            return;
          }
          let a: Asks;
          let unchangedPref = ['minAge', 'maxAge', 'numKids', 'idPrefer'];
          if (this.gender == 'woman') unchangedPref.push('smoker');
          for (let i in this.personPref) {
            if (this.personPref[i] == 'לא משנה') this.personPref[i] = ';0';
            else if (!unchangedPref.includes(i)) {
              // console.log(i);
              if (this.asksPrefMap[i] == undefined) continue;
              a = this.asksPrefMap[i][0];
              let ansIdArr: Array<number>, ansNamesArr: Array<string>;
              ansNamesArr = this.personPref[i].split(';');
              ansIdArr = this.answersAll
                .filter(({ idAsk }) => a.id == idAsk)
                .filter((o) => ansNamesArr.includes(o[this.otherGender]))
                .map((o) => o.id);
              this.personPref[i] =
                i == 'headCover' ? ansIdArr[0] + '' : ';' + ansIdArr.join(';');
              console.log('============');
              console.log(i + ': ' + this.personPref[i]);
            } else if (i == 'smoker')
              this.personPref[i] = this.personPref[i] + '';
            else if (i == 'numKids' && this.personPref['numKids'] > 0)
              this.maxNumKids = this.personPref['numKids'];
          }
          this.tabsLoaded[2] = true;
          this.personPrefPrev = JSON.parse(JSON.stringify(this.personPref));
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  generateUser(): User {
    const u: User = new User();
    u.firstName = this.personTemp.firstName;
    u.lastName = this.personTemp.lastName;
    u.gender = 'zakan' in this.personTemp ? 1 : 2;
    u.email = this.personTemp.email;
    u.israelID = this.personTemp.israelID;
    u.phone = this.personTemp.tel1;

    u.permission =
      this.userService.permission == 1 || this.userService.permission == 2
        ? this.userService.id
        : 0;

    return u;
  }

  openBlank(page: string) {
    const u: User = this.generateUser();
    u.password = getRandomPassword();
    u.id = this.userService.personId;
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${page}/${JSON.stringify(u)}`])
    );
    window.open('/#' + url, '_blank');
  }

  changePassword() {
    if (!this.isShadchan) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['password', 'change'])
      );
      window.open('/#' + url, '_blank');
    } else alert('אין אפשרות לשדכנ/ית לשנות סיסמא של משתמש');
  }

  removeMe() {
    if (confirm('האם הנך בטוח/ה שברצונך להמחק מהמערכת?')) {
      this.http
        .get<Person>(
          this.globals.apiURL +
            `${this.gender}/delete/${this.userService.personId}`,
          {
            responseType: 'json',
          }
        )

        .subscribe((res) => {
          console.log('deleted:' + res);
          this.router.navigate(['/remove-me']);
        });
    }
  }

  //#endregion

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.hasChanges()) $event.returnValue = true;
  }
}
