//#region imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Globals } from '../common/globals';
import { Answers, Asks } from '../entities/AskAnswer';
import { Pair } from '../entities/entities';
//#endregion

@Component({
  selector: 'app-filter-pair',
  templateUrl: './filter-pair.component.html',
  styleUrls: ['./filter-pair.component.css'],
})
export class FilterPairComponent implements OnInit {
  asks: Array<Asks>;
  answers: Array<Answers>;
  ansMap: Map<number, Array<Answers>>; //instead arr of Answers do arr of strings= gender
  pairsList: Array<Pair>;
  gender: string;
  otherGender: string;
  Gender: string;
  noResults: boolean = false;
  showFilter: boolean = false;
  filterAsks: Array<number>;
  pairsFilter = new PairsFilter();
  @Input() pairsListRoot: Array<Pair>;
  @Input() allAsks: Array<Asks>;
  @Output() filterClick = new EventEmitter();

  constructor(private globals: Globals) {}

  ngOnInit() {}

  filter() {
    // this.pairsList=take from offer
    this.pairsList = JSON.parse(JSON.stringify(this.pairsListRoot));

    if (this.pairsFilter.tel1) {
      this.pairsFilter.tel2 = this.pairsFilter.tel1;
    }

    const searches = Object.keys(this.pairsFilter)
      .map((k) => {
        return {
          key: k,
          value: this.pairsFilter?.[k],
        };
      })
      .concat(
        this.allAsks.map((al) => ({
          key: al.name,
          value: undefined,
        }))
      );

    const free = this.pairsFilter['free'];
    const resList = [];
    searches.forEach(({ key, value }) => {
      this.pairsList.forEach((pair) => {
        const { woman, man } = pair;
        if (woman?.[key] && value && woman[key] === value) {
          resList.push(pair);
        } else if (man?.[key] && value && man[key] === value) {
          resList.push(pair);
        } else if (free && (woman[key] === free || man[key] === free)) {
          resList.push(pair);
        }
      });
    });

    this.pairsList = resList;
    this.filterClick.emit(this.pairsList);
  }

  clear(onlyBar: boolean) {
    this.pairsFilter = new PairsFilter();
    if (!onlyBar) this.filterClick.emit(this.pairsListRoot);
  }
}

export class PairsFilter {
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  tel1: string;
  tel2: string;
  free: string;
  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.street = '';
    this.city = '';
    this.tel1 = '';
    this.tel2 = '';
    this.free = '';
  }
}
