import { Component } from '@angular/core';
import { UserService } from '../common/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Globals } from '../common/globals';
import { User } from '../entities/entities';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-shadchan-list',
  templateUrl: './shadchan-list.component.html',
  styleUrls: ['./shadchan-list.component.css'],
})
export class ShadchanListComponent {
  shadchanList: Array<User>;

  constructor(
    public userService: UserService,
    private modalService: BsModalService,
    private http: HttpClient,
    private globals: Globals,
    private router: ActivatedRoute
  ) {
    this.getShadchanList();
  }

  ngOnInit(): void {}

  getShadchanList() {
    this.http
      .get<Array<User>>(this.globals.apiURL + `shadchan/get/allShadchan`, {
        responseType: 'json',
      })
      .subscribe(
        (data) => {
          this.shadchanList = data;
          setTimeout(() => {
            let el = document.getElementById(
              this.router.snapshot.queryParams.id
            );
            this.scroll(el);
          }, 500);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  scroll(el: HTMLElement) {
    el.style.backgroundColor = '#dfdada';
    el.scrollIntoView({
      block: 'center',
      inline: 'center',
    });
  }
}
