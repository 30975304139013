import { Component,TemplateRef, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from '../common/user.service';
import {Routes,RouterModule,ActivatedRoute,Router,Params} from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-multi-user-meshudach',
  templateUrl: './add-multi-user-meshudach.component.html',
  styleUrls: ['./add-multi-user-meshudach.component.css']
})
export class AddMultiUserMeshudachComponent implements OnInit {
  modalRef: BsModalRef;
  myform: UntypedFormGroup;
  shadchanName: string;
  isSystem: boolean;
  isAdmin: boolean;
  isPrivate: boolean;
  tabMap: Map<string, number>;

  constructor(private titleService: Title,private modalService: BsModalService, private userService:UserService, private router:Router, private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.isPrivate=this.userService.getPermission()==1;
    this.tabMap=this.userService.tabMap;
    this.shadchanName = sessionStorage.getItem('shadchanName');
    this.isAdmin = sessionStorage.getItem('isAdmin')== 'true';
    this.titleService.setTitle('שידוכים כהלכה');
    if(this.isAdmin){
      this.shadchanName = this.userService.getLastName()+" "+this.userService.getFirstName();
      this.titleService.setTitle('שדכנ/ית- '+this.userService.getFirstName());
    }
    this.isSystem = sessionStorage.getItem('isSystem') === 'true';
    this.myform = new UntypedFormGroup({
      gender: new UntypedFormControl('', Validators.required)
    });

  }
  openModal(chooseGender: TemplateRef<any>) {
    this.modalRef = this.modalService.show(chooseGender);
  }
  initGender(){
    this.userService.setGender(parseInt(this.myform.controls.gender.value));
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
    const url = this.router.serializeUrl(this.router.createUrlTree(['register','add']));
    window.open('/#' + url, '_blank');
    this.modalRef.hide();
  }
}
