//#region imports
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import { Answers, AskAnswer, Asks } from '../entities/AskAnswer';
import {
  Men,
  OffersStatus,
  Person,
  Recommend,
  Women,
} from '../entities/entities';
import { FilterComponent } from '../filter/filter.component';
//#endregion

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css'],
})
export class OfferComponent implements OnInit {
  user;
  offersType: string;
  asks: Array<Asks>;
  asksOther: Array<Asks>;
  answers: Array<Answers>;
  personList: Array<Person> = [];
  personListFiltered: Array<Person>;
  personListRoot: Array<Person>;
  gender: string;
  otherGender: string;
  Gender: string;
  noResults: boolean = false;
  isLoaded: boolean = false;
  noRecommend: boolean = false;
  isShadchanFolder: boolean;
  isShadchan: boolean;
  isSystem: boolean;
  recommendList: Array<Recommend> = new Array<Recommend>();
  person: Person;
  offer: OffersStatus;
  meetList: Array<OffersStatus>;
  idNeedBtnMsg: Array<number> = [];
  offersDataMap: Map<string, Array<string>> = new Map();
  colors: Array<string> = ['pink', 'gray', 'blue'];
  reactionIconMap = new Map([
    ['?', 'question'],
    ['x', 'times'],
    ['v', 'thumbs-up'],
  ]);
  reactionTextMap = new Map([
    ['v', 'התקבלה תגובה חיובית, שדכנית תחזור אליכם בהקדם בע"ה'],
    ['x', 'התקבלה תגובה שלילית'],
    ['?', 'עדיין לא התקבלה תגובה'],
  ]);
  reactionDateTextMap = new Map([
    ['v', ' התגובה התקבלה ב'],
    ['x', ' התגובה התקבלה ב'],
    ['?', ' הבקשה נשלחה ב '],
    ['i', 'נשלח לברורים ב'],
    ['r', 'נשלל בתאריך'],
    ['n', 'נשלל בתאריך'],
  ]);
  showAlert = {
    favorite: 'ההצעה נשמרה במועדפים',
    send: 'ההצעה נשלחה',
    ok: 'ההצעה אושרה',
    no: 'ההצעה הוסרה',
    check: 'ההצעה הועברה להצעות בבירורים',
    recycle: 'ההצעה שוחזרה',
  };
  actionDoneMsg: string;
  msgIcon: string;
  pageTitle: string;
  page: number;
  modalRef: BsModalRef;
  @ViewChild('actionDone') actionDone;
  @ViewChild(FilterComponent) filterComp;
  isMobile = this.globals.isMobile;
  addMsg: boolean = false;
  msg: string;
  //isEditLockedUserReasonEnable : boolean = true;
  isAdmin: boolean;

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true';

    console.log(
      'offer.component ngOnInit getfirstName - ' + this.globals.loginFirstName
    );
    this.route.params.subscribe((params) => {
      this.offersType = params.type;
      switch (this.offersType) {
        case 'system':
          this.pageTitle = 'הצעות מערכת';
          break;
        case 'sent':
          this.pageTitle = 'הצעות ששלחתי';
          break;
        case 'receive':
          this.pageTitle = 'הצעות ששלחו לי';
          break;
        case 'search':
          this.pageTitle = 'חיפוש חופשי';
          break;
        case 'favorites':
          this.pageTitle = 'המועדפים שלי';
          break;
        case 'check':
          this.pageTitle = 'הצעות בבירורים';
          break;
        case 'confirm':
          this.pageTitle = 'הצעות שאישרתי';
          break;
        case 'refuse':
          this.pageTitle = 'הצעות ששללתי';
          break;
        case 'finallyNo':
          this.pageTitle = 'בסוף לא התאים';
          break;
      }
      //init
      this.initUserData();
      this.initOffersDataMap();
      this.getAskAnswer().then(() => this.getOffers());
    });
  }

  ngAfterViewInit(): void {
    if (location.hash.split('#').pop() != '') this.scrollToSpecOffer();
  }

  scrollToSpecOffer() {
    const observer = new MutationObserver((mutations, me) => {
      const el = document.getElementById(location.hash.split('#').pop());
      if (el) {
        console.log(el);
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        me.disconnect();
        return;
      }
    });
    observer.observe(document, { childList: true, subtree: true });
  }

  initUserData() {
    if (this.userService.getGender() == 1) {
      this.gender = 'woman';
      this.otherGender = 'man';
      this.Gender = 'Women';
      this.person = new Women();
    } else {
      this.gender = 'man';
      this.otherGender = 'woman';
      this.Gender = 'Men';
      this.person = new Men();
    }
    this.user = JSON.parse(JSON.stringify(this.userService));
    this.isShadchanFolder =
      this.user.isShadchan && this.router.url.includes('shadchan-folder');
    this.isShadchan = this.user.isShadchan;
    this.isSystem =
      sessionStorage.getItem('isSystem') === 'true' || this?.user?.isSystem;
  }

  openReasonText(person: Person) {
    const toLock = !person.locked;

    if (
      !window.confirm(
        toLock ? 'האם לחסום משתמש זה?' : 'האם לבטל חסימה למשמש זה ?'
      )
    ) {
      return;
    }

    if (toLock && !person.lockedReason) {
      alert('.נא להזין סיבת חסימה');
      return;
    }

    person.isEditLockedUserReasonEnable = !person.isEditLockedUserReasonEnable;

    this.http
      .put(
        this.globals.apiURL +
          `users/lockUnLockUserByMan?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        JSON.stringify({
          ...person,
          locked: toLock,
          lockedReason: toLock
            ? `${person.lockedReason}.\nנחסם ע״י ${this.user.firstName} ${this.user.lastName}`
            : '',
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .subscribe(
        (response) => {
          //alert("response - "+ response);
          const first = this.personList.find((obj) => {
            return obj.idUser === person.idUser;
          });

          if (response.toString().match('true')) {
            this.msg = 'המשתמש נחסם';
          } else {
            this.msg = 'החסימה בוטלה';
            first.lockedReason = '';
          }
          this.addMsg = true;
          person.locked = !person.locked;
          person.lockedReason = toLock ? person.lockedReason : '';
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  goToUserFolder(p: Person, type: string) {
    let u: UserService = new UserService(this.globals);
    u.setLoginFirstName(u.getFirstName());
    u.setLoginLastName(u.getLastName());
    u.setGender('zakan' in p ? 1 : 2);
    u.setFirstName(p.firstName);
    u.setLastName(p.lastName);
    u.personId = p.id;
    sessionStorage.setItem('currentUser', JSON.stringify(u));
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`user-folder/${type}`], {
        queryParams: {
          params1: this.userService.loginFirstName,
          params2: this.userService.loginLastName,
        },
      })
    );

    //const url = this.router.serializeUrl(this.router.createUrlTree([`user-folder/${type}`]));
    // this.router.navigateByUrl(url);
    window.open('/#' + url, '_blank');
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
  }

  initOffersDataMap() {
    this.offersDataMap['system'] = [
      { title: 'שמור הצעה', icon: 'star', onclick: 'favorite' },
      { title: 'שלח הצעה', icon: 'thumbs-up', onclick: 'send' },
      { title: 'לא מתאים', icon: 'times', onclick: 'no' },
    ];
    this.offersDataMap['receive'] = this.user.isShadchan
      ? [{ title: 'לא מתאים', icon: 'times', onclick: 'no' }]
      : [
          { title: 'אשר הצעה', icon: 'thumbs-up', onclick: 'ok' },
          { title: 'לא מתאים', icon: 'times', onclick: 'no' },
        ];
    this.offersDataMap['search'] = [
      { title: 'שמור הצעה', icon: 'star', onclick: 'favorite' },
      { title: 'שלח הצעה', icon: 'thumbs-up', onclick: 'send' },
      { title: 'לא מתאים', icon: 'times', onclick: 'no' },
    ];
    this.offersDataMap['favorites'] = [
      { title: 'שלח הצעה', icon: 'thumbs-up', onclick: 'send' },
      { title: 'לא מתאים', icon: 'times', onclick: 'no' },
    ];
    this.offersDataMap['refuse'] = [
      { title: 'שמור הצעה', icon: 'star', onclick: 'favorite' },
      { title: 'שלח הצעה', icon: 'thumbs-up', onclick: 'send' },
      { title: 'לא מתאים', icon: 'times', onclick: 'no' },
    ];
    this.offersDataMap['finallyNo'] = [
      { title: 'שחזר הצעה', icon: 'refresh', onclick: 'recycle' },
    ];
    this.offersDataMap['check'] =
      this.offersDataMap['sent'] =
      this.offersDataMap['confirm'] =
        [];
  }

  getOffers() {
    if (this.offersType == 'search') {
      this.personList = [];
      this.noResults = false;
      return;
    }
    // console.log('i call ws');
    this.http
      .get<Array<Person>>(
        this.globals.apiURL +
          this.otherGender +
          `/offers?id=${this.userService.personId}&type=${
            this.offersType
          }&userIDRequested=${this.userService.id}-${
            this.userService.loginFirstName
          }-${
            this.userService.loginLastName
          }&isShadchan=${this.userService.getIsShadchan()}&firstTime=${
            this.userService.tabMap[this.offersType] != 0
          }`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        console.log(data);
        this.personList = data;
        if (this.personList != null) {
          //this.personList.forEach(o => o['birthdate'] = (new Date().getFullYear() - parseInt(o['birthdate'].split('-')[0])) + "");
          //   this.personList.forEach(o => o['birthdate'] = o['birthdate']);
          this.addDetailsToOtherAns();
          this.personList = JSON.parse(
            JSON.stringify(this.personList).replace(/;/g, ', ')
          );
        }
        this.personListRoot = JSON.parse(JSON.stringify(this.personList));
        this.personListFiltered = JSON.parse(JSON.stringify(this.personList));
        // console.log(this.personList);
        this.noResults = !(Array.isArray(data) && data.length);
        //removeCount
        if (
          !this.user.isShadchan &&
          ['sent', 'receive'].includes(this.offersType)
        ) {
          this.userService.tabMap[this.offersType] = 0;
          sessionStorage.setItem(
            'currentUser',
            JSON.stringify(this.userService)
          );
          //  console.log("this.userService.tabMap - "+this.userService.tabMap[this.offersType]);
        }
        if (
          ['sent', 'receive'].includes(this.offersType) &&
          this.user.isShadchan
        )
          this.addBtnMsgIfNeed();
      });
  }

  addBtnMsgIfNeed() {
    this.http
      .get<Array<number>>(
        this.globals.apiURL +
          `shadchan/get/byIsShown?id=${this.userService.personId}
        &gender=${this.userService.getGender()}` +
          `&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        this.idNeedBtnMsg = data;
      });
  }

  addDetailsToOtherAns() {
    for (let a of this.asksOther) {
      let attr = a['name'].replace('Other', '');
      if (this.personList) {
        for (let p of this.personList)
          if (p[attr] == 'אחר')
            p[attr] = p[attr].replace(/אחר/g, 'אחר - ' + p[a.name]);
      }
    }
  }

  getMeetPeople() {
    //setstatus
    return new Promise((resolve, reject) => {
      this.http
        .get<Array<OffersStatus>>(
          this.globals.apiURL +
            `offersStatus/getMeetPeople?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
          {}
        )
        .subscribe((data) => {
          this.meetList = data;
          if (this.personList) {
            for (let p of this.personList) {
              switch (p.status) {
                case 1:
                  p['statusDesc'] = this.isShadchanFolder ? 'פעיל' : '';
                  break;
                case 0:
                  p['statusDesc'] = p.locked ? 'נחסם ע״י שדכנ/ית' : 'לא פעיל';
                  break;
                case 2:
                  p['statusDesc'] = this.gender == 'man' ? 'מאורס' : 'מאורסת';
              }
              if (this.isShadchanFolder) {
                let list = this.meetList.filter((o) => o[this.gender] == p.id);
                if (Array.isArray(list) && list.length)
                  p['statusDesc'] = 'בפגישות';
              }
            }
          }
          // console.log("data line 223 "+data);
          resolve(true);
        }); //add throwError
    });
  }

  getAskAnswer() {
    console.log('getAskAnswer offer.component');

    return new Promise((resolve, reject) => {
      this.http
        .get<AskAnswer>(
          this.globals.apiURL +
            `actions/getAsks?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
          { responseType: 'json' }
        )
        .subscribe((data) => {
          this.asks = data.askList.concat(data.subAskList);
          this.answers = data.ansList;
          //  alert("1231");
          this.initDependentParams(this.asks, this.answers);
          resolve(true);
        }); //add throwError
    });
  }

  initDependentParams(asks, answers) {
    // alert("initDependentParams");
    this.asksOther = asks.filter(({ name }) => name.includes('Other'));
    asks = asks.sort((a, b) => (a.orderAll > b.orderAll ? 1 : -1));
    asks[asks.findIndex(({ name }) => name == 'moreToShadchan')][this.gender] =
      'עוד פרטים לשדכן';
    asks[asks.findIndex(({ name }) => name == 'moreToDisplay')][this.gender] =
      'עוד קצת עלי';
    asks[asks.findIndex(({ name }) => name == 'birthdate')][this.gender] =
      'תאריך לידה';
    asks = asks
      .filter(({ isPublic }) => isPublic)
      .filter((o) => o[this.gender] !== '0');
    answers = answers.filter((o) => o[this.gender] !== '0');
    this.asks = asks;
    this.answers = answers;
  }

  getCheckersInfo(p: Person, col: string) {
    if (this.globals.isDefined(p[col + 'Info'])) return;
    let g: string = 'zakan' in p ? 'man' : 'woman';
    console.log(p[col]);
    this.http
      .get<Array<string>>(
        this.globals.apiURL +
          `${g}/getCheckersInfo/${p[col]}` +
          `?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        p[col + 'Info'] = data;
        console.log(data);
        // this.personList[this.personList.findIndex(o => o.id == p.id)][col+'Info'] = data;
      }); //add throwError
  }
  //#endregion

  removeSystemAlert(id: number) {
    this.http
      .post(
        this.globals.apiURL +
          `offersStatus/set/isShown?id=${this.user.personId}&userIDRequested=${
            this.userService.id
          }-${this.userService.loginFirstName}-${
            this.userService.loginLastName
          }&idOffered=${id}&gender=${this.gender == 'man' ? 2 : 1}`,
        {
          withCredentials: true,
        }
      )
      .subscribe((response) => {
        console.log(response.toString());
      }); //add throwError
    this.userService.tabMap['systemAlerts']--;
    // this.user.systemAlertsIds = this.userService.systemAlertsIds = this.user.systemAlertsIds[this.gender == "man" ? 2 : 1].filter(x => x !== id);
    this.idNeedBtnMsg = this.idNeedBtnMsg.filter((x) => x !== id);
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
    this.openModal(this.actionDone);
    this.actionDoneMsg = 'אישור';
    this.msgIcon = 'check-circle';
    alert('מומלץ ליצור תזכורת להמשך.');
  }

  playBtn(action: string, personId: number) {
    this.offer = new OffersStatus();
    this.offer[this.otherGender] = this.userService.personId;
    this.offer[this.gender] = personId;
    this.offer.dateStatus = this.globals.timeToString(new Date());
    this.offer.lastEditGender = this.otherGender == 'man' ? 1 : 2;
    this.offer.shadchan = this.userService.getIsShadchan()
      ? this.userService.getId()
      : 0;
    this.offer.isShown = false;

    switch (action) {
      case 'favorite':
        this.offer.offerStatus = 1;
        this.offer.openedBy = this.offer.lastEditGender;
        break;
      case 'send':
        this.offer.offerStatus = 2;
        this.offer.openedBy = this.offer.lastEditGender;
        break;
      case 'ok':
        this.offer.offerStatus = 4;
        this.offer.openedBy = this.gender == 'man' ? 1 : 2;
        break;
      case 'no':
        this.offer.offerStatus = 7;
        this.offer.openedBy = this.offer.lastEditGender;
        break;
      case 'finallyNo':
        this.offer.offerStatus = 13;
        this.offer.openedBy = this.offer.lastEditGender;
        break;
      case 'check':
        this.offer.offerStatus = 5;
        this.offer.openedBy = this.offer.lastEditGender;
        break;
    }
    console.log(this.offer);
    console.log(JSON.stringify(this.offer));
    this.http
      .post(
        this.globals.apiURL +
          `offersStatus/playBtn?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}&action=${action}`,
        JSON.stringify(this.offer),
        {
          responseType: 'text' as 'json',
          headers: {},
        }
      )
      .subscribe(
        (response) => {
          console.log(response.toString());
          if (response.toString().includes('man')) {
            this.msgIcon = 'times-circle';
            this.actionDoneMsg =
              'לא ניתן לבצע את הפעולה, כיון ש' +
              this.getMatchMsg(response.toString());
          } else {
            this.msgIcon = 'check-circle';
            this.actionDoneMsg = this.showAlert[action];
            this.personList = this.personList.filter(
              ({ id }) => id !== personId
            );
            this.personListRoot = JSON.parse(JSON.stringify(this.personList));
            this.personListFiltered = this.personListFiltered.filter(
              ({ id }) => id !== personId
            );
            // this.filterComp.clear(true);
            //removeCount from system offer
            if (
              this.offersType == 'receive' &&
              this.user.isShadchan &&
              this.user.systemAlertsIds != null &&
              this.user.systemAlertsIds[this.gender == 'man' ? 1 : 2].includes(
                this.user.personId
              )
            ) {
              this.userService.tabMap['systemAlerts']--;
              this.user.systemAlertsIds = this.userService.systemAlertsIds =
                this.user.systemAlertsIds[this.gender == 'man' ? 1 : 2].filter(
                  (x) => x !== this.user.personId
                );
              sessionStorage.setItem(
                'currentUser',
                JSON.stringify(this.userService)
              );
              console.log(this.userService.tabMap[this.offersType]);
            }
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.msgIcon = 'times-circle';
          this.actionDoneMsg = 'התרחשה שגיאה, אנא נסו מאוחר יותר';
        }
      );
  }

  getMatchMsg(res: string) {
    let arr = res.split(' ');
    let tmp;
    if (arr.includes(this.otherGender)) {
      tmp = arr[arr.indexOf(this.otherGender) + 1];
      return tmp == 'mzlt' ? 'התארסת' : 'הסטטוס שלך מוגדר כלא פעיל';
    }
    if (arr.includes('mzlt')) {
      if (this.gender == 'man') return 'המשודך התארס. בקרוב אצלכם!';
      return 'המשודכת התארסה. בקרוב אצלכם!';
    }
    if (arr.includes('off')) {
      if (this.gender == 'man') return 'המשודך אינו פעיל';
      return 'המשודכת אינה פעילה';
    }
  }

  updatePersonList(personListFiltered) {
    this.personListFiltered = personListFiltered;
    console.log(this.personList);
    this.noResults = this.personListFiltered.length == 0;
  }

  getPersonList(personList) {
    this.personListFiltered = personList;
    this.personList = personList;
    this.addDetailsToOtherAns();
    console.log(this.personList);
    this.noResults = this?.personListFiltered?.length == 0;
    if (this.offersType == 'search' && !this.noResults && this.isShadchanFolder)
      this.getMeetPeople().then(() => (this.isLoaded = true));
    else this.isLoaded = true;
  }

  clear(personList) {
    this.personListFiltered = personList;
    this.personList = personList;
    console.log(this.personList);
    this.noResults = false;
  }

  openModal(template: TemplateRef<any>) {
    this.actionDoneMsg = '';
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
    });
  }

  getRecommends(id: number) {
    this.http
      .get<Recommend[]>(
        this.globals.apiURL +
          `recommends/getRecommends/${id}/${this.gender == 'man' ? 1 : 2}` +
          `?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        this.recommendList = data;
        console.log(this.recommendList);
        let recommendsInfo = document.getElementsByClassName(
          'recommendsInfo'
        ) as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < recommendsInfo.length; i++)
          recommendsInfo[i].style.display = 'none';
        if (!(Array.isArray(data) && data.length))
          document.getElementById('noRecommendOf' + id).style.display =
            'inline';
        else
          document.getElementById('recommendsOf' + id).style.display = 'block';
      }); //add throwError
  }

  setGender(g: number) {
    this.noResults = false;
    this.userService.setGender(g);
    this.initUserData();
    this.getAskAnswer();
    console.log('set gender to ' + g);
  }

  definedAndIncludes(a: Array<number>, x: number) {
    if (Array.isArray(a) && a.length) if (a.includes(x)) return true;
    return false;
  }

  delOffer(id: number) {
    let m = this.gender == 'man' ? id : this.userService.personId;
    let w = this.gender == 'woman' ? id : this.userService.personId;
    this.http
      .delete(
        this.globals.apiURL +
          `offersStatus/delByManWoman?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}&m=${m}&w=${w}`,
        {}
      )
      .subscribe((response) => {
        console.log(response.toString());
        this.msgIcon = 'check-circle';
        this.actionDoneMsg = 'ההצעה הוסרה';
        this.personList = this.personList.filter(({ id }) => id !== id);
        this.personListRoot = JSON.parse(JSON.stringify(this.personList));
        this.personListFiltered = this.personListFiltered.filter(
          ({ id }) => id !== id
        );
      }); //add throwError
  }
  onChat(p: Person) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`shadchan-folder/chat`], {
        queryParams: { chatWith: p?.idUser },
      })
    );

    window.open('/#' + url);
  }
}
