<div *ngIf="!isLoaded" class="overlay">
  <i class="fa fa-spinner fa-pulse fa-4x"></i>
</div>
<div *ngIf="isLoaded">
  <div id="part1" class="frame">
    <div class="right-title">הפרטים שלי</div>
    <div class="left-container">
      <div
        class="w-100 d-flex justify-content-center py-2"
        style="margin-bottom: -23px"
        *ngIf="(isShadchan || isAdmin) && person?.joinDate"
      >
        <div>
          <label class="text-info ml-2"> תאריך הצטרפות : </label>
          <label class="font-bold">{{ person?.joinDate }}</label>
        </div>
      </div>
      <div class="inner-container row-flex">
        <div
          *ngFor="let ask of asksPerPerson; let i = index"
          class="d-flex"
          [ngClass]="{ 'w-50': !isMobile }"
        >
          <div *ngIf="isShadchan || isAdmin">
            <div
              *ngIf="
                (ask.name == 'tel1' || ask.name == 'tel2') &&
                person[ask.name] != '' &&
                person[ask.name] != undefined &&
                person[ask.name] != 'undefined'
              "
            >
              <button
                id="phoneButtonID_{{ ask.name }}"
                (click)="togglePhoneNumber($event)"
                type="button"
                class="btn"
              >
                הצגת מספר המשודך
              </button>
              <div *ngIf="phoneVisibility" class="bg-white">
                <p>
                  שים לב בחשיפת מספר המשודך הנך מתחייב לשלם תשלום לשדכנית בסך של
                  250 ש"ח
                </p>
                <p>מספר השדכנית הוא : {{ person["phoneShadchan"] }}</p>
                <label class="key-label">{{ ask[gender] }}</label>
                <label
                  *ngIf="ask.typeInput != 5"
                  class="value-label label-{{ ask.name }}"
                  >{{ person[ask.name] }}</label
                >
                <label
                  *ngIf="ask.typeInput == 5"
                  class="value-label label-{{ ask.name }}"
                  >{{ person[ask.name] == 1 ? "כן" : "לא" }}</label
                >
              </div>
            </div>

            <div *ngIf="ask.name != 'tel1' && ask.name != 'tel2'">
              <label class="key-label">{{ ask[gender] }}</label>
              <label
                *ngIf="ask.typeInput != 5"
                class="value-label label-{{ ask.name }}"
                >{{ person[ask.name] }}</label
              >
              <label
                *ngIf="ask.typeInput == 5"
                class="value-label label-{{ ask.name }}"
                >{{ person[ask.name] == 1 ? "כן" : "לא" }}</label
              >
            </div>

            <div *ngIf="i === asksPerPerson.length - 1">
              <label class="key-label font-bold"> מוכן לשלם לשדכנית</label>
              <label
                class="value-label"
                [ngStyle]="{ color: !!person?.payment ? 'blue' : '' }"
                >{{ person["payment"] || "כרגיל" }}</label
              >
            </div>
          </div>
          <div *ngIf="!isShadchan && !isAdmin">
            <label class="key-label">{{ ask[gender] }}</label>
            <label
              *ngIf="ask.typeInput != 5"
              class="value-label label-{{ ask.name }}"
              >{{ person[ask.name] }}</label
            >
            <label
              *ngIf="ask.typeInput == 5"
              class="value-label label-{{ ask.name }}"
              >{{ person[ask.name] == 1 ? "כן" : "לא" }}</label
            >
          </div>
          <!-- <label *ngIf="ask.name=='familyStatus'&&!person[ask.name].includes('רווק')" class="value-label label-{{ask.name}}"></label> -->
          <!-- <label *ngIf="ask.name=='familyStatus'&&!person[ask.name].includes('רווק')" class="value-label label-{{ask.name}}">, {{person.familyStatusDetails}}</label> -->
        </div>

        <div class="mt-3" *ngIf="isShadchan || isAdmin">
          <button
            class="btn"
            style="color: var(--pink)"
            (click)="open(content, person)"
          >
            אימייל למשודך
          </button>
          <button
            class="btn mx-2"
            style="color: #4ec1d1"
            (click)="open(contentSms, person)"
          >
            סמס למשודך
          </button>
          <button
            *ngIf="userService?.extension"
            class="btn mx-2"
            style="color: #b74799"
            (click)="open(phoneContact, person)"
          >
            טלפן למשודך <fa name="phone"></fa>
          </button>
          <button
            (click)="onChat(person)"
            *ngIf="isShadchan && !!person?.idUser"
            class="btn btn-warning mx-2"
            style="color: #000"
          >
            צאט עם משתמש <fa name="comments-o"></fa>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- --------------recommends------------------------------------------------------------------- -->
  <div id="part2" class="frame">
    <div class="right-title">ממליצים עלי</div>
    <div class="left-container">
      <div class="inner-container">
        <div *ngFor="let item of recommendList; let i = index" class="row-flex">
          <label class="w-25 key-label"
            >{{ recommendList[i]["name"] }} -
            {{ recommendList[i]["descrip"] }}</label
          >
          <label class="w-25 value-label">{{
            recommendList[i]["phone"]
          }}</label>
          <label class="w-25 value-label">{{
            recommendList[i]["phone2"]
          }}</label>
          <label class="w-25">&nbsp;</label>
        </div>
      </div>
    </div>
  </div>
  <!-- --------------prefAsks------------------------------------------------------------------- -->
  <div id="part3" class="frame">
    <div class="right-title">ההעדפות שלי</div>
    <div class="left-container">
      <div *ngIf="!noPref" class="inner-container prefAsks row-flex">
        <div
          *ngFor="let ask of prefAsks; let i = index"
          class="d-flex"
          [ngClass]="{ 'w-50': !isMobile }"
        >
          <label class="key-label">{{
            asksIdMap[ask.idAsk][0][otherGender]
          }}</label>
          <label *ngIf="ask.name != 'smoker'" class="value-label">{{
            personPref[ask.name]
          }}</label>
          <label
            *ngIf="ask.name == 'smoker'"
            class="value-label label-{{ ask.name }}"
            >{{ personPref[ask.name] == 1 ? "לא משנה" : "לא" }}</label
          >
          <!-- <label *ngIf="i==familyStatusPrefI && numKids!=''" class="value-label">&nbsp;({{numKids}})</label> -->
          <!-- <label *ngIf="ask.name=='smoker'" class="value-label label-{{ask.name}}">{{personPref[ask.name]==1?'לא משנה':'לא'}}</label>
          <label *ngIf="i==familyStatusPrefI && numKids!=''" class="value-label">&nbsp;({{numKids}})</label> -->
        </div>
        <label class="key-label">טווח גילאים</label>
        <label class="value-label"
          >{{ personPref["maxAge"] }} - {{ personPref["minAge"] }}</label
        >
      </div>
      <h3 *ngIf="noPref" class="text-center text-info" style="margin-top: 25px">
        לא הוזנו העדפות
      </h3>
    </div>
  </div>

  <!-- --------------prefAsks------------------------------------------------------------------- -->
  <div *ngIf="isShadchan" id="part4" class="frame">
    <div class="right-title">הערות שדכנ/ית</div>
    <div class="left-container px-3 py-2">
      <div *ngIf="person?.notes" class="w-100 bg-light p-3">
        {{ person?.notes }}

        <div class="d-flex">
          <label class="value-label ml-4 mr-1 text-info">{{
            person.notesInfo
          }}</label>
          <a
            class="gray-color"
            title="עריכה"
            routerLink="/user-folder/edit-notes-status"
            routerLinkActive="active"
            ><fa name="pencil"></fa>
          </a>
        </div>
      </div>
      <app-notes userId="{{ person.id }}"> </app-notes>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">אימייל חדש</h4>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="emailAddress">כתובת מייל</label>
        <input
          type="email"
          class="form-control"
          required
          id="emailAddress"
          [(ngModel)]="this.customEmailMessage.to"
          placeholder=""
        />
      </div>
      <div class="form-group">
        <label for="emailSubject">נושא</label>
        <input
          type="text"
          class="form-control"
          required
          id="emailSubject"
          [(ngModel)]="this.customEmailMessage.subject"
          placeholder="נא להזין את נושא המייל"
        />
      </div>
      <div class="form-group">
        <label for="emailBody">תוכן המייל</label>
        <textarea
          rows="5"
          class="form-control"
          id="emailBody"
          required
          [(ngModel)]="this.customEmailMessage.body"
        ></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="sendEmail()">
        שליחה
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal.dismiss('')"
      >
        ביטול
      </button>
    </div>
  </div>
</ng-template>

<ng-template #contentSms let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">סמס חדש</h4>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="phone">טלפון</label>
        <input
          type="text"
          class="form-control"
          required
          disabled
          id="phone"
          [(ngModel)]="this.customSMSMessage.to"
          placeholder=""
        />
      </div>
      <div class="form-group">
        <label for="emailBody">תוכן</label>
        <textarea
          rows="5"
          class="form-control"
          id="emailBody"
          required
          [(ngModel)]="this.customSMSMessage.body"
        ></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="sendSms()">
        שליחה
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal.dismiss('')"
      >
        ביטול
      </button>
    </div>
  </div>
</ng-template>

<ng-template #phoneContact let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">טלפן ממרכזיה</h4>
    </div>
    <div class="modal-body">
      <div
        class="d-flex flex-column align-items-center mx-auto"
        style="width: 150px"
      >
        <div *ngIf="person.tel1" class="d-flex justify-content-between w-100">
          <div>{{ person.tel1 }}</div>
          <button
            type="button"
            class="btn btn-outline-dark"
            (click)="call(person.tel1)"
          >
            <fa name="phone"></fa>
          </button>
        </div>
        <div
          *ngIf="person.tel2"
          class="d-flex justify-content-between w-100 mt-3"
        >
          <div>{{ person.tel2 }}</div>
          <button
            type="button"
            class="btn btn-outline-dark"
            (click)="call(person.tel2)"
          >
            <fa name="phone"></fa>
          </button>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="text-danger">* נא לוודא שתוכנת המרכזיה פעילה</div>
    </div>
  </div>
</ng-template>
