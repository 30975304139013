import { Component, OnInit, OnChanges, SimpleChanges, AfterViewInit, Input, Output, ViewEncapsulation } from '@angular/core';
import { FamilyStatusAsk, FamilyStatusArrs } from '../entities/familyStatusAsks';
import { UserService } from '../common/user.service';
import { concat } from 'rxjs';

@Component({
  selector: 'app-family-status-asks',
  templateUrl: './family-status-asks.component.html',
  styleUrls: ['./family-status-asks.component.css']
})
export class FamilyStatusAsksComponent implements OnInit, OnChanges {

  @Input() idFamilyStatus: number; // garush-17 alman-18
  @Input() validFlag: boolean; 
  familyStatusDetails: string = "";
  askHasSub: Array<boolean>;
  ask: FamilyStatusAsk;
  asks: Array<FamilyStatusAsk>;
  currentLevel: number = 0;
  person:string;

  constructor(private familyStatusArrs: FamilyStatusArrs, private userService: UserService) { }

  ngOnInit(): void {
    this.person =this.userService.getGender() == 1? "man":"woman";
    console.log(this.userService.getGender());
    console.log(this.idFamilyStatus);
    this.familyStatusDetails = "";
    setTimeout(() => {
      this.asks = this.idFamilyStatus == 17 ? this.familyStatusArrs.garushArr : this.familyStatusArrs.almanArr;
      this.askHasSub = new Array(this.asks.length).fill(false);
    });
    console.log(this.asks);
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.familyStatusDetails = "";
    this.asks = this.idFamilyStatus == 17 ? this.familyStatusArrs.garushArr : this.familyStatusArrs.almanArr;
  }

  getNumKids() {
    if(this.asks[1].answer=='')
      return null;
    return this.asks[2].answer!='' ? parseInt(this.asks[2].answer) : (this.asks[1].answer != '0' ? -1 : 0);
  }
  getFamilyStatusDetails() {
    let kids, mezonot, contact: string = "";
    if (this.asks[1].answer != '0') {
      if (this.asks[2].answer)
        kids = this.asks[2].answer + " ילדים";
      else
        kids = "עם ילדים";
    }
    else
      kids = "בלי ילדים";
    if (this.idFamilyStatus == 17 && this.asks[1].answer != '0') {
      //notic in woman:
      let yesMezonot:string=this.userService.getGender()==1? "משלם מזונות":"מקבלת מזונות";     
      mezonot =  this.asks[3].answer !=''?(this.asks[3].answer=='1'? yesMezonot:"לא "+yesMezonot):'';
      if (this.asks[5].answer != '0' && this.asks[5].answer !='') { //notic in woman
        contact = "בקשר עם הילדים";
        if (this.asks[5].answer != '1')
          contact += " לפעמים";
        else if (this.asks[6].answer)
          contact += " " + this.asks[6].answer + " פעמים בשבוע";
      }
      else
        contact =this.asks[5].answer ==''?'': "לא בקשר עם הילדים";        
    }
    this.familyStatusDetails =
      (this.asks[0].answer ? this.asks[0].answer + ", " : "") + kids;
    if (this.idFamilyStatus == 17 && this.asks[1].answer != '0')
      this.familyStatusDetails = this.familyStatusDetails + ", " + mezonot +
        (this.asks[4].answer ? " - " + this.asks[4].answer + " לחודש, " : ", ") + 
        (contact!="" && this.userService.getGender()==2?"האבא "+contact:contact);        
    return this.familyStatusDetails.replace(", ,",", ");
  }

}
