<div id="sidebar" class="sidebar">
    <div class="above">
        <!-- <fa name="bars"></fa> -->
        <div class="shadchan-img"><h4 style="color: #fff;text-align: center;padding-top: 30%;font-weight: bold;"></h4></div>
        <p> <label *ngIf="!isAdmin">שלום</label></p>   
        <div style="height: 20px;"></div>
    </div>
    <ul>
      
        <li (click)="openModal(chooseGender)" routerLinkActive="active">הוספת משודך <fa name="plus"></fa>
        </li>
       
    </ul>
    <!-- <div class="under"></div> -->
</div>

<ng-template #chooseGender>
    <form novalidate [formGroup]="myform" (ngSubmit)="initGender()" class="padding-20">
        <p class="text-center">מה ברצונך להוסיף?</p><br>
        <div btnRadioGroup formControlName="gender" style="width:100%; max-width: 400px; margin: auto;">
            <button btnRadio="1" class="btn btn-blue btn-round pull-right" style="width: 180px;">
                <fa name="male left-space"></fa>גבר
            </button>
            <button btnRadio="2" class="btn btn-pink btn-round pull-left" style="width: 180px;">
                <fa name="female left-space"></fa>אישה
            </button>
        </div>
    </form>
</ng-template>

<router-outlet class="pull-left" ></router-outlet>
