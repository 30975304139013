<div class="card" *ngIf="isLoad">
  <form
    novalidate
    [formGroup]="myform"
    autocomplete="off"
    (keydown.enter)="submit()"
  >
    <alert
      *ngIf="addMsg"
      type="pink alert-top"
      dismissible="true"
      (onClosed)="addMsg = false"
    >
      <p class="text-center">{{ msg }}</p>
    </alert>
    <alert
      *ngIf="isExistsUser"
      type="info alert-top"
      dismissible="true"
      (onClosed)="isExistsUser = false"
    >
      <p class="text-center">השדכן כבר קיים במאגר</p>
    </alert>
    <div *ngIf="!addMsg">
      <p *ngIf="!fix" class="text-center pink-color">יש למלא את כל הפרטים</p>
      <p
        class="text-center pink-color"
        style="margin-bottom: 30px"
        *ngIf="
          myform.controls.password.invalid && myform.controls.password.touched
        "
      >
        * על הסיסמא להיות באורך 6 תוים לפחות, ולכלול אות ומספר
      </p>
      <div class="row-flex-between">
        <div class="form-group">
          <label>דוא"ל</label>
          <input
            class="form-control"
            type="text"
            formControlName="email"
            autocomplete="nope"
            [ngClass]="{ invalid: !fix && myform.controls.email.invalid }"
          />
        </div>
        <div class="form-group">
          <label>סיסמא</label>
          <div class="w-100">
            <input
              class="form-control"
              [type]="showPass ? 'text' : 'password'"
              formControlName="password"
              [ngClass]="{ invalid: !fix && myform.controls.password.invalid }"
              autocomplete="new-password"
            />
            <fa
              [name]="showPass ? 'eye-slash' : 'eye'"
              class="btn-eye"
              (click)="showPass = !showPass"
            ></fa>
          </div>
        </div>
        <div class="form-group">
          <label>שם פרטי</label>
          <input
            class="form-control"
            type="text"
            formControlName="firstName"
            [ngClass]="{ invalid: !fix && myform.controls.firstName.invalid }"
          />
        </div>
        <div class="form-group">
          <label>שם משפחה</label>
          <input
            class="form-control"
            type="text"
            formControlName="lastName"
            [ngClass]="{ invalid: !fix && myform.controls.lastName.invalid }"
          />
        </div>
        <div class="form-group">
          <label>טלפון</label>
          <input
            class="form-control"
            type="text"
            formControlName="phone"
            [ngClass]="{ invalid: !fix && myform.controls.phone.invalid }"
          />
        </div>
        <div class="form-group">
          <label>שלוחת מרכזייה</label>
          <input
            class="form-control"
            type="text"
            formControlName="extension"
            [ngClass]="{ invalid: !fix && myform.controls.extension.invalid }"
          />
        </div>
        <div *ngIf="state != 'add'" class="form-group">
          <label style="width: 350px">התראות על משודכים ללא מייל</label>
          <div
            class="row-flex-between btn-my-group"
            btnRadioGroup
            formControlName="getAlerts"
          >
            <label
              class="btn btn-light"
              btnRadio="true"
              tabindex="0"
              role="button"
              >שלח לי מייל</label
            >
            <label
              class="btn btn-light"
              btnRadio="false"
              tabindex="0"
              role="button"
              >במערכת בלבד</label
            >
          </div>
        </div>
        <!-- <div *ngIf="state=='edit'" class="form-group">
                    <label>האם המשודכים שלך יכנסו למאגר הכללי?</label>
                    <div class="btn-my-group {{ask.name}}" btnRadioGroup formControlName="personal">
                        <label class="btn btn-light" btnRadio="true" tabindex="0" role="button">כן</label>
                        <label class="btn btn-light" btnRadio="false" tabindex="0" role="button">לא</label>
                      </div>
                </div>                -->
        <div
          *ngIf="state == 'add' || isAdmin"
          class="form-group"
          [ngClass]="{ invalid: !fix && myform.controls.permission.invalid }"
        >
          <label> הרשאת גישה</label>
          <div
            class="row-flex-between btn-my-group permission"
            btnRadioGroup
            formControlName="permission"
          >
            <label
              class="btn btn-light"
              [btnRadio]="1"
              tabindex="0"
              role="button"
              >תכנה אישית</label
            >
            <label
              class="btn btn-light"
              [btnRadio]="2"
              tabindex="0"
              role="button"
              >תכנה אישית + מאגר</label
            >
            <label
              class="btn btn-light"
              [btnRadio]="3"
              tabindex="0"
              role="button"
              >שדכנית פרימיום</label
            >
            <label
              class="btn btn-light"
              [btnRadio]="4"
              tabindex="0"
              role="button"
              (click)="getGroupList()"
              >שדכנית פרימיום בקבוצה</label
            >
          </div>
        </div>
        <div *ngIf="state == 'edit'" class="form-group"></div>
        <div
          *ngIf="state == 'edit' && myform.controls.permission.value == 4"
          class="form-group pink-color"
        >
          <label></label>{{ notic }}
        </div>
        <div
          *ngIf="
            state == 'add' &&
            myform.controls.permission.value == 4 &&
            groupList &&
            groupList.length > 0
          "
          class="form-group"
          [ngClass]="{ invalid: !fix && myform.controls.groupId.value == 0 }"
          style="margin-right: auto"
        >
          <label>בחר קבוצה</label>
          <select
            autocomplete="on"
            class="form-control"
            formControlName="groupId"
            placeholder="בחר קבוצה"
          >
            <option [value]="g.id" *ngFor="let g of groupList">
              {{ g.name }}
            </option>
          </select>
        </div>
        <div class="w-100" style="height: 20px"></div>
        <button
          *ngIf="state == 'add'"
          [disabled]="isBusy"
          class="btn btn-blue btn-round d-block mx-auto btn-ok"
          (click)="submit()"
        >
          <fa name="check left-space"></fa>אישור
        </button>
        <button
          *ngIf="state == 'edit'"
          class="btn btn-blue btn-round d-block mx-auto btn-ok"
          (click)="submit()"
        >
          <fa name="check left-space"></fa>עדכון
        </button>
        <!-- <div *ngIf="state=='edit'"></div> -->
      </div>
      <div class="d-block mx-auto" style="width: fit-content; padding: 30px">
        <button class="btn btn-round btn-blue" (click)="exportMensToExcel()">
          <fa name="cloud-download left-space"></fa>ייצוא משודכים גברים לאקסל</button
        >&nbsp;&nbsp;&nbsp;
        <button class="btn btn-round btn-blue" (click)="exportWoMansToExcel()">
          <fa name="cloud-download left-space"></fa>ייצוא משודכים נשים לאקסל
        </button>
      </div>
    </div>
  </form>
</div>
