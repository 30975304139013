const pwdChars =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
const vCodeChars = '0123456789';

const randomStr = (chars: string, strLen: number) => {
  return Array(strLen)
    .fill(chars)
    .map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    })
    .join('');
};

export const getRandomPassword = () => {
  const pwdLen = 6;
  return randomStr(pwdChars, pwdLen) + randomStr(vCodeChars, 1);
};
