<div *ngIf="!showFilter" class="wrap-div-filter">
  <button
    type="button"
    class="btn btn-blue d-block mx-auto"
    (click)="showFilter = true"
  >
    <fa name="filter left-space"></fa>סינון
  </button>
</div>
<div *ngIf="showFilter" class="div-filter" (keydown.enter)="filter()">
  <button class="close" (click)="clear(false); showFilter = false">
    <span aria-hidden="true" style="padding: 0px 10px">×</span>
  </button>
  <h5
    class="text-info text-center w-100"
    style="margin-top: 3px; margin-bottom: 0px"
  >
    ניתן לסנן לפי פרטי האיש /האישה
  </h5>
  <div class="row-flex-between w-100" style="max-width: 1010px">
    <input
      class="form-control"
      type="text"
      [(ngModel)]="pairsFilter.firstName"
      placeholder="שם פרטי"
    />
    <input
      class="form-control"
      type="text"
      [(ngModel)]="pairsFilter.lastName"
      placeholder="שם משפחה"
    />
    <input
      class="form-control"
      type="text"
      [(ngModel)]="pairsFilter.street"
      placeholder="רחוב"
    />
    <input
      class="form-control"
      type="text"
      [(ngModel)]="pairsFilter.city"
      placeholder="ישוב"
    />
    <input
      class="form-control"
      type="text"
      [(ngModel)]="pairsFilter.tel1"
      placeholder="טלפון"
    />
    <input
      class="form-control"
      type="text"
      [(ngModel)]="pairsFilter.free"
      placeholder="חיפוש חופשי"
    />
  </div>
  <div class="w-100"></div>
  <div class="d-block mx-auto">
    <button class="btn btn-blue" (click)="filter()">
      <fa name="filter left-space"></fa>סינון
    </button>
    &nbsp;&nbsp;
    <button class="btn btn-blue" (click)="clear(false)">
      <fa name="times left-space"></fa>ניקוי
    </button>
  </div>
</div>
