import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Globals } from '../common/globals';
import { LoaderService } from '../common/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit { 
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  leftMr:string;
  topMr:string;
  hide:boolean=false;
  isMobile=this.globals.isMobile;

  constructor(private globals: Globals, private loaderService: LoaderService, private router: Router, private route: ActivatedRoute, 
    private activatedRoute: ActivatedRoute) {}
  
  ngOnInit(): void {    
    // let compArr = ["login", "new-user", "password","register-by-steps","register/add"];
    // this.leftMr = compArr.some(v => location.hash.includes(v)) ? 'calc(50% - 28px)' : 'calc(50% - 90px)';
    // this.topMr = compArr.some(v => location.hash.includes(v)) ? '0px' : '150px'; 
     
    this.leftMr = !location.hash.includes('-folder') || this.isMobile? 'calc(50% - 28px)' : 'calc(50% - 90px)';
    this.topMr = !location.hash.includes('-folder')? '0px' : '150px'; 
    this.hide=location.hash.includes('register/edit');
    this.hide=['register/edit','user-folder/user-details'].some(v => location.hash.includes(v));
  }

}