<div class="d-flex flex-column h-100 offer-box">
  <div
    class="d-flex shadchan-container"
    *ngFor="let shadchan of shadchanList"
    id="{{ shadchan.id }}"
  >
    <div class="profile-container">
      <div [class]="shadchan.gender == 1 ? 'male-img' : 'female-img'">
        <app-add-img
          *ngIf="shadchan.img != null"
          [onlyShow]="true"
          [userImg]="shadchan.img"
        ></app-add-img>
      </div>
    </div>
    <div class="inner-container d-flex w-100">
      <div class="profile-title">
        {{ shadchan.firstName }} {{ shadchan.lastName }}<br />
      </div>
      <div class="d-flex flex-column justify-content-center">
        <div class="d-flex align-items-center">
          <fa name="phone" class="ml-1" /> {{ shadchan.phone }}
        </div>
        <div class="mt-1 text-info">
          <fa name="envelop" />{{ shadchan.email }}
        </div>
      </div>
    </div>
  </div>
</div>
