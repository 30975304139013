//#region imports
import { Component, OnInit, Injectable, ElementRef, ViewChild, Input, TemplateRef } from '@angular/core';
import { Globals } from '../common/globals';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; import { Person, PersonPref, Men, Women, MenPref, WomenPref, Recommend, OffersStatus, Pair,  AlertedPerson } from '../entities/entities';
import { Routes, RouterModule, Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router'
import { UserService } from '../common/user.service';
//#endregion

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  alertsList: Array<AlertedPerson>;
  noResults: boolean = false;

  constructor(private http: HttpClient, private globals: Globals, private modalService: BsModalService,
    private router: Router, private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit(): void {
    this.getAlert();
  }

  getAlert() {
    this.http.get<Array<AlertedPerson>>(this.globals.apiURL + `shadchan/get/alerts/${this.userService.getId()}`+`?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`, { responseType: 'json' })
      .subscribe(data => {
        this.alertsList = data;
        this.noResults = !(Array.isArray(data) && data.length);
        //   this.globals.setCounter(this.userService, data.length, 'alerts');
        console.log(this.alertsList);
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  goToUserFolder(p: Person, gender: number) {
    this.userService.setLoginFirstName(this.userService.firstName);
    this.userService.setLoginLastName(this.userService.lastName);
    this.userService.setGender(gender);
    this.userService.setFirstName(p.firstName);
    this.userService.setLastName(p.lastName);
    this.userService.personId = p.id;
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
    const url = this.router.serializeUrl(this.router.createUrlTree([`user-folder/user-details`]));
    this.router.navigateByUrl(url);
    //window.open('/#' + url, '_blank');..
  }

  treated(id: number) {
    this.http.post(this.globals.apiURL + `shadchan/set/closeAlert/${id}`+`?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, {})
      .subscribe(response => {
        console.log(response.toString());
        this.userService.tabMap['alerts']--;
        sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
        this.alertsList=this.alertsList.filter(p => p.alert.id !== id);
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  getAge(birthdate:string){
    return new Date().getFullYear()-parseInt(birthdate.split('-')[0]);
  }

}
