import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AddManStepsComponent } from '../add-man-steps/add-man-steps.component';
import { AddManComponent } from '../add-man/add-man.component';


@Injectable()
export class CanDeactivateGuard implements CanDeactivate<AddManComponent> {
  canDeactivate(component: AddManComponent | AddManStepsComponent): boolean {  
    if(component.hasChanges())
        return confirm('לצאת מהדף?\n יתכן שהשינויים שביצעת לא ישמרו.');
    return true;
    
  }
  
  // canDeactivate(component: AddManComponent | AddManStepsComponent): boolean {  
  //   if(component.hasChanges())
  //       return confirm('לצאת מהדף?\n יתכן שהשינויים שביצעת לא ישמרו.');
  //   return true;

  // }
  
}
