<div class="container-fluid">
  <div class="logo pull-right"></div>
  <div *ngIf="!in" class="d-flex pull-left top-btns">
      <button #btnMenu (click)="isMenuOpen = !isMenuOpen" class="btn-transparent">
        <i class="fa fa-bars"></i>
      </button>
      <button class="btn btn-blue btn-round" (click)="goToLogin()">כניסה</button>
      <button class="btn btn-light btn-round" routerLink="/new-user">הרשמה</button>      
  </div>
  <div *ngIf="isMenuOpen" #menu (click)="hideMenu()">
      <ul class="navbar-nav">
        <li routerLink="/login" routerLinkActive="active">דף הבית</li><div class="hr"></div>
        <li routerLink="/info/about" routerLinkActive="active">אודותינו</li><div class="hr"></div>
        <li routerLink="/info/how-it-works" routerLinkActive="active">איך זה עובד</li><div class="hr"></div>      
        <li routerLink="/info/contact" routerLinkActive="active">צרו קשר</li>      
      </ul>
  </div>
  <div class="clearfix"></div>
</div>
