<div
  class="text-center"
  style="font-size: 3.05em; padding-top: 30px; color: #565656"
>
  להקים בית בישראל
</div>
<div class="text-center" style="font-size: 2.15em; color: #565656">
  בסיוע שדכניות מקצועיות
</div>
<div class="form-login">
  <form novalidate [formGroup]="myform" autocomplete="off">
    <div class="header">משתמש חדש / הרשמה</div>
    <div style="padding: 20px 60px" (keydown.enter)="submit()">
      <div *ngIf="isExistsUser && msg == ''">
        <p class="text-center pink-color">דוא"ל זה כבר קיים במערכת</p>
        <!-- <p class="text-center pink-color">(אם הנך רוצה להקים משתמש נוסף אנא השתמש בדוא"ל אחר)</p> -->
        <button
          *ngIf="!byShadchan"
          routerLink="/login"
          class="btn btn-blue btn-round d-block w-100 mx-auto"
        >
          כניסה לרשומים
        </button>
      </div>
      <div *ngIf="isDuplicateLastFirstPhone">
        <p class="text-center pink-color">משתמש זה נראה כקיים במערכת</p>
        <p class="text-center pink-color">לבדיקת המשתמש נא פנה ל</p>
        <p class="text-center pink-color" dir="ltr">6093636@gmail.com</p>
        <button
          *ngIf="!byShadchan"
          routerLink="/login"
          class="btn btn-blue btn-round d-block w-100 mx-auto"
        >
          כניסה לרשומים
        </button>
      </div>

      <alert *ngIf="msg != ''" type="pink alert-top">
        <p class="text-center">{{ msg }}</p>
      </alert>
      <div
        *ngIf="
          ((!isExistsUser && !isDuplicateLastFirstPhone) || byShadchan) &&
          msg == ''
        "
      >
        <p class="text-center pink-color">
          <b>שים לב!</b><br />
          חשוב למלא מייל תקין ואישי. למייל הזה ישלחו ההצעות שלך מהמערכת.
        </p>
        <hr />
        <p *ngIf="!fix" class="text-center pink-color">יש למלא את כל הפרטים</p>
        <div
          *ngIf="showGender"
          class="row-flex-between gender"
          btnRadioGroup
          formControlName="gender"
          [ngClass]="{ invalid: !fix && myform.controls.gender.invalid }"
        >
          <label>מי אני?</label>
          <button
            btnRadio="1"
            class="btn btn-outline-blue btn-round"
            type="button"
          >
            <fa name="male left-space"></fa>גבר
          </button>
          <button
            btnRadio="2"
            class="btn btn-outline-pink btn-round"
            type="button"
          >
            <fa name="female left-space"></fa>אישה
          </button>
        </div>
        <input
          class="form-control email"
          type="text"
          placeholder="דוא״ל"
          formControlName="email"
          autocomplete="nope"
          [ngClass]="{ invalid: !fix && myform.controls.email.invalid }"
          [attr.disabled]="byShadchan ? '' : null"
        />
        <p
          class="pink-color"
          *ngIf="myform.controls.email.invalid && myform.controls.email.touched"
        >
          כתובת המייל אינה תקינה
        </p>
        <p class="pink-color" *ngIf="byShadchan && myform.controls.email.dirty">
          שינוי המייל יעדכן גם את המייל שהכנסת בפרטי המשודך
        </p>
        <div>
          <input
            class="form-control"
            [type]="showPass ? 'text' : 'password'"
            placeholder="סיסמא"
            autocomplete="new-password"
            formControlName="password"
            [ngClass]="{ invalid: !fix && myform.controls.password.invalid }"
          />
          <fa
            [name]="showPass ? 'eye-slash' : 'eye'"
            class="btn-eye"
            (click)="showPass = !showPass"
          ></fa>
        </div>
        <p
          class="pink-color"
          *ngIf="
            myform.controls.password.invalid && myform.controls.password.touched
          "
        >
          על הסיסמא להיות באורך 6 תוים לפחות, ולכלול אות ומספר
        </p>
        <input
          class="form-control"
          type="text"
          placeholder="שם פרטי"
          formControlName="firstName"
          [ngClass]="{ invalid: !fix && myform.controls.firstName.invalid }"
        />
        <input
          class="form-control"
          type="text"
          placeholder="שם משפחה"
          formControlName="lastName"
          [ngClass]="{ invalid: !fix && myform.controls.lastName.invalid }"
        />
        <input
          class="form-control"
          type="text"
          placeholder="טלפון"
          formControlName="phone"
          [ngClass]="{ invalid: !fix && myform.controls.phone.invalid }"
        />
        <p
          class="pink-color"
          *ngIf="myform.controls.phone.invalid && myform.controls.phone.touched"
        >
          מס' הטלפון אינו תקין
        </p>
        <button
          *ngIf="!showAsk"
          [disabled]="isBusy"
          class="btn btn-blue btn-round d-block mx-auto"
          (click)="submit()"
        >
          אישור
        </button>
        <div *ngIf="showAsk">
          יתכן כי פרטיך האישיים מופיעים במערכת האם שדכנית רשמה אותך למערכת?
          <div class="row-flex-between">
            <button
              class="btn btn-blue btn-round"
              style="width: 40%"
              (click)="addUserToExistsPerson()"
            >
              כן
            </button>
            <button
              class="btn btn-blue btn-round"
              style="width: 40%"
              (click)="addNewUser()"
            >
              לא
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
