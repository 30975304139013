import { Injectable } from '@angular/core';
import { User } from '../entities/entities';
import { Globals } from '../common/globals';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  id: number;
  isShadchan: boolean;
  isSystem: boolean;
  report: boolean;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  getAlerts: boolean;
  isLocked: boolean;
  gender: number;
  password: string;
  permission: number;
  isMultiAddUser: number;
  loginFirstName: string;
  loginLastName: string;
  israelID: string;

  tabMap: Map<string, number>;
  systemAlertsIds: Map<number, Array<number>>;
  members: Array<number>;
  personId: number;
  registerLevel: number;
  extension?: string;

  loginUser: User;

  public getId(): number {
    return this.id;
  }
  public setId(value: number) {
    this.id = value;
  }

  public getIsShadchan(): boolean {
    return this.isShadchan;
  }
  public setIsShadchan(value: boolean) {
    this.isShadchan = value;
  }

  public getIsSystem(): boolean {
    return this.isSystem;
  }
  public setIsSystem(value: boolean) {
    this.isSystem = value;
  }

  public getPermission(): number {
    return this.permission;
  }
  public setPermission(value: number) {
    this.permission = value;
  }

  public getEmail(): string {
    return this.email;
  }
  public setEmail(value: string) {
    this.email = value;
  }

  public getPhone(): string {
    return this.phone;
  }
  public setPhone(value: string) {
    this.phone = value;
  }

  public getLoginFirstName(): string {
    return this.loginFirstName;
  }
  public setLoginFirstName(value: string) {
    this.loginFirstName = value;
  }

  public getLoginLastName(): string {
    return this.loginFirstName;
  }
  public setLoginLastName(value: string) {
    this.loginFirstName = value;
  }

  public getFirstName(): string {
    return this.firstName;
  }
  public setFirstName(value: string) {
    this.firstName = value;
  }

  public getLastName(): string {
    return this.lastName;
  }
  public setLastName(value: string) {
    this.lastName = value;
  }

  public getGetAlerts(): boolean {
    return this.getAlerts;
  }
  public setGetAlerts(value: boolean) {
    this.getAlerts = value;
  }

  public getIsLocked(): boolean {
    return this.isLocked;
  }
  public setIsLocked(value: boolean) {
    this.isLocked = value;
  }

  public getGender(): number {
    return this.gender;
  }
  public setGender(value: number) {
    this.gender = value;
  }

  public getPassword(): string {
    return this.password;
  }

  public setPassword(value: string) {
    this.password = value;
  }

  constructor(private globals: Globals, private http?: HttpClient) {
    if (
      !globals.isDefined(this.id) &&
      sessionStorage.getItem('currentUser') != null
    ) {
      this.initUser(JSON.parse(sessionStorage.getItem('currentUser')));
      this.loginUser = JSON.parse(sessionStorage.getItem('loginUser'));
      // this.router.navigate(['/login']);
      // return;
    }
  }

  // initUser(members: Array<number>, user: User, personId: boolean) {
  initUser(userInfo: any) {
    if (this.globals.isDefined(userInfo.myUser)) {
      this.id = userInfo.myUser.id;
      this.isShadchan = userInfo.myUser.isShadchan;
      this.isSystem = userInfo.myUser.isSystem;
      this.report = userInfo.myUser?.report;
      this.email = userInfo.myUser.email;
      this.phone = userInfo.myUser.phone;
      this.firstName = userInfo.myUser.firstName;
      this.lastName = userInfo.myUser.lastName;
      this.gender = userInfo.myUser.gender;
      this.password = userInfo.myUser.password;
      this.permission = userInfo.myUser.permission;
      this.getAlerts = userInfo.myUser.getAlerts;
      this.isLocked = userInfo.myUser.isLocked;
      this.loginFirstName = userInfo.myUser.loginFirstName;
      this.loginLastName = userInfo.myUser.loginLastName;
      this.israelID = userInfo.myUser?.israelID;
      this.extension = userInfo.myUser?.extension;
    } else {
      this.id = userInfo.id;
      this.isShadchan = userInfo.isShadchan;
      this.isSystem = userInfo.isSystem;
      this.email = userInfo.email;
      this.phone = userInfo.phone;
      this.firstName = userInfo.firstName;
      this.lastName = userInfo.lastName;
      this.gender = userInfo.gender;
      this.password = userInfo.password;
      this.permission = userInfo.permission;
      this.getAlerts = userInfo.getAlerts;
      this.isLocked = userInfo.isLocked;
      this.loginFirstName = userInfo.loginFirstName;
      this.loginLastName = userInfo.loginLastName;
      this.israelID = userInfo?.israelID;
      this.extension = userInfo?.extension;
    }
    console.log(this.getAlerts);
    this.personId = userInfo.personId;
    this.tabMap = userInfo.tabMap;
    this.systemAlertsIds = userInfo.systemAlertsIds;
    this.registerLevel = userInfo.registerLevel;
    if (this.isShadchan) {
      this.members = userInfo.members == null ? [this.id] : userInfo.members;
    }
  }

  initLoginUser() {
    this.loginUser = {
      id: this.id,
      isShadchan: this.isShadchan,
      isSystem: this.isSystem,
      email: this.email,
      phone: this.phone,
      firstName: this.firstName,
      lastName: this.lastName,
      gender: this.gender,
      password: this.password,
      permission: this.permission,
      getAlerts: this.getAlerts,
      isLocked: this.isLocked,
      israelID: this?.israelID,
    } as User;
  }

  sendEmail(user: User, subject: string, body: string) {
    this.http
      .post(
        this.globals.apiURL +
          `shadchan/get/sendEmail?userIDRequested=${this.id}-${this.firstName}-${this.lastName}`,
        {
          from: '',
          to: user.email,
          subject,
          body,
        },
        {
          responseType: 'text' as 'json',
          headers: {},
        }
      )
      .toPromise();
  }
}
