<div class="d-block mx-auto" style="width: fit-content;padding: 30px;">
    <button class="btn btn-round btn-blue" (click)="export()">
        <fa name="cloud-download left-space"></fa>יצירת גיבוי
    </button>&nbsp;&nbsp;&nbsp;
    <button class="btn btn-round btn-blue" (click)="import()">
        <fa name="cloud-upload left-space"></fa>שחזור DB
    </button> 
 <!--    <button class="btn btn-round btn-blue" (click)="showImport=true">
        <fa name="cloud-upload left-space"></fa>שחזור DB
    </button> 
   <div *ngIf="showImport" class="card d-block">
        <h3>בחר את הקובץ ממנו ישוחזר הDB</h3>  

        <input type="file" class="file-input"
       (change)="onFileSelected($event)" #fileUpload>

<div class="file-upload">
    <button class="btn btn-blue btn-round" (click)="fileUpload.click()">
        <fa name="upload left-space"></fa>בחר את הקובץ ממנו ישוחזר הDB
    </button>&nbsp;&nbsp;&nbsp;{{fileName || "לא נבחר קובץ"}}
</div>
<br>
    <button class="btn btn-round btn-blue" (click)="import()">
        <fa name="check left-space"></fa>אישור
    </button>
    
</div>
    
    -->


</div>
