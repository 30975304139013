import { Component, OnInit } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { UserService } from '../common/user.service';
import { Globals } from '../common/globals';
import { Reports, UserReports ,ProductivityReport , User} from '../entities/entities';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-users-reports',
  templateUrl: './users-reports.component.html',
  styleUrls: ['./users-reports.component.css']
})
export class UsersReportsComponent implements OnInit {

  period: number = 0;
  gender: number = 0;
  reports : Array<UserReports>;
  totalUsers: number;
  systemEnv: string;
  isAdmin: boolean;
  shadchanList: Array<User>;
  productivityReports: Array<ProductivityReport>;
  shadchanId: number;

  constructor(private http: HttpClient,private userService: UserService,private globals: Globals) { }

  ngOnInit(): void {
    this.isAdmin = sessionStorage.getItem('isAdmin')== 'true';
    this.getSystemEnv();
    this.getShadchanList();
   }



  getSystemEnv(){

    console.log("login.component getSystemEnv");
    console.log("environment - baseUrl - " + environment.baseUrl);
    var promise =  new Promise((resolve, reject) => {
      this.http.get(this.globals.apiURL +`users/systemEnvironment`,{responseType: 'text'} 
      ).subscribe(data => {
        console.log("system environment is - " +data.toString());
        this.systemEnv = data.toString();
      });
    });
    return promise;
  }



  showUsers(){
    if(this.period == 0 || this.gender == 0){
      alert("אחד מהשדות לא נבחר אנא בחר תקופה ואוכלוסיה בבקשה ....");
    }
      console.log("call http users/getUsersByPeriod");
      this.http.get<Array<UserReports>>(this.globals.apiURL + `users/getUsersByPeriod?period=${this.period}&gender=${this.gender}&userIDRequested=${this.userService.id}-${this.userService.firstName}`, { responseType: 'json' })
      .subscribe(data => {
        console.log(data);
        this.reports = data;
        this.totalUsers = this.reports.pop().numberOfUsers;
      });

  }
  
  getUsersByPeriod(event){
     const value = event.target.value;
     this.period = value;
     console.log(value);
  }


  getShadchanList(){
    console.log("call http users/getShadchanList");
    this.http.get<Array<User>>(this.globals.apiURL + `users/getShadchanList`, { responseType: 'json' })
    .subscribe(data => {
      console.log(data);
      this.shadchanList = data;
    });
  }

  setShadchanId(event){
     const value = event.target.value;
     this.shadchanId = value;
  }

  
  showProductivityReport(){
      console.log("call http users/getProductivityReport");
      this.http.get<Array<ProductivityReport>>(this.globals.apiURL + `users/getProductivityReport?shadchanID=${this.shadchanId}`, { responseType: 'json' })
      .subscribe(data => {
        console.log(data);
         this.productivityReports = data;
      });
  }

  








}
