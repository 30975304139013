import { Pipe, PipeTransform } from '@angular/core';
import { Person } from '../entities/entities';

@Pipe({
  name: 'myOfferFilter'
})
export class MyOfferFilterPipe implements PipeTransform {

  transform(list:Array<any>, listFiltered:Array<any>): unknown {
      if (!list || list.length == 0 ) 
        return list;
      if(!listFiltered || listFiltered.length == 0)
        return [];
      return listFiltered;
  }
}
