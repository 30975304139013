import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AddUserComponent } from './add-user/add-user.component';
import { ShadchanFolderComponent } from './shadchan-folder/shadchan-folder.component';
import { UserFolderComponent } from './user-folder/user-folder.component';
import { AddManStepsComponent } from './add-man-steps/add-man-steps.component';
import { FamilyStatusAsksComponent } from './family-status-asks/family-status-asks.component';
import { AddManComponent } from './add-man/add-man.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PasswordComponent } from './password/password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { OfferPairComponent } from './offer-pair/offer-pair.component';
import { OfferComponent } from './offer/offer.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { AdminFolderComponent } from './admin-folder/admin-folder.component';
import { SettingComponent } from './setting/setting.component';
import { AddShadchanComponent } from './add-shadchan/add-shadchan.component';
import { GroupsComponent } from './groups/groups.component';
import { UsersManageComponent } from './users-manage/users-manage.component';
import { UserUnavailableComponent } from './user-unavailable/user-unavailable.component';
import { SystemAlertsComponent } from './system-alerts/system-alerts.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertsUserComponent } from './alerts-user/alerts-user.component';
import { CanDeactivateGuard } from './common/can-deactivate-guard';
import { AuthGuard } from './common/auth-guard';
import { DbManageComponent } from './db-manage/db-manage.component';
import { DelShadchanComponent } from './del-shadchan/del-shadchan.component';
import { EditNotesStatusComponent } from './edit-notes-status/edit-notes-status.component';
import { RemoveMeComponent } from './remove-me/remove-me.component';
import { InfoPagesComponent } from './info-pages/info-pages.component';
import { AddMultiUserMeshudachComponent } from './add-multi-user-meshudach-folder/add-multi-user-meshudach.component';
import { UsersReportsComponent } from './users-reports/users-reports.component';
import { ShadchanNotesComponent } from './shadchan-notes/shadchan-notes.component';
import { ChatComponent } from './chat/chat.component';
import { ShadchanListComponent } from './shadchan-list/shadchan-list.component';
import { ShdchanReportComponent } from './shdchan-report/shdchan-report.component';

const routes: Routes = [
  { path: 'info/:name', component: InfoPagesComponent },
  { path: 'login', component: LoginComponent, data: { title: 'כניסה למערכת' } },
  {
    path: 'login/:returnUrl',
    component: LoginComponent,
    data: { title: 'הזדהות' },
  },
  // {path: 'login', component: LoginComponent, data: {title: 'כניסה למערכת'}},
  {
    path: 'login/:token',
    component: LoginComponent,
    data: { title: 'הזדהות' },
  },
  {
    path: 'password/:action',
    component: PasswordComponent,
    data: { title: 'איפוס/ שינוי סיסמא' },
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    data: { title: 'איפוס סיסמא' },
  },
  {
    path: 'new-user',
    component: AddUserComponent,
    data: { title: 'משתמש חדש' },
  },
  {
    path: 'new-user/:user',
    component: AddUserComponent,
    data: { title: 'משתמש חדש' },
  },
  {
    path: 'user-unavailable/:state',
    component: UserUnavailableComponent,
    data: { title: '' },
  },
  { path: 'remove-me', component: RemoveMeComponent, data: { title: '' } },
  {
    path: 'register-by-steps/:level',
    component: AddManStepsComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'הרשמה' },
  },
  {
    path: 'register/:state',
    component: AddManComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'הרשמה' },
  },
  {
    path: 'shadchan-folder',
    component: ShadchanFolderComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'offer-pair/:type',
        component: OfferPairComponent,
        data: { title: '' },
      },
      { path: 'offer/:type', component: OfferComponent, data: { title: '' } },
      {
        path: 'system-alerts',
        component: SystemAlertsComponent,
        data: { title: 'התראות מערכת' },
      },
      {
        path: 'alerts',
        component: AlertsComponent,
        data: { title: 'תזכורות' },
      },
      {
        path: 'add-shadchan/:state',
        component: AddShadchanComponent,
        data: { title: 'הפרטים שלי' },
      },
      {
        path: 'chat',
        component: ChatComponent,
        data: { title: 'צ׳אט' },
      },
      {
        path: 'shadchan-list',
        component: ShadchanListComponent,
        data: { title: 'רשימת שדכנים' },
      },
      {
        path: 'shdchan-report',
        component: ShdchanReportComponent,
        data: { title: 'דוחות לשדכנים' },
      },
    ],
  },
  {
    path: 'user-folder',
    component: UserFolderComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'offer/:type', component: OfferComponent, data: { title: '' } },
      {
        path: 'alerts-user',
        component: AlertsUserComponent,
        data: { title: 'תזכורות' },
      },
      {
        path: 'user-details',
        component: UserDetailsComponent,
        data: { title: 'הפרטים שלי' },
      },
      {
        path: 'shadchan-notes',
        component: ShadchanNotesComponent,
        data: { title: 'הערות שדכנ/ית' },
      },
      {
        path: 'register/:state',
        component: AddManComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'עדכון פרטים' },
      },
      {
        path: 'edit-notes-status',
        component: EditNotesStatusComponent,
        data: { title: 'עדכון פרטים' },
      },
      {
        path: 'chat/:isPersonChat',
        component: ChatComponent,
        data: { title: 'צאט עם שדכנים' },
      },
    ],
  },
  {
    path: 'admin-folder',
    component: AdminFolderComponent,
    children: [
      {
        path: 'chat',
        component: ChatComponent,
        data: { title: 'צ׳אט' },
      },
      {
        path: 'setting',
        component: SettingComponent,
        data: { title: 'הגדרות' },
      },
      {
        path: 'add-shadchan/:state',
        component: AddShadchanComponent,
        data: { title: 'הוספת שדכנ/ית' },
      },
      {
        path: 'del-shadchan',
        component: DelShadchanComponent,
        data: { title: 'העברה/ מחיקת שדכנ/ית' },
      },
      { path: 'groups', component: GroupsComponent, data: { title: 'קבוצות' } },
      {
        path: 'users-manage',
        component: UsersManageComponent,
        data: { title: 'משתמשים' },
      },
      {
        path: 'db-manage',
        component: DbManageComponent,
        data: { title: 'ניהול DB' },
      },
      {
        path: 'shdchan-report',
        component: ShdchanReportComponent,
        data: { title: 'דוחות לשדכנים' },
      },
      {
        path: 'users-reports',
        component: UsersReportsComponent,
        data: { title: 'דוחות משתמשים' },
      },
    ],
  },
  {
    path: 'add-multi-user-meshudach-folder',
    component: AddMultiUserMeshudachComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'register/:state',
        component: AddManComponent,
        canActivate: [AuthGuard],
        data: { title: 'עדכון פרטים' },
      },
    ],
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }, // Wildcard route for a 404 page
  // {path:'a',component: AaComponent ,data: {title: 'Login'}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
