import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import { Pair, Person, User } from '../entities/entities';
import { Answers, AskAnswer, Asks } from '../entities/AskAnswer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';

interface Statistics {
  totalAdded: number;
  manAdded: number;
  womanAdded: number;
  addedAlone: number;
  addedNotEnd: number;
  movedToNonActive: number;
  movedToErusin: number;
}

interface ShadchanStatistics {
  user: User;
  myPersons: number;
  myCare: number;
  movedCheck: number;
  movedMeet: number;
  movedNotMatch: number;
}

@Component({
  selector: 'app-shdchan-report',
  templateUrl: './shdchan-report.component.html',
  styleUrls: ['./shdchan-report.component.css'],
})
export class ShdchanReportComponent {
  @ViewChild('offersModal') offersModal;
  modalRef: BsModalRef;

  startValue: Date = moment().set('hour', 3).startOf('day').toDate();
  endValue: Date = moment().endOf('day').toDate();
  personList: Array<Person> = [];
  pairsList: Array<Pair>;
  asks: Array<Asks>;
  allAsks: Array<Asks>;
  asksMen: Array<Asks>;
  asksWomen: Array<Asks>;
  answers: Array<Answers>;
  offersType: string;

  adminStatistics: Statistics;

  shadchanStatistics: ShadchanStatistics[] = [];
  isMobile: any = false;
  shadchanFilter: string = '';

  filterUser(user: ShadchanStatistics) {
    return this.shadchanFilter?.length > 2
      ? user?.user?.firstName?.includes(this.shadchanFilter) ||
          user?.user?.lastName?.includes(this.shadchanFilter)
      : true;
  }

  selectStartDate(date: Date) {
    this.startValue = moment(date).startOf('day').toDate();
  }
  selectEndDate(date: Date) {
    this.endValue = moment(date).endOf('day').toDate();
  }

  search() {
    this.getUsersNumbering();
    this.getShadchanStatistics();
  }

  constructor(
    private localeService: BsLocaleService,
    private http: HttpClient,
    private globals: Globals,
    private userService: UserService,
    private modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.localeService.use('he');
    this.getUsersNumbering();
    this.getShadchanStatistics();
    this.getAskAnswer();
  }

  getAskAnswer() {
    console.log('getAskAnswer offer-pair.component');

    this.http
      .get<AskAnswer>(
        this.globals.apiURL +
          `actions/getAsks?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        this.asks = data.askList.concat(data.subAskList);
        this.allAsks = JSON.parse(JSON.stringify(this.asks));
        this.answers = data.ansList;
        this.initDependentParams(this.asks, this.answers);
      }); //add throwError
  }

  initDependentParams(asks, answers) {
    asks = asks.sort((a, b) => (a.orderAll > b.orderAll ? 1 : -1));
    // this.asksIdMap = asks.reduce(function (r, a) {
    //   r[a.id] = r[a.id] || []; r[a.id].push(a); return r;
    // }, Object.create(null));
    asks[asks.findIndex(({ name }) => name == 'moreToShadchan')]['man'] =
      'עוד פרטים לשדכן';
    asks[asks.findIndex(({ name }) => name == 'moreToShadchan')]['woman'] =
      'עוד פרטים לשדכן';
    asks[asks.findIndex(({ name }) => name == 'moreToDisplay')]['man'] =
      'עוד קצת עלי';
    asks[asks.findIndex(({ name }) => name == 'moreToDisplay')]['woman'] =
      'עוד קצת עלי';
    let arrInTitle = [
      'firstName',
      'birthdate',
      'religiousLevel',
      'familyStatus',
      'city',
    ];
    asks = asks
      .filter(({ isPublic }) => isPublic)
      .filter((o) => !arrInTitle.includes(o.name));
    this.asksMen = asks.filter((o) => o.man !== '0');
    this.asksWomen = asks.filter((o) => o.woman !== '0');
    // answers = answers.filter(o => o[this.gender] !== "0");
    // console.log(this.men);
    // for (let ask of asks)
    //   if (this.men[ask.name] != "")
    //     this.asksPerMen.push(ask);
    // this.isLoaded = true;

    this.asks = asks;
    this.answers = answers;
  }

  getUsersNumbering() {
    this.http
      .get<Statistics>(
        this.globals.apiURL +
          `users/getStatistics?start=${this.startValue.toISOString()}&end=${this.endValue.toISOString()}`
      )
      .subscribe((data) => {
        this.adminStatistics = data;
      });
  }

  getShadchanStatistics() {
    this.http
      .get<Array<ShadchanStatistics>>(
        this.globals.apiURL +
          `users/getShadchanStatistics?start=${this.startValue.toISOString()}&end=${this.endValue.toISOString()}`
      )
      .subscribe((data) => {
        this.shadchanStatistics = data;
      });
  }

  clear() {
    this.personList = [];
    this.pairsList = [];
  }

  getPersonStatistics(type) {
    this.clear();
    this.http
      .get<Array<Person>>(
        this.globals.apiURL +
          `users/getStatisticsPerson?type=${type}&start=${this.startValue.toISOString()}&end=${this.endValue.toISOString()}`
      )
      .subscribe((data) => {
        this.personList = data;
        if (data?.length)
          this.modalRef = this.modalService.show(this.offersModal, {
            class: 'modal-lg',
            backdrop: 'static',
          });
      });
  }

  personOfShadchan(shadchanId) {
    this.clear();
    this.http
      .get<Array<Person>>(
        this.globals.apiURL +
          `users/personOfShadchan?shadchanId=${shadchanId}&start=${this.startValue.toISOString()}&end=${this.endValue.toISOString()}`
      )
      .subscribe((data) => {
        this.personList = data;
        if (data?.length)
          this.modalRef = this.modalService.show(this.offersModal, {
            class: 'modal-lg',
            backdrop: 'static',
          });
      });
  }

  getOffers(offersType, shadchanId) {
    this.clear();
    this.offersType = offersType;
    this.http
      .get<Array<Pair>>(
        this.globals.apiURL +
          `shadchan/get/offers?type=${offersType}&shadchanId=${shadchanId}&userIDRequested=${
            this.userService.id
          }-${this.userService.firstName}-${
            this.userService.lastName
          }&firstTime=false&start=${this.startValue.toISOString()}&end=${this.endValue.toISOString()}`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        this.pairsList = data;
        if (data?.length)
          this.modalRef = this.modalService.show(this.offersModal, {
            class: 'modal-lg',
            backdrop: 'static',
          });
      });
  }

  goToUserFolder(p: Person, gender: number) {
    this.userService.setLoginFirstName(this.userService.firstName);
    this.userService.setLoginLastName(this.userService.lastName);
    this.userService.setGender(gender);
    this.userService.setFirstName(p.firstName);
    this.userService.setLastName(p.lastName);
    this.userService.personId = p.id;
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`user-folder/user-details`])
    );
    this.router.navigate([url]);
  }

  goToPersonFolder(p: Person, type: string) {
    let u: UserService = new UserService(this.globals);
    u.setLoginFirstName(u.getFirstName());
    u.setLoginLastName(u.getLastName());
    u.setGender('zakan' in p ? 1 : 2);
    u.setFirstName(p.firstName);
    u.setLastName(p.lastName);
    u.personId = p.id;
    sessionStorage.setItem('currentUser', JSON.stringify(u));
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`user-folder/${type}`], {
        queryParams: {
          params1: this.userService.loginFirstName,
          params2: this.userService.loginLastName,
        },
      })
    );

    //const url = this.router.serializeUrl(this.router.createUrlTree([`user-folder/${type}`]));
    // this.router.navigateByUrl(url);
    window.open('/#' + url, '_blank');
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
  }
}
