import { Component, OnInit } from '@angular/core';
import { Globals } from '../common/globals';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { ShadchanDefault, User } from '../entities/entities';
import { UserService } from '../common/user.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css'],
})
export class SettingComponent implements OnInit {
  selected1: number = 0;
  selected2: number = 0;
  collapses: Array<boolean> = [false, true];
  addMsg1: boolean = false;
  addMsg2: boolean = false;
  addMsg3: boolean = false;
  notic: boolean = false;
  isLoaded: Promise<boolean>;
  shadchanList: Array<User>;
  shadchanSystemList: Array<User>;
  shadchanSystemListPrev: Array<User>;
  shadchanReportedList: Array<User>;
  sDefault: ShadchanDefault;

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.getShadchanDefault();
    this.getShadchanList();
  }

  getShadchanList() {
    this.http
      .get<Array<User>>(
        this.globals.apiURL +
          `shadchan/get/shadchanPublicList` +
          `?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        this.shadchanList = data;
        this.shadchanSystemList = this.shadchanList.filter((o) => o.isSystem);
        this.shadchanSystemListPrev = JSON.parse(
          JSON.stringify(this.shadchanSystemList)
        );
        // console.log();
      }); //add throwError
    this.http
      .get<Array<User>>(
        this.globals.apiURL +
          `shadchan/get/systemList` +
          `?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        this.shadchanSystemList = data;
        // console.log();
      }); //add throwError

    this.updateReported();
  }

  updateReported() {
    this.http
      .get<Array<User>>(this.globals.apiURL + `shadchan/get/reportedList`, {
        responseType: 'json',
      })
      .subscribe((data) => {
        this.shadchanReportedList = data;
      }); //add throwError
  }

  getShadchanDefault() {
    this.http
      .get<ShadchanDefault>(
        this.globals.apiURL +
          `actions/getShadchanDefault?userIDRequested=${this.userService.id}`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        this.sDefault = data;
        this.isLoaded = Promise.resolve(true);
      }); //add throwError
  }

  addShadchan(id: number) {
    if (id != 0) {
      this.shadchanSystemList.push(
        this.shadchanList.filter((o) => o.id == id)[0]
      );
      this.selected1 = 0;
      this.notic = false;
    }
  }

  addShadchaToReport(id: number, add = true) {
    this.http
      .post(
        this.globals.apiURL + `shadchan/set/addToReport/${id}?toAdd=${add}`,
        {}
      )
      .subscribe((response) => {
        this.addMsg3 = true;
        this.updateReported();
      });
  }

  updateSDefault(id: number) {
    console.log(id + '  ' + this.sDefault.id);
    if (id != this.sDefault.id && id != 0) {
      this.http
        .post(
          this.globals.apiURL +
            `actions/setShadchanDefault/${id}?userIDRequested=${this.userService.id}`,
          {}
        )
        .subscribe((response) => {
          console.log(response.toString());
          this.sDefault.id = id;
          this.addMsg2 = true;
        });
    }
  }

  delShadchan(id: number) {
    this.shadchanSystemList = this.shadchanSystemList.filter((o) => o.id != id);
  }

  saveShadchanSystemList() {
    let stId: string = this.shadchanSystemList.map((o) => o.id).toString();
    if (
      JSON.stringify(this.shadchanSystemListPrev) ===
        JSON.stringify(this.shadchanSystemList) ||
      stId == ''
    ) {
      this.notic = true;
      return;
    }
    this.http
      .post(
        this.globals.apiURL +
          `shadchan/set/systemList` +
          `?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        stId,
        {}
      )
      .subscribe((response) => {
        console.log(response.toString());
        this.addMsg1 = true;
      }); //add throwError
  }
}
