import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { User, UsersGroups, Groups } from '../entities/entities';
import {
  Routes,
  RouterModule,
  ActivatedRoute,
  Router,
  Params,
} from '@angular/router';
import { DownloadService } from '../services/download.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-add-shadchan',
  templateUrl: './add-shadchan.component.html',
  styleUrls: ['./add-shadchan.component.css'],
})
export class AddShadchanComponent implements OnInit {
  user: User = new User();
  groupList: Array<Groups>;
  myform: UntypedFormGroup;
  isExistsUser: boolean = false;
  addMsg: boolean = false;
  isAdmin: boolean;
  showPass: boolean = false;
  isLoad: boolean = false;
  isBusy: boolean = false;
  fix: boolean = true;
  state: string;
  msg: string;
  notic: string = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private globals: Globals,
    private userService: UserService,
    private route: ActivatedRoute,
    private downloadService: DownloadService
  ) {}

  ngOnInit(): void {
    this.state = this.route.snapshot.params.state;
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true';
    console.log(this.isAdmin);
    if (this.state == 'edit') this.getDetails();
    else this.initForm();
  }

  initForm() {
    this.myform = new UntypedFormGroup({
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      permission: new UntypedFormControl(0, Validators.required),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('[^ @]*@[^ @]*'),
      ]),
      phone: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('0[0-9]{8,9}'),
      ]),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.globals.passwordPattern),
      ]),
      groupId: new UntypedFormControl(0, []),
    });
    this.isLoad = true;
  }

  // selectGroup(){
  //   this.groupSelected=true;
  //   this.getGroupList();
  // }

  exportMensToExcel() {
    this.http
      .get(
        this.globals.apiURL +
          `db/exportMensToExcel/` +
          this.userService.getId() +
          `?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        { responseType: 'blob' }
      )
      .subscribe((blob) => saveAs(blob, 'meshudachim.xlsx')),
      (err: HttpErrorResponse) => {
        console.log(err);
      };
  }

  exportWoMansToExcel() {
    this.http
      .get(
        this.globals.apiURL +
          `db/exportWomansToExcel/` +
          this.userService.getId() +
          `?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        { responseType: 'blob' }
      )
      .subscribe((blob) => saveAs(blob, 'meshudachim.xlsx')),
      (err: HttpErrorResponse) => {
        console.log(err);
      };
  }

  getGroupList() {}

  getDetails() {
    this.http
      .get<User>(
        this.globals.apiURL +
          `shadchan/get/byId/${this.userService.getId()}` +
          `?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        { responseType: 'json' }
      )
      .subscribe(
        (data) => {
          this.user = data;
          console.log(this.user);
          this.myform = new UntypedFormGroup({
            firstName: new UntypedFormControl(
              this.user.firstName,
              Validators.required
            ),
            lastName: new UntypedFormControl(
              this.user.lastName,
              Validators.required
            ),
            permission: new UntypedFormControl(
              this.user.permission,
              Validators.required
            ),
            email: new UntypedFormControl(this.user.email, [
              Validators.required,
              Validators.pattern('[^ @]*@[^ @]*'),
            ]),
            phone: new UntypedFormControl(this.user.phone, [
              Validators.required,
              Validators.pattern('[0-9]{9,10}'),
            ]),
            extension: new UntypedFormControl(this.user.extension, [
              Validators.pattern('[0-9]+'),
            ]),
            password: new UntypedFormControl(this.user.password, [
              Validators.required,
              Validators.pattern(this.globals.passwordPattern),
            ]),
            getAlerts: new UntypedFormControl(this.user.getAlerts + '', []),
          });
          this.isLoad = true;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  addToGroup(id) {
    this.http
      .post(
        this.globals.apiURL +
          `shadchan/set/group?id=${id}&idG=${this.myform.controls.groupId.value}` +
          `&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        {}
      )
      .subscribe(
        (response) => {
          console.log(response.toString());
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  submit() {
    console.log(this.myform.value);
    console.log(this.myform.status);

    if (this.myform.valid) {
      if (this.state == 'add') {
        if (
          this.myform.controls.permission.value == 4 &&
          this.myform.controls.groupId.value == 0
        ) {
          this.fix = false;
          return;
        }
        this.user = this.myform.value;
        if ('groupId' in this.user) delete this.user.groupId;
        this.user.isShadchan = true;
        this.user.isSystem = false;
        this.user.isLocked = false;
        console.log(this.user);
        this.add();
      } else {
        if (!confirm('האם הנך בטוח שברצונך לעדכן את פרטי השדכנ/ית?')) return;
        console.log(this.user.permission);
        for (let field in this.myform.controls)
          this.user[field] = this.myform.get(field).value;
        this.http
          .put(
            this.globals.apiURL +
              `actions/edit?className=db.Users&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
            JSON.stringify(this.user),
            {
              headers: { 'Content-Type': 'application/json' },
            }
          )
          .subscribe(
            (response) => {
              console.log(response.toString());
              this.msg = 'העדכון בוצע בהצלחה!';
              this.addMsg = true;
            },
            (err: HttpErrorResponse) => {
              console.log(err);
            }
          );
      }
    }
    // this.myform.reset();
    else this.fix = false;
  }

  add() {
    this.user.getAlerts = false;
    this.isBusy = true;
    this.http
      .post(
        this.globals.apiURL +
          `actions/add?className=db.Users&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        JSON.stringify(this.user),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .subscribe(
        (response) => {
          console.log(response.toString());
          this.user.id = parseInt(response.toString());
          console.log(this.user.id);
          this.fix = true;
          if (this.user.id > 0) {
            console.log(this.myform.controls.permission.value == 4);
            console.log(this.myform.controls.groupId.value > 0);
            if (
              this.myform.controls.permission.value == 4 &&
              this.myform.controls.groupId.value > 0
            )
              this.addToGroup(this.user.id);
            //needed?
            // this.userService.initUser(this.user);
            // console.log(this.userService);
            this.msg = 'ההוספה בוצעה בהצלחה!';
            this.addMsg = true;

            // sessionStorage.setItem('shadchanName', this.userService.getFirstName());
            // this.router.navigate(['/shadchan-folder',this.userService.getId()]);
          } else this.isExistsUser = true;
          this.isBusy = false;
          this.myform.reset();
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.isBusy = false;
        }
      );
  }
}
