//#region imports
import {
  Component,
  OnInit,
  Injectable,
  ElementRef,
  ViewChild,
  Input,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Globals } from '../common/globals';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FilterComponent } from '../filter/filter.component';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { retry, filter, map, catchError, timestamp } from 'rxjs/operators';
import { AskAnswer, Asks, Answers, PrefAsks } from '../entities/AskAnswer';
import {
  Person,
  PersonPref,
  Men,
  Women,
  MenPref,
  WomenPref,
  Recommend,
  OffersStatus,
  Pair,
  PairStatus,
} from '../entities/entities';
import {
  Routes,
  RouterModule,
  Router,
  ActivatedRoute,
  Params,
  NavigationEnd,
} from '@angular/router';
import { UserService } from '../common/user.service';
import { combineLatest, concat, forkJoin, merge } from 'rxjs';
//#endregion

@Component({
  selector: 'app-offer-pair',
  templateUrl: './offer-pair.component.html',
  styleUrls: ['./offer-pair.component.css'],
})
export class OfferPairComponent implements OnInit {
  offersType: string;
  asks: Array<Asks>;
  allAsks: Array<Asks>;
  asksMen: Array<Asks>;
  asksWomen: Array<Asks>;
  answers: Array<Answers>;
  pairsList: Array<Pair>;
  pairsListFiltered: Array<Pair>;
  pairsListRoot: Array<Person>;
  gender: string;
  otherGender: string;
  Gender: string;
  noResults: boolean = false;
  cancleModal: boolean = false;
  person: Person;
  offer: OffersStatus;
  offersDataMap: Map<string, Array<string>> = new Map();
  colors: Array<string> = ['pink', 'gray', 'blue', 'dark'];
  reactionIconMap = new Map([
    ['?', 'question'],
    ['x', 'times'],
    ['v', 'thumbs-up'],
  ]);
  reactionTextMap = new Map([
    ['v', 'התקבלה תגובה חיובית, שדכנית תחזור אליכם בהקדם בע"ה'],
    ['x', 'התקבלה תגובה שלילית'],
    ['?', 'עדיין לא התקבלה תגובה'],
  ]);

  reactionDateTextMap = new Map([
    ['check', ' בבירורים מתאריך : '],
    ['meet', ' בפגישות מתאריך : '],
    ['mzlt', ' דווח שהתארסו בתאריך : '],
    ['shadchanUsers', ' בטיפולי מתאריך : '],
    ['system', 'עודכן בתאריך : '],
    ['search', 'עודכן בתאריך : '],
  ]);

  showAlert = {
    finallyNo: 'ההצעה הוסרה',
    check: 'ההצעה הועברה להצעות בבירורים',
    meet: 'ההצעה הועברה להצעות בפגישות',
    mzlt: 'מזל טוב!!! ההצעה הועברה לשידוכים שהתארסו',
    ok: 'הפעולה שביצעת נשמרה',
  };
  actionDoneMsg: string;
  msgIcon: string;
  pageTitle: string;
  repo: number;
  modalRef: BsModalRef;
  @ViewChild(FilterComponent) filterComp;
  shadchanId: number;
  personsMzlt: Person[];

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.offersType = params.type;
      let isInSwitch: boolean = true;
      switch (this.offersType) {
        case 'meet':
          this.pageTitle = 'הצעות בפגישות';
          break;
        case 'check':
          this.pageTitle = 'הצעות בבירורים';
          break;
        case 'mzlt': {
          this.pageTitle = 'שידוכים שהתארסו';
          this.getMzlt();
          break;
        }

        case 'system':
          this.pageTitle = 'שידוכי מערכת';
          break;
        case 'shadchanUsers':
          this.pageTitle = 'בטיפולי';
          break;
        case 'search':
          this.pageTitle = 'חיפוש חופשי';
          break;
        default:
          isInSwitch = false;
      }
      // if(isInSwitch)
      //   this.titleService.setTitle(this.pageTitle);
      this.offersType = params.type;
      this.repo =
        this.offersType == 'check' || this.offersType == 'meet' ? 1 : 2;
      this.shadchanId = this.userService.getIsShadchan()
        ? this.userService.getId()
        : -1;
      this.initUserData();
      this.initOffersDataMap();
      this.getOffers();
      this.getAskAnswer();
      console.log(this.noResults);
    });
  }

  toggleRepo(n: number) {
    this.repo = n;
    this.getOffers();
  }

  initUserData() {
    if (this.userService.getGender() == 1) {
      // opposite!
      this.gender = 'woman';
      this.otherGender = 'man';
      this.Gender = 'Women';
      this.person = new Women();
    } else {
      this.gender = 'man';
      this.otherGender = 'woman';
      this.Gender = 'Men';
      this.person = new Men();
    }
    // this.isShadchan = this.userService.getIsShadchan();
    // this.userName = this.userService.getFirstName();
  }

  goToUserFolder(p: Person, gender: number) {
    this.userService.setLoginFirstName(this.userService.firstName);
    this.userService.setLoginLastName(this.userService.lastName);
    this.userService.setGender(gender);
    this.userService.setFirstName(p.firstName);
    this.userService.setLastName(p.lastName);
    this.userService.personId = p.id;
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`user-folder/user-details`])
    );
    this.router.navigate([url]);
  }

  getCheckersInfo(p: Person, col: string) {
    // checkers= ws return people arr with all wanted attr :gender, firstName, lastName
    // where id in idCheckers
    // and then it solve how to call "goToUserFolder(p: Person, gender: number) "

    if (this.globals.isDefined(p[col + 'Info'])) return;
    let g: string = 'zakan' in p ? 'man' : 'woman';
    console.log(p[col]);
    this.http
      .get<Array<string>>(
        this.globals.apiURL +
          `${g}/getCheckersInfo/${p[col]}` +
          `?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        p[col + 'Info'] = data;
        console.log(data);
      }); //add throwError
  }

  initOffersDataMap() {
    this.offersDataMap['system'] = [
      { title: 'בבירורים', icon: 'question-circle', onclick: 'check' },
      { title: 'לא מתאים', icon: 'times', onclick: 'finallyNo' },
    ];
    // this.offersDataMap['sent'] = [];
    // this.offersDataMap['receive'] = [{ title: 'אשר הצעה', icon: 'thumbs-up', onclick: 'ok' }, { title: 'לא מתאים', icon: 'times', onclick: 'no' }];
    // this.offersDataMap['search'] = [{ title: 'שמור הצעה', icon: 'star', onclick: 'favorite' }, { title: 'שלח הצעה', icon: 'thumbs-up', onclick: 'send' }, { title: 'לא מתאים', icon: 'times', onclick: 'no' }];
    // this.offersDataMap['favorites'] = [{ title: 'שלח הצעה', icon: 'thumbs-up', onclick: 'send' }, { title: 'לא מתאים', icon: 'times', onclick: 'no' }];
    // this.offersDataMap['refuse'] = [{ title: 'שמור הצעה', icon: 'star', onclick: 'favorite' }, { title: 'שלח הצעה', icon: 'thumbs-up', onclick: 'send' }, { title: 'לא מתאים', icon: 'times', onclick: 'no' }];
  }

  getMzlt() {
    combineLatest(
      this.http.get<Person[]>(this.globals.apiURL + `man/getByStatus/2`, {
        responseType: 'json',
      }),
      this.http.get<Person[]>(this.globals.apiURL + `woman/getByStatus/2`, {
        responseType: 'json',
      })
    ).subscribe((persons) => {
      this.personsMzlt = persons[0].concat(persons[1]);
      this.noResults = !(Array.isArray(persons) && persons?.length);
    });
  }

  getOffers() {
    if (this.offersType == 'search') {
      this.pairsList = [];
      this.noResults = false;
      return;
    }
    let shadchanId =
      this.repo == 1 || ['system', 'mzlt'].includes(this.offersType)
        ? this.userService.getId()
        : 0;
    this.http
      .get<Array<Pair>>(
        this.globals.apiURL +
          `shadchan/get/offers?type=${
            this.offersType
          }&shadchanId=${shadchanId}&userIDRequested=${this.userService.id}-${
            this.userService.firstName
          }-${this.userService.lastName}&firstTime=${
            this.userService.tabMap[this.offersType] != 0
          }`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        this.pairsList = data;
        this.pairsList = JSON.parse(
          JSON.stringify(this.pairsList).replace(/;/g, ', ')
        );
        this.pairsListRoot = JSON.parse(JSON.stringify(this.pairsList));
        this.pairsListFiltered = JSON.parse(JSON.stringify(this.pairsList));
        console.log(this.pairsList);
        this.noResults = !(Array.isArray(data) && data?.length);
        //removeCount
        if (['meet', 'check', 'mzlt'].includes(this.offersType)) {
          this.userService.tabMap[this.offersType] = 0;
          sessionStorage.setItem(
            'currentUser',
            JSON.stringify(this.userService)
          );
          console.log(this.userService.tabMap[this.offersType]);
        }
      }); //add throwError
  }

  getAskAnswer() {
    console.log('getAskAnswer offer-pair.component');

    this.http
      .get<AskAnswer>(
        this.globals.apiURL +
          `actions/getAsks?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        this.asks = data.askList.concat(data.subAskList);
        this.allAsks = JSON.parse(JSON.stringify(this.asks));
        this.answers = data.ansList;
        this.initDependentParams(this.asks, this.answers);
      }); //add throwError
  }

  initDependentParams(asks, answers) {
    asks = asks.sort((a, b) => (a.orderAll > b.orderAll ? 1 : -1));
    // this.asksIdMap = asks.reduce(function (r, a) {
    //   r[a.id] = r[a.id] || []; r[a.id].push(a); return r;
    // }, Object.create(null));
    asks[asks.findIndex(({ name }) => name == 'moreToShadchan')]['man'] =
      'עוד פרטים לשדכן';
    asks[asks.findIndex(({ name }) => name == 'moreToShadchan')]['woman'] =
      'עוד פרטים לשדכן';
    asks[asks.findIndex(({ name }) => name == 'moreToDisplay')]['man'] =
      'עוד קצת עלי';
    asks[asks.findIndex(({ name }) => name == 'moreToDisplay')]['woman'] =
      'עוד קצת עלי';
    let arrInTitle = [
      'firstName',
      'birthdate',
      'religiousLevel',
      'familyStatus',
      'city',
    ];
    asks = asks
      .filter(({ isPublic }) => isPublic)
      .filter((o) => !arrInTitle.includes(o.name));
    this.asksMen = asks.filter((o) => o.man !== '0');
    this.asksWomen = asks.filter((o) => o.woman !== '0');
    // answers = answers.filter(o => o[this.gender] !== "0");
    // console.log(this.men);
    // for (let ask of asks)
    //   if (this.men[ask.name] != "")
    //     this.asksPerMen.push(ask);
    // this.isLoaded = true;

    this.asks = asks;
    this.answers = answers;
  }
  //#endregion

  otherChecked(offer: OffersStatus, g: number) {
    //מסדר את הדברים כך שאם מישהו בכלל לא ידע שמבררים עליו, לא יראה את ההצעה בבסוף לא התאים
    if (offer.offerStatus != 5) return false;
    let t: number = this.pairsList
      .filter((o) => o.offer.id == offer.id)
      .map((o) => o.firstOk)[0];
    return t != g && offer.lastEditGender != g;
  }

  playBtn(action: string, offer: OffersStatus, g: number) {
    if (action !== 'shadchanUsers') {
      offer.dateStatus = this.globals.timeToString(new Date());
    } else {
      offer.dateCare = this.globals.timeToString(new Date());
    }
    if (action != 'finallyNo') offer.lastEditGender = g;
    offer.shadchan = this.userService.getId();
    offer.isShown = false;
    let isSecond = false;

    switch (
      action //allways edit
    ) {
      case 'check':
        if (offer.offerStatus == 5) {
          offer.lastEditGender = 3;
          isSecond = true;
        } else offer.offerStatus = 5;
        break;
      case 'system':
        delete offer.dateCare;
        offer.offerStatus = 4;
        break;
      case 'meet':
        offer.offerStatus = 6;
        break;
      case 'shadchanUsers': {
        offer.offerStatus = 14;
        break;
      }
      case 'finallyNo':
        if (this.otherChecked(offer, g)) offer.lastEditGender = 3;
        offer.offerStatus = 13;
        break;
      case 'mzlt':
        offer.offerStatus = 9;
        break;
    }
    this.http
      .post<PairStatus>(
        this.globals.apiURL +
          `shadchan/set/playBtnPair?action=${action}` +
          `&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        JSON.stringify(offer),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .subscribe(
        (data) => {
          // let res = parseInt(response.toString());
          console.log(data);
          let ps: PairStatus = data;
          if (
            ['no', 'mzlt'].includes(ps.man + ps.woman) &&
            !(action == 'mzlt' && !(ps.man + ps.woman).includes('no'))
          ) {
            this.msgIcon = 'times-circle';
            this.actionDoneMsg =
              'לא ניתן לבצע את הפעולה, כיון שאחד הצדדים מוגדר כלא פעיל/ מאורס';
          } else {
            this.msgIcon = 'check-circle';
            if (action == 'ok' && ps.man + ps.woman == 'ok') {
              // first answered ok
              this.actionDoneMsg = this.showAlert['ok'];
              this.pairsList[
                this.pairsList.findIndex((p) => p.offer.id == offer.id)
              ].firstOk = g;
              this.getOffers();
              // offer.lastEditGender = 3;
            } else if (action == 'check' && isSecond) {
              // זה השני שלוחץ על בירורים   אף אחד עדיין לא ענה
              this.actionDoneMsg = this.showAlert['ok'];
              this.pairsList[
                this.pairsList.findIndex((p) => p.offer.id == offer.id)
              ].offer = JSON.parse(JSON.stringify(offer));
              this.getOffers();
            } else if (
              action == 'ok' &&
              (ps.man == 'meet' || ps.woman == 'meet')
            ) {
              // אמור לעבור לפגישות  אבל יש כפילות בפגישות
              this.actionDoneMsg = this.getAlreadyMeetMsg(ps);
              this.getOffers();
            } else {
              if (action == 'ok' && ps.man + ps.woman == 'okok')
                //עובר לפגישות
                action = 'meet';
              this.actionDoneMsg = this.showAlert[action];
              console.log(offer.id);
              this.pairsList = this.pairsList.filter(
                (p) => p.offer.id !== offer.id
              );
              this.pairsListRoot = JSON.parse(JSON.stringify(this.pairsList));
              this.pairsListFiltered = this.pairsListFiltered.filter(
                (p) => p.offer.id !== offer.id
              );
              //removeCount from system offer
              if (this.offersType == 'system') {
                // && ['check','finallyNo'].includes(action)
                this.userService.tabMap['system']--;
                sessionStorage.setItem(
                  'currentUser',
                  JSON.stringify(this.userService)
                );
                console.log(this.userService.tabMap[this.offersType]);
              }
            }
          }
          // window.scroll(0, 0);
          // this.filterComp.clear(true);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.msgIcon = 'times-circle';
          this.actionDoneMsg = 'התרחשה שגיאה, אנא נסו מאוחר יותר';
        }
      );
  }

  updatePairsList(pairsListFiltered) {
    this.pairsListFiltered = pairsListFiltered;
  }

  getPairsList(pairsList) {
    this.pairsListFiltered = pairsList;
    this.pairsList = pairsList;
  }

  openModal(template: TemplateRef<any>) {
    if (this.cancleModal) {
      this.cancleModal = false;
      return;
    }
    this.actionDoneMsg = '';
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
    });
  }

  getAge(birthdate: string) {
    return new Date().getFullYear() - parseInt(birthdate.split('-')[0]);
  }

  getAlreadyMeetMsg(ps: PairStatus) {
    let g1 = 'המשודך נמצא כבר בתהליך פגישות עם משודכת אחרת.   ';
    let g2 = 'המשודכת נמצאת כבר בתהליך פגישות עם משודך אחר.   ';
    let info;
    if (ps.man == 'meet' && ps.woman == 'meet') info = g1 + 'ובנוסף, ' + g2;
    else if (ps.man == 'meet') info = g1;
    else if (ps.woman == 'meet') info = g2;
    return (
      ' עפ"י נתוני המערכת, ' + info + 'אם התהליך הקודם נפסק יש לעדכן זאת תחילה.'
    );
  }

  // getAlreadyMeetMsg(res) {
  //   let g1 = "המשודך נמצא כבר בתהליך פגישות עם משודכת אחרת.   ";
  //   let g2 = "המשודכת נמצאת כבר בתהליך פגישות עם משודך אחר.   ";
  //   let info;
  //   switch (res) {
  //     case -1: info = g1;
  //       break;
  //     case -2: info = g2;
  //       break;
  //     default: info = g1 +  "ובנוסף, "+ g2;
  //   }
  //   return ' עפ"י נתוני המערכת, ' + info
  //     + "אם התהליך הקודם נפסק יש לעדכן זאת תחילה."
  // }

  delOffer(id: number) {
    if (!confirm('הודעת לצד השני?')) {
      this.cancleModal = true;
      return;
    }
    this.http
      .delete(
        this.globals.apiURL +
          `offersStatus/delById/${id}?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        {}
      )
      .subscribe((response) => {
        console.log(response.toString());
        this.msgIcon = 'check-circle';
        this.actionDoneMsg = 'ההצעה הוסרה';
        this.pairsList = this.pairsList.filter((p) => p.offer.id !== id);
        this.pairsListRoot = JSON.parse(JSON.stringify(this.pairsList));
        this.pairsListFiltered = this.pairsListFiltered.filter(
          (p) => p.offer.id !== id
        );
        if (this.offersType == 'system') {
          this.userService.tabMap['system']--;
          sessionStorage.setItem(
            'currentUser',
            JSON.stringify(this.userService)
          );
        }
      }); //add throwError
  }
}
