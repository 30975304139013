import { Injectable } from "@angular/core";
export class FamilyStatusAsk {
  id: number;
  name: string;
  man: string;
  woman: string;
  typeInput: number;
  ordered: number;
  isPublic: boolean;
  idParent: number;
  required: boolean;
  answer: string;
}
@Injectable()
export class FamilyStatusArrs {
  garushArr: Array<FamilyStatusAsk> = [
    {
      id: 1,
      name: 'familyStatusYears',
      man: 'כמה זמן?',
      woman: 'כמה זמן?',
      typeInput: 3,
      ordered: 1,
      isPublic: false,
      idParent: 0,
      required: false,
      answer: '',
    },
    {
      id: 2,
      name: 'hasKids',
      man: 'האם יש לך ילדים?',
      woman: 'האם יש לך ילדים?',
      typeInput: 5,
      ordered: 2,
      isPublic: true,
      idParent: 0,
      required: true,
      answer: '',
    },
    {
      id: 3,
      name: 'numKids',
      man: 'כמה ילדים?',
      woman: 'כמה ילדים?',
      typeInput: 3,
      ordered: 3,
      isPublic: true,
      idParent: 2,
      required: true,
      answer: '',
    },
    {
      id: 4,
      name: 'payMezonot',
      man: 'האם אתה משלם מזונות?',
      woman: 'האם את מקבלת מזונות?',
      typeInput: 5,
      ordered: 4,
      isPublic: false,
      idParent: 2,
      required: false,
      answer: '',
    },
    {
      id: 5,
      name: 'sumPay',
      man: 'מה הסכום?',
      woman: 'מה הסכום?',
      typeInput: 3,
      ordered: 5,
      isPublic: false,
      idParent: 4,
      required: false,
      answer: '',
    },
    {
      id: 6,
      name: 'kidsContact',
      man: 'האם יש לך קשר עם הילדים? ',
      woman: 'האם יש לאבא קשר עם הילדים? ',
      typeInput: 1,
      ordered: 6,
      isPublic: false,
      idParent: 2,
      required: false,
      answer: '',
    },
    {
      id: 7,
      name: 'contactTimes',
      man: 'כמה פעמים בשבוע?',
      woman: 'כמה פעמים בשבוע?',
      typeInput: 3,
      ordered: 7,
      isPublic: false,
      idParent: 6,
      required: false,
      answer: '',
    },
  ];

  almanArr: Array<FamilyStatusAsk> = [
    {
      id: 1,
      name: 'familyStatusYears',
      man: 'כמה זמן?',
      woman: 'כמה זמן?',
      typeInput: 3,
      ordered: 1,
      isPublic: false,
      idParent: 0,
      required: false,
      answer: '',
    },
    {
      id: 2,
      name: 'hasKids',
      man: 'האם יש לך ילדים?',
      woman: 'האם יש לך ילדים?',
      typeInput: 5,
      ordered: 2,
      isPublic: true,
      idParent: 0,
      required: true,
      answer: '',
    },
    {
      id: 3,
      name: 'numKids',
      man: 'כמה ילדים?',
      woman: 'כמה ילדים?',
      typeInput: 3,
      ordered: 3,
      isPublic: true,
      idParent: 2,
      required: true,
      answer: '',
    },
  ];
}
