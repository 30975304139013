<div class="flex-column mh-50 overflow-auto">
  <div
    *ngFor="let note of notes; let i = index"
    class="w-100 mt-3 bg-light p-3"
  >
    <div class="d-flex" style="min-width: 34vw">
      <div class="flex-fill ml-50">
        <div *ngIf="!note.editMode" class="flex-1">{{ note.note }}</div>
        <textarea
          *ngIf="note.editMode"
          class="flex-1 form-control notes"
          rows="3"
          [(ngModel)]="note.note"
        ></textarea>
      </div>

      <div
        class="col-3 flex flex-col mx-2"
        *ngIf="!note.editMode"
        style="min-width: 150px"
      >
        <div class="text-info" dir="ltr">{{ moment(note.createdAt) }}</div>
        <div class="text-info">{{ getShadchan(note.createdBy) }}</div>
      </div>

      <div class="d-flex flex-column mr-2">
        <button
          *ngIf="note.createdBy === userService.id || isAdmin"
          class="btn btn-outline-danger mb-3"
          (click)="deleteNote(note)"
        >
          מחק
        </button>
        <button
          class="btn btn-outline-primary"
          *ngIf="
            !note.editMode && (note.createdBy === userService.id || isAdmin)
          "
          (click)="note.editMode = true"
        >
          ערוך
        </button>
        <button
          class="btn btn-outline-primary mb-3"
          *ngIf="note.editMode"
          (click)="editNote(note)"
        >
          שמור
        </button>
        <button
          class="btn btn-outline-secondary"
          *ngIf="note.editMode"
          (click)="cancelAllNote(note)"
        >
          בטל
        </button>
      </div>
    </div>
  </div>
  <div class="mt-2">
    <button class="btn btn-info" (click)="createNote()">הוספת הערה</button>
  </div>
</div>
