<div class="logo pull-right"></div>
<ul class="pull-left">
    <li *ngIf="!in"><button class="btn btn-light btn-round" routerLink="/new-user">הרשמה</button></li>
    <!-- <li><button class="btn btn-blue btn-round" (click)="goToLogin()">{{in?'יציאה':'כניסה'}}</button></li> -->
    <!-- <li><button class="btn btn-blue btn-round" routerLink="/login" (click)="in=false">{{in?'יציאה':'כניסה'}}</button></li> -->
    <li *ngIf="!in"><button class="btn btn-blue btn-round" routerLink="/login">כניסה</button></li>
    <li *ngIf="in"><button class="btn btn-blue btn-round" routerLink="/login" (click)="goToLogin()">יציאה</button></li>

    <li><a routerLink="/info/contact" routerLinkActive="active">צרו קשר</a></li>  
    <li><a routerLink="/info/how-it-works" routerLinkActive="active">איך זה עובד</a></li>
    <li><a routerLink="/info/about" routerLinkActive="active">אודותינו</a></li>
    <li><a routerLink="/login" routerLinkActive="active">דף הבית</a></li>   
</ul>
<div class="clearfix"></div>
