<link
  href="https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700"
  rel="stylesheet"
/>
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
/>
<h1 class="page-title">{{ pageTitle }}</h1>
<div
  *ngIf="
    offersType != 'search' &&
    personList &&
    personList !== undefined &&
    personList.length
  "
>
  <app-filter
    [personListRoot]="personListRoot"
    (filterClick)="updatePersonList($event)"
  ></app-filter>
</div>
<app-free-search
  *ngIf="offersType == 'search'"
  (searchClick)="getPersonList($event)"
  (clearClick)="clear($event)"
  (setGenderClick)="setGender($event)"
></app-free-search>

<div *ngIf="noResults" class="mx-auto" class="div-noResults">
  <h1 class="text-center pink-color">לא נמצאו הצעות.</h1>
</div>
<div *ngIf="!(isShadchanFolder && offersType == 'search') || isLoaded">
  <div
    class="offer-box"
    id="p{{ person.id }}"
    *ngFor="let person of personList | myOfferFilter : personListFiltered"
  >
    <div
      *ngIf="isMobile && offersType == 'sent' && person['forMe']"
      class="reaction"
    >
      <fa
        name="{{
          reactionIconMap.has(person['forMe'].split(',')[0])
            ? reactionIconMap.get(person['forMe'].split(',')[0])
            : 'times'
        }}"
      >
      </fa>
      <p *ngIf="!reactionIconMap.has(person['forMe'].split(',')[0])">
        {{ person["forMe"].split(",")[0] }}
      </p>
    </div>
    <div [ngClass]="{ 'd-flex': !isMobile }">
      <div class="profile-container" *ngIf="!isMobile">
        <div [class]="gender == 'man' ? 'male-img' : 'female-img'">
          <app-add-img
            *ngIf="user.isShadchan && person.img != null"
            [onlyShow]="true"
            [userImg]="person.img"
          ></app-add-img>
        </div>
        <p *ngIf="user.isShadchan" class="p-name">
          {{ person.firstName }} {{ person.lastName }}
        </p>
        <p class="p-name pink-color">{{ person["statusDesc"] }}</p>
        <p
          *ngIf="person.status != 1 && !person['statusDesc']"
          class="pink-color text-center isActive"
        >
          ({{ person.status == 0 ? "לא פעיל" : "התארס/ה" }})
        </p>
      </div>
      <div>
        <div class="inner-container" [ngClass]="{ 'row-flex': !isMobile }">
          <!-- <div *ngFor="let ask of asks; let i=index" class="d-flex"  [ngClass]="isMobile?'w-100':'w-50'"> -->
          <div
            [ngClass]="{ 'w-50': !isMobile }"
            [ngStyle]="{ margin: isMobile ? 'auto' : '0' }"
          >
            <label *ngIf="isShadchan || isSystem" class="key-label"
              >תאריך הצטרפות:</label
            >
            <label *ngIf="isShadchan || isSystem" class="value-label">{{
              person.joinDate
            }}</label>
          </div>
          <div
            *ngFor="let ask of asks; let i = index"
            class="d-flex"
            [ngClass]="{ 'w-50': !isMobile }"
            [ngStyle]="{ margin: isMobile ? 'auto' : '0' }"
          >
            <div *ngIf="ask.name != 'israelID'">
              <label class="key-label"
                >{{ ask[gender]
                }}{{ ask[gender].slice(-1) == "?" ? "" : ":" }}</label
              >
              <label
                *ngIf="ask.typeInput != 5"
                class="value-label label-{{ ask.name }}"
                >{{ person[ask.name] }}</label
              >
              <label
                *ngIf="ask.typeInput == 5"
                class="value-label label-{{ ask.name }}"
                >{{ person[ask.name] == 1 ? "כן" : "לא" }}</label
              >
            </div>
          </div>
          <div
            *ngIf="
              person.familyStatus == 'גרוש' ||
              person.familyStatus == 'גרושה' ||
              person.familyStatus == 'אלמן' ||
              person.familyStatus == 'אלמנה'
            "
            [ngClass]="{ 'w-50': !isMobile }"
            [ngStyle]="{ margin: isMobile ? 'auto' : '0' }"
          >
            <label class="key-label">מספר ילדים:</label>
            <label class="value-label">{{
              person["numKids"] == -1 || person["numKids"] == null
                ? "0"
                : person["numKids"]
            }}</label>
          </div>
          <div
            *ngIf="isShadchan && person.insertedBy != person.idUser"
            class="d-flex"
            [ngClass]="isMobile ? 'w-100' : 'w-50'"
          >
            <label class="key-label">בטיפול השדכנ/ית:</label>
            <label class="value-label">{{ person.phoneShadchan }}</label>
          </div>

          <div
            *ngIf="offersType == 'sent' && person['forMe']"
            class="row-flex-between bg-light reaction-line"
          >
            <p
              *ngIf="person.status != 1 && !person['statusDesc']"
              class="pink-color"
            >
              ({{ person.status == 0 ? "לא פעיל" : "התארס/ה" }})
            </p>
            <p class="pink-color">
              {{ reactionTextMap.get(person["forMe"].split(",")[0]) }}
            </p>
            <p class="gray-color">
              {{ reactionDateTextMap?.get(person?.["forMe"]?.split(",")?.[0]) }}
              {{ person["forMe"].split(",")[1] }}
            </p>
            <button
              *ngIf="isMobile && person.status != 1"
              (click)="delOffer(person.id); openModal(actionDone)"
              class="btn btn-pink btn-remove"
              style="height: min-content"
            >
              <fa name="trash left-space"></fa>הסר
            </button>
          </div>

          <div
            *ngIf="offersType == 'receive' && person['forMe'] == 'v'"
            class="bg-light reaction-line"
            style="margin-top: 15px"
          >
            <p class="pink-color">
              אישרת את ההצעה, שדכנית תחזור אליכם בהקדם בע"ה
            </p>
          </div>
          <div
            *ngIf="offersType == 'finallyNo' && person?.['forMe']?.includes('n,')"
            class="bg-light reaction-line w-auto"
            style="margin-top: 15px; margin-bottom: -20px"
          >
            <p class="gray-color">
              {{ reactionDateTextMap.get(person["forMe"].split(",")[0]) }}
              {{ person["forMe"].split(",")[1] }}
            </p>
          </div>
          <div
            *ngIf="offersType == 'refuse' && person?.['forMe']?.includes('r,')"
            class="bg-light reaction-line w-auto"
            style="margin-top: 15px; margin-bottom: -20px"
          >
            <p class="gray-color">
              {{ reactionDateTextMap.get(person["forMe"].split(",")[0]) }}
              {{ person["forMe"].split(",")[1] }}
            </p>
          </div>
          <div
            *ngIf="offersType == 'check' && person?.['forMe']?.includes('i,')"
            class="bg-light div-phone-check"
          >
            <div class="d-flex justify-content-between">
              <p class="pink-color" (click)="getRecommends(person.id)">
                טלפונים לבירורים<i
                  class="fa fa-chevron-left right-space"
                  style="font-size: 12px"
                ></i>
                <label id="noRecommendOf{{ person.id }}" class="recommendsInfo"
                  >(אין)</label
                >
              </p>
              <p class="gray-color">
                {{ reactionDateTextMap.get(person["forMe"].split(",")[0]) }}
                {{ person["forMe"].split(",")[1] }}
              </p>
            </div>
            <div style="height: 1px"></div>
            <div id="recommendsOf{{ person.id }}" class="recommendsInfo">
              <div
                *ngFor="let item of recommendList; let i = index"
                class="row-flex"
              >
                <label class="col-lg-5 key-label"
                  >{{ recommendList[i]["name"] }} -
                  {{ recommendList[i]["descrip"] }}</label
                >
                <label class="col-lg-4 value-label">{{
                  recommendList[i]["phone"]
                }}</label>
                <label class="col-lg-3 value-label">{{
                  recommendList[i]["phone2"]
                }}</label>
              </div>
            </div>
          </div>

          <div class="w-100"></div>
          <div
            *ngIf="
              user.isShadchan &&
              (person.ICheck != null || person.checkMe != null)
            "
            class="w-100 div-check-info"
          >
            <div class="w-50" *ngIf="person.ICheck != null">
              <div
                class="title-check-info"
                (click)="getCheckersInfo(person, 'ICheck')"
              >
                <fa name="exclamation-circle left-space"></fa>אני
                {{ gender == "man" ? "מברר" : "מבררת" }} על<fa
                  name="chevron-left right-space"
                ></fa>
              </div>
              <div *ngFor="let n of person.ICheckInfo">
                <label>{{ n }}</label>
              </div>
            </div>
            <div class="w-50" *ngIf="person.checkMe != null">
              <div
                class="title-check-info"
                (click)="getCheckersInfo(person, 'checkMe')"
              >
                <fa name="exclamation-circle left-space"></fa>מבררים עלי<fa
                  name="chevron-left right-space"
                >
                </fa>
              </div>
              <div *ngFor="let n of person.checkMeInfo">
                <label>{{ n }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          *ngIf="!isMobile && person.status != 1 && offersType != 'search'"
          (click)="delOffer(person.id); openModal(actionDone)"
          class="btn btn-pink"
          style="height: min-content"
        >
          <fa name="trash left-space"></fa>הסר
        </button>
        <div
          *ngIf="!isMobile && offersType == 'sent' && person['forMe']"
          class="reaction"
        >
          <fa
            name="{{
              reactionIconMap.has(person['forMe'].split(',')[0])
                ? reactionIconMap.get(person['forMe'].split(',')[0])
                : 'times'
            }}"
          >
          </fa>
          <p *ngIf="!reactionIconMap.has(person['forMe'].split(',')[0])">
            {{ person["forMe"].split(",")[0] }}
          </p>
        </div>
      </div>
    </div>

    <div class="row-btns">
      <div *ngIf="user.isShadchan || isAdmin" class="inner-container">
        <label class="key-label" style="margin-right: 37px">סיבת חסימה</label>
        <div>
          <fa
            [name]="person.locked ? 'lock' : 'unlock-alt'"
            class="circled"
            style="display: center"
            (click)="openReasonText(person)"
            [tooltip]="person.locked ? 'בטל חסימה' : 'חסום משתמש'"
            placement="right"
          ></fa>
          <textarea
            id="reasonLockUser"
            type="text"
            [readonly]="person.locked"
            [disabled]="person?.locked"
            [value]="person.lockedReason"
            [(ngModel)]="person.lockedReason"
          ></textarea>
        </div>
      </div>

      <button
        *ngIf="user.isShadchan"
        class="btn btn-round btn-blue"
        (click)="goToUserFolder(person, 'user-details')"
      >
        לפרופיל המלא<fa name="address-card right-space"></fa>
      </button>

      <div
        *ngIf="
          (!user.isShadchan &&
            !(offersType == 'receive' && person['forMe'] == 'v')) ||
          (user.isShadchan && offersType != 'search') ||
          (user.isShadchan && !isShadchanFolder)
        "
        [ngClass]="isMobile ? 'row-flex-between' : 'd-inline'"
      >
        <button
          *ngFor="let btn of offersDataMap[offersType]; let i = index"
          class="btn btn-round btn-{{ colors[i] }}"
          (click)="playBtn(btn.onclick, person['id']); openModal(actionDone)"
        >
          {{ btn.title }}<fa name="{{ btn.icon }} right-space"></fa>
        </button>
      </div>
      <button
        *ngIf="user.isShadchan && isShadchanFolder && offersType == 'search'"
        class="btn btn-round btn-gray"
        (click)="goToUserFolder(person, 'offer/system')"
      >
        חפש הצעות<fa name="search right-space"></fa>
      </button>
      <button
        *ngIf="
          ((user.isShadchan && offersType != 'search') ||
            (user.isShadchan && !isShadchanFolder)) &&
          offersType != 'finallyNo' &&
          offersType != 'check'
        "
        class="btn btn-round btn-pink"
        (click)="playBtn('check', person['id']); openModal(actionDone)"
      >
        בבירורים<fa name="question-circle right-space"></fa>
      </button>
      <button
        *ngIf="
          user.isShadchan &&
          ['sent', 'receive'].includes(offersType) &&
          idNeedBtnMsg.includes(person.id)
        "
        class="btn btn-round btn-blue"
        (click)="removeSystemAlert(person.id)"
      >
        הודעתי ל{{ user.firstName }}<fa name="check right-space"></fa>
      </button>
      <button
        (click)="onChat(person)"
        *ngIf="user.isShadchan && !!person?.idUser"
        class="btn btn-round btn-warning"
      >
        צאט עם משתמש <fa name="comments-o"></fa>
      </button>
    </div>
  </div>
</div>
<ng-template #actionDone>
  <div class="modal-header">
    <p
      *ngIf="actionDoneMsg != ''"
      class="modal-title modal-body text-center text-info"
      style="padding: 0px !important"
    >
      {{ actionDoneMsg }}
    </p>
    <p
      *ngIf="actionDoneMsg == ''"
      class="modal-title modal-body text-center text-info"
      style="padding: 0px !important"
    >
      אנא המתן...
    </p>
    <button
      type="button"
      class="close pull-left"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h1 *ngIf="actionDoneMsg != ''" class="text-info text-center">
      <fa [name]="msgIcon"></fa>
    </h1>
    <p *ngIf="actionDoneMsg.includes('אושרה')" class="text-info text-center">
      שדכנית תחזור אליכם בהקדם בע"ה
    </p>
    <div
      *ngIf="actionDoneMsg == ''"
      class="d-block mx-auto"
      style="width: 45px"
    >
      <app-fulfilling-bouncing-circle-spinner
        [animationDuration]="1000"
        [size]="45"
        [color]="'var(--blue)'"
      >
      </app-fulfilling-bouncing-circle-spinner>
    </div>
  </div>
</ng-template>
