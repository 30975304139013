import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../common/user.service';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Globals } from '../common/globals';

@Component({
  selector: 'app-logo-menu',
  templateUrl: './logo-menu.component.html',
  styleUrls: ['./logo-menu.component.css'],
})
export class LogoMenuComponent implements OnInit {
  in: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private http: HttpClient,
    private globals: Globals
  ) {}

  ngOnInit(): void {}

  goToLogin() {
    console.log('logout .. ' + this.userService.firstName);
    console.log('logout .. ' + this.userService.loginFirstName);
    this.in = false;
    this.http
      .get(
        this.globals.apiURL +
          `users/logout?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .subscribe();
    sessionStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }
}
