//#region imports
import { Component, OnInit, Injectable, ElementRef, ViewChild, Input, TemplateRef } from '@angular/core';
import { Globals } from '../common/globals';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';import { Person, PersonPref, Men, Women, MenPref, WomenPref, Recommend, OffersStatus, Pair } from '../entities/entities';
import { Routes, RouterModule, Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router'
import { UserService } from '../common/user.service';
//#endregion

@Component({
  selector: 'app-system-alerts',
  templateUrl: './system-alerts.component.html',
  styleUrls: ['./system-alerts.component.css']
})
export class SystemAlertsComponent implements OnInit {
  pairsList: Array<Pair>;
  pairsListFiltered: Array<Pair>;
  pairsListRoot: Array<Person>;
  noResults: boolean = false;

  constructor(private http: HttpClient, private globals: Globals, private modalService: BsModalService,
    private router: Router, private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit(): void {
    this.getOffers();
  }

  getOffers() {
    this.http.get<Array<Pair>>(this.globals.apiURL + `shadchan/get/systemAlerts/${this.userService.getId()}?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`, { responseType: 'json' })
    .subscribe(data => {
      this.pairsList = data;
      this.pairsListRoot = JSON.parse(JSON.stringify(data));
      this.pairsListFiltered = JSON.parse(JSON.stringify(data));
      console.log(this.pairsList);
      this.noResults = !(Array.isArray(data) && data.length);
      this.globals.setCounter(this.userService, data.length, 'systemAlerts');
    });  //add throwError
  }

  goToUserFolder(p: Person, gender: number,forUrl:string) {
    this.userService.setLoginFirstName(this.userService.firstName);
    this.userService.setLoginLastName(this.userService.lastName);
    this.userService.setGender(gender);
    this.userService.setFirstName(p.firstName);
    this.userService.setLastName(p.lastName);
    this.userService.personId = p.id;
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
    const url = this.router.serializeUrl(this.router.createUrlTree(['user-folder']));
    let stUrl=forUrl.includes('receive')?'/offer/'+forUrl:'/user-details';
    window.open('/#'+url+stUrl, '_blank');
}

getAge(birthdate:string){
  return new Date().getFullYear()-parseInt(birthdate.split('-')[0]);
}

}
