<div class="card d-block">
    <div class="row-select-action">
        <button class="btn btn-round btn-blue" (click)="init('add',true)">
            <fa name="plus left-space"></fa>{{actionTitle['add']}}
        </button>
        <button class="btn btn-round btn-blue" (click)="init('edit',true)">
            <fa name="pencil left-space"></fa>{{actionTitle['edit']}}
        </button>
        <button class="btn btn-round btn-blue" (click)="init('del',true)">
            <fa name="times left-space"></fa>{{actionTitle['del']}}
        </button>
        <button class="btn btn-round btn-blue" (click)="init('show',true)">
            <fa name="bars left-space"></fa>{{actionTitle['show']}}
        </button>
    </div>
</div>
<div class="card d-block" *ngIf="action!='show'">
    <h2 class="text-info text-center">{{actionTitle[action]}}</h2>
    <hr >
    <alert *ngIf="addMsg" type="info alert-top" dismissible="true" (onClosed)="addMsg=false">
        <p class="text-center">{{msg}}</p>
    </alert>
    <div class="row-flex-between" style="justify-content: space-around;">
        <div *ngIf="action=='edit'">
            <!-- class="w-50" -->
            <p>איזו קבוצה ברצונך לערוך?</p>
            <div class="btn-group" dropdown>
                <button dropdownToggle type="button" class="btn dropdown-toggle">
                    {{groupName}} &nbsp;&nbsp; <i class="fa fa-chevron-down like-select"></i>
                </button>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                    <li *ngFor="let g of groupList" role="menuitem">
                        <a class="dropdown-item" (click)="setGroup(g)">{{g.name}}</a>
                    </li>
                </ul>
            </div>
            <br><br>
            <p>כאן ניתן לערוך את שם הקבוצה (לא חובה)</p>
            <input class="form-control group-name" type="text" placeholder="שם קבוצה חדש" [(ngModel)]="newName" />
        </div>

        <div *ngIf="action=='add'">
            <!-- class="w-50" -->
            שם קבוצה
            <input class="form-control group-name" type="text" [(ngModel)]="groupName" />
        </div>
        <div *ngIf="['add','edit'].includes(action) && !['בחר קבוצה',''].includes(groupName)">
            <!-- class="w-50" --> 
            <p> שדכניות בקבוצה:</p>
            <div *ngFor="let s of shadchanInGroupList" class="d-flex">
                <fa name="times circled del" (click)="delShadchan(s.id)" style="margin-left: 20px;cursor: pointer;">
                </fa>
                <p>{{s.lastName}} {{s.firstName}}</p>
            </div>
            <select autocomplete="on" class="btn" [(ngModel)]="selected" placeholder=" הוסף שדכנ/ית">
                <option value="0" selected style="color: var(--gray);">בחר שדכנ/ית</option>
                <option [value]="s.id" *ngFor="let s of shadchanList | filterSort:shadchanInGroupList">
                    {{s.lastName}} {{s.firstName}}
                </option>
            </select> &nbsp;
            <button class="btn btn-blue" (click)="addShadchan(selected)">הוסף</button>
        </div>
        <div *ngIf="action=='del'">
            <!-- class="w-50" -->
            <p>איזו קבוצה ברצונך למחוק?</p>
            <div class="btn-group" dropdown>
                <button dropdownToggle type="button" class="btn dropdown-toggle">
                    {{groupName}} &nbsp;&nbsp; <i class="fa fa-chevron-down like-select"></i>
                </button>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                    <li *ngFor="let g of groupList" role="menuitem">
                        <a class="dropdown-item" (click)="setGroup(g)">{{g.name}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="w-100" style="height: 20px;"></div>
        <button class="btn btn-blue btn-round d-block mx-auto btn-ok" (click)="submit()">
            <fa name="check left-space"></fa>אישור
        </button>
    </div>
</div>
<!-- ------------------------------------------------------------------------ -->
<input *ngIf="action=='show'" type="text" [(ngModel)]="search" class="form-control search" placeholder="חיפוש..." />
<div *ngIf="action=='show'" style="margin-left: 20px;">
    <div *ngIf="noResults" class="mx-auto" class="div-noResults">
        <h1 class="text-center pink-color">לא נמצאו תוצאות.</h1>
    </div>
    <table class="table table-striped">
        <thead>
            <tr>
                <th>שם קבוצה</th>
                <th>שדכנים /ות</th>            
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let sg of sInG | tblFreeSearch:search ">
                <td>{{sg[0]}}</td>                
                <td>{{sg[1]}}</td>                
            </tr>
        </tbody>
    </table>
</div>