<p
  *ngIf="!isMobile"
  class="pink-color right-space"
  style="width: 345px; margin: auto; margin-top: 20px; cursor: pointer"
>
  <fa name="expand" class="expand-compress" routerLink="/register/add"></fa
  >לתצוגה במסך מלא לחצו כאן
</p>
<div class="form-asks" *ngIf="isLoaded">
  <div class="header" [@shrink]="fullHeader">{{ formTitle[currentTitle] }}</div>
  <div style="padding: 20px 60px">
    <div *ngIf="currentTitle == 0 && i < asks.length">
      <div *ngIf="wellcom; else ShowDiff" class="wellcom">
        <br />
        <div style="line-height: 1.8em; margin: auto -38px">
          <div style="font-size: 2.15em">שלום, {{ userName }}!</div>
          <br />
          <div style="font-size: 1.1em">
            כאן {{ canByGender }} למלא את פרטיך בקלות ובזריזות. <br /><br />בכל
            שלב {{ canByGender }} ללחוץ על ENTER במקום על כפתור "הבא".
          </div>
          <br />
          <button
            class="btn btn-blue btn-round d-block mx-auto"
            (click)="closeWellcome()"
          >
            הבא
          </button>
          <br />
        </div>
      </div>
      <ng-template #ShowDiff>
        <p class="text-center">{{ ask[gender] }}</p>
        <p *ngIf="!ask.isPublic" class="text-center note">
          תשובתך תוצג לשדכניות בלבד
        </p>
        <p *ngIf="requiredMsg" class="note">*זהו שדה חובה</p>
        <p *ngIf="ask.name == 'moneySum'" class="note">(במספרים בלבד)</p>
        <p *ngIf="!validFlag && !duplicateEmail" class="note">
          הביטוי שהזנת אינו חוקי
        </p>
        <p
          *ngIf="ask.name == 'email' && validFlag && duplicateEmail"
          class="note"
        >
          דוא"ל זה כבר קיים במערכת
        </p>
        <p
          *ngIf="ask.name == 'israelID' && validFlag && duplicateIsraelID"
          class="note"
        >
          תעודת זהות זאת כבר קיימת במערכת
        </p>
        <div *ngIf="ask.typeInput == 1">
          <div
            (keydown.enter)="next()"
            class="btn-my-group"
            btnRadioGroup
            [(ngModel)]="personTemp[ask.name]"
          >
            <label
              *ngFor="let ans of ansMap[ask.id]"
              class="btn btn-light"
              btnRadio="{{ ans.id }}"
              tabindex="0"
              role="button"
              (click)="updateAns(personTemp[ask.name], ask, i)"
              >{{ ans[gender] }}</label
            >
          </div>
        </div>
        <div *ngIf="ask.typeInput == 2">
          <p class="text-center note">ניתן לבחור יותר מתשובה אחת</p>
          <div (keydown.enter)="next()" class="btn-my-group {{ ask.name }}">
            <label
              *ngFor="let ans of ansMap[ask.id]"
              class="btn btn-light"
              [ngClass]="{
                active: personTemp[ask.name]?.includes(ans.id)
              }"
              tabindex="0"
              role="button"
              (click)="
                addMultiAns(ans.id, ask.name);
                updateAns(personTemp[ask.name], ask, i)
              "
              >{{ ans[gender] }}</label
            >
          </div>
        </div>
        <div *ngIf="ask.typeInput == 3">
          <input
            autofocus
            #currentInput
            class="form-control"
            [type]="ask.name == 'moneySum' ? 'number' : 'text'"
            [(ngModel)]="personTemp[ask.name]"
            [disabled]="ask.name == 'email' && hasntEmail == true"
            (keydown.enter)="next()"
          />
          <div *ngIf="ask.name == 'email'" class="form-check">
            <div *ngIf="isShadchan">
              <br />
              <input
                class="left-space"
                type="checkbox"
                [(ngModel)]="hasntEmail"
                (click)="emptyEmail()"
              />
              <label>אין לי</label>
            </div>
          </div>
        </div>
        <div *ngIf="ask.typeInput == 4">
          <textarea
            #currentInput
            class="form-control"
            rows="8"
            [(ngModel)]="personTemp[ask.name]"
            (keydown.enter)="next()"
          ></textarea>
        </div>
        <div *ngIf="ask.typeInput == 5">
          <div
            class="btn-my-group"
            (keydown.enter)="next()"
            btnRadioGroup
            [(ngModel)]="personTemp[ask.name]"
          >
            <label
              class="btn btn-light"
              btnRadio="true"
              tabindex="0"
              role="button"
              >כן</label
            >
            <label
              class="btn btn-light"
              btnRadio="false"
              tabindex="0"
              role="button"
              >לא</label
            >
          </div>
        </div>
        <div *ngIf="ask.typeInput == 6">
          <!-- <app-datepicker-hebrew (dateSelected)="setBirthdate($event)"></app-datepicker-hebrew> -->
          <app-date-with-heb
            (dateSelected)="setBirthdate($event)"
            [prevHebD]="personTemp.birthdateHeb"
            [prevD]="personTemp.birthdate"
          ></app-date-with-heb>
        </div>
        <div *ngIf="ask.typeInput == 7">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="personTemp['israelID']"
            maxlength="9"
            (keypress)="validateNo($event)"
          />
        </div>
        <div
          *ngIf="ask.name == 'familyStatusDetails'"
          class="clearfix bordered"
        >
          <app-family-status-asks
            [idFamilyStatus]="personTemp['familyStatus']"
            [validFlag]="!requiredMsg"
          >
          </app-family-status-asks>
        </div>
        <div *ngIf="ask.name == 'img'">
          <app-add-img
            (imgLoaded)="setImgLoaded()"
            [prevImg]="personTemp['img']"
            [state]="'add'"
          ></app-add-img>
        </div>
        <div class="row-flex-between">
          <button class="btn btn-blue float-right" (click)="prev()">
            הקודם
          </button>
          <div></div>
          <button
            class="btn btn-blue float-left"
            (click)="next()"
            [disabled]="imgLoading || isBusy"
          >
            הבא
          </button>
        </div>
      </ng-template>
    </div>
    <!-- --------------recommends----------------------------------------------------------------- -->
    <div *ngIf="currentTitle == 1" class="recommends-container">
      <br />
      <p class="pink-color" *ngIf="!recoPhone && recommendList[i].phone != ''">
        מס' הטלפון אינו תקין
      </p>
      <div *ngFor="let field of recommendFields | keyvalue">
        <input
          *ngIf="field.key != 'phone'"
          class="form-control"
          type="text"
          [(ngModel)]="recommendList[i][field.key]"
          placeholder="{{ field.value }}"
        />
        <input
          *ngIf="field.key == 'phone'"
          class="form-control"
          type="text"
          [(ngModel)]="recommendList[i][field.key]"
          placeholder="{{ field.value }}"
          (input)="onChangeRecoPhone($event.target.value)"
        />
      </div>
      <button class="btn btn-blue add-recommend" (click)="addRecommend()">
        <fa name="plus left-space"></fa>הוספת ממליץ
      </button>
      <div class="row-flex-between">
        <button class="btn btn-blue float-right" (click)="prevReco()">
          הקודם
        </button>
        <div></div>
        <button
          class="btn btn-blue float-left"
          (click)="nextReco()"
          [disabled]="isBusy"
        >
          הבא
        </button>
      </div>
    </div>
    <!-- --------------prefAsks-------------------------------------------------------------------- -->
    <div *ngIf="currentTitle == 2 && i <= prefAsks.length">
      <!-- <p class="text-center pink-color" style="font-weight: bold;">איזה בחורה אני מחפש (זה יעזור לנו להתאים לך הצעות) -->
      <!-- </p> -->
      <div *ngIf="i < prefAsks.length">
        <p class="text-center note">
          &nbsp;&nbsp;&nbsp;&nbsp;בכל שאלה ניתן לבחור יותר מתשובה אחת
        </p>
        <p class="text-center">
          {{ asksIdMap[askPref.idAsk][0][otherGender] }}
        </p>
        <p *ngIf="requiredMsg" class="note">*זהו שדה חובה</p>
        <div *ngIf="askPref.typeInput == 1">
          <div
            (keydown.enter)="nextPref()"
            class="btn-my-group {{ askPref.name }}"
            btnRadioGroup
            [(ngModel)]="personPref[askPref.name]"
          >
            <label
              *ngFor="let ans of ansPrefMap[askPref.idAsk]"
              class="btn btn-light"
              btnRadio="{{ ans.id }}"
              tabindex="0"
              role="button"
              >{{ ans[otherGender] }}</label
            >
            <label
              *ngIf="askPref.defaultAns !== '0'"
              class="btn btn-light"
              btnRadio="{{ 0 }}"
              tabindex="0"
              role="button"
              >{{ askPref.defaultAns }}</label
            >
          </div>
        </div>
        <div *ngIf="askPref.typeInput == 2">
          <div
            (keydown.enter)="nextPref()"
            class="btn-my-group {{ askPref.name }}"
          >
            <label
              *ngFor="let ans of ansPrefMap[askPref.idAsk]"
              class="btn btn-light"
              tabindex="0"
              role="button"
              [ngClass]="{
                active: personPref[askPref.name].includes(';' + ans.id)
              }"
              (click)="addMultiPref(ans.id, askPref.name)"
              >{{ ans[otherGender] }}</label
            >
            <label
              *ngIf="askPref.defaultAns !== '0'"
              (click)="addMultiPref(';0', askPref.name)"
              [ngClass]="{ active: personPref[askPref.name] == ';0' }"
              class="btn btn-light"
              tabindex="0"
              role="button"
              >{{ askPref.defaultAns }}</label
            >
          </div>
        </div>
        <div *ngIf="askPref.typeInput == 5">
          <div
            (keydown.enter)="nextPref()"
            class="btn-my-group {{ askPref.name }}"
            btnRadioGroup
            [(ngModel)]="personPref[askPref.name]"
          >
            <label
              class="btn btn-light"
              btnRadio="true"
              tabindex="0"
              role="button"
              checked=""
              >לא משנה</label
            >
            <label
              class="btn btn-light"
              btnRadio="false"
              tabindex="0"
              role="button"
              >לא</label
            >
          </div>
        </div>
        <div
          *ngIf="
            i == (gender == 'man' ? 2 : 3) &&
            personPref['familyStatus'] != ';16' &&
            personPref['familyStatus'] != ''
          "
        >
          <!-- <div style="flex-basis: 100%;height:0;"></div>                            -->
          <div
            (keydown.enter)="nextPref()"
            class="btn-my-group kids-pref-group"
            [ngClass]="{
              invalid:
                (personPref['numKids'] == null || !personPref['numKids']) &&
                requiredMsg
            }"
            btnRadioGroup
            [(ngModel)]="personPref['numKids']"
          >
            <label class="btn btn-light" btnRadio="0" tabindex="0" role="button"
              >בלי ילדים</label
            >
            <label
              class="btn btn-light"
              btnRadio="-1"
              tabindex="0"
              role="button"
              >עם ילדים</label
            >
            <label
              class="btn btn-light"
              btnRadio="-2"
              tabindex="0"
              role="button"
              >לא משנה</label
            >
            <label
              class="btn btn-light"
              btnRadio="{{ maxNumKids }}"
              tabindex="0"
              role="button"
              style="padding: 3.5px 0px"
              >עד<input
                type="text"
                class="small-input"
                [(ngModel)]="maxNumKids"
              />ילדים
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="i == prefAsks.length" style="padding-bottom: 1px">
        <br />
        <p *ngIf="requiredMsg" class="note">*זהו שדה חובה</p>
        <p class="text-center">גיל</p>
        <div class="row-flex age" style="justify-content: center">
          <input
            type="number"
            class="form-control"
            [(ngModel)]="personPref['minAge']"
            placeholder="מ-"
            style="width: 80px; margin-left: 15px"
          />
          <input
            type="number"
            class="form-control"
            [(ngModel)]="personPref['maxAge']"
            placeholder="עד"
            style="width: 80px"
          />
        </div>
      </div>
      <div class="row-flex-between">
        <button class="btn btn-blue float-right" (click)="prevPref()">
          הקודם
        </button>
        <div></div>
        <button class="btn btn-blue float-left" (click)="nextPref()">
          הבא
        </button>
      </div>
    </div>
    <!-- -------------finish------------------------------------------------------------------ -->
    <div *ngIf="currentTitle == 3" class="finish-register">
      <div *ngIf="!isShadchan">
        <br />
        <div style="line-height: 1.8em; margin: auto -38px">
          <div class="d-flex flex-column align-items-center py-2">
            <p>שימו לב</p>
            <p>השדכניות משקיעות מזמנם</p>
            <p>ועושות את המקסימום לנסות להציע לכם שידוך</p>
            <p>בעזרת השם אם יצא שידוך</p>
            <p>אנו מתחייבים לשלם לשדכנית</p>
            <p>לפחות <strong>3000</strong> שקל לכל צד כמקובל בשידוך רגיל</p>
            <p>או <strong>4500</strong> בשידוך פרק ב' או עם בעיה רפואית</p>
            <p dir="auto">לאישור לחצו כאן</p>
            <p dir="auto">באם אתם מוכנים לשלם יותר לשדכנית</p>
            <p dir="auto">הקישו כאן את הסכום</p>
            <input
              autofocus
              #currentInput
              class="form-control w-50"
              [type]="'number'"
              [(ngModel)]="personTemp['payment']"
            />
            <button
              class="btn btn-blue btn-round d-block mx-auto mt-3"
              (click)="savePayment()"
            >
              אישור
            </button>
          </div>
        </div>
      </div>
      <!-- ----------------------------------------------------------------------->
      <div *ngIf="isShadchan" style="line-height: 2.5em; margin: auto -38px">
        <div style="font-size: 2.5em">ההרשמה בוצעה בהצלחה</div>
        <div style="font-size: 1.8em">בשורות טובות!</div>
        <div class="row-flex" class="finish-register-shadchan">
          <div>
            <label class="right-label">מודל תשלום</label>
            <div
              class="btn-my-group"
              btnRadioGroup
              [(ngModel)]="person['payModel']"
            >
              <label
                class="btn btn-light"
                btnRadio="דמי שידוך"
                tabindex="0"
                role="button"
                checked
                >דמי שידוך</label
              >
              <label
                class="btn btn-light"
                btnRadio="דמי פגישה"
                tabindex="0"
                role="button"
                >דמי פגישה</label
              >
              <label
                class="btn btn-light"
                btnRadio="דמי רישום"
                tabindex="0"
                role="button"
                >דמי רישום</label
              >
            </div>
          </div>
          <!-- && !isPersonalShadchan -->
          <div *ngIf="!hasntEmail" class="a-register">
            <fa name="edit left-space"></fa
            ><a (click)="openBlank('new-user')">לרישום המשתמש לחצו כאן</a>
          </div>
          <button
            class="btn btn-blue btn-round d-block mx-auto"
            (click)="saveShadchanSetting()"
            routerLink="/user-folder/offer/system"
          >
            חפש הצעות<fa name="search right-space"></fa>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="currentTitle == 4" class="finish-register">
      <div *ngIf="!isShadchan">
        <br />
        <div style="line-height: 1.8em; margin: auto -38px">
          <div style="font-size: 3.05em">נרשמת בהצלחה</div>
          <br />
          <div style="font-size: 2.15em">בשורות טובות!</div>
          <br />
          <div class="pink-color" style="font-size: 1.1em">
            בכל עת {{ canByGender }} לערוך את פרטיך, פרטי הממליצים וההעדפות שלך
            בעמוד "הפרופיל שלי".
            <br />
            אם ברצונך לא לקבל התראות על הצעות מתאימות {{ canByGender }} לסמן זאת
            באותו עמוד (גם באופן זמני).
          </div>
          <br />
          <button
            class="btn btn-blue btn-round d-block mx-auto"
            (click)="complete()"
            routerLink="/user-folder/offer/system"
          >
            המשך לאתר
          </button>
        </div>
      </div>
    </div>

    <!-- ------------------------------------------------------------------------------- -->
    <div *ngIf="progressMax?.[currentTitle] > 0" class="row">
      <div class="col-sm-12">
        <progressbar
          [value]="i + 1"
          [max]="progressMax?.[currentTitle]"
        ></progressbar>
      </div>
    </div>
  </div>
</div>

<ng-template #showDuplicate> asas </ng-template>
