<div *ngIf="!showFilter" class="wrap-div-filter">
    <button type="button" class="btn btn-blue d-block mx-auto" (click)="showFilter = true"><fa name="filter left-space"></fa>סינון הצעות</button>
</div>
<!-- -->
<div *ngIf="showFilter" class="div-filter" (keydown.enter)="filter()">
    <button class="close" (click)="showFilter=false"><span aria-hidden="true" style="padding: 0px 10px;">×</span></button>
    <div *ngFor="let ask of asks; let i=index" style="margin: 5px;">
        <div *ngIf="ask.typeInput<3">
            <ng-select [items]="ansMap[ask.id]" [bindLabel]="gender" [placeholder]="ask[gender]" appendTo="body"
                multiple="true" [(ngModel)]="personFilter[ask.name]" dropdownPosition="bottom">
            </ng-select>
        </div>
        <input *ngIf="ask.typeInput>=3" class="form-control" [type]="ask.name=='moneySum'?'number':'text'" [(ngModel)]="personFilter[ask.name]"
            [placeholder]="ask[gender]" />
    </div>
    <div style="margin: 5px; display: flex;">
        <input type="number" class="form-control" [(ngModel)]="personFilter['minAge']" placeholder="מגיל"
          style="width: 78px;" />
        <input type="number" class="form-control" [(ngModel)]="personFilter['maxAge']" placeholder="עד"
          style="width: 72px;" />
      </div>
    <div style="margin: 5px; display: flex;">
        <input class="form-control" [(ngModel)]="personFilter['minHeight']" placeholder="מגובה"
          style="width: 78px;" />
        <input class="form-control" [(ngModel)]="personFilter['maxHeight']" placeholder="עד"
          style="width: 72px;" />
      </div>
      <input class="form-control" type="text" [(ngModel)]="personFilter['free']" placeholder="חיפוש חופשי"  style="margin: 5px;"/>
    <div style="margin: 7px;">
        <button class="btn btn-blue" (click)="filter()">
            <!-- סינון<fa name="filter right-space"></fa> -->
            <fa name="filter left-space"></fa>סינון
        </button> &nbsp;&nbsp;
        <button class="btn btn-blue" (click)="clear(false)">
            <!-- סינון<fa name="filter right-space"></fa> -->
            <fa name="times left-space"></fa>ניקוי
        </button>
    </div>
</div>