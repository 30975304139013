import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cloudinary } from '@cloudinary/angular-5.x';
import { Globals } from './globals';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  ngOnDestroy() {
    console.log('ngOnDestroy: cleaning up...');
  }
  msg: string;
  loading: boolean;

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private cloudinary: Cloudinary,
    private userService: UserService
  ) {}

  isValid(file: File): boolean {
    if (file.type.match('image/jpeg') == null) {
      throw new Error('ניתן להעלות קובץ מסוג JPG בלבד');
    }
    if (file.size > 104857600 / 2) {
      throw new Error('ניתן להעלות תמונה עד גודל 5MB');
    }
    return true;
  }

  public async addImg(event) {
    const file: File = event.target.files[0];
    if (file) {
      if (!this.isValid(file)) return;
      const fileName = file.name;
      const formData = new FormData();
      formData.append('file', file);
      console.log(file);
      this.loading = true;
      return this.http
        .put(
          this.globals.apiURL +
            `img/upload?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
          formData,
          { responseType: 'text' }
        )
        .toPromise()
        .then((res) => {
          console.log(res);
          return res;
        });
    }
  }

  getImgUrl(path: string) {
    return this.cloudinary.url('pepoleImg/' + path + '.jpg', {});
  }

  async dell(path: string) {
    return this.http
      .delete(
        this.globals.apiURL +
          'img/delete/' +
          path +
          `?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`
      )
      .toPromise()
      .then((res) => {
        console.log(res);
        return 'התמונה נטענה בהצלחה!';
      });
  }
}
