    <div class="text-center" style="font-size: 3.05em; padding-top: 30px; color: #565656;">להקים בית בישראל</div>
    <div class="text-center" style="font-size: 2.15em; color: #565656;">בסיוע שדכניות מקצועיות</div>
    <div class="form-login">
        <form novalidate [formGroup]="myform" autocomplete="on">
        <div class="header">כניסה למערכת</div>
        <div style="padding: 20px 60px;">
            <p *ngIf="!isExistsUser" class="text-center pink-color">המשתמש אינו קיים במאגר</p>
            <input autofocus class="form-control email" type="text" formControlName="email" placeholder='דוא"ל' (keydown.enter)="submit()"/>
            <p class="pink-color" *ngIf="myform.controls.email.invalid && myform.controls.email.touched">כתובת המייל אינה תקינה</p>
            <input class="form-control" type="password" formControlName="password" placeholder="סיסמא" (keydown.enter)="submit()"/>
            <p class="pink-color" *ngIf="myform.controls.password.invalid && myform.controls.password.touched">על הסיסמא להיות באורך 6 תוים לפחות, ולכלול אות ומספר</p>
            <!-- <div class="form-check">
                <input class="left-space" type="checkbox"><label>זכור אותי</label>
            </div> -->
            <button class="btn btn-blue btn-round d-block w-100 mx-auto" (click)="submit()">אישור</button>
            <div class="row-flex-between" style="font-size: 11pt;">
                <a routerLink="/new-user">משתמש חדש</a><a routerLink="/password/reset">שכחתי סיסמא</a>
            </div>

        </div>
        </form>
    </div>

   