<div id="sidebar" class="sidebar">
  <div class="above">
    <!-- <fa name="bars"></fa> -->
    <div class="shadchan-img">
      <h4
        style="
          color: #fff;
          text-align: center;
          padding-top: 30%;
          font-weight: bold;
        "
      >
        שדכנ/ית
      </h4>
    </div>
    <p><label *ngIf="!isAdmin">שלום</label> {{ shadchanName }}</p>
    <div style="height: 20px"></div>
  </div>
  <ul>
    <li *ngIf="!isAdmin" routerLink="chat" routerLinkActive="active">
      {{ "       צ׳אט" }} <fa name="comments-o"></fa>
      <span class="badge bg-danger chat-badge" *ngIf="chat.unreadAll() > 0">{{
        chat.unreadAll()
      }}</span>
    </li>
    <li routerLink="alerts" routerLinkActive="active">
      תזכורות
      <span class="badge" *ngIf="tabMap['alerts'] > 0">{{
        tabMap["alerts"]
      }}</span
      ><fa name="bell"></fa>
    </li>
    <li routerLink="offer-pair/meet" routerLinkActive="active">
      הצעות בפגישות<fa name="calendar"></fa>
    </li>
    <li routerLink="offer-pair/check" routerLinkActive="active">
      הצעות בבירורים<fa name="question-circle"></fa>
    </li>
    <li routerLink="offer/search" routerLinkActive="active">
      חיפוש חופשי<fa name="search"></fa>
    </li>
    <li (click)="openModal(chooseGender)" routerLinkActive="active">
      הוספת משודך <fa name="plus"></fa>
    </li>
    <li
      *ngIf="!isPrivate"
      routerLink="offer-pair/mzlt"
      routerLinkActive="active"
    >
      שידוכים שהתארסו
      <span class="badge" *ngIf="tabMap['mzlt'] > 0">{{ tabMap["mzlt"] }}</span
      ><fa name="heart"></fa>
    </li>
    <!-- *ngIf="isSystem" -->
    <li
      *ngIf="!isPrivate"
      routerLink="offer-pair/system"
      routerLinkActive="active"
    >
      שידוכי מערכת
      <span class="badge" *ngIf="tabMap['system'] > 0">{{
        tabMap["system"]
      }}</span
      ><fa name="wrench"></fa>
    </li>
    <!-- *ngIf="isSystem" -->
    <li *ngIf="!isPrivate" routerLink="system-alerts" routerLinkActive="active">
      התראות מערכת
      <span class="badge" *ngIf="tabMap['systemAlerts'] > 0">{{
        tabMap["systemAlerts"]
      }}</span
      ><fa name="bell"></fa>
    </li>

    <li routerLink="add-shadchan/edit" routerLinkActive="active">
      הפרטים שלי<fa name="pencil"></fa>
    </li>
    <li routerLink="offer-pair/shadchanUsers" routerLinkActive="active">
      השידוכים שבטיפולי
      <span class="badge" *ngIf="tabMap['shadchanUsers'] > 0">{{
        tabMap["shadchanUsers"]
      }}</span
      ><fa name="wrench"></fa>
    </li>
    <li routerLink="shadchan-list" routerLinkActive="active">
      רשימת שדכנים<fa name="list"></fa>
    </li>
    <li
      *ngIf="userService.report"
      routerLink="shdchan-report"
      routerLinkActive="active"
    >
      דוחות לשדכנים<fa name="book"></fa>
    </li>
  </ul>
  <!-- <div class="under"></div> -->
</div>
<router-outlet class=""> </router-outlet>

<ng-template #chooseGender>
  <form
    novalidate
    [formGroup]="myform"
    (ngSubmit)="initGender()"
    class="padding-20"
  >
    <p class="text-center">מה ברצונך להוסיף?</p>
    <br />
    <div
      btnRadioGroup
      formControlName="gender"
      style="width: 100%; max-width: 400px; margin: auto"
    >
      <button
        btnRadio="1"
        class="btn btn-blue btn-round pull-right"
        style="width: 180px"
      >
        <fa name="male left-space"></fa>גבר
      </button>
      <button
        btnRadio="2"
        class="btn btn-pink btn-round pull-left"
        style="width: 180px"
      >
        <fa name="female left-space"></fa>אישה
      </button>
    </div>
  </form>
</ng-template>
