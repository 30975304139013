<div *ngIf="!this.tabsLoaded[0] && state != 'add'">
  <i class="fa fa-spinner fa-pulse fa-4x mr-194"></i>
</div>
<div [ngClass]="{ 'app-add-man': state == 'add' }">
  <div
    *ngIf="this.tabsLoaded[0] || state == 'add'"
    [ngClass]="{ 'app-edit-man': state == 'edit' }"
  >
    <p
      *ngIf="state == 'add' && currentTitle == 0 && !isShadchan"
      class="pink-color right-space"
      style="width: 345px; margin: auto; margin-top: 20px; cursor: pointer"
    >
      <fa
        name="compress"
        class="expand-compress"
        routerLink="/register-by-steps/0"
      ></fa
      >צא ממסך מלא
    </p>
    <div *ngIf="state == 'edit'">
      <br />
      <button class="btn btn-blue btn-update-all" (click)="updateAll()">
        עדכן פרטים<fa name="refresh right-space"></fa>
      </button>

      <div
        style="
          width: 95%;
          max-width: 800px;
          display: table;
          margin: -33px auto 20px auto;
        "
      >
        <button
          class="btn btn-light-gray d-block mx-auto"
          (click)="removeMe()"
          style="float: left"
        >
          מחק אותי מהמערכת<fa name="times right-space"></fa>
        </button>
      </div>

      <alert
        *ngIf="updateAllMsg.length > 0"
        type="info alert-top"
        dismissible="true"
        (onClosed)="updateAllMsg = []"
      >
        <p *ngIf="updateAllMsg[3]; else Detailed" class="text-center">
          {{ updateAllMsg[3] }}
        </p>
        <ng-template #Detailed>
          <p *ngFor="let msg of updateAllMsg" class="text-center">{{ msg }}</p>
        </ng-template>
      </alert>
      <div
        *ngIf="loadIcon"
        class="d-block mx-auto"
        style="width: 55px; margin-top: 20px"
      >
        <app-fulfilling-bouncing-circle-spinner
          [animationDuration]="1500"
          [size]="55"
          [color]="'var(--blue)'"
        >
        </app-fulfilling-bouncing-circle-spinner>
      </div>
    </div>

    <div *ngIf="state == 'add' && hasDraft" class="header-form">
      <button
        class="btn btn-light-gray d-block mr-auto"
        (click)="recoverDraft()"
        style="float: left"
      >
        {{ draftMessage }}<fa name="backward right-space"></fa>
      </button>
    </div>

    <div
      class="register-form"
      [ngClass]="{ 'edit-form': state == 'edit' }"
      *ngIf="state == 'add' || tabsLoaded[0]"
      (input)="startFill = true"
    >
      <tabset type="pills" [justified]="true">
        <tab [active]="currentTitle == 0" (selectTab)="currentTitle = 0">
          <ng-template tabHeading>
            <div class="div-switch" (click)="tryToSwitch($event, 0)">
              {{ formTitle[0] }}&nbsp;<fa name="pencil circled"></fa>
              <fa name="chevron-left"></fa>
            </div>
          </ng-template>
          <div class="inner-container row-flex">
            <div
              *ngIf="state == 'edit'"
              class="row-flex-between"
              style="width: 100%; max-width: 625px; margin-right: 0px"
            >
              <div class="d-flex w-100">
                <label class="right-label">הסטטוס שלי</label>
                <div class="btn-group" dropdown>
                  <button
                    dropdownToggle
                    type="button"
                    class="btn dropdown-toggle"
                  >
                    {{ statusLbl[personTemp.status] }}&nbsp;&nbsp;<span
                      class="caret"
                    ></span>
                  </button>
                  <ul *dropdownMenu class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" (click)="changeStatus(1)"
                        >פעיל</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" (click)="changeStatus(0)"
                        >לא פעיל</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" (click)="changeStatus(2)"
                        >התארסתי</a
                      >
                    </li>
                  </ul>
                </div>
                <label *ngIf="personTemp.status == 2" class="mzlt-label"
                  >מזל טוב!!!</label
                >

                <!-- <button
                  style="margin-right: auto"
                  *ngIf="canToCreaetUser"
                  class="btn btn-danger btn-update-all"
                  (click)="createNewUser()"
                >
                  יצירת משתמש <fa name="user right-space"></fa>
                </button> -->
              </div>
              <div *ngIf="!hasntEmail && !isShadchan" style="margin-top: 5px">
                <label>שלח לי התראות</label>
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="getAlerts" />
                  <span class="slider round"></span>
                </label>
                <label>אל תשלח</label>
              </div>
              <!-- <div *ngIf="isShadchan || isAdmin">
                <br /><label>הערות שדכנ/ית</label>
                <div *ngIf="personTemp?.notes" class="w-100 bg-light p-3">
                  {{ personTemp?.notes }}
                  <div class="d-flex">
                    <button
                      class="btn btn-outline-danger ml-3 mr-1"
                      (click)="personTemp['notes'] = ''"
                    >
                      מחק
                    </button>
                    <a
                      class="gray-color"
                      title="עריכה"
                      routerLink="/user-folder/edit-notes-status"
                      routerLinkActive="active"
                      >ערוך
                    </a>
                  </div>
                </div>

                <app-notes userId="{{ personId }}"> </app-notes>
              </div> -->
            </div>

            <p *ngIf="gender == 'man'" class="pink-color w-100">
              שים לב! הפרטים המסומנים ב <fa name="eye-slash"></fa> יוצגו
              לשדכניות בלבד.
            </p>
            <p *ngIf="gender == 'woman'" class="pink-color w-100">
              שימי לב! הפרטים המסומנים ב <fa name="eye-slash"></fa> יוצגו
              לשדכניות בלבד.
            </p>
            <p *ngIf="!validFlag" class="invalid w-100" style="color: brown">
              <fa name="exclamation-circle left-space"></fa>יש להשלים /לתקן את
              שדות החובה
            </p>
            <div
              *ngFor="let ask of asks; let i = index"
              [ngClass]="{ 'w-100': ask.typeInput < 3 }"
            >
              <div
                *ngIf="
                  (!ask.idParentAns || ansHasSub[ask.idParentAns]) &&
                  ask.name != 'age' &&
                  !ask.hidden
                "
                class="mb-15"
                [ngClass]="{
                  'ml-45':
                    (ask.typeInput == 3 || ask.typeInput == 6) &&
                    ((i + 1) % 3 > 0 || i > 10)
                }"
              >
                <div
                  [ngClass]="{
                    invalid:
                      !validFlag &&
                      validationMap[ask.name] == false &&
                      ifCurrentValid(ask)
                  }"
                >
                  <fa
                    *ngIf="
                      (this.state == 'add' || ask.name != 'israelID') &&
                      !ask.isPublic
                    "
                    name="eye-slash pink-color left-space"
                  ></fa>
                  <label
                    *ngIf="this.state == 'add' || ask.name != 'israelID'"
                    class="right-label label-{{ ask.name }}"
                    >{{ ask[gender] }}</label
                  >
                  <label *ngIf="ask.name == 'moneySum'" class="note"
                    >(במספרים בלבד)</label
                  >
                  <div *ngIf="ask.name == 'email'" class="form-check">
                    <div *ngIf="isShadchan || state == 'edit'">
                      <input
                        (blur)="bluerDartPerson()"
                        class="left-space"
                        type="checkbox"
                        [(ngModel)]="hasntEmail"
                        (click)="emptyEmail()"
                      />
                      <label>אין לי</label>
                    </div>
                  </div>
                  <label *ngIf="ask.typeInput == 2" class="text-center note"
                    >&nbsp;&nbsp;&nbsp;&nbsp;ניתן לבחור יותר מתשובה אחת</label
                  >
                  <div *ngIf="ask.typeInput == 1">
                    <div
                      class="btn-my-group {{ ask.name }}"
                      btnRadioGroup
                      [(ngModel)]="personTemp[ask.name]"
                    >
                      <label
                        *ngFor="let ans of ansMap[ask.id]"
                        class="btn btn-light"
                        btnRadio="{{ ans.id }}"
                        tabindex="0"
                        role="button"
                        (click)="updateAns(personTemp[ask.name], ask, i)"
                        >{{ ans[gender] }}</label
                      >
                    </div>
                    <!-- <textarea *ngIf="personTemp[ask.name].includes(';other')" rows="1" class="form-control"[(ngModel)]="personTemp[ask.name].split(';')[0]"></textarea> -->
                  </div>
                  <div *ngIf="ask.typeInput == 2">
                    <div class="btn-my-group {{ ask.name }}">
                      <label
                        *ngFor="let ans of ansMap[ask.id]"
                        class="btn btn-light"
                        tabindex="0"
                        role="button"
                        [ngClass]="{
                          active: personTemp[ask.name].includes(';' + ans.id)
                        }"
                        (click)="
                          addMultiAns(ans.id, ask.name);
                          updateAns(personTemp[ask.name], ask, i)
                        "
                      >
                        {{ ans[gender] }}
                      </label>
                    </div>
                    <!-- {{personTemp[ask.name]}} -->
                  </div>
                  <div *ngIf="ask.typeInput == 3">
                    <input
                      class="form-control {{ ask.name }}"
                      [type]="ask.name == 'moneySum' ? 'number' : 'text'"
                      [(ngModel)]="personTemp[ask.name]"
                      (blur)="
                        ask.name == 'email'
                          ? blurEmail($event)
                          : ask.name == 'tel1'
                          ? blurTel1($event)
                          : bluerDartPerson()
                      "
                      [disabled]="ask.name == 'email' && hasntEmail == true"
                    />
                    <div
                      *ngIf="duplicateEmail && ask.name == 'email'"
                      style="color: brown"
                    >
                      <label>דוא"ל זה כבר קיים במערכת</label>
                    </div>
                    <div
                      *ngIf="
                        ask.name == 'email' && !hasntEmail && state == 'edit'
                      "
                      class="a-change-pass"
                    >
                      <fa name="lock left-space"></fa
                      ><a (click)="changePassword()">שינוי סיסמא</a>
                    </div>
                  </div>
                  <div *ngIf="ask.typeInput == 4">
                    <textarea
                      #currentInput
                      class="form-control {{ ask.name }}"
                      rows="1"
                      [(ngModel)]="personTemp[ask.name]"
                      (blur)="bluerDartPerson()"
                    ></textarea>
                  </div>
                  <div *ngIf="ask.typeInput == 5">
                    <div
                      class="btn-my-group {{ ask.name }}"
                      btnRadioGroup
                      [(ngModel)]="personTemp[ask.name]"
                    >
                      <label
                        class="btn btn-light"
                        btnRadio="true"
                        tabindex="0"
                        role="button"
                        >כן</label
                      >
                      <label
                        class="btn btn-light"
                        btnRadio="false"
                        tabindex="0"
                        role="button"
                        >לא</label
                      >
                    </div>
                  </div>
                  <div *ngIf="ask.typeInput == 6">
                    <app-date-with-heb
                      *ngIf="state == 'edit'"
                      (dateSelected)="setBirthdate($event)"
                    ></app-date-with-heb>
                    <input
                      *ngIf="state == 'edit'"
                      class="form-control {{ ask.name }}"
                      type="text"
                      [(ngModel)]="personTemp['birthdateHeb']"
                    />
                    <app-date-with-heb
                      [prevD]="personTemp?.['birthdate']"
                      (dateSelected)="setBirthdate($event)"
                    ></app-date-with-heb>
                    <input
                      *ngIf="state == 'add' && !showDatepicker"
                      class="form-control"
                      type="text"
                      (focus)="showDatepicker = true"
                      [(ngModel)]="personTemp['birthdateHeb']"
                    />
                    <!-- <app-datepicker-hebrew *ngIf="state=='add' && showDatepicker" (dateSelected)="setBirthdate($event)"> </app-datepicker-hebrew>
                  <input *ngIf="state=='edit'" class="form-control {{ask.name}}" type="text" [(ngModel)]="personTemp['birthdateHeb']"  />   -->
                  </div>
                  <div *ngIf="ask.name == 'israelID' && state == 'add'">
                    <div
                      *ngIf="duplicateIsraelID && ask.name == 'israelID'"
                      style="color: brown"
                    >
                      <label>תעודת זהות זאת כבר קיימת במערכת</label>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="personTemp['israelID']"
                      maxlength="9"
                      (keypress)="validateNo($event)"
                      (blur)="bluerDartPerson()"
                    />
                  </div>
                  <div
                    *ngIf="
                      i == familyStatusI && familyStatusFlag && state == 'edit'
                    "
                  >
                    <fa name="eye-slash pink-color left-space"></fa>
                    <label class="right-label">פירוט</label>
                    <textarea
                      class="form-control"
                      rows="1"
                      [(ngModel)]="personTemp['familyStatusDetails']"
                      (blur)="bluerDartPerson()"
                    ></textarea>
                  </div>
                  <div
                    *ngIf="
                      i == familyStatusI && familyStatusFlag && state == 'add'
                    "
                    class="clearfix bordered"
                  >
                    <app-family-status-asks
                      [idFamilyStatus]="personTemp['familyStatus']"
                      [validFlag]="validFlag"
                    >
                    </app-family-status-asks>
                  </div>
                </div>
              </div>
            </div>
            <!-- upload img -->
            <app-add-img
              (imgLoading)="setImgLoading()"
              (imgLoaded)="setImgLoaded()"
              [prevImg]="personTemp['img']"
              [state]="state"
            ></app-add-img>
          </div>
          <button
            *ngIf="state == 'add'"
            id="btnSave0"
            class="btn btn-blue btn-save d-block mx-auto"
            (click)="saveMen()"
            [disabled]="imgLoading"
          >
            <fa name="check left-space"></fa>שמירה
          </button>
        </tab>
        <!-- --------------recommends------------------------------------------------------------------- -->
        <tab [active]="currentTitle == 1" (selectTab)="currentTitle = 1">
          <!-- [disabled]="currentTitle>=1" -->
          <ng-template tabHeading>
            <div class="div-switch" (click)="tryToSwitch($event, 1)">
              {{ formTitle[1] }}&nbsp;<fa name="comments circled"></fa>
              <fa name="chevron-left"></fa>
            </div>
          </ng-template>
          <div *ngIf="tabsLoaded[1]">
            <div *ngFor="let item of arrNum(countRecommends); let i = index">
              <div
                class="container row-flex"
                style="justify-content: space-between"
              >
                <div *ngFor="let field of recommendFields | keyvalue">
                  <div style="width: 150px">
                    <label class="right-label">{{ field.value }}</label>
                    <input
                      class="form-control"
                      type="text"
                      [(ngModel)]="recommendList[i][field.key]"
                    />
                  </div>
                </div>
                <div class="mobile-separator">
                  <div class="btn-wrapper" style="width: 75px">
                    <label class="right-label">&nbsp;</label>
                    <button
                      class="btn btn-danger"
                      (click)="delRecommend(i)"
                      style="margin-left: 40px; padding: 5px 9px"
                    >
                      <fa name="remove left-space"></fa>מחק
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-block mx-auto"
              style="display: table !important; margin-top: 30px"
            >
              <button
                class="btn btn-blue"
                (click)="addRecommend()"
                style="margin-left: 40px"
              >
                <fa name="plus left-space"></fa>הוספת ממליץ
              </button>
              <button
                *ngIf="state == 'add'"
                id="btnSave1"
                class="btn btn-blue btn-save"
                (click)="saveRecommends()"
                style="margin-left: 40px"
              >
                <fa name="check left-space"></fa>שמירה / המשך
              </button>
            </div>
          </div>
        </tab>
        <!-- --------------prefAsks------------------------------------------------------------------- -->
        <tab [active]="currentTitle == 2" (selectTab)="currentTitle = 2">
          <ng-template tabHeading>
            <div
              class="div-switch"
              (click)="tryToSwitch($event, 2)"
              style="padding: 0.5rem 0px"
            >
              {{ formTitle[2] }}&nbsp;<fa name="search circled"></fa>
              <fa name="chevron-left"></fa>
            </div>
          </ng-template>

          <div
            *ngIf="state == 'add' || tabsLoaded[2]"
            class="inner-container prefAsks"
          >
            <p
              *ngIf="gender == 'man'"
              class="text-center pink-color"
              style="font-weight: bold"
            >
              איזה בחורה אני מחפש (זה יעזור לנו להתאים לך הצעות)
            </p>
            <p
              *ngIf="gender == 'woman'"
              class="text-center pink-color"
              style="font-weight: bold"
            >
              איזה בחור אני מחפשת (זה יעזור לנו להתאים לך הצעות)
            </p>
            <p
              *ngIf="!validFlag"
              class="invalid w-100 text-center"
              style="color: brown"
            >
              <fa name="exclamation-circle left-space"></fa>יש להשלים /לתקן את
              שדות החובה
            </p>
            <br />
            <p class="text-center note">
              &nbsp;&nbsp;&nbsp;&nbsp;בכל שאלה ניתן לבחור יותר מתשובה אחת
            </p>
            <div *ngFor="let ask of prefAsks; let i = index">
              <div
                [ngClass]="{ invalid: !validFlag && !validationMap[ask.name] }"
              >
                <div
                  [ngClass]="{
                    'flex-wrap': ask.name == 'familyStatus',
                    'row-flex-separated': !isMobile
                  }"
                >
                  <div>
                    <label class="right-label label-{{ ask.name }}">{{
                      asksIdMap[ask.idAsk][0][otherGender]
                    }}</label>
                  </div>
                  <div *ngIf="ask.typeInput == 1">
                    <div
                      class="btn-my-group {{ ask.name }}"
                      btnRadioGroup
                      [(ngModel)]="personPref[ask.name]"
                    >
                      <label
                        *ngFor="let ans of ansPrefMap[ask.idAsk]"
                        class="btn btn-light"
                        btnRadio="{{ ans.id }}"
                        tabindex="0"
                        role="button"
                        >{{ ans[otherGender] }}</label
                      >
                    </div>
                    <!-- {{personTemp[ask.name]}} -->
                  </div>
                  <div *ngIf="ask.typeInput == 2">
                    <div class="btn-my-group {{ ask.name }}">
                      <label
                        *ngFor="let ans of ansPrefMap[ask.idAsk]"
                        class="btn btn-light"
                        tabindex="0"
                        role="button"
                        [ngClass]="{
                          active: personPref[ask.name].includes(';' + ans.id)
                        }"
                        (click)="addMultiPref(ans.id, ask.name)"
                        >{{ ans[otherGender] }}</label
                      >
                      <label
                        *ngIf="ask.defaultAns !== '0'"
                        (click)="addMultiPref(';0', ask.name)"
                        [ngClass]="{
                          active: personPref[ask.name].includes(';0')
                        }"
                        class="btn btn-light"
                        tabindex="0"
                        role="button"
                        >{{ ask.defaultAns }}</label
                      >
                    </div>
                  </div>
                  <div *ngIf="ask.typeInput == 5">
                    <div
                      class="btn-my-group {{ ask.name }}"
                      btnRadioGroup
                      [(ngModel)]="personPref[ask.name]"
                    >
                      <label
                        class="btn btn-light"
                        btnRadio="true"
                        tabindex="0"
                        role="button"
                        >לא משנה</label
                      >
                      <label
                        class="btn btn-light"
                        btnRadio="false"
                        tabindex="0"
                        role="button"
                        >לא</label
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="
                      i == (gender == 'man' ? 2 : 3) &&
                      !numKidsIrrelevant.includes(personPref['familyStatus'])
                    "
                    [ngStyle]="{ 'margin-right': isMobile ? '0' : '130px' }"
                  >
                    <!-- style="margin-right: 130px;">isMobile -->
                    <div
                      class="btn-my-group"
                      btnRadioGroup
                      [(ngModel)]="personPref['numKids']"
                      [ngClass]="{
                        invalid: !validFlag && !validationMap['numKids']
                      }"
                    >
                      <label
                        class="btn btn-light"
                        [btnRadio]="0"
                        tabindex="0"
                        role="button"
                        >בלי ילדים</label
                      >
                      <label
                        class="btn btn-light"
                        [btnRadio]="-1"
                        tabindex="0"
                        role="button"
                        >עם ילדים</label
                      >
                      <label
                        class="btn btn-light"
                        [btnRadio]="-2"
                        tabindex="0"
                        role="button"
                        >לא משנה</label
                      >
                      <label
                        class="btn btn-light"
                        [btnRadio]="maxNumKids"
                        tabindex="0"
                        role="button"
                        >עד
                        <input
                          type="text"
                          class="small-input"
                          [(ngModel)]="maxNumKids"
                        />ילדים
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              [ngClass]="{
                invalid:
                  !validFlag &&
                  !(validationMap['minAge'] && validationMap['maxAge'])
              }"
              style="padding-bottom: 1px"
            >
              <div class="row-flex age" style="justify-content: flex-start">
                <label class="right-label">גיל</label>
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="personPref['minAge']"
                  placeholder="מ-"
                  style="width: 80px"
                />
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="personPref['maxAge']"
                  placeholder="עד"
                  style="width: 80px"
                />
              </div>
            </div>
          </div>
          <button
            *ngIf="state == 'add'"
            id="btnSave2"
            class="btn btn-blue btn-save d-block mx-auto"
            (click)="savePrefAsks()"
          >
            <fa name="check left-space"></fa>שמירה
          </button>
        </tab>
        <!-- --------------finish------------------------------------------------------------------- -->

        <tab
          *ngIf="state == 'add'"
          [active]="currentTitle == 3"
          (selectTab)="currentTitle = 3"
        >
          <ng-template tabHeading>
            <div class="div-switch" (click)="tryToSwitch($event, 3)">
              {{ formTitle[3] }}&nbsp;<fa name="thumbs-up circled"></fa>
              <!-- check -->
            </div>
          </ng-template>
          <div class="finish-register">
            <div style="width: 60%; margin: 6% auto; line-height: 2.4em">
              <div *ngIf="!isShadchan">
                <div style="font-size: 3.8em">נרשמת בהצלחה</div>
                <br />
                <div style="font-size: 2.3em">בשורות טובות!</div>
                <br />
                <div class="pink-color" style="font-size: 1.1em">
                  בכל עת {{ canByGender }} לערוך את פרטיך, פרטי הממליצים
                  וההעדפות שלך בעמוד "הפרטים שלי".
                  <br />
                  אם ברצונך לא לקבל התראות על הצעות מתאימות
                  {{ canByGender }} לסמן זאת באותו עמוד (גם באופן זמני).
                </div>
                <br />
                <button
                  class="btn btn-blue btn-round d-block mx-auto"
                  (click)="complete()"
                  routerLink="/user-folder/offer/system"
                >
                  המשך לאתר
                </button>
              </div>
              <!-- ----------------------------------------------------------------------->
              <div *ngIf="isShadchan">
                <div style="font-size: 3.05em">ההרשמה בוצעה בהצלחה</div>
                <br />
                <div style="font-size: 2.3em">בשורות טובות!</div>
                <br />
                <div class="row-flex" class="finish-register-shadchan">
                  <div>
                    <label class="right-label">מודל תשלום</label>
                    <div
                      class="btn-my-group"
                      btnRadioGroup
                      [(ngModel)]="person['payModel']"
                    >
                      <label
                        class="btn btn-light"
                        btnRadio="דמי שידוך"
                        tabindex="0"
                        role="button"
                        checked
                        >דמי שידוך</label
                      >
                      <label
                        class="btn btn-light"
                        btnRadio="דמי פגישה"
                        tabindex="0"
                        role="button"
                        >דמי פגישה</label
                      >
                      <label
                        class="btn btn-light"
                        btnRadio="דמי רישום"
                        tabindex="0"
                        role="button"
                        >דמי רישום</label
                      >
                    </div>
                  </div>
                  <!-- <div>
                  <div><label class="right-label">מי יראה את הטלפון של המשודך</label><label
                      class="note">&nbsp;&nbsp;&nbsp;&nbsp;מי שלא יראה יתקשר
                      מולך</label></div>
                  <div class="btn-my-group" btnRadioGroup [(ngModel)]="person['permission']">
                    <label class="btn btn-light" btnRadio="0" tabindex="0" role="button">רק אני</label>
                    <label class="btn btn-light" btnRadio="1" tabindex="0" role="button" checked>שדכניות מהקבוצה
                      שלי</label>
                    <label class="btn btn-light" btnRadio="2" tabindex="0" role="button">כל השדכניות במערכת</label>
                  </div>
                </div> -->

                  <!-- <div *ngIf="hasntEmail">
                <label class="right-label">האם ברצונך לקבל התראות על הצעות מתאימות למשודך זה?</label>
                <div class="btn-my-group" btnRadioGroup [(ngModel)]="getAlerts">
                  <label class="btn btn-light" btnRadio="true" tabindex="0" role="button">כן</label>
                  <label class="btn btn-light" btnRadio="false" tabindex="0" role="button">לא</label>
                </div>
              </div> -->
                  <br />
                  <!-- && !isPersonalShadchan -->
                  <div
                    *ngIf="!hasntEmail"
                    class="a-register"
                    style="font-size: 12pt; text-decoration: underline"
                  >
                    {{ registerMsg }}
                  </div>
                  <br />

                  <button
                    class="btn btn-blue btn-round d-block mx-auto"
                    (click)="goToUserFolder(person, 'offer/system')"
                  >
                    חפש הצעות<fa name="search right-space"></fa>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </tab>
      </tabset>
    </div>
  </div>

  <ng-template #duplicatePersons>
    <div
      class="modal-dialog modal-lg"
      style="pointer-events: unset"
      role="dialog"
    >
      <div class="modal-header">
        <p
          class="modal-title modal-body text-center text-info"
          style="padding: 0px !important"
        >
          משתמש עם טלפון או אמייל כבר קיים
          <fa name="user right-space"></fa>
          <button
            type="button"
            class="close pull-left"
            aria-label="Close"
            (click)="modalRef.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </p>
      </div>
      <div class="modal-body">
        <table *ngIf="duplicates.length" class="table">
          <thead>
            <tr>
              <th>שם משפחה</th>
              <th>שם פרטי</th>
              <th>טלפון</th>
              <th>דוא"ל</th>
              <th>נערך ב</th>
              <th>צפיה בפרופיל</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let u of duplicates">
              <td>{{ u.lastName }}</td>
              <td>{{ u.firstName }}</td>
              <td>{{ u.tel1 || u.phone }}</td>
              <td>{{ u.email || "אין" }}</td>
              <th>{{ format(u?.lastEditDate || u?.lastEditedAt) }}</th>
              <td>
                <button
                  class="btn btn-round btn-blue"
                  (click)="goToUserFolder(u, 'user-details')"
                >
                  לפרופיל המלא<fa name="address-card right-space"></fa>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>

  <!-- ----------------------------------------------------------------------- -->
  <ng-template #addUser>
    <div class="modal-header">
      <p
        class="modal-title modal-body text-center text-info"
        style="padding: 0px !important"
      >
        יצירת איזור אישי
        <fa name="user right-space"></fa>
      </p>
      <button
        type="button"
        class="close pull-left"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <button
        class="btn btn-blue btn-round d-block mx-auto"
        (click)="openBlank('new-user')"
      >
        לחץ כאן לרישום המשתמש
      </button>
    </div>
  </ng-template>
</div>

<style>
  .my-modal {
    max-width: 1000px;
  }
</style>
