import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  myform: UntypedFormGroup;
  showPass: boolean = false;
  done: boolean = false;
  fix: boolean = true;
  errorMsg: string="";
  token: string;

  constructor(private http: HttpClient, private globals: Globals, private router: Router,
    private route: ActivatedRoute, private userService: UserService) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.params.token || '';
    this.initForm();
  }

  initForm() {
    this.myform = new UntypedFormGroup({
      newPass: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      repeatPass: new UntypedFormControl('', [Validators.required]),
    });
  }

  checkValidation(){
    if(this.myform.controls['newPass'].value!=this.myform.controls['repeatPass'].value){
      this.errorMsg="הסיסמא אינה זהה";
      return false;
    }
    return true;
  }

  submit() {
    this.errorMsg="";
    if(!this.myform.valid || !this.checkValidation()){
      this.fix=false;
      return;
    }
    if(this.token=='')
      this.errorMsg="הקישור אינו תקין/ אינו בתוקף.";
    else
      this.setPassIfTokenExists(this.token, this.myform.controls['newPass'].value);
  }

  setPassIfTokenExists(token:string, p:string) {
      this.http.post(this.globals.apiURL + `users/setPassIfTokenExists?token=${token}&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,p, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).subscribe(response => {
        console.log(response.toString());
        console.log(response.toString());
        if(response.toString()=="1")    {
          this.done = true;
        } else{
          this.errorMsg="הקישור אינו תקין/ אינו בתוקף.";
        }
      }, (err: HttpErrorResponse) => {
        console.log(err);
        this.errorMsg="התרחשה שגיאה, אנא נסו מאוחר יותר";
        this.done = false;
      });
    }

    sendLinkIfExists(){
      this.http.post(this.globals.apiURL + `users/sendLinkIfExists/${this.myform.controls['email'].value}&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).subscribe(response => {
        console.log(response.toString());
        if(response.toString()=="1")
          this.done = true;
        else
          this.errorMsg="המשתמש אינו קיים במאגר";
      }, (err: HttpErrorResponse) => {
        console.log(err);
        this.errorMsg="התרחשה שגיאה, אנא נסו מאוחר יותר";
        this.done = false;
      });
    }

    blockPaste(e: any) { e.preventDefault()}

}
