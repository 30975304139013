
<div class="mainDiv">
    <div>
        <p> <label *ngIf="isAdmin">THIS DB IS: </label> {{systemEnv}}</p>

                   <div>
                        <select id="genderDD"  name="gender" [(ngModel)]="gender" (change)="getUsersByPeriod($event)">
                            <option value="0" >בחר אוכלוסיה</option>
                            <option value="1">גברים</option>
                            <option value="2">נשים</option>
                        </select>
                        <select id="periodDD2" name="period" [(ngModel)]="period" (change)="getUsersByPeriod($event)">
                            <option value="0" >בחר תקופה</option>
                            <option value="1">היום</option>
                            <option value="3">3 ימים אחרונים</option>
                            <option value="7">שבוע</option>
                            <option value="30">חודש</option>
                            <option value="90">3 חודשים</option>
                            <option value="180">6 חודשים</option>
                            <option value="360">שנה</option>
                            <option value="540">שנה וחצי</option>
                            <option value="720">שנתיים</option>
                        </select>
                        <button (click)="showUsers()" value="">הצג משתמשים</button>
                    </div>
    </div> 
    
 
       <div>
            <label for="totalUsers">מספר המשתמשים </label>
            <input type="text" readonly id="totalUsers" [(ngModel)]="totalUsers"/> 
            </div>
            <div  class="reportsTable table-wrap">
                <table class="table table-responsive-xl">
                    <thead >
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">תאריך הצטרפות למערכת</th>
                            <th scope="col">שם פרטי</th>
                            <th scope="col">שם משפחה</th>
                            <th scope="col">מייל </th>
                            <th scope="col">טלפון ראשי</th>
                            <th>טלפון משני</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let userReport of reports ; index as i">
                            <td scope="row">{{i+1}}</td>
                            <td>{{userReport.joinDate}}</td>
                            <td>{{userReport.firstName}}</td>
                            <td>{{userReport.lastName}}</td>
                            <td>{{userReport.email}}</td>
                            <td>{{userReport.tel1}}</td>
                            <td>{{userReport.tel2}}</td>
                        </tr>
                </tbody>
            </table>
      
   
    

        <div>
            <select (change)="setShadchanId($event)" placeholder="בחר שדכן">
                <option  [value]="g.id" *ngFor="let g of shadchanList">{{g.firstName}} {{g.lastName}}</option>
            </select>
            <button (click)="showProductivityReport()" value="">הצג הישגים</button>

        </div>

        <div class="reportsTable table-wrap">
            <table class="table table-responsive-xl">
                <thead >
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">שם השדכנית</th>
                        <th scope="col">סטטוס שידוך</th>
                        <th scope="col">כמות הישגים</th>                  
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let report of productivityReports ; index as i">
                        <td scope="row">{{i+1}}</td>
                        <td>{{report.name}}</td>
                        <td>{{report.type}}</td>
                        <td>{{report.quantity}}</td>
                    </tr>
                </tbody>
            </table>
        </div> 

    </div> 

</div>

