import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-unavailable',
  templateUrl: './user-unavailable.component.html',
  styleUrls: ['./user-unavailable.component.css'],
})
export class UserUnavailableComponent implements OnInit {
  msg: string;
  msg2: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.msg2 = '';
    if (this.route.snapshot.params.state == 'locked') {
      this.msg = ' המשתמש שלך נחסם ע"י מנהל המערכת';
      this.msg = this.msg + ' לפניה למערכת נא שלחו מייל ל - ';
      this.msg = this.msg + '6093636';
      this.msg = this.msg + '@gmail.com';
    } else if (this.route.snapshot.params.state == 'new') {
      this.msg = '.אמייל נשלח אליך עכשיו לאישור ולהמשך ההרשמה';
      this.msg2 =
        'באם לא קיבלתם מייל , בדקו בקידומי מכירות או בספאם ואשרו את זה שזה לא ספאם להמשך קבלת הצעות מהמערכת';
    } else if (this.route.snapshot.params.state == 'duplicate') {
      this.msg = 'למשתמש זה קיימים כמה כרטיסיות .';
      this.msg = this.msg + ' לפניה למערכת נא שלחו מייל ל - ';
      this.msg = this.msg + '6093636';
      this.msg = this.msg + '@gmail.com';
    } else this.msg = 'משתמש זה הקים משתמש אך לא מילא פרטים';
  }
}
