import {
  CollectionReference,
  Firestore,
  collection,
  collectionChanges,
  query,
  where,
} from '@angular/fire/firestore';

import { Injectable } from '@angular/core';
import { EMPTY, Subscription, map, mergeAll, switchMap } from 'rxjs';
import { Chat, Message } from '../chat/interface';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  messageCollation: CollectionReference<Message>;
  chatCollection: CollectionReference<Chat>;
  me: number;
  chats: Map<string, Set<string>> = new Map();
  subscription: Subscription;

  ngOnDestroy() {
    console.log('ngOnDestroy: cleaning up...');
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  constructor(private userService: UserService, private firestore: Firestore) {
    if (userService.id) {
      this.me = userService.id;
      this.messageCollation = collection(
        this.firestore,
        'messages'
      ) as CollectionReference<Message>;

      this.chatCollection = collection(
        this.firestore,
        'chats'
      ) as CollectionReference<Chat>;
    }
  }

  unreadAll() {
    return Object.values([...this.chats.values()]).reduce(
      (p, c) => p + c.size,
      0
    );
  }

  unread(id: string): number {
    return this?.chats?.get(id)?.size || 0;
  }

  initData() {
    if (!this.me) {
      return;
    }
    this.subscription = collectionChanges<Chat>(
      query<Chat>(
        this.chatCollection,
        where('members', 'array-contains', this.me)
      ),
      { events: ['removed', 'added'] }
    )
      .pipe(
        switchMap((docs) => {
          const subs = docs.map(({ type, doc }) => {
            if (type !== 'removed') {
              if (!this.chats.has(doc.id)) {
                this.chats.set(doc.id, new Set([]));
              }
              return collectionChanges<Message>(
                query<Message>(
                  this.messageCollation,
                  where('chat', '==', doc.ref),
                  where('sentBy', '!=', this.me),
                  where('notRead', 'array-contains', this.me)
                )
              ).pipe(
                map((messageDoc) => {
                  return {
                    chatId: doc.id,
                    removed: messageDoc
                      .filter((v) => v.type === 'removed')
                      .map((d) => d.doc.id),
                    added: messageDoc
                      .filter((v) => v.type !== 'removed')
                      .map((d) => d.doc.id),
                  };
                })
              );
            } else {
              this.chats.delete(doc.id);
              return EMPTY;
            }
          });
          return subs;
        }),
        mergeAll()
      )
      .subscribe(({ removed, added, chatId }) => {
        added.forEach((dc) => {
          this.chats.get(chatId).add(dc);
        });
        removed.forEach((dc) => {
          this.chats?.get(chatId)?.delete?.(dc);
        });
      });
  }
}
