//#region imports
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import { Notes, Person, User } from '../entities/entities';
import * as moment from 'moment';
//#endregion

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css'],
})
export class NotesComponent implements OnInit {
  @Input() userId: number;
  user;
  gender: string;
  isLoaded: boolean = false;
  isShadchanFolder: boolean;
  isShadchan: boolean;
  isSystem: boolean;
  person: Person;
  isMobile = this.globals.isMobile;
  addMsg: boolean = false;
  msg: string;
  isAdmin: boolean;
  shadchanList: Array<User> = [];

  notes: Notes[];

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router,
    public userService: UserService
  ) {}

  getShadchanList() {
    this.http
      .get<Array<User>>(this.globals.apiURL + `shadchan/get/allShadchan`, {
        responseType: 'json',
      })
      .subscribe(
        (data) => {
          this.shadchanList = data;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  ngOnInit() {
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true';

    if (this.userId) {
      this.updateNotes();
      this.getShadchanList();
    }
  }

  updateNotes() {
    return this.http
      .get<Notes[]>(
        this.globals.apiURL +
          `users/getNotes/${this.userId}/${this.userService.gender}`,
        {
          responseType: 'json',
        }
      )
      .subscribe((data) => {
        this.notes = data;
      });
  }

  moment(date) {
    return moment(date).format('DD/MM/yy HH:mm');
  }

  ngAfterViewInit(): void {}

  initUserData() {
    this.user = JSON.parse(JSON.stringify(this.userService));
    this.isShadchanFolder =
      this.user.isShadchan && this.router.url.includes('shadchan-folder');
    this.isShadchan = this.user.isShadchan;
    this.isSystem =
      sessionStorage.getItem('isSystem') === 'true' || this?.user?.isSystem;
  }

  deleteNote(note: Notes) {
    if (!note?.id) {
      this.cancelAllNote(note);
      return;
    }
    this.http
      .delete(this.globals.apiURL + `users/deleteNote/${note.id}`, {
        responseType: 'text',
      })
      .subscribe((res) => {
        this.updateNotes();
      });
  }

  editNote(note: Notes) {
    note.createdAt = new Date();
    note.createdBy = this.userService.id;
    if (!note?.id) {
      this.addNote(note);
      return;
    }
    this.http
      .put(this.globals.apiURL + `users/updateNote`, JSON.stringify(note), {
        headers: { 'Content-Type': 'application/json' },
      })
      .subscribe((res) => {
        this.updateNotes();
      });
  }

  createNote() {
    this.notes.push({
      editMode: true,
      createdBy: this.isShadchan || this.isAdmin ? this.userService.id : 0,
      note: '',
      personId: this.userId,
      gender: this.userService.gender,
    });
  }

  addNote(note: Notes) {
    this.http
      .post(this.globals.apiURL + `users/addNote`, JSON.stringify(note), {
        headers: { 'Content-Type': 'application/json' },
      })
      .subscribe((res) => {
        this.updateNotes();
      });
  }

  cancelAllNote(note: any) {
    note.editMode = false;
    this.notes = this.notes.filter((n) => n?.id);
  }

  getShadchan(id) {
    const user: User = this.shadchanList?.find((s) => s.id === id);
    if (id === 123) {
      return 'מנהל כללי';
    }
    return user && `${user.firstName} ${user.lastName}`;
  }
}
