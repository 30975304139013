import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logo-menu-mobile',
  templateUrl: './logo-menu-mobile.component.html',
  styleUrls: ['./logo-menu-mobile.component.css']
})
export class LogoMenuMobileComponent implements OnInit {
  isMenuOpen: boolean = false;
  @ViewChild('btnMenu') btnMenu: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  in:boolean=false;

  constructor(private renderer: Renderer2, private router: Router) {
    this.renderer.listen('window', 'click', (e: Event) => {
      try{
        if (e.target !== this.btnMenu.nativeElement && e.target !== this.menu.nativeElement 
            && !this.menu.nativeElement.contains(e.target) && !this.btnMenu.nativeElement.contains(e.target)) {
          this.isMenuOpen=false;
        }
      } catch(err){return;}
    });
  }

  ngOnInit(): void { }

  hideMenu(){
    setTimeout(() => {
      this.isMenuOpen=false;
    }, 200); 
  }

  goToLogin(){
    this.in=false;
    sessionStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }

}
