import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Cloudinary } from '@cloudinary/angular-5.x';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import { Person } from '../entities/entities';
import { ChatService } from '../common/chat.service';

@Component({
  selector: 'app-user-folder',
  templateUrl: './user-folder.component.html',
  styleUrls: ['./user-folder.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [ChatService],
})
export class UserFolderComponent implements OnInit {
  gender: string;
  otherGender: string;
  Gender: string;
  userName: string;
  isShadchan: boolean;
  isAdmin: boolean;
  isLoaded: boolean = false;
  img: string;
  isYourShadchan: boolean;
  // isYourShadchan: Promise<boolean>;
  // img: Promise<string>;
  tabMap: Map<string, number>;
  //for mobile:
  isMobile = this.globals.isMobile;
  isMenuOpen: boolean = false;
  @ViewChild('btnMenu') btnMenu: ElementRef;
  @ViewChild('menu') menu: ElementRef;

  constructor(
    private titleService: Title,
    private http: HttpClient,
    private globals: Globals,
    private cloudinary: Cloudinary,
    private router: Router,
    private userService: UserService,
    private renderer: Renderer2,
    public chat: ChatService
  ) {
    if (!this.userService.isShadchan) {
      this.chat.initData();
    }

    this.renderer.listen('window', 'click', (e: Event) => {
      try {
        if (
          e.target !== this.btnMenu.nativeElement &&
          e.target !== this.menu.nativeElement &&
          !this.menu.nativeElement.contains(e.target) &&
          !this.btnMenu.nativeElement.contains(e.target)
        ) {
          this.isMenuOpen = false;
        }
      } catch (err) {
        return;
      }
    });
  }

  ngOnInit(): void {
    if (this.userService.personId == -1) {
      this.router.navigate(['/user-unavailable/duplicate']);
      return;
    }
    if (this.userService.personId == 0) {
      this.router.navigate(['/user-unavailable/empty']);
      return;
    }
    this.initUserData();
  }

  initUserData() {
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true';
    console.log(this.isAdmin);
    if (this.userService.getGender() == 1) {
      this.gender = 'man';
      this.Gender = 'Men';
    } else {
      this.gender = 'woman';
      this.Gender = 'Women';
    }
    if (!this.globals.isDefined(this.userService.tabMap) && !this.isShadchan) {
      this.userService.tabMap = new Map();
      this.userService.tabMap['receive'] = 0;
      this.userService.tabMap['sent'] = 0;
    }
    this.tabMap = this.userService.tabMap;
    this.isShadchan = this.userService.getIsShadchan();
    // this.setIsYourShadchan();
    this.getPerson().then(() => (this.isLoaded = true));
    this.userName = this.userService.getFirstName();
    if (this.isShadchan || this.isAdmin) {
      this.userName += ' ' + this.userService.getLastName();
      this.titleService.setTitle(
        (this.gender == 'man' ? 'משודך' : 'משודכת') +
          '- ' +
          this.userService.getFirstName()
      );
    }
  }

  getPerson() {
    console.log('user-folder.component getPersonDetails');
    return new Promise((resolve, reject) => {
      this.http
        .get<Person>(
          this.globals.apiURL +
            this.gender +
            `/getById/${this.userService.personId}?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .subscribe(
          (data) => {
            this.img = data.img == null ? '' : data.img;
            if (!this.isShadchan || this.isAdmin) {
              this.isYourShadchan = true;
              resolve(true);
            } else {
              let idShadchan = data.insertedBy;
              this.isYourShadchan =
                this.userService.members.includes(idShadchan);
              resolve(true);
            }
          },
          (err: HttpErrorResponse) => {
            console.log(err);
            resolve(true);
          }
        );
    });
  }

  logout() {
    console.log(
      'logout . .' + this.userService.id + '-' + this.userService.firstName
    );
    console.log(
      'logout . .' + this.userService.id + '-' + this.userService.loginFirstName
    );
    this.http
      .get(
        this.globals.apiURL +
          `users/logout?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .subscribe();
    sessionStorage.removeItem('currentUser');
  }

  hideMenu() {
    setTimeout(() => {
      this.isMenuOpen = false;
    }, 200);
  }
}
