import { Component, OnInit } from '@angular/core';
import { User } from '../entities/entities';

@Component({
  selector: 'app-remove-me',
  templateUrl: './remove-me.component.html',
  styleUrls: ['./remove-me.component.css']
})
export class RemoveMeComponent implements OnInit {
  
  isAdmin: boolean;


  constructor() { }

  ngOnInit(): void {
    this.isAdmin = sessionStorage.getItem('isAdmin')== 'true';
  }


 





}
