import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSort',
  pure: false
})
export class FilterSortPipe implements PipeTransform {

  transform(list: any, listToRemove:any): unknown {
    if (!list || list.length == 0) 
      return;

    list=list.filter(x => { return !listToRemove.some(t=> t.id == x.id)});

    list.sort((a: any, b: any) => {
      if (a.lastName < b.lastName) {
        return -1;
      } else if (a.lastName > b.lastName) {
        return 1;
      } else {
        return 0;
      }
    });

    return list;
  }
}