import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from './loader.service';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    finishLoad:boolean=true;
    constructor(public loaderService: LoaderService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.finishLoad=false;
        setTimeout(() => { if(!this.finishLoad) this.loaderService.show() }, 1000);
        return next.handle(req).pipe(
            finalize(() => {this.finishLoad=true; this.loaderService.hide();})
        );
    }
}