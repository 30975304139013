<p class="text-info">שים לב! כל המשודכים, השידוכים וההצעות שבטיפול השדכנית יועברו לשדכנית שתבחר.</p>
<div class="col-lg-10">
    <alert *ngIf="msg!=''" type="info alert-top" dismissible="true" (onClosed)="msg=''">
        <p class="text-center">{{msg}}</p>
    </alert>
    <alert *ngIf="msgWarn!=''" type="warning alert-top" dismissible="true" (onClosed)="msgWarn=''">
        <p class="text-center">{{msgWarn}}</p>
    </alert>
</div>
<div class="card" style="flex-direction: row">
        <div class="p-25">
            <p>ממי להעביר</p>
            <select autocomplete="on" class="btn" [(ngModel)]="selectedFrom" placeholder=" בחר שדכנ/ית">
                <option value="0" selected style="color: var(--gray);">בחר שדכנ/ית</option>
                <option [value]="s.id" *ngFor="let s of shadchanList">
                    {{s.lastName}} {{s.firstName}}
                </option>
            </select> 
            <div class="form-check">
                <input class="left-space" name="del" type="checkbox" (change)="del=!del"><label class="text-danger">מחק אותי</label>
            </div> 
        </div>
        <i class="fa fa-chevron-left" style="-webkit-text-stroke: 3px #fff;font-size: 24pt;margin-top: 55px;"></i>
        <div class="p-25">
            <p>למי להעביר</p>
            <select autocomplete="on" class="btn" [(ngModel)]="selectedTo" placeholder=" בחר שדכנ/ית">
                <option value="0" selected style="color: var(--gray);">בחר שדכנ/ית</option>
                <option [value]="s.id" *ngFor="let s of shadchanList">
                    {{s.lastName}} {{s.firstName}}
                </option>
            </select> 
        </div>
</div>  
<button (click)="transfer(selectedFrom,selectedTo)" class="btn btn-blue btn-round d-block mx-auto">
    העבר  <fa name="exchange right-space"></fa>  
</button>