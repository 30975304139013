import { Component, OnInit } from '@angular/core';
import { Globals } from '../common/globals';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../common/user.service';


@Component({
  selector: 'app-db-manage',
  templateUrl: './db-manage.component.html',
  styleUrls: ['./db-manage.component.css']
})
export class DbManageComponent implements OnInit {

  showImport: boolean = false;
  fileName:string = '';
  constructor( private http: HttpClient, private globals: Globals,private userService: UserService) { }

  ngOnInit(): void { }

  export(){
    console.log("export");
    this.http.get(this.globals.apiURL + 'db/export'+`?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, {
    }).subscribe(response => {
      console.log(response);
    }, (err: HttpErrorResponse) => {
      console.log(err);
     });
   }

  import (){
    console.log("import");
    this.http.get(this.globals.apiURL + 'db/import'+`?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, {
    }).subscribe(response => {
      console.log(response);
    }, (err: HttpErrorResponse) => {
      console.log(err);
     });
  }



  onFileSelected(event) {

    const file:File = event.target.files[0].path;
    console.log(file);
    console.log(event.target);
    console.log(event.target.value);
    console.log(event.target.files);

    if (file) {
      console.log(file);

        this.fileName = file.name;

        // const formData = new FormData();

        // formData.append("thumbnail", file);

        // const upload$ = this.http.post("/api/thumbnail-upload", formData);

        // upload$.subscribe();
    }
}
}
//   onSelectFile(event) {
//     if (event.target.files && event.target.files[0]) {
//       var reader = new FileReader();

//       reader.readAsDataURL(event.target.files[0]); // read file as data url

//       reader.onload = (event) => { // called once readAsDataURL is completed
//         this.url = event.target.result;
//       }
//     }
// }
