<div *ngIf="!noResults">
  <app-filter-pair
    [pairsListRoot]="pairsListRoot"
    [allAsks]="allAsks"
    (filterClick)="updatePairsList($event)"
  ></app-filter-pair>
</div>
<h1 class="page-title">{{ pageTitle }}</h1>
<div *ngIf="offersType == 'check' || offersType == 'meet'">
  <!-- <form>
        <label style="margin: 0px 20px 17px 15px;"><input type="radio" name="repo" value="1" [checked]="repo==1"
                (change)="toggleRepo(1)"> מאגר אישי</label>&nbsp;&nbsp;
        <label><input type="radio" name="repo" value="2" [checked]="repo==2" (change)="toggleRepo(2)"> מאגר כללי</label>
    </form> -->
</div>
<div *ngIf="noResults" class="mx-auto" class="div-noResults">
  <h1 class="text-center pink-color">
    לא נמצאו {{ offersType == "mzlt" ? "שידוכים" : "הצעות" }}.
  </h1>
</div>

<div
  id="o{{ pair.offer.id }}"
  *ngFor="let pair of pairsList | myOfferFilter : pairsListFiltered"
>
  <div
    *ngIf="
      pair.man != null &&
      pair.woman != null &&
      ((offersType == 'shadchanUsers' && pair.offer.shadchan == shadchanId) ||
        (offersType == 'system' && shadchanId == pair.man.relatedShadchanID) ||
        (offersType == 'system' &&
          shadchanId == pair.woman.relatedShadchanID) ||
        offersType != 'shadchanUsers')
    "
    class="offer-box"
  >
    <div *ngIf="offersType == 'shadchanUsers' && pair.offer.offerStatus == 14">
      <div class="d-flex flex-column align-items-center">
        <div style="display: flex; justify-content: center">
          <label class="key-label"
            >{{ reactionDateTextMap?.get(offersType) }}
          </label>
          <label class="value-label">{{
            pair.offer?.dateCare || pair.offer?.dateStatus
          }}</label>
        </div>
        <div style="text-align: center">
          <button
            class="btn btn-round btn-gray"
            (click)="playBtn('system', pair.offer, 1)"
          >
            שחרור מטיפולי האישי
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="offersType != 'shadchanUsers' && pair.offer.offerStatus != 14">
      <div class="d-flex flex-column align-items-center">
        <div style="display: flex; justify-content: center">
          <label class="key-label"
            >{{ reactionDateTextMap?.get(offersType) }}
          </label>
          <label class="value-label">{{ pair.offer.dateStatus }}</label>
        </div>

        <div *ngIf="offersType == 'system'" style="text-align: center">
          <button
            class="btn btn-round btn-gray"
            (click)="playBtn('shadchanUsers', pair.offer, 1)"
          >
            השידוך בטיפולי האישי
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="man-container w-50 d-flex">
        <div class="profile-container">
          <div class="male-img">
            <app-add-img
              *ngIf="pair.man.img != null"
              [onlyShow]="true"
              [userImg]="pair.man.img"
            ></app-add-img>
          </div>
          <p>{{ pair.man.tel1 }}</p>
          <p style="margin-top: -12px">{{ pair.man.tel2 }}&nbsp;</p>
          <button
            (click)="goToUserFolder(pair.man, 1)"
            class="btn btn-round btn-blue d-block mx-auto"
          >
            לפרופיל המלא
          </button>
          <button
            *ngIf="
              offersType == 'shadchanUsers' ||
              offersType == 'system' ||
              (offersType == 'check' && pair.offer.lastEditGender == 2)
            "
            class="btn btn-round btn-pink"
            (click)="playBtn('check', pair.offer, 1); openModal(actionDone)"
          >
            בבירורים על {{ pair.woman.firstName }}
          </button>
          <div
            *ngIf="
              offersType == 'shadchanUsers' ||
              (offersType == 'check' && pair.offer.lastEditGender != 2)
            "
          >
            <div *ngIf="pair.firstOk != 1">
              <button class="btn btn-round btn-pink disabled" disabled>
                כרגע בבירורים על {{ pair.woman.firstName }}
              </button>
              <button
                class="btn btn-round btn-pink"
                (click)="playBtn('ok', pair.offer, 1); openModal(actionDone)"
              >
                ענו תשובה חיובית?
              </button>
            </div>
            <button
              *ngIf="pair.firstOk == 1"
              class="btn btn-round btn-pink disabled"
              disabled
            >
              ענו תשובה חיובית
            </button>
          </div>
          <button
            *ngIf="offersType != 'mzlt'"
            class="btn btn-round btn-gray"
            (click)="playBtn('finallyNo', pair.offer, 1); openModal(actionDone)"
          >
            לא מתאים - {{ pair.woman.firstName }}
          </button>
        </div>
        <div class="inner-container">
          <div class="profile-title">
            {{ pair.man.firstName }} {{ pair.man.lastName }}, {{ pair.man.age
            }}<br />
            {{ pair.man.religiousLevel }} | {{ pair.man.familyStatus }}<br />{{
              pair.man.city
            }}
            <span
              *ngIf="pair.man.status != 1 && offersType != 'mzlt'"
              class="pink-color isActive"
              >( {{ pair.man.status == 0 ? "לא פעיל" : "התארס" }})
            </span>
          </div>
          <div *ngFor="let ask of asksMen; let i = index" class="d-flex">
            <label class="key-label"
              >{{ ask.man }}{{ ask.man.slice(-1) == "?" ? "" : ":" }}</label
            >
            <label
              *ngIf="ask.typeInput != 5"
              class="value-label label-{{ ask.name }}"
              >{{ pair.man[ask.name] }}</label
            >
            <label
              *ngIf="ask.typeInput == 5"
              class="value-label label-{{ ask.name }}"
              >{{ pair.man[ask.name] == 1 ? "כן" : "לא" }}</label
            >
          </div>
          <div class="w-100"></div>
          <div
            *ngIf="pair.man.ICheck != null || pair.man.checkMe != null"
            class="w-100 div-check-info"
          >
            בנוסף:
            <div
              *ngIf="pair.man.ICheck != null"
              class="title-check-info"
              (click)="getCheckersInfo(pair.man, 'ICheck')"
            >
              <fa name="exclamation-circle left-space"></fa>אני מברר על<fa
                name="chevron-left right-space"
              ></fa>
            </div>
            <div *ngFor="let n of pair.man.ICheckInfo" class="d-flex">
              <label>{{ n }}</label>
            </div>
            <div
              *ngIf="pair.man.checkMe != null"
              class="title-check-info"
              (click)="getCheckersInfo(pair.man, 'checkMe')"
            >
              <fa name="exclamation-circle left-space"></fa>מבררים עלי<fa
                name="chevron-left right-space"
              ></fa>
            </div>
            <div *ngFor="let n of pair.man.checkMeInfo" class="d-flex">
              <label>{{ n }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="border-left" style="margin-top: 25px"></div>
      <div class="woman-container w-50 d-flex">
        <div class="profile-container">
          <div class="female-img">
            <app-add-img
              *ngIf="pair.woman.img != null"
              [onlyShow]="true"
              [userImg]="pair.woman.img"
            ></app-add-img>
          </div>
          <p>{{ pair.woman.tel1 }}</p>
          <p style="margin-top: -12px">{{ pair.woman.tel2 }}&nbsp;</p>
          <button
            (click)="goToUserFolder(pair.woman, 2)"
            class="btn btn-round btn-blue d-block mx-auto"
          >
            לפרופיל המלא
          </button>
          <button
            *ngIf="
              offersType == 'shadchanUsers' ||
              offersType == 'system' ||
              (offersType == 'check' && pair.offer.lastEditGender == 1)
            "
            class="btn btn-round btn-pink"
            (click)="playBtn('check', pair.offer, 2); openModal(actionDone)"
          >
            בבירורים על {{ pair.man.firstName }}
          </button>
          <div
            *ngIf="
              offersType == 'shadchanUsers' ||
              (offersType == 'check' && pair.offer.lastEditGender != 1)
            "
          >
            <div *ngIf="pair.firstOk != 2">
              <button class="btn btn-round btn-pink disabled" disabled>
                כרגע בבירורים על {{ pair.man.firstName }}
              </button>
              <button
                class="btn btn-round btn-pink"
                (click)="playBtn('ok', pair.offer, 2); openModal(actionDone)"
              >
                ענו תשובה חיובית?
              </button>
            </div>
            <button
              *ngIf="pair.firstOk == 2"
              class="btn btn-round btn-pink disabled"
              disabled
            >
              ענו תשובה חיובית
            </button>
          </div>
          <button
            *ngIf="offersType == 'shadchanUsers' || offersType != 'mzlt'"
            class="btn btn-round btn-gray"
            (click)="playBtn('finallyNo', pair.offer, 2); openModal(actionDone)"
          >
            לא מתאים - {{ pair.man.firstName }}
          </button>
        </div>
        <div class="inner-container">
          <div class="profile-title">
            {{ pair.woman.firstName }} {{ pair.woman.lastName }},
            {{ pair.woman.age }}<br />
            {{ pair.woman.religiousLevel }} | {{ pair.woman.familyStatus
            }}<br />{{ pair.woman.city }}
            <span
              *ngIf="pair.woman.status != 1 && offersType != 'mzlt'"
              class="pink-color isActive"
              >({{ pair.woman.status == 0 ? "לא פעיל" : "התארסה" }})</span
            >
          </div>
          <div *ngFor="let ask of asksWomen; let i = index" class="d-flex">
            <label class="key-label"
              >{{ ask.woman }}{{ ask.woman.slice(-1) == "?" ? "" : ":" }}</label
            >
            <label
              *ngIf="ask.typeInput != 5"
              class="value-label label-{{ ask.name }}"
              >{{ pair.woman[ask.name] }}</label
            >
            <label
              *ngIf="ask.typeInput == 5"
              class="value-label label-{{ ask.name }}"
              >{{ pair.woman[ask.name] == 1 ? "כן" : "לא" }}</label
            >
          </div>
          <div class="w-100"></div>
          <div
            *ngIf="pair.woman.ICheck != null || pair.woman.checkMe != null"
            class="w-100 div-check-info"
          >
            בנוסף:
            <div
              *ngIf="pair.woman.ICheck != null"
              class="title-check-info"
              (click)="getCheckersInfo(pair.woman, 'ICheck')"
            >
              <fa name="exclamation-circle left-space"></fa>אני מבררת על<fa
                name="chevron-left right-space"
              ></fa>
            </div>
            <div *ngFor="let n of pair.woman.ICheckInfo" class="d-flex">
              <label>{{ n }}</label>
            </div>
            <div
              *ngIf="pair.woman.checkMe != null"
              class="title-check-info"
              (click)="getCheckersInfo(pair.woman, 'checkMe')"
            >
              <fa name="exclamation-circle left-space"></fa>מבררים עלי<fa
                name="chevron-left right-space"
              ></fa>
            </div>
            <div *ngFor="let n of pair.woman.checkMeInfo" class="d-flex">
              <label>{{ n }}</label>
            </div>
          </div>
        </div>
      </div>
      <button
        *ngIf="
          offersType != 'mzlt' &&
          (pair.man.status != 1 || pair.woman.status != 1)
        "
        (click)="delOffer(pair.offer.id); openModal(actionDone)"
        class="btn btn-pink treated"
      >
        טופל<fa name="trash right-space"></fa>
      </button>
    </div>

    <div class="w-100"></div>
    <div style="background-color: var(--pinklight); margin-top: 25px">
      <div class="row-btns">
        <button
          *ngIf="offersType == 'check'"
          class="btn btn-round btn-pink"
          (click)="playBtn('meet', pair.offer, 3); openModal(actionDone)"
        >
          בפגישות<fa name="calendar right-space"></fa>
        </button>
        <button
          *ngIf="offersType == 'meet'"
          class="btn btn-round btn-pink"
          (click)="playBtn('mzlt', pair.offer, 3); openModal(actionDone)"
        >
          מזל טוב!!!<fa name="heart right-space"></fa>
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="offersType == 'mzlt'">
  <div
    class="offer-box"
    id="p{{ person.id }}"
    *ngFor="let person of personsMzlt"
  >
    <div class="d-flex">
      <div class="mzlt_profile-container">
        <div [class]="gender == 'man' ? 'male-img' : 'female-img'">
          <app-add-img [onlyShow]="true" [userImg]="person.img"></app-add-img>
        </div>
        <p class="p-name">{{ person.firstName }} {{ person.lastName }}</p>
        <p class="p-name pink-color">{{ person["statusDesc"] }}</p>
        <p
          *ngIf="person.status != 1 && !person['statusDesc']"
          class="pink-color text-center isActive"
        >
          ({{ person.status == 0 ? "לא פעיל" : "התארס/ה" }})
        </p>
      </div>
      <div>
        <div class="mzlt_inner-container" class="row-flex">
          <!-- <div *ngFor="let ask of asks; let i=index" class="d-flex"  [ngClass]="isMobile?'w-100':'w-50'"> -->
          <div class="w-50">
            <label class="key-label">תאריך הצטרפות:</label>
            <label class="value-label">{{ person.joinDate }}</label>
          </div>
          <div *ngFor="let ask of asks; let i = index" class="d-flex w-50">
            <div *ngIf="ask.name != 'israelID'">
              <label class="key-label"
                >{{ ask[gender]
                }}{{ ask[gender].slice(-1) == "?" ? "" : ":" }}</label
              >
              <label
                *ngIf="ask.typeInput != 5"
                class="value-label label-{{ ask.name }}"
                >{{ person[ask.name] }}</label
              >
              <label
                *ngIf="ask.typeInput == 5"
                class="value-label label-{{ ask.name }}"
                >{{ person[ask.name] == 1 ? "כן" : "לא" }}</label
              >
            </div>
          </div>
          <div
            *ngIf="
              person.familyStatus == 'גרוש' ||
              person.familyStatus == 'גרושה' ||
              person.familyStatus == 'אלמן' ||
              person.familyStatus == 'אלמנה'
            "
            class="w-50"
          >
            <label class="key-label">מספר ילדים:</label>
            <label class="value-label">{{
              person["numKids"] == -1 || person["numKids"] == null
                ? "0"
                : person["numKids"]
            }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #actionDone>
  <div *ngIf="!actionDoneMsg.includes('כבר')">
    <div class="modal-header">
      <p
        *ngIf="actionDoneMsg != ''"
        class="modal-title modal-body text-center text-info"
        style="padding: 0px !important"
      >
        {{ actionDoneMsg }}
      </p>
      <p
        *ngIf="actionDoneMsg == ''"
        class="modal-title modal-body text-center text-info"
        style="padding: 0px !important"
      >
        אנא המתן...
      </p>
      <button
        type="button"
        class="close pull-left"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h1 *ngIf="actionDoneMsg != ''" class="text-info text-center">
        <fa [name]="msgIcon"></fa>
      </h1>
      <div
        *ngIf="actionDoneMsg == ''"
        class="d-block mx-auto"
        style="width: 45px"
      >
        <app-fulfilling-bouncing-circle-spinner
          [animationDuration]="1000"
          [size]="45"
          [color]="'var(--blue)'"
        >
        </app-fulfilling-bouncing-circle-spinner>
      </div>
    </div>
  </div>
  <div *ngIf="actionDoneMsg.includes('כבר')">
    <!--alreadyMeet-->
    <div class="modal-header">
      <button
        type="button"
        class="close pull-left"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="modal-title modal-body text-center text-info">
        {{ actionDoneMsg }}
      </div>
      <button
        type="button"
        class="btn btn-blue btn-round d-block mx-auto"
        (click)="modalRef.hide()"
      >
        אישור
      </button>
    </div>
  </div>
</ng-template>
