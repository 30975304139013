<div *ngIf="isShadchan || isAdmin" id="part4" class="frame">
  <div class="right-title">הערות שדכנ/ית</div>
  <div class="left-container px-3 py-2">
    <div *ngIf="person?.notes" class="w-100 bg-light p-3">
      {{ person?.notes }}

      <div class="d-flex">
        <label class="value-label ml-4 mr-1 text-info">{{
          person.notesInfo
        }}</label>
        <a
          class="gray-color"
          title="עריכה"
          routerLink="/user-folder/edit-notes-status"
          routerLinkActive="active"
          ><fa name="pencil"></fa>
        </a>
      </div>
    </div>
    <app-notes userId="{{ person.id }}"> </app-notes>
  </div>
</div>
