export class Asks {
  id: number;
  name: string;
  man: string;
  woman: string;
  typeInput: number;
  ordered: number;
  isPublic: boolean;
  idParentAns: number;
  required: boolean;
  orderAll: number;
  hidden?: boolean;
}
export class Answers {
  id: number;
  idAsk: number;
  man: string;
  woman: string;
  ordered: number;
}
export class PrefAsks {
  id: number;
  name: string;
  man: boolean;
  woman: boolean;
  typeInput: number;
  ordered: number;
  defaultAns: string;
  idAsk: number;
}
export class AskAnswer {
  askList: Array<Asks>;
  ansList: Array<Answers>;
  subAskList: Array<Asks>;
  prefAskList: Array<PrefAsks>;
}
