//#region imports
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import { Person } from '../entities/entities';

//#endregion

//#region @Component
@Component({
  selector: 'app-shadchan-notes',
  templateUrl: './shadchan-notes.component.html',
  styleUrls: ['./shadchan-notes.component.css'],
})
export class ShadchanNotesComponent implements OnInit {
  isShadchan: boolean;

  //entities
  person: Person;
  isAdmin: boolean;

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private userService: UserService
  ) {}
  //#endregion

  //#region init firstly
  ngOnInit() {
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true';
    this.isShadchan = this.userService.getIsShadchan();
    this.getPersonDetails();
  }

  getPersonDetails() {
    console.log('user-details.component getPersonDetails');
    return new Promise((resolve, reject) => {
      this.http
        .get<Person>(
          this.globals.apiURL +
            `${this.userService.gender === 1 ? 'man' : 'woman'}/getById/${
              this.userService.personId
            }?userIDRequested=${this.userService.id}-${
              this.userService.firstName
            }-${this.userService.lastName}`,
          { responseType: 'json' }
        )
        .subscribe((data) => {
          this.person = data;
          if (this.person.familyStatusDetails != '')
            this.person.familyStatus += ', ' + this.person.familyStatusDetails;
          console.log(data);
          resolve(true);
        }); //add throwError
    });
  }

  //#endregion
}
