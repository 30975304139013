<!-- <div *ngIf="!noResults && offersType!='search'">
    <app-filter [alertsListRoot]="alertsListRoot" (filterClick)="updatealertsList($event)"></app-filter>
</div>
<app-free-search *ngIf="offersType=='search'" (searchClick)="getalertsList($event)"></app-free-search> -->

<div *ngIf="noResults" class="mx-auto" class="div-noResults">
  <h1 class="text-center pink-color">אין תזכורות.</h1>
</div>
<div class="offer-box" *ngFor="let a of alertsList">
  <div *ngIf="!!a?.person" class="d-flex">
    <div class="d-flex">
      <div class="profile-container">
        <div [class]="a.alert.gender == 1 ? 'male-img' : 'female-img'">
          <app-add-img
            *ngIf="a.person.img != null"
            [onlyShow]="true"
            [userImg]="a.person.img"
          ></app-add-img>
        </div>
      </div>
      <div class="inner-container d-flex">
        <div class="profile-title">
          {{ a.person.firstName }} {{ a.person.lastName }}, {{ a.person.age
          }}<br />
          {{ a.person.religiousLevel }} | {{ a.person.familyStatus }}<br />{{
            a.person.city
          }}
          <span *ngIf="a.person.status != 1" class="pink-color isActive">
            ({{
              a.person.status == 0
                ? "לא פעיל"
                : a.person.gender == 2
                ? "התארסה"
                : "התארס"
            }})</span
          >
        </div>
        <button
          (click)="goToUserFolder(a.person, a.alert.gender == 1 ? 1 : 2)"
          class="btn btn-round btn-blue"
        >
          לפרופיל המלא
        </button>
      </div>
    </div>
    <div
      class="border-left"
      style="margin-top: 15px; margin-bottom: 15px"
    ></div>
    <div class="alert-content inner-container w-100">
      <div class="date-label">{{ a.alert.date | date : "d/M/y" }}</div>
      {{ a.alert.text }}
    </div>
    <button
      (click)="treated(a.alert.id)"
      class="btn btn-pink"
      style="height: min-content"
    >
      <fa name="times left-space"></fa>טופל
    </button>
  </div>
</div>
