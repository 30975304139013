export class Person {
  id: number;
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  tel1: string;
  tel2: string;
  email: string;
  idUser: number;
  birthdate: string;
  birthdateHeb: string;
  age: string;
  height: number;
  nameMosad: string;
  religiousLevel: string;
  spiritualStyle: string;
  spiritualStyleOther: string;
  familyStatus: string;
  workLearn: string;
  skinColor: string;
  bodyStructure: string;
  motza: string;
  eda: string;
  healthy: string;
  israelID: string;
  money: string;
  moreToDisplay: string;
  moreToShadchan: string;
  familyStatusDetails: string;
  insertedBy: number;
  rekaTorany: string;
  rekaToranyOther: string;
  status: number;
  img: string;
  joinDate: string;
  payModel: string;
  religiousYears: string;
  profession: string;
  hasidut: string;
  healthyDetails: string;
  etzGimel: string;
  numKids: number;
  lastEditDate: string;
  phoneShadchan: string;
  motzaAv: string;
  motzaEm: string;
  permission: number;
  notes: string;
  notesInfo: string;
  forMe: string;
  iCheck: string;
  checkMe: string;
  iCheckInfo: Array<string>;
  checkMeInfo: Array<string>;
  locked: boolean;
  lockedReason: string;
  isEditLockedUserReasonEnable: boolean;
  gender: number;
  relatedShadchanID: number;

  brothersAndSisters: string;
  brothersAndSistersReligiousLevel: string;
  brothersAndSistersReligiousLevelOther: string;
  parentsReligiousLevel: string;
  parentsReligiousLevelOther: string;

  motherName: string;
  motherWork: string;
  fatherName: string;
  fatherWork: string;
  askIndex?: number;
  payment?: string | number;
}

export class PersonFilter {
  gender: number;
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  tel1: string;
  religiousLevel: Array<string>;
  spiritualStyle: Array<string>;
  workLearn: Array<string>;
  rekaTorany: Array<string>;
  familyStatus: Array<string>;
  skinColor: Array<string>;
  zakan: Array<string>;
  headCover: Array<string>;
  motza: Array<string>;
  eda: string;
  healthy: Array<string>;
  money: Array<string>;
  moneySum: number;
  minAge: string;
  maxAge: string;
  minHeight: string;
  maxHeight: string;
  free: string;
  askIndex: number;
}

export class Men extends Person {
  howMuchLearn: string;
  zakan: string;
  smoker: boolean;
  typeMosad: string;
  rabbiName: string;
}

export class Women extends Person {
  workPlace: string;
  highSchool: string;
  army: boolean;
  professionLearnt: string;
}

export class Notes {
  id?: number;
  personId: number;
  gender: number;
  note: string;
  createdBy?: number;
  createdAt?: Date;
  editMode: boolean;
}

export class PersonPref {
  idPrefer: number;
  familyStatus: string;
  maxAge: number;
  minAge: number;
  motza: string;
  spiritualStyle: string;
  religiousLevel: string;
  healthy: string;
  numKids: number;
  constructor() {
    this.familyStatus = '';
    this.motza = '';
    this.spiritualStyle = '';
    this.religiousLevel = '';
    this.healthy = '';
    this.numKids = null;
    this.maxAge = null;
    this.minAge = null;
  }
}

export class MenPref extends PersonPref {
  headCover: string;
  constructor() {
    super();
    this.headCover = '';
  }
}

export class WomenPref extends PersonPref {
  workLearn: string;
  zakan: string;
  smoker: boolean;
  constructor() {
    super();
    this.zakan = '';
    this.workLearn = '';
    this.smoker = null;
  }
}

export class CustomSMSMessage {
  sendSms: boolean;
  from: string;
  to: string;
  body: string;
}

export class CustomEmailMessage {
  from: string;
  to: string;
  subject: string;
  body: string;

  constructor() {
    this.from = '';
    this.to = '';
    this.subject = '';
    this.body = '';
  }
}

export class Reports {
  usersReports: Array<UserReports>;
}

export class UserReports {
  joinDate: Date;
  firstName: String;
  lastName: String;
  email: String;
  tel1: String;
  tel2: String;
  numberOfUsers: number;
}

export class Recommend {
  id: number;
  idWhom: number;
  whomGender: number;
  name: string;
  descrip: string;
  phone: string;
  phone2: string;
}
export class User {
  id: number;
  israelID: string;
  isShadchan: boolean;
  name: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  permission: number;
  getAlerts: boolean;
  gender: number;
  password: string;
  isLocked: boolean;
  lockedReason: string;
  isSystem: boolean;
  groupId: number;
  createdAt: string;
  extension?: string;
  constructor() {
    this.permission = 0;
    this.isLocked = false;
    this.isSystem = false;
    this.firstName = '';
    this.lastName = '';
    this.gender = 0;
    this.email = '';
    this.phone = '';
    this.extension = '';
    this.password = '';
    this.permission = 0;
  }
}
export class OffersStatus {
  id: number;
  man: number;
  woman: number;
  dateStatus: string;
  dateCare: string;
  offerStatus: number;
  shadchan: number;
  openedBy: number;
  isShown: boolean;
  lastEditGender: number;
  notes: string;
}
// export class OkAfterCheck {
//     idOffer: number;
//     man: boolean;
//     woman: boolean;
//     constructor(id: number, g: number) {
//         this.idOffer = id;
//         // if (g == 1)
//         //     {this.man = true; this.woman = false;}
//         // else
//         //     {this.woman = true; this.man = false;}

//         this.man = g == 1;
//         this.woman = g != 1; //!man
//     }
// }
export class Pair {
  man: Men;
  woman: Women;
  offer: OffersStatus;
  firstOk: number;
  // mCheck:Array<number>; wCheck:Array<number>; checkM:Array<number>; checkW:Array<number>;
  // mCheckInfo:string; wCheckInfo:string; checkMInfo:string; checkWInfo:string;
}
export class PairStatus {
  man: string;
  woman: string;
}
export class Groups {
  id: number;
  name: string;
}
export class UsersGroups {
  idUser: number;
  idGroup: number;
  constructor(idU, idG) {
    this.idUser = idU;
    this.idGroup = idG;
  }
}
export class Alerts {
  id: number;
  idShadchan: number;
  idPerson: number;
  gender: number;
  date: string;
  text: string;
  insertDate: string;
}
export class AlertedPerson {
  person: Person;
  alert: Alerts;
}
export class DateFull {
  d: string;
  hebD: string;
  constructor() {
    this.hebD = '';
  }
}
export class SearcherInfo {
  isShadchan: boolean;
  isShadchanFolder: boolean;
  personalRepo: boolean;
  idPerson: number;
  permission: number;
  idShadchan: number;
}
export class ShadchanDefault {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
}
export class TotalStatistics {
  TotalBlocked: number;
  TotalMatchmakers: number;
  TotalMen: number;
  TotalWomen: number;
  TotalUsers: number;
  TotalMensJoinToday: number;
  TotalWoMensJoinToday: number;
  TotalUnregisterUsers: number;
}

export class ProductivityReport {
  name: string;
  type: string;
  quantity: string;
}
