import { DocumentReference, Timestamp } from 'firebase/firestore';

export enum ChatType {
  ADMIN = 'ADMIN',
  SHADCHANIM = 'SHADCHANIM',
  USER_SHADCHAN = 'USER_SHADCHAN',
  USER_USER = 'USER_USER',
}

export interface Chat {
  id?: string;
  ref?: DocumentReference;
  dateCreated?: Timestamp | Date;
  dateModify?: Timestamp | Date;
  chatType: ChatType;
  subject?: string;
  createBy: number;
  members: number[];
  recentMessage?: Message;
  unread?: number;
}

export interface Message {
  id?: string;
  dateCreated?: Timestamp | Date;
  ref?: DocumentReference;
  replayMessage?: DocumentReference | Message;
  chat: DocumentReference;
  title?: string;
  body?: string;
  uri?: string;

  sentBy: number;
  //אם אני פה אז זה אומר שזה לא נחשב במיספור
  notRead?: number[];

  isAdmin?: boolean;
  isSystem?: boolean;
}
