import { Component, HostListener, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from './common/user.service';
import { Globals } from './common/globals';
import { LoaderComponent } from './loader/loader.component';
import { filter, map } from 'rxjs/operators';
import IdleTimer from './common/IdleTimer';
import {
  Routes,
  RouterModule,
  Router,
  Params,
  ActivatedRoute,
  NavigationEnd,
} from '@angular/router';
import { LogoMenuComponent } from './logo-menu/logo-menu.component';
import { LogoMenuMobileComponent } from './logo-menu-mobile/logo-menu-mobile.component';
import { environment as env } from '../environments/environment';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  @ViewChild(LoaderComponent) loader;
  @ViewChild(LogoMenuComponent) menu;
  @ViewChild(LogoMenuMobileComponent) menuMobile;
  title: string;
  bgHeight: string = '430px';
  timer: IdleTimer;
  isMobile = this.globals.isMobile;
  isProd: boolean = !env.baseUrl.includes('localhost:8080');

  isShow: boolean;
  topPosToStartShowing = 100;

  constructor(
    private globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-YGWZ10Z6EV', { page_path: event.urlAfterRedirects });
      }
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        var rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data) => {
          this.title = data.title;
          this.loader.ngOnInit();
        });
      });
  }

  startIdleTimer() {
    this.timer = new IdleTimer({
      timeout: 30 * 60,
      onTimeout: () => {
        console.log('Timeout');
        sessionStorage.removeItem('currentUser');
        this.router.navigate(['/']);
      },
    });
  }

  ngOnDestroy() {
    this.timer.clear();
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild)
      return this.getChild(activatedRoute.firstChild);
    else return activatedRoute;
  }

  public onRouterOutletActivate(event: any) {
    let compArr = ['login', 'new-user', 'password'];
    this.bgHeight = compArr.some((v) => location.hash.includes(v))
      ? '430px'
      : '200px'; //270px
    this.startIdleTimer();
    // if(this.isMobile)    {
    //   this.menuMobile.in=sessionStorage.getItem('currentUser')!==null;
    //   console.log(sessionStorage.getItem('currentUser'));
    //   console.log(this.menuMobile.in);
    // }

    // else{
    //   this.menu.in=sessionStorage.getItem('currentUser')!=null;
    //   console.log(sessionStorage.getItem('currentUser'));
    //   console.log(sessionStorage.getItem('currentUser')==null);
    //   console.log(this.menu.in);
    // }
    //--------------------------------
    if (this.isMobile)
      this.menuMobile.in = sessionStorage.getItem('currentUser');
    else this.menu.in = sessionStorage.getItem('currentUser');

    //--------------------------------------------------
    // if(sessionStorage.getItem('currentUser')){
    //   this.menu.in=true;
    //   this.menuMobile.toggleIn();
    // } else{

    // }
  }

  @HostListener('window:scroll')
  checkScroll() {
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
}
