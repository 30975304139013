import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Globals } from '../common/globals';
import { Cloudinary } from '@cloudinary/angular-5.x';
import { UserService } from '../common/user.service';

@Component({
  selector: 'app-add-img',
  templateUrl: './add-img.component.html',
  styleUrls: ['./add-img.component.css']
})
export class AddImgComponent implements OnInit {
  imgName: string;
  imgPath: string = "";
  @Input() onlyShow: boolean;
  @Input() userImg: string;
  @Input() prevImg: string;
  @Input() state: string = "";
  msg: string = "";
  style: string ='c_thumb,g_face,h_85,w_85/r_max/';
  action:string;
  fileName: string;
  @Output() imgLoaded = new EventEmitter();
  @Output() imgLoading = new EventEmitter();
  loadIcon:boolean=false;
  showOnLoad:boolean=false;
  isMobile=this.globals.isMobile;

  constructor(private http: HttpClient, private globals: Globals , private cloudinary: Cloudinary,private userService: UserService) { }

  ngOnInit(): void {
    if(this.onlyShow)
    {
     // console.log(this.userImg);
      return;
    }
    if(!this.globals.isDefined(this.prevImg) || this.prevImg==null){
      this.imgName=this.prevImg=null;
      this.fileName ="לא נבחרה תמונה";
      this.action="add";
    } else{
      this.action="replace";
      this.imgName=this.prevImg;
      this.fileName = "";
      this.updateImgPath();
    }
  }
  

  addImg(event) {
    if(this.imgName!=null)
      this.prevImg=this.imgName;
    const file: File = event.target.files[0];
    if (file) {
      if(!this.isValid(file))
        return;
      this.fileName = file.name;
      const formData = new FormData();
      formData.append('file', file);
      console.log(file);
      this.loadIcon=true;
      this.imgLoading.emit();
      this.http.put(this.globals.apiURL + `img/upload?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, formData, { responseType: 'text' })
        .subscribe(res => {
          console.log(res);
          this.msg ="התמונה נטענה בהצלחה!";
          if(this.state=='edit')
            this.msg+=' לשמירה לחץ על';
          this.showOnLoad=true;
          this.action='replace';
          this.imgName=res;
          this.updateImgPath();
          this.imgLoaded.emit();
          this.loadIcon=false;
          setTimeout(() => {
            this.showOnLoad=false;
          }, 5000);
        }, (err: HttpErrorResponse) => {
          this.msg = 'העלאת התמונה נכשלה';
          console.log(err);
          this.loadIcon=false;
          this.imgLoaded.emit();
        });
    }
  }

  delPrevImgIfNeed(){
    if(this.prevImg==null || this.prevImg==this.imgName)
      return;
    this.http.delete(this.globals.apiURL + `img/delete/`+this.prevImg+`?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`)
    .subscribe(res => {
      console.log('התמונה הקודמת נמחקה'+this.prevImg);
     },(err: HttpErrorResponse) => {
        console.log('מחיקת התמונה הקודמת נכשלה'+this.prevImg);
        console.log(err);
    });
  }

  delImg(){
    if(!confirm('האם הנך בטוח/ה שברצונך למחוק את התמונה?'))
      return;
    this.imgLoading.emit();
    this.http.delete(this.globals.apiURL + 'img/delete/'+this.imgName+`?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`)
    .subscribe(res => {
      this.prevImg=this.imgName;
      this.imgName=null;
      this.fileName ="לא נבחרה תמונה";
      this.imgLoaded.emit();
      this.msg = 'התמונה נמחקה!';
      if(this.state=='edit')
         this.msg+=' למחיקה סופית לחץ על';
      this.action='add';
     },(err: HttpErrorResponse) => {
        this.msg = 'מחיקת התמונה נכשלה';
        this.imgLoaded.emit();
        console.log(err);
    });
  }

  isValid(file: File):boolean{
      if (file.type.match('image/jpeg') == null) {
        this.msg = 'ניתן להעלות קובץ מסוג JPG בלבד';
        return false;
      }
      if(file.size> 104857600/2){
        this.msg = 'ניתן להעלות תמונה עד גודל 5MB';
        return false;
      }
      return true;
  }

  scrollTop(){
    window.scroll(0,0);
  }

  updateImgPath(){
    this.imgPath='pepoleImg/'+this.imgName+'.jpg';
  }

  getImg() {
    if(this.imgName==null){
      this.fileName ="לא נבחרה תמונה";
      this.action="add";
    }
    return this.imgName;
  }

  getImgUrl() {
    return this.cloudinary.url(this.imgPath);
  }

  updateUserImg() {
    if(this.prevImg==null || this.prevImg==this.imgName)
      return;
    this.userImg=this.imgName;
  }

  getUserImgUrl() {
    return this.cloudinary.url('pepoleImg/'+ this.userImg + '.jpg',{});
    // .replace(/v1/g, '');
  }

  delAll(){
    this.http.delete(this.globals.apiURL + `img/deleteAll?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, { responseType: 'text' })
        .subscribe(res => {
          console.log(res);
       });
  }

}
