import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { UserService } from './user.service';
import { Globals } from './globals';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private globals: Globals,
    private userService: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!sessionStorage.getItem('currentUser')) {
      console.log(state.url);
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    } else {
      this.userService = JSON.parse(sessionStorage.getItem('currentUser'));
      return true;
    }
  }
}
