//#region imports
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  HostListener,
  Injectable,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { AddImgComponent } from '../add-img/add-img.component';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import { Answers, AskAnswer, Asks, PrefAsks } from '../entities/AskAnswer';
import {
  DateFull,
  Men,
  MenPref,
  Person,
  PersonPref,
  Recommend,
  User,
  Women,
  WomenPref,
} from '../entities/entities';
import { FamilyStatusAsksComponent } from '../family-status-asks/family-status-asks.component';
//#endregion

//#region @Component
@Component({
  selector: 'app-add-man-steps',
  templateUrl: './add-man-steps.component.html',
  styleUrls: ['./add-man-steps.component.css'],
  providers: [],
  animations: [
    trigger('shrink', [
      state(
        'in',
        style({
          position: 'absolute',
          height: '405px',
          width: '343px',
          zIndex: '10',
          fontSize: '3em',
          lineHeight: '7',
        })
      ),
      state(
        'out',
        style({
          position: 'relative',
          lineHeight: 'normal',
          height: 'auto',
        })
      ),
      transition('in => out', animate('300ms ease-in-out')),
      transition('out => in', animate('0ms ease-in-out')),
    ]),
  ],
})
//#endregion

@Injectable()
export class AddManStepsComponent implements OnInit {
  modalRef: BsModalRef;

  //#region variables
  @ViewChild(FamilyStatusAsksComponent) familyDetails;
  @ViewChild('currentInput') currentInput;
  @ViewChild(AddImgComponent) addImg;
  @ViewChild(TabsetComponent) tabset;
  //arrays
  arrNum(n: number): any[] {
    return Array(n);
  }
  asks: Array<Asks>;
  subAsks: Array<Asks>;
  prefAsks: Array<PrefAsks>;
  answers: Array<Answers>;
  answersAll: Array<Answers>;
  recommendList: Array<Recommend> = new Array<Recommend>();
  // otherArr: Array<string>;
  progressMax: Array<number>;
  ansHasSub: Array<boolean>;
  formTitle: Array<string>;
  //maps
  ansMap: Map<number, Array<Answers>>;
  ansPrefMap: Map<number, Array<Answers>>;
  asksMap: Map<string, Array<Asks>>;
  asksPrefMap: Map<string, Array<Asks>>;
  asksIdMap: Map<number, Array<Asks>>;
  validationMap: Map<string, boolean> = new Map();
  //primitives
  hasntEmail: boolean = false;
  isLoaded: boolean = false;
  isBusy: boolean = false;
  isPersonalShadchan: boolean = false;
  requiredMsg: boolean = false;
  duplicateEmail: boolean = false;
  duplicateIsraelID: boolean = false;
  imgLoaded: boolean = false;
  imgLoading: boolean = false;
  validFlag: boolean = true;
  isShadchan: boolean = false;
  recoPhone: boolean = true;
  getAlerts: boolean = false;
  firstGlance: boolean = false;
  completed: boolean = false;
  wellcom: boolean;
  currentTitle: number = 0;
  i: number = 0; //currentLevel - question
  maxNumKids: number;
  countRecommends: number = 1;
  recommendFields: any;
  fullHeader: string = 'out';
  personId: number = 0;
  gender: string;
  otherGender: string;
  Gender: string;
  canByGender: string = 'תוכל';
  userName: string;
  //entities
  ask: Asks;
  askPref: PrefAsks;
  person: Person;
  personTemp: Person;
  personPref: PersonPref;
  recommend: Recommend = new Recommend();
  askIndex: number;
  isMobile = this.globals.isMobile;

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private modalService: BsModalService
  ) {}
  //#endregion

  //#region init firstly
  ngOnInit() {
    this.currentTitle = +this.route.snapshot.params.level;
    this.askIndex = +(this.route.snapshot?.queryParams?.askIndex || '');

    this.initUserData();
    this.wellcom = !this.isShadchan;
    let st = 'מה אני מחפש' + (this.gender == 'woman' ? 'ת' : '');
    this.formTitle = ['הפרטים שלי', 'ממליצים עלי', st, 'אישור', 'סיום'];
    this.getAskAnswer();
    this.recommendFields = {
      name: 'שם',
      descrip: 'הקשר ביננו',
      phone: 'טלפון',
      phone2: 'טלפון נוסף',
    };
    this.recommendList.push(new Recommend());
  }

  initUserData() {
    if (this.userService.gender == 1) {
      this.gender = 'man';
      this.otherGender = 'woman';
      this.Gender = 'Men';
      this.person = new Men();
      this.personTemp = new Men();
      this.personPref = new MenPref();
    } else {
      this.gender = 'woman';
      this.otherGender = 'man';
      this.Gender = 'Women';
      this.person = new Women();
      this.personTemp = new Women();
      this.personPref = new WomenPref();
      this.canByGender += 'י';
    }
    this.isShadchan = this.userService.isShadchan;
    this.isPersonalShadchan =
      this.isShadchan && this.userService.permission < 3;
    this.userName = this.userService.firstName;
    if (!this.globals.isDefined(this.userService.personId))
      this.userService.personId = 0;
    this.personId = this.userService.personId;
  }

  initKnownUserData() {
    this.personTemp.email = this.userService.email;
    this.personTemp.tel1 = this.userService.phone;
    this.personTemp.firstName = this.userService.firstName;
    this.personTemp.lastName = this.userService.lastName;
  }

  getAskAnswer() {
    //this.globals.apiURL+ "actions/getAsks"
    console.log('getAskAnswer add-man-steps.component');
    this.http
      .get<AskAnswer>(
        this.globals.apiURL +
          `actions/getAsks?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        { responseType: 'json' }
      )
      .subscribe(
        (data) => {
          this.asks = data.askList.concat(data.subAskList);
          this.asks = this.asks.filter((o) => o.name != 'age');
          this.answers = data.ansList;
          this.prefAsks = data.prefAskList.filter(
            (o) => o[this.gender] == true
          );
          this.initDependentParams(this.asks, this.answers);

          if (this.currentTitle == 0 && !this.userService.isShadchan) {
            this.initKnownUserData();
          } else {
            this.personId = this.userService.personId;
            if (this.currentTitle == 2) this.askPref = this.prefAsks[0];
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  initDependentParams(asks, answers) {
    this.isLoaded = true;
    asks = asks.sort((a, b) => (a.orderAll > b.orderAll ? 1 : -1));
    this.asksIdMap = asks.reduce(function (r, a) {
      r[a.id] = r[a.id] || [];
      r[a.id].push(a);
      return r;
    }, Object.create(null));

    this.asksPrefMap = asks.reduce(function (r, a) {
      r[a.name] = r[a.name] || [];
      r[a.name].push(a);
      return r;
    }, Object.create(null));

    this.answersAll = JSON.parse(JSON.stringify(answers)); //copy arr

    answers = answers.filter((o) => o[this.gender] !== '0');

    asks = asks.filter((o) => o[this.gender] !== '0');

    this.ansHasSub = new Array(answers.length).fill(false);

    this.ansMap = answers.reduce(function (r, a) {
      r[a.idAsk] = r[a.idAsk] || [];
      r[a.idAsk].push(a);
      return r;
    }, Object.create(null));

    this.ansPrefMap = this.answersAll
      .filter((o) => o[this.gender] !== 'אחר')
      .reduce(function (r, a) {
        r[a.idAsk] = r[a.idAsk] || [];
        r[a.idAsk].push(a);
        return r;
      }, Object.create(null));

    this.asksMap = asks.reduce(function (r, a) {
      r[a.name] = r[a.name] || [];
      r[a.name].push(a);
      return r;
    }, Object.create(null));

    if (!this.isShadchan) asks = asks.filter((o) => o['name'] !== 'email');

    //for (let ask of asks) this.personTemp[ask.name] = '';

    //img
    for (let ask of asks) {
      this.personTemp[ask.name] = '';
      this.person[ask.name] = '';
    }

    this.setProgressMax();

    let newAsk = new Asks();
    newAsk.name = 'img';
    newAsk.orderAll = 80;
    newAsk.idParentAns = 0;
    newAsk.isPublic = true;
    asks.push(newAsk);

    this.asks = asks;
    this.answers = answers;

    this.ask = asks[this.i];

    if (this.askIndex > -1 && !this.currentTitle) {
      if (!!sessionStorage?.getItem('person')) {
        const person = JSON.parse(sessionStorage.getItem('person'));
        this.personTemp = { ...person };
        this.person = { ...person };
        this.recoverPerson();
      }
    }
  }

  // updateDraft() {
  //   this.http
  //     .get<Person>(
  //       this.globals.apiURL +
  //         this.gender +
  //         `/getById/1835?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
  //       {
  //         headers: { 'Content-Type': 'application/json' },
  //       }
  //     )
  //     .subscribe((person) => {
  //       if (person?.askIndex > 0) {
  //         this.askIndex = person?.askIndex;
  //         this.personTemp = { ...person };
  //         this.person = { ...person };
  //         this.recoverPerson();
  //       }
  //     });
  // }

  recoverPerson() {
    this.wellcom = false;
    this.i = this.askIndex;
    this.ask = this.asks[this.i];

    for (let index = 0; index <= this.i; index++) {
      const ask = this.asks[index];

      if (ask.typeInput == 1 || ask.typeInput == 2) {
        const lasAnswer = this?.ansMap[ask?.id]
          .filter((a) =>
            this?.person?.[ask?.name].split(';').includes(a[this?.gender])
          )
          .map((a) => a.id)
          .join(';');

        if (lasAnswer) {
          this.personTemp[ask.name] = lasAnswer;
          this.updateAns(lasAnswer, ask, index);
        }
      }
      if (ask.typeInput == 5) {
        const val = this.person?.[ask.name];
        this.personTemp[ask.name] = val !== undefined && String(val);
      }
    }

    this.toDoNext();
  }

  validateNo(e): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  setProgressMax() {
    this.progressMax = Array(4).fill(0);
    this.progressMax[0] = this.asks.length;
    this.progressMax[2] = this.prefAsks.length + 1;
  }
  //#endregion

  setImgLoading() {
    this.imgLoading = true;
  }

  setImgLoaded() {
    this.imgLoading = false;
    this.imgLoaded = true;
    this.personTemp['img'] = this.addImg.getImg();
  }

  //#region prev next func
  goToNextSection() {
    this.currentTitle++;
    this.i = 0;
    if (this.currentTitle == 2) this.askPref = this.prefAsks[this.i];
    //animation
    this.fullHeader = 'in';
    window.setTimeout(() => {
      this.fullHeader = 'out';
    }, 500);
  }

  goToPrev() {
    if (
      this.userService.registerLevel > 0 &&
      this.currentTitle == this.userService.registerLevel
    )
      return;
    this.currentTitle--;
    switch (this.currentTitle) {
      case 0:
        this.i = this.asks.length - 1;
        this.ask = this.asks[this.i];
        break;
      case 1:
        this.i =
          this.recommendList.length > 0 ? this.recommendList.length - 1 : 0;
        if (this.recommendList.length == 0)
          this.recommendList.push(new Recommend());
        break;
      case 2:
        this.i = this.prefAsks.length;
        this.askPref = this.prefAsks[this.i];
    }
  }

  next() {
    if (this.i == this.asks.length - 1) {
      this.saveMen();
      return;
    }
    if (this.checkValidation()) {
      if (
        this.asks[this.i].name == 'email' &&
        !this.hasntEmail &&
        this.person.email !== this.personTemp.email
      )
        this.isDuplicateEmail().then(
          () => this.nextReally(),
          () => console.log('duplicate email')
        );
      else if (
        this.asks[this.i].name == 'israelID' &&
        this.person.israelID !== this.personTemp.israelID
      )
        this.isDuplicateIsraelID().then(
          () => this.nextReally(),
          () => console.log('duplicate israelID')
        );
      else this.nextReally();
    }
  }

  closeWellcome() {
    this.wellcom = false;
    window.setTimeout(() => {
      this.currentInput.nativeElement.focus();
    }, 10);
  }

  nextReally() {
    this.updateLocalPerson();
    if (this.personId) {
      this.editMen(() => {
        this.toDoNext();
      });
    } else {
      this.initMen();
      this.addMen((p: Person) => {
        this.person = { ...p };
        this.personId = this.userService.personId = this.person.id;
        this.isBusy = false;
        this.toDoNext();
      });
    }
  }

  toDoNext() {
    do {
      this.i++;
      this.ask = this.asks[this.i];
    } while (this.ask.idParentAns && !this.ansHasSub[this.ask.idParentAns]);

    if (
      this.ask.man == 'פרט' &&
      this.personTemp[this.ask.name] == this.ask.idParentAns + ''
    )
      if (this.globals.isDefined(this.currentInput))
        // this.personTemp[this.ask.name] = this.person[this.ask.name] || '';
        this.currentInput.nativeElement.focus();
  }

  prev() {
    if (this.currentTitle + this.i == 0) return;
    do {
      this.i--;
      this.ask = this.asks[this.i];
    } while (this.ask.idParentAns && !this.ansHasSub[this.ask.idParentAns]);
    this.requiredMsg = false;
    this.validFlag = true;
    if (this.globals.isDefined(this.currentInput))
      this.currentInput.nativeElement.focus();
  }

  nextPref() {
    this.checkPrefValidation();
    console.log(this.personPref);
    if (!this.requiredMsg) {
      this.i++;
      this.askPref = this.prefAsks[this.i];
      this.requiredMsg = false;
      if (this.i == this.prefAsks.length + 1) this.savePrefAsks();
    }
  }

  prevPref() {
    if (this.i == 0) this.goToPrev();
    else {
      this.i--;
      this.askPref = this.prefAsks[this.i];
      this.requiredMsg = false;
    }
  }

  nextReco() {
    this.i++;
    if (!this.globals.isDefined(this.recommendList[this.i]))
      this.saveRecommends();
  }

  prevReco() {
    if (this.i == 0) this.goToPrev();
    else this.i--;
  }
  //#endregion

  //#region functions for html
  forShadchan() {
    this.isShadchan = true;
  }

  emptyEmail() {
    this.personTemp['email'] = '';
    this.duplicateEmail = false;
  }

  updateAns(answer: string, ask: Asks, i: number) {
    for (let ans of this.ansMap[ask.id]) this.ansHasSub[ans.id] = false;
    let ansNamesArr = answer.split(';');
    for (let ans of ansNamesArr) this.ansHasSub[ans] = true;
    //empty sub that irrelevant now
    for (let ans of this.ansMap[ask.id]) {
      let subs = this.asks.filter(({ idParentAns }) => ans.id == idParentAns);
      for (let sub of subs) {
        if (!this.ansHasSub[sub.idParentAns] && !this.personTemp?.[sub?.name]) {
          this.personTemp[sub.name] = '';
        }
      }
    }
    this.treatFamilyStatusFlag(answer, ask, i);
  }

  treatFamilyStatusFlag(answer: string, ask: Asks, i: number) {
    if (ask.name == 'familyStatus' && (answer == '17' || answer == '18')) {
      this.personTemp['familyStatus'] = answer;
      let newAsk = new Asks();
      newAsk.id = 800;
      if (!this.asks.filter((e) => e.id === newAsk.id).length) {
        //avoid multi insert
        newAsk.name = 'familyStatusDetails';
        newAsk.orderAll = this.ask.orderAll + 0.5;
        newAsk.idParentAns = parseInt(answer);
        this.asks.splice(i + 1, 0, newAsk);
        console.log(this.asks);
        this.progressMax[0]++;
      }
    }
  }

  addMultiAns(checkedAns: string, askName: string) {
    if (this.personTemp?.[askName]?.includes(checkedAns))
      this.personTemp[askName] = this.personTemp[askName].replace(
        ';' + checkedAns,
        ''
      );
    else
      this.personTemp[askName] = this.personTemp?.[askName] + ';' + checkedAns;
  }

  addMultiPref(checkedAns: string, askName: string) {
    if (checkedAns == ';0') this.personPref[askName] = checkedAns;
    else if (checkedAns != ';0' && this.personPref[askName] == ';0')
      this.personPref[askName] = this.personPref[askName].replace(
        ';0',
        ';' + checkedAns
      );
    else if (this.personPref[askName].includes(checkedAns))
      this.personPref[askName] = this.personPref[askName].replace(
        ';' + checkedAns,
        ''
      );
    else this.personPref[askName] = this.personPref[askName] + ';' + checkedAns;
  }

  addRecommend() {
    if (
      this.recoPhone &&
      this.globals.isDefined(this.recommendList[this.i].phone)
    ) {
      this.i++;
      this.countRecommends++;
      this.recommendList.push(new Recommend());
      console.log(this.recommendList);
    }
  }

  onChangeRecoPhone(target: string): void {
    this.recoPhone = /0[0-9]{8,9}$/.test(target);
  }
  //#endregion

  //#region validate func
  checkValidation(): boolean {
    let answered = this.personTemp[this.ask.name] + '';
    if (this.ask.name == 'email' && this.hasntEmail) {
      this.requiredMsg = false;
      this.validFlag = true;
      return true;
    }
    this.requiredMsg =
      (!this.globals.isDefined(answered) || answered.trim() == '') &&
      this.ask.required;
    if (!this.requiredMsg)
      this.validFlag = this.checkSpecValidation(this.ask.name);
    else this.validFlag = true;
    if (this.ask.name == 'familyStatusDetails') {
      this.personTemp['familyStatusDetails'] =
        this.familyDetails.getFamilyStatusDetails();
      this.personTemp['numKids'] = this.familyDetails.getNumKids();
      this.requiredMsg = this.personTemp['numKids'] == null;
    }
    return !this.requiredMsg && this.validFlag;
  }

  getAge(birthdate: string) {
    console.log(birthdate);
    return new Date().getFullYear() - parseInt(birthdate.split('-')[0]);
  }

  checkSpecValidation(name: string): boolean {
    this.duplicateEmail = false;
    switch (name) {
      case 'tel1':
        this.personTemp['tel1'] = this.personTemp['tel1'].replace(/-/g, '');
        return /0[0-9]{8,9}$/.test(this.personTemp['tel1']);
        break;
      case 'height':
        let height: string = this.personTemp['height'] + '';
        if (height.indexOf('.') == -1)
          this.personTemp['height'] = parseFloat(
            height.substring(0, 1) + '.' + height.substring(1)
          );
        return (
          !isNaN(this.personTemp['height']) &&
          this.personTemp['height'] > 0.5 &&
          this.personTemp['height'] < 2.4
        );
        break;
      case 'birthdate':
        return (
          this.personTemp['birthdate'] != '' &&
          this.getAge(this.personTemp['birthdate']) < 100 &&
          this.getAge(this.personTemp['birthdate']) > 15
        );
        break;
      case 'email':
        this.personTemp['email'] =
          this.personTemp['email'] == undefined
            ? ''
            : this.personTemp['email'].toLowerCase();
        return this.hasntEmail || this.validateEmail(this.personTemp['email']);
        break;
      case 'israelID':
        this.personTemp['israelID'] =
          this.personTemp['israelID'] == undefined
            ? ''
            : this.personTemp['israelID'].toLowerCase();
        return /[0-9]{9}$/.test(this.personTemp['israelID']);
        break;
    }
    if (name == 'tel2')
      this.personTemp['tel2'] = this.personTemp?.['tel2']?.replace(/-/g, '');
    return true;
  }

  isDuplicateEmail() {
    let idUser: number = this.isShadchan ? 0 : this.userService.getId();
    var promise = new Promise((resolve, reject) => {
      this.http
        .get(
          this.globals.apiURL +
            `users/isDuplicateEmail/${this.personTemp['email']}/${idUser}?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .subscribe((response) => {
          console.log(response.toString());
          this.duplicateEmail = response.toString() == 'true';
          if (this.duplicateEmail) reject();
          resolve(this.duplicateEmail);
        });
    });
    return promise;
  }

  isDuplicateIsraelID() {
    let idUser: number = this.isShadchan ? 0 : this.userService.getId();
    var promise = new Promise((resolve, reject) => {
      this.http
        .get(
          this.globals.apiURL +
            `users/isDuplicateIsraelID?israelID=${this.personTemp['israelID']}&idUser=${idUser}&userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .subscribe((response) => {
          console.log(response.toString());
          this.duplicateIsraelID = response.toString() == 'true';
          if (this.duplicateIsraelID) reject();
          resolve(this.duplicateIsraelID);
        });
    });
    return promise;
  }

  checkPrefValidation(): void {
    if (this.i < this.prefAsks.length) {
      let answered = this.personPref[this.askPref.name];
      this.validationMap = new Map();
      this.requiredMsg = answered == '';
      if (
        this.askPref.name == 'familyStatus' &&
        answered != ';16' &&
        answered != '' &&
        (this.personPref['numKids'] == null || !this.personPref['numKids'])
      )
        this.requiredMsg = true;
    } else {
      this.validationMap['minAge'] =
        !isNaN(this.personPref['minAge']) &&
        this.personPref['minAge'] < 90 &&
        this.personPref['minAge'] > 15;
      this.validationMap['maxAge'] =
        !isNaN(this.personPref['maxAge']) &&
        this.personPref['maxAge'] < 90 &&
        this.personPref['maxAge'] > this.personPref['minAge'];
      console.log(this.validationMap['minAge']);
      console.log(this.validationMap['maxAge']);
      this.requiredMsg =
        !this.validationMap['minAge'] || !this.validationMap['maxAge'];
    }
  }

  validateEmail(email: string) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  //#endregion

  //#region saveData
  setBirthdate(date: DateFull) {
    this.personTemp.birthdate = date.d;
    this.personTemp.birthdateHeb = date.hebD;
    console.log('yyyyyyyyyyyy: ' + this.personTemp.birthdateHeb);
  }

  updateLocalPerson() {
    console.log('updateLocalPerson');

    let a: Asks;
    // this.personTemp['img']=this.addImg.getImg();
    this.person = { ...this.person, ...this.personTemp }; //copy
    for (let i in this.person) {
      if (
        i == 'familyStatus' &&
        (this.person[i] == '17' || this.person[i] == '18')
      ) {
        this.person['familyStatusDetails'] =
          this.personTemp?.['familyStatusDetails']?.trim();
        this.person['numKids'] = this.personTemp?.['numKids'];
      }
      if (i == 'familyStatusDetails' || i == 'numKids') continue;
      if (i == 'birthdateHeb')
        this.person.birthdateHeb = this.personTemp.birthdateHeb;
      else if (this.globals.isDefined(this.asksMap[i])) {
        let ansIdArr: Array<string>, ansNamesArr: Array<string>;
        a = this.asksMap[i][0];
        if (
          a.typeInput < 3 &&
          (/^\d+$/.test(this.person[i]) || this.person[i].includes(';'))
        ) {
          ansIdArr = this.person[i].split(';');
          ansNamesArr = this.answersAll
            .filter(({ id }) => ansIdArr.includes(id + ''))
            .map((o) => o[this.gender]);
          this.person[i] = ansNamesArr.join(';');
        } else if (i != 'moneySum' && this.person[i] != null)
          this.person[i] = ('' + this.person[i]).trim();
      }
    }
  }

  saveMen() {
    this.initMen();
    if (this.personId > 0) {
      this.editMen(() => {
        if (!this.isShadchan) {
          this.getAlerts = !this.hasntEmail;
          this.editAlerts();
        }
        this.goToNextSection();
      });
    } else {
      this.addMen((p: Person) => {
        this.person = { ...p };
        this.personId = this.userService.personId = this.person.id;
        this.isBusy = false;
        this.goToNextSection();
      });
    }
    this.addImg.delPrevImgIfNeed();
    // }  //end of validFlag
    window.scroll(0, 0);
  }

  initMen(): void {
    //man.image=fd or send it like class type
    this.person['phoneShadchan'] = this.isShadchan
      ? this.userService.getFirstName() + ' - ' + this.userService.getPhone()
      : '0722733744';
    this.person['relatedShadchanID'] = this.userService.isShadchan
      ? this.userService.personId
      : 99999999;
    this.person['payModel'] = this.isShadchan ? 'דמי שידוך' : 'חינם';
    this.person['joinDate'] = this.globals.dateToString(new Date());
    this.person['lastEditDate'] = this.globals.dateToString(new Date());
    this.person['status'] = 1;
    this.person.permission =
      this.userService.getPermission() == 1 ||
      this.userService.getPermission() == 2
        ? this.userService.getId()
        : 0;
    if (!this.globals.isDefined(this.person['numKids']))
      this.person['numKids'] = null;
    this.person['insertedBy'] = this.userService.getId();
    this.person['idUser'] = this.isShadchan ? 0 : this.person['insertedBy'];
    if (this.gender == 'man' && !this.globals.isDefined(this.person['smoker']))
      this.person['smoker'] = false; //not need because its must, remove after do it
    if (!this.globals.isDefined(this.person['familyStatusDetails']))
      this.person['familyStatusDetails'] = '';
  }

  editAlerts() {
    this.http
      .put(
        this.globals.apiURL +
          `users/setAlerts?userIDRequested=${this.userService.id}-${
            this.userService.firstName
          }-${
            this.userService.lastName
          }&id=${this.userService.getId()}&getAlerts=${this.getAlerts}`,
        {}
      )
      .subscribe(
        (response) => {
          console.log(
            response.toString() == '1'
              ? 'קבלת התראות לא עודכן'
              : 'קבלת התראות עודכן'
          );
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
    this.userService.getAlerts = this.getAlerts;
  }

  editMen(todo: () => void) {
    this.person['id'] = this.personId;
    const askIndex = this.i;
    this.http
      .put(
        this.globals.apiURL +
          `actions/edit?className=db.${this.Gender}&userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        JSON.stringify({
          ...this.person,
          askIndex: this.asks.length - 1 === askIndex ? -1 : askIndex,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .subscribe(
        (response) => {
          console.log(response.toString());
          todo();
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  addMen(todo: (response) => void) {
    console.log('i add');
    this.isBusy = true;
    this.http
      .post(
        this.globals.apiURL +
          `actions/addPerson?className=db.${this.Gender}&userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        JSON.stringify({ ...this.person, askIndex: this.i }),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .subscribe(
        (response) => {
          todo(response);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.isBusy = false;
        }
      );
  }

  savePrefAsks() {
    let a: Asks;
    let unchangedPref = ['minAge', 'maxAge', 'numKids'];
    if (this.gender == 'woman') unchangedPref.push('smoker');
    for (let i in this.personPref) {
      if (this.personPref[i] == ';0') this.personPref[i] = 'לא משנה';
      else if (!unchangedPref.includes(i)) {
        console.log(i);
        a = this.asksPrefMap[i][0];
        let ansIdArr: Array<string>, ansNamesArr: Array<string>;
        ansIdArr = this.personPref[i].split(';');
        ansNamesArr = this.answersAll
          .filter(({ id }) => ansIdArr.includes(id + ''))
          .map((o) => o[this.otherGender]);
        this.personPref[i] = ansNamesArr.join(';');
      }
    }
    this.personPref['idPrefer'] = this.personId;
    this.isBusy = true;
    this.http
      .post(
        this.globals.apiURL +
          `actions/add?className=db.${this.Gender}Preferences&userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        JSON.stringify(this.personPref),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .subscribe(
        (response) => {
          console.log(response.toString());
          this.goToNextSection();
          this.isBusy = false;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.isBusy = false;
        }
      );
  }

  saveRecommends() {
    let recommendFlag: boolean = false;
    this.recommendList.forEach((r, i) => {
      if (
        this.globals.isDefined(r.phone) &&
        /0[0-9]{8,9}$/.test(r.phone.replace(/-/g, ''))
      ) {
        r.idWhom = this.personId;
        r.whomGender = this.gender == 'man' ? 1 : 2;
        recommendFlag = true;
      } else {
        this.recommendList.splice(i, 1);
        this.countRecommends--;
      }
    });
    if (recommendFlag) {
      this.isBusy = true;
      this.http
        .post(
          this.globals.apiURL +
            `actions/add?className=RecommendsList&userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
          JSON.stringify(this.recommendList),
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .subscribe(
          (response) => {
            console.log(response.toString());
            this.isBusy = false;
          },
          (err: HttpErrorResponse) => {
            console.log(err);
            this.isBusy = false;
          }
        );
    }
    this.goToNextSection();
  }

  saveShadchanSetting() {
    this.userService.setLoginFirstName(this.userService.firstName);
    this.userService.setLoginLastName(this.userService.lastName);
    this.userService.setFirstName(this.personTemp.firstName);
    this.userService.setLastName(this.personTemp.lastName);
    this.editMen(() => {
      this.goToNextSection();
    });
    this.completed = true;
    // this.userService.setGetAlerts(this.getAlerts);
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
  }

  editMenValue(key, value, todo: () => void) {
    const g = this.userService.gender == 1 ? 'man' : 'woman';
    this.http
      .post(
        this.globals.apiURL +
          `${g}/update/${this.personId}?key=${key}&value=${value}`,
        {}
      )
      .subscribe(
        () => {
          todo();
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  savePayment() {
    const healthy = this.person?.['healthy'];
    if (!healthy || this.personTemp?.payment === undefined) {
      this.goToNextSection();
      return;
    }
    if (this.personTemp?.payment < (healthy === 'בריא' ? '3000' : '4500')) {
      alert(` מתחת למחיר לבסיס : ${healthy === 'בריא' ? 3000 : 4500}`);
      return;
    }
    this.editMenValue('payment', this.personTemp?.['payment'], () => {});
    this.goToNextSection();
  }

  complete() {
    this.completed = true;
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
  }

  openBlank(page: string) {
    let u: User = new User();
    u.firstName = this.personTemp.firstName;
    u.lastName = this.personTemp.lastName;
    u.gender = 'zakan' in this.personTemp ? 1 : 2;
    u.email = this.personTemp.email;
    u.israelID = this.personTemp.israelID;

    u.phone = this.personTemp.tel1;
    u.permission =
      this.userService.permission == 1 || this.userService.permission == 2
        ? this.userService.id
        : 0;
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${page}/${JSON.stringify(u)}`])
    );
    window.open('/#' + url, '_blank');
  }
  //#endregion

  hasChanges() {
    //if you started but not finished
    return (
      !Object.values(this.personTemp).every((x) => x === null || x === '') &&
      !this.completed
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.hasChanges()) $event.returnValue = true;
  }
}
