import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tblFreeSearch'
})
export class TblFreeSearchPipe implements PipeTransform {
  transform(list:Array<any>, searchText:string): unknown {
    let newList=new Array<any>();
    if (!list || list.length == 0 || searchText == '' || searchText==null) 
      return list;
    return list.filter(row => row.find(a =>a.includes(searchText)));
  }
}
