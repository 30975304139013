<!-- <h1>Chat</h1>
<div *ngFor="let message of messages$ | async">
  {{ message.name }}
</div> -->

<div class="container-s" dir="ltr">
  <div class="messages-block">
    <div
      id="messages"
      class="message_container"
      appDnd
      (fileDropped)="onFileDropped($event)"
    >
      <div
        class="message"
        *ngFor="let message of messages$"
        [id]="message.id"
        [ngStyle]="{
          marginLeft: isMe(message) ? 'auto' : 'none',
          backgroundColor: isMe(message) ? '#fff' : '#F16BAA',
          color: isMe(message) ? '#303841' : '#fff',
          'box-shadow': isMe(message)
            ? '0 4px 8px 0 rgba(0, 0, 0, 0.01), 0 6px 20px 0 rgba(0, 0, 0, 0.04)'
            : 'none',
          'border-bottom-left-radius': !isMe(message) ? '0px' : '20px',
          'border-bottom-right-radius': isMe(message) ? '0px' : '20px'
        }"
      >
        <div
          *ngIf="message?.replayMessage"
          class="replay-in-container"
          (click)="scrollToMessage(message?.replayMessage?.id)"
        >
          <div class="replay-in-container-message">
            {{ getMemberName(message?.replayMessage.sentBy) }}
          </div>
          <div class="replay-in-container-message">
            {{ message?.replayMessage.body }}
          </div>
        </div>
        <div
          (click)="openLink(message.sentBy)"
          class="message-person"
          [ngStyle]="{ color: isMe(message) ? '#7e7e7e' : '#000',}"
        >
          {{ getMemberName(message.sentBy) }}
        </div>

        <div
          [ngStyle]="{ 'font-weight': isMe(message) ? '900' : 'none',}"
          class="message-body"
        >
          <a
            *ngIf="!!message.uri"
            (click)="onViewImage(imgService.getImgUrl(message.uri))"
            title="פתח בלשונית חדשה"
            class="image-chat"
          >
            <cl-image [public-id]="'pepoleImg/' + message.uri + '.jpg'">
              <cl-transformation
                width="143"
                height="102"
                crop="thumb"
                gravity="face"
              >
              </cl-transformation>
            </cl-image>
          </a>
          {{ message.body }}
        </div>
        <div
          [ngStyle]="{
            color: isMe(message) ? '#999' : '#fff'
          }"
          class="message-date align-self-stretch"
        >
          <div class="d-flex justify-content-between align-items-center">
            <svg
              *ngIf="isMe(message) && !allRead(message)"
              width="15px"
              height="15px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 12.6111L8.92308 17.5L20 6.5"
                stroke="#999"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              *ngIf="isMe(message) && allRead(message)"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 12.5L5.57574 16.5757C5.81005 16.8101 6.18995 16.8101 6.42426 16.5757L9 14"
                stroke="#418ce8"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M16 7L12 11"
                stroke="#418ce8"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M7 12L11.5757 16.5757C11.8101 16.8101 12.1899 16.8101 12.4243 16.5757L22 7"
                stroke="#418ce8"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
            <div class="ml-auto">{{ toTimeStr(message.dateCreated) }}</div>
          </div>
        </div>
        <button
          *ngIf="message.sentBy === me"
          tooltip="מחיקה"
          placement="right"
          class="remove-btn btn"
          (click)="onRemoveMessage(message)"
        >
          <fa name="trash"></fa>
        </button>
        <button
          tooltip="תגובה"
          placement="right"
          class="remove-btn btn"
          (click)="onReplay(message)"
          [ngStyle]="{ top: message.sentBy === me ? '27px' : '0' }"
        >
          <fa name="repeat"></fa>
        </button>
      </div>
    </div>

    <div class="d-flex flex-column mr-4 mt-1 py-1">
      <div *ngIf="selectedMessage" class="replay-container">
        <div class="replay-container-close">
          <fa (click)="onCloseReplay()" name="close"></fa>
        </div>
        <div class="replay-container-message">
          <div style="font-size: 13px">
            {{ getMemberName(selectedMessage?.sentBy) }}
          </div>
          <div>{{ selectedMessage?.body }}</div>
        </div>
      </div>
      <div class="d-flex">
        <textarea
          dir="rtl"
          #input
          *ngIf="!!selectedChat"
          rows="2"
          class="send-input form-control w-100"
        ></textarea>
        <button *ngIf="!!selectedChat" (click)="onSend()" class="send btn">
          <fa name="send"></fa>
        </button>

        <div *ngIf="!!selectedChat" class="input_container">
          <label for="files" class="btn"> <fa name="upload"></fa></label>
          <input
            id="files"
            style="display: none"
            type="file"
            accept=".jpg, .jpeg"
            class="file-input"
            (change)="addImg($event)"
          />
        </div>
      </div>
    </div>
  </div>
  <div id="chats" class="chats_container">
    <div class="chats-header">
      <button
        tooltip="יצירת צאט חדש"
        (click)="openModal()"
        class="btn add-chat-icon"
      >
        <fa name="plus"></fa>
      </button>
    </div>
    <div
      *ngFor="let chat of sortChats"
      (click)="onChat(chat)"
      [ngClass]="{ 'chat-selected': chat?.id === selectedChat?.id, chat: true }"
    >
      <button
        *ngIf="chat.createBy === me"
        tooltip="מחיקה"
        placement="right"
        class="remove-btn btn"
        (click)="onRemoveChat(chat)"
      >
        <fa name="trash"></fa>
      </button>

      <div class="chat-header" dir="rtl">
        <div class="d-flex ml-2">
          <div *ngFor="let member of getChatUsers(chat); index as i">
            <div
              *ngIf="i === 0"
              [class]="member.gender == 1 ? 'male-img' : 'female-img'"
            ></div>
          </div>
        </div>

        <div class="w-100 d-flex flex-column">
          <div class="chat-person">
            <div
              *ngFor="let member of getChatMembers(chat); index as i"
              (click)="openLink(member)"
              class="link"
            >
              {{ i > 0 ? ", " : "" }}{{ getMemberName(member) }}
            </div>
          </div>
          <div class="chat-date mt-1">
            {{ toTimeStr(chat.dateCreated) }}
          </div>
        </div>
        <span
          class="badge chat-badge bg-danger text-white"
          *ngIf="chatService.unread(chat.id) > 0"
          >{{ chatService.unread(chat.id) }}</span
        >
      </div>
    </div>
  </div>
</div>

<ng-template #createChat>
  <div class="modal-header">
    <p
      class="modal-title modal-body text-center text-info"
      style="padding: 0px !important"
    >
      יצירת צאט חדש
      <fa name="user right-space"></fa>
    </p>

    <button
      type="button"
      class="close pull-left"
      aria-label="Close"
      (click)="onClose()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-select
      [searchFn]="changed.bind(this)"
      [closeOnSelect]="true"
      [placeholder]="'הזן שם של שדכנית'"
      appendTo="body"
      [multiple]="true"
      dropdownPosition="bottom"
      [(ngModel)]="selectedTo"
      (add)="add()"
      [isOpen]="!userService?.isShadchan && !isAdmin ? term.length : undefined"
    >
      <ng-option *ngFor="let s of selectShadchanList()" [value]="s.id"
        >{{ s.firstName }} {{ s.lastName }}</ng-option
      >
    </ng-select>

    <button
      (click)="onCreateChat(selectedTo)"
      class="mt-2 btn btn-blue btn-round d-block mx-auto"
    >
      צור צאט
    </button>
    <div *ngIf="userService.isShadchan" class="text-danger mt-2">
      * ליצירת צאט עם משודך ,מצא את המשודך בחיפוש חופשי ולחץ על ׳צאט עם משתמש׳
    </div>
  </div>
</ng-template>

<ng-template #viewImage>
  <div class="modal-body pb-5">
    <div class="pb-5">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="onHideViewImage()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <cl-image
        class="d-flex align-items-center"
        [public-id]="selectedImage"
        style="max-width: 750px; max-height: 1000px; min-height: 300px"
      />
    </div>
  </div>
</ng-template>
