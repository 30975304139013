<div
  id="bg"
  class="bg"
  [ngStyle]="{
    height: bgHeight,
    maxHeight: bgHeight == '200px' ? '25vh' : bgHeight
  }"
>
  <app-logo-menu *ngIf="!isMobile"></app-logo-menu>
  <app-logo-menu-mobile *ngIf="isMobile"></app-logo-menu-mobile>
  <div *ngIf="bgHeight == '200px'" class="main-title">{{ title }}</div>
</div>
<app-loader></app-loader>
<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
<fa *ngIf="isShow" name="chevron-up" id="scrollTopBtn" (click)="gotoTop()"></fa>
