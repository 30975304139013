import { Component, OnInit } from '@angular/core';
import { Globals } from '../common/globals';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ShadchanDefault, User } from '../entities/entities';
import { UserService } from '../common/user.service';




@Component({
  selector: 'app-del-shadchan',
  templateUrl: './del-shadchan.component.html',
  styleUrls: ['./del-shadchan.component.css']
})
export class DelShadchanComponent implements OnInit {
  selectedFrom: number=0;
  selectedTo: number=0;
  msg:string='';
  msgWarn:string='';
  del:boolean=false;
  shadchanList: Array<User>;
  shadchanSystemList: Array<User>;

  constructor(private http: HttpClient, private globals: Globals,private userService: UserService) { }

  ngOnInit(): void {
    this.getShadchanList();
  }

  getShadchanList(){
    this.http.get<Array<User>>(this.globals.apiURL + `shadchan/get/shadchanPublicList`+`?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, { responseType: 'json' })
    .subscribe(data => {
      this.shadchanList = data;
      this.shadchanSystemList = this.shadchanList.filter(o=> o.isSystem);
    }, (err: HttpErrorResponse) => {
      console.log(err);
    });
  }

  transfer(from:number,to:number){
    this.msg='';
    this.msgWarn='';
    if(from==0 || to==0)
      this.msg="לא בחרת ממי /למי להעביר";
    else if(from!=to){
      if(this.del && this.shadchanSystemList.length==1 && this.shadchanSystemList[0].id==from){
        this.msgWarn=" ההעברה לא בוצעה. לא ניתן למחוק את השדכנית כי היא מוגדרת כשדכנית מערכת היחידה";
        return;
      }
      this.http.post(this.globals.apiURL + `shadchan/set/transfer?from=${from}&to=${to}&del=${this.del}`+`&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, { responseType: 'json' })
      .subscribe(response => {
        console.log(response.toString());
        this.msg="ההעברה בוצעה בהצלחה!";
        if(response.toString()=='-1')
          this.msgWarn=" לא ניתן למחוק את השדכנית כי היא מוגדרת כשדכנית ברירת מחדל";
        else if(this.del)
          this.msg+=" השדכנית נמחקה.";
      });
    }
  }

}
