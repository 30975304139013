import { Pipe, PipeTransform } from '@angular/core';
import { Asks } from '../entities/AskAnswer';

@Pipe({
  name: 'advancedSearch'
})
export class AdvancedSearchPipe implements PipeTransform {

  transform(asks:Array<Asks>, partialAsks:Array<Asks>): unknown {
    if (!asks || asks.length == 0 ) 
      return asks;
    if(!partialAsks || partialAsks.length == 0)
      return [];
    return partialAsks;
}

}
