<alert *ngIf="addMsg" type="info alert-top max-800" dismissible="true" (onClosed)="addMsg=false">
    <p class="text-center" style="margin-bottom: 0px;">{{msg}}</p>
</alert>
<div *ngIf="mzlt" class="mx-auto" style="width: 100% ;max-width: 1009px;margin-top: 30px;">
    <h1 class="text-center pink-color">{{gender==1?'המשודך התארס':'המשודכת התארסה'}}. מזל טוב!</h1>
</div>
<div *ngIf="!mzlt" class="container">
    <div class="mb-30" [ngClass]="{'invalid': !validDate }">
        <label>מתי לחזור אלי?</label>
        <input type="date" class="form-control" [(ngModel)]="alert.date">
    </div>
    <div class="mb-30">
        <label>תוכן התזכורת</label>
        <textarea class="form-control notes" rows="3" [(ngModel)]="alert.text"></textarea>
    </div>
    <div class="row-btns">
        <button (click)="ok(alert.id)" class="btn btn-round btn-blue">
            <fa name="{{state=='add'?'check':'pencil'}} left-space"></fa>{{state=='add'?'שמירה':'עדכון'}}
        </button>
        <button *ngIf="state=='edit'" (click)="close(alert.id)" class="btn btn-round btn-gray">
            <fa name="trash left-space"></fa>טופל
        </button>
    </div>
</div>