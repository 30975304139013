<div class="text-center" style="font-size: 3.05em; padding-top: 30px; color: #565656;">להקים בית בישראל</div>
<div class="text-center" style="font-size: 2.15em; color: #565656;">בסיוע שדכניות מקצועיות</div>
<div class="form-login">
    <form novalidate [formGroup]="myform" autocomplete="off">
        <div class="header">{{action=='change'?'שינוי':'איפוס'}} סיסמא</div>
        <div style="padding: 20px 60px;">
            <div *ngIf="action=='change'">
                <p *ngIf="errorMsg!='' && !done" class="text-center pink-color">{{errorMsg}}</p>
                <alert *ngIf="done" type="pink alert-top">
                    <p class="text-center pink-color" style="margin-bottom: 0px;">הסיסמא עודכנה!</p>
                </alert>
                <div>
                    <input class="form-control" [type]="showPrevPass ? 'text' : 'password'" placeholder="סיסמא קודמת"
                        autocomplete="new-password" formControlName="prevPass" (keydown.enter)="submit()" 
                        [ngClass]="{'invalid': !fix && myform.controls.prevPass.invalid}" />
                    <fa [name]="showPrevPass ? 'eye-slash' : 'eye'" class="btn-eye"
                        (click)="showPrevPass = !showPrevPass;">
                    </fa>
                </div>

                <div>
                    <input class="form-control" [type]="showPass ? 'text' : 'password'" placeholder="סיסמא חדשה"
                        autocomplete="new-password" formControlName="newPass" (keydown.enter)="submit()" 
                        [ngClass]="{'invalid': !fix && myform.controls.newPass.invalid}" />
                    <fa [name]="showPass ? 'eye-slash' : 'eye'" class="btn-eye" (click)="showPass = !showPass;"
                        (paste)="blockPaste($event)"></fa>
                </div>

                <p class="pink-color" *ngIf="myform.controls.newPass.invalid && myform.controls.newPass.touched">על
                    הסיסמא
                    להיות באורך 6 תוים לפחות, ולכלול אות ומספר</p>
                <input class="form-control" type="password" formControlName="repeatPass" (paste)="blockPaste($event)"
                    placeholder="אימות סיסמא" (keydown.enter)="submit()" [ngClass]="{'invalid': !fix && myform.controls.repeatPass.invalid}" />
                <button class="btn btn-blue btn-round d-block w-100 mx-auto" (click)="submit()"
                    [disabled]="done">אישור</button>
            </div>
            <div *ngIf="action=='reset'">
                <p *ngIf="errorMsg!='' && !done" class="text-center pink-color">{{errorMsg}}</p>
                <input class="form-control text-center" type="text" placeholder='דוא"ל' formControlName="email" autocomplete="nope"
                    [ngClass]="{'invalid': !fix && myform.controls.email.invalid}" />
                <p class="pink-color" *ngIf="myform.controls.email.invalid && myform.controls.email.touched">כתובת המייל
                    אינה תקינה</p>
                <button *ngIf="!done" class="btn btn-blue btn-round d-block w-100 mx-auto"
                    (click)="submit()">אישור</button>   
                    <div *ngIf="done" >
                        <p class="pink-color text-center"> כעת נשלח אליך בדוא"ל קישור לאיפוס הסיסמא שלך.</p>
                    </div>             
            </div>
        </div>
    </form>
</div>