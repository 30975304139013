//#region imports
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import { Answers, AskAnswer, Asks, PrefAsks } from '../entities/AskAnswer';
import {
  CustomEmailMessage,
  CustomSMSMessage,
  Men,
  MenPref,
  Person,
  PersonPref,
  Recommend,
  Women,
  WomenPref,
} from '../entities/entities';
import { FamilyStatusAsksComponent } from '../family-status-asks/family-status-asks.component';

//#endregion

//#region @Component
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
})
export class UserDetailsComponent implements OnInit {
  //#region variables
  @ViewChild(FamilyStatusAsksComponent) familyDetails;
  @ViewChild(TabsetComponent) tabset;
  @ViewChild('imgSelected') imgSelected;
  closeResult: string;
  //arrays
  arrNum(n: number): any[] {
    return Array(n);
  }

  isShadchan: boolean;

  asks: Array<Asks>;
  asksPerPerson: Array<Asks> = [];
  subAsks: Array<Asks>;
  prefAsks: Array<PrefAsks>;
  answers: Array<Answers>;
  recommendList: Array<Recommend> = new Array<Recommend>();
  otherArr: Array<string>;
  ansHasSub: Array<boolean>;
  formTitle: Array<string>;
  //maps
  ansMap: Map<number, Array<Answers>>;
  ansPrefMap: Map<number, Array<Answers>>;
  asksMap: Map<string, Array<Asks>>;
  asksPrefMap: Map<string, Array<Asks>>;
  asksIdMap: Map<number, Array<Asks>>;
  validationMap: Map<string, boolean> = new Map();
  otherClicked: Map<number, boolean> = new Map();
  //primitives
  hasntEmail: boolean = false;
  isLoaded: boolean = false;
  noPref: boolean = false;
  askfamilyStatus: boolean = false;
  familyStatusFlag: boolean = false;
  validFlag: boolean = true;
  emptyForNext: boolean = false;
  // isShadchan: boolean = false;
  getAlerts: boolean = false;
  currentTitle: number = 0;
  familyStatusI: number = 23;
  familyStatusPrefI: number;
  maxNumKids: number;
  countRecommends: number = 2;
  numKids: string = '';
  gender: string;
  otherGender: string;
  Gender: string;
  canByGender: string = 'תוכל';
  //entities
  person: Person;
  personTemp: Person;
  personPref: PersonPref;
  recommend: Recommend = new Recommend();
  isMobile = this.globals.isMobile;
  customEmailMessage: CustomEmailMessage = new CustomEmailMessage();
  customSMSMessage: CustomSMSMessage = new CustomSMSMessage();

  phoneVisibility: boolean;
  isAdmin: boolean;

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    public userService: UserService,
    private modalService: NgbModal
  ) {}
  //#endregion

  //#region init firstly
  ngOnInit() {
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true';

    //console.log(this.globals.isDefined(this.userService.getId));
    //console.log(this.globals.isFunction(this.userService.getId));
    this.initUserData();
    this.isShadchan = this.userService.getIsShadchan();

    this.familyStatusI = this.gender == 'man' ? 23 : 24;
    this.familyStatusPrefI = this.gender == 'man' ? 2 : 3;
    let st = 'מה אני מחפש' + (this.gender == 'woman' ? 'ת' : '');
    this.formTitle = ['הפרטים שלי', 'ממליצים עלי', st, 'סיום'];
    this.getPersonDetails()
      .then(() => this.getRecommends())
      .then(() => this.getManPref())
      .then(() => this.getAskAnswer())
      .then(() => (this.isLoaded = true));
  }

  open(content, person: Person) {
    this.customEmailMessage.to = person.email;
    this.customSMSMessage.to = person.tel1;
    this.customSMSMessage.sendSms = true;

    this.customEmailMessage.body =
      '\n\nלא ניתן להשיב למייל זה  להשבת מייל לשדכנית השיבו למייל הבא: ' +
      this.userService.email;

    this.customSMSMessage.body =
      '\n\nלא ניתן להשיב למספר זה להשבת הודעה לשדכנית נא שילחו מייל ל: ' +
      this.userService.email;

    this.customEmailMessage.from = 'שידוכים כהלכה<shiduch25@gmail.com>';

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  close() {
    this.modalService.dismissAll();
  }

  togglePhoneNumber(e) {
    if (this.phoneVisibility) {
      document.getElementById(e.target.id).innerHTML = 'הצגת מספר המשודך';
    } else {
      document.getElementById(e.target.id).innerHTML = 'הסתר מספר המשודך';
    }
    this.phoneVisibility = !this.phoneVisibility;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  sendEmail() {
    if (this.isShadchan) {
      this.http
        .post(
          this.globals.apiURL +
            `shadchan/get/sendEmailToMatchedPerson?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
          JSON.stringify({
            ...this.customEmailMessage,
            subject: this.customEmailMessage?.subject || 'שידוכים כהלכה',
            body: `<div dir='rtl'>${this.customEmailMessage.body?.replace(
              /\n/g,
              '<br/>'
            )}</div>`,
          }),
          {
            responseType: 'text' as 'json',
            headers: {},
          }
        )
        .subscribe();
      this.modalService.dismissAll();
    } else {
      console.log('trying to send email but you are not shadchan ... ');
    }
  }

  sendSms() {
    if (this.isShadchan) {
      this.customSMSMessage.from = 'shiduch';
      this.http
        .post(
          this.globals.apiURL + `shadchan/get/sendSMS`,
          JSON.stringify(this.customSMSMessage),
          {
            responseType: 'text' as 'json',
            headers: {},
          }
        )
        .subscribe();
      this.modalService.dismissAll();
    } else {
      console.log('trying to send email but you are not shadchan ... ');
    }
  }

  initUserData() {
    if (this.userService.gender == 1) {
      this.gender = 'man';
      this.otherGender = 'woman';
      this.Gender = 'Men';
      this.person = new Men();
      this.personPref = new MenPref();
    } else {
      this.gender = 'woman';
      this.otherGender = 'man';
      this.Gender = 'Women';
      this.person = new Women();
      this.personPref = new WomenPref();
    }
  }

  getPersonDetails() {
    console.log('user-details.component getPersonDetails');
    return new Promise((resolve, reject) => {
      this.http
        .get<Person>(
          this.globals.apiURL +
            `${this.gender}/getById/${this.userService.personId}?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
          { responseType: 'json' }
        )
        .subscribe((data) => {
          this.person = data;
          if (this.person.familyStatusDetails != '')
            this.person.familyStatus += ', ' + this.person.familyStatusDetails;
          console.log(data);
          resolve(true);
        }); //add throwError
    });
  }

  getRecommends() {
    return new Promise((resolve, reject) => {
      this.http
        .get<Recommend[]>(
          this.globals.apiURL +
            `recommends/getRecommends/${this.userService.personId}/${this.userService.gender}?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
          { responseType: 'json' }
        )
        .subscribe((data) => {
          this.recommendList = data;
          resolve(true);
        }); //add throwError
    });
  }

  getManPref() {
    return new Promise((resolve, reject) => {
      console.log(
        this.globals.apiURL +
          `${this.gender}/getPref/${this.userService.personId}`
      );
      this.http
        .get<PersonPref>(
          this.globals.apiURL +
            `${this.gender}/getPref/${this.userService.personId}?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
          { responseType: 'json' }
        )
        .subscribe((data) => {
          resolve(true);
          if (data == null) {
            this.noPref = true;
            return;
          }
          this.personPref = data;
          this.personPref = JSON.parse(
            JSON.stringify(this.personPref).replace(/;/g, ' /')
          );
          if (
            this.globals.isDefined(this.personPref['numKids']) &&
            this.personPref['numKids'] != null
          )
            switch (this.personPref['numKids']) {
              case 0:
                this.numKids = 'בלי ילדים';
                break;
              case -1:
                this.numKids = 'עם ילדים';
                break;
              case -2:
                this.numKids = "מס' ילדים- לא משנה";
                break;
              default:
                this.numKids = 'עד ' + this.personPref['numKids'] + ' ילדים';
            }
          if (this.numKids != '')
            this.personPref['familyStatus'] += ' (' + this.numKids + ')';
        }); //add throwError
    });
  }

  getAge(birthdate: string) {
    return new Date().getFullYear() - parseInt(birthdate.split('-')[0]);
  }

  getAskAnswer() {
    console.log('getAskAnswer user-details.component');
    return new Promise((resolve, reject) => {
      this.http
        .get<AskAnswer>(
          this.globals.apiURL +
            `actions/getAsks?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
          { responseType: 'json' }
        )
        .subscribe((data) => {
          this.asks = data.askList.concat(data.subAskList);
          this.answers = data.ansList;
          this.prefAsks = data.prefAskList.filter(
            (o) => o[this.gender] == true
          );
          this.initDependentParams(this.asks, this.answers);
          resolve(true);
        }); //add throwError
    });
  }

  initDependentParams(asks, answers) {
    asks = asks.sort((a, b) => (a.orderAll > b.orderAll ? 1 : -1));
    this.asksIdMap = asks.reduce(function (r, a) {
      r[a.id] = r[a.id] || [];
      r[a.id].push(a);
      return r;
    }, Object.create(null));
    this.asksPrefMap = asks.reduce(function (r, a) {
      r[a.name] = r[a.name] || [];
      r[a.name].push(a);
      return r;
    }, Object.create(null));
    asks = asks.filter((o) => o[this.gender] !== '0');
    answers = answers.filter((o) => o[this.gender] !== '0');
    for (let ask of asks) {
      if (
        this.globals.isDefined(this.person[ask.name]) &&
        !this.globals.isEmpty(this.person[ask.name])
      ) {
        //if you are sub check if your parent is defined
        if (!ask.idParentAns || !this.globals.isEmpty(this.person[ask.name]))
          this.asksPerPerson.push(ask);
        //  if(ask.name=='birthdate')
        //  this.treatAge(ask);
      }
      if (ask.typeInput == 2)
        this.person[ask.name] = this.person[ask.name].replace(/;/g, ', ');
    }
    this.treatContactDetails();
    this.addPayModelIfNeed();
    this.asksPerPerson = this.asksPerPerson.filter(
      (v, i, a) => a.findIndex((t) => t.name === v.name) === i
    );
    try {
      this.asksPerPerson[
        this.asksPerPerson.findIndex(({ name }) => name == 'moreToShadchan')
      ][this.gender] = 'עוד פרטים לשדכן';
    } catch (err) {}
    try {
      this.asksPerPerson[
        this.asksPerPerson.findIndex(({ name }) => name == 'moreToDisplay')
      ][this.gender] = 'עוד קצת עלי (יוצג באתר)';
    } catch (err) {}
    try {
      this.asksPerPerson[
        this.asksPerPerson.findIndex(({ name }) => name == 'nameMosad')
      ]['man'] = 'היכן אתה לומד?';
      this.asksPerPerson[
        this.asksPerPerson.findIndex(({ name }) => name == 'nameMosad')
      ]['woman'] = 'שם מקום הלימודים';
    } catch (err) {}

    this.otherArr = answers
      .filter((o) => o[this.gender] == 'אחר')
      .map((o) => o.id + '');
    // this.otherClicked = new Array(this.otherArr.length).fill(false);
    this.ansMap = answers.reduce(function (r, a) {
      r[a.idAsk] = r[a.idAsk] || [];
      r[a.idAsk].push(a);
      return r;
    }, Object.create(null));
    this.ansPrefMap = answers
      .filter((o) => o[this.gender] !== 'אחר')
      .reduce(function (r, a) {
        r[a.idAsk] = r[a.idAsk] || [];
        r[a.idAsk].push(a);
        return r;
      }, Object.create(null));
    this.asksMap = asks.reduce(function (r, a) {
      r[a.name] = r[a.name] || [];
      r[a.name].push(a);
      return r;
    }, Object.create(null));
    this.asks = asks;
    this.answers = answers;
  }

  treatAge(ask: Asks) {
    let newAsk: Asks = JSON.parse(JSON.stringify(ask)); //copy
    ask.name = 'age';
    ask[this.gender] = 'גיל';
    this.person['age'] =
      new Date().getFullYear() -
      parseInt(this.person['birthdate'].split('-')[0]) -
      1 +
      '.' +
      (12 - parseInt(this.person['birthdate'].split('-')[1]));
    this.asksPerPerson.pop();
    this.asksPerPerson.push(ask);
    let newAsk1: Asks = JSON.parse(JSON.stringify(ask)); //copy
    newAsk.name = 'birthdate';
    newAsk[this.gender] = 'תאריך לועזי';
    this.asksPerPerson.push(newAsk);
    newAsk1.name = 'birthdateHeb';
    newAsk1[this.gender] = 'תאריך עברי';
    this.asksPerPerson.push(newAsk1);
    console.log(this.asksPerPerson);
  }

  treatContactDetails() {
    if (
      this.person.insertedBy != this.person.idUser &&
      (this.isShadchan || this.isAdmin)
    ) {
      // inserted by shadchan
      //  if(this.userService.isShadchan && !this.userService.members.includes(this.person.insertedBy))
      //   this.asksPerPerson = this.asksPerPerson.filter(o => !['email','tel1','tel2'].includes(o.name));
      let newAsk: Asks = new Asks();
      newAsk.name = 'phoneShadchan';
      newAsk.man = newAsk.woman = 'בטיפול השדכנ/ית';
      newAsk.ordered = newAsk.orderAll = 100;
      newAsk.idParentAns = 0;
      this.asksPerPerson.push(newAsk);
    }
  }

  addPayModelIfNeed() {
    if (
      this.userService.isShadchan &&
      this.person.insertedBy == this.userService.id
    ) {
      let newAsk: Asks = new Asks();
      newAsk.name = 'payModel';
      newAsk.man = newAsk.woman = 'מודל תשלום';
      newAsk.ordered = newAsk.orderAll = 101;
      newAsk.idParentAns = 0;
      this.asksPerPerson.push(newAsk);
    }
  }

  onChat(p: Person) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`shadchan-folder/chat`], {
        queryParams: { chatWith: p?.idUser },
      })
    );

    window.open('/#' + url);
  }

  call(tel: string) {
    fetch(
      `https://www.015pbx.net/local/api/json/calls/make/?auth_username=kavhalacha849@kav;auth_password=035789054;stype=phone;snumber=${this.userService.extension};cnumber=${tel}&answer1=1&`
    );
  }

  //#endregion
}
