import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Person,
  PersonPref,
  Men,
  Women,
  MenPref,
  WomenPref,
  Recommend,
  OffersStatus,
  Pair,
  AlertedPerson,
  Alerts,
} from '../entities/entities';
import { DateFull } from '../entities/entities';
import { Globals } from '../common/globals';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { heLocale } from 'ngx-bootstrap/locale';
defineLocale('he', heLocale);

@Component({
  selector: 'app-date-with-heb',
  templateUrl: './date-with-heb.component.html',
  styleUrls: ['./date-with-heb.component.css'],
})
export class DateWithHebComponent implements OnChanges {
  dateFull: DateFull = new DateFull();
  @Output() dateSelected = new EventEmitter<DateFull>();
  @Input() prevD: string = '';
  @Input() prevHebD: string = '';
  bsValue: Date;

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private localeService: BsLocaleService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.prevD != '') this.bsValue = new Date(this.prevD);
  }

  ngOnInit(): void {
    this.localeService.use('he');
    this.prevHebD = this.prevHebD || '';
    this.prevD = this.prevD || '';
    if (this.prevHebD != '') this.dateFull.hebD = this.prevHebD;
    if (this.prevD != '') this.bsValue = new Date(this.prevD);
  }

  ok(date: Date) {
    if (!this.globals.isDefined(date)) return;
    this.dateFull.d = this.globals.dateToString(date);
    console.log(this.dateFull.d);
    let dateParts = this.dateFull.d.split('-'); // 1999-06-22
    let url =
      'https://www.hebcal.com/converter?cfg=json&gy=' +
      date.getFullYear() +
      '&gm=' +
      (date.getMonth() + 1) +
      '&gd=' +
      date.getDate() +
      '&g2h=1';
    console.log(url);
    this.http.get<JSON>(url, { responseType: 'json' }).subscribe(
      (res) => {
        console.log(res);
        console.log(res['hebrew']);
        this.dateFull.hebD = res['hebrew'];
        this.dateSelected.emit(this.dateFull);
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      }
    );
  }

  getDate() {
    return this.dateFull;
  }
}
