<div id="sidebar" class="sidebar">
  <div class="above">
    <!-- <fa name="bars"></fa> -->
    <div class="shadchan-img">
      <h3
        style="
          color: #fff;
          text-align: center;
          padding-top: 30%;
          font-weight: bold;
        "
      >
        מנהל
      </h3>
    </div>
    <!-- <p> שלום {{shadchanName}}</p> -->
    <div style="height: 20px"></div>
  </div>
  <ul>
    <li routerLink="chat" routerLinkActive="active">
      {{ "       צ׳אט" }} <fa name="comments-o"></fa>
      <span class="badge bg-danger chat-badge" *ngIf="chat.unreadAll() > 0">{{
        chat.unreadAll()
      }}</span>
    </li>
    <li routerLink="setting" routerLinkActive="active">
      הגדרות<fa name="wrench"></fa>
    </li>
    <li routerLink="add-shadchan/add" routerLinkActive="active">
      הוספת שדכן/ית<fa name="plus"></fa>
    </li>
    <li routerLink="del-shadchan" routerLinkActive="active">
      העברה /מחיקת שדכן/ית<fa name="times"></fa>
    </li>
    <li routerLink="groups" routerLinkActive="active">
      קבוצות<fa name="users"></fa>
    </li>
    <li routerLink="users-manage" routerLinkActive="active">
      משתמשים<fa name="user"></fa>
    </li>
    <li routerLink="db-manage" routerLinkActive="active">
      ניהול מסד נתונים<fa name="user"></fa>
    </li>
    <li routerLink="shdchan-report" routerLinkActive="active">
      דוחות לשדכנים<fa name="book"></fa>
    </li>
  </ul>
</div>
<router-outlet class="pull-left"></router-outlet>
