import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import { Person, TotalStatistics, User } from '../entities/entities';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-users-manage',
  templateUrl: './users-manage.component.html',
  styleUrls: ['./users-manage.component.css'],
})
export class UsersManageComponent implements OnInit {
  format(str: string) {
    const m = moment(new Date(str));
    if (m.isValid()) {
      return moment(new Date(str)).format('DD/MM/yy HH:mm');
    }
  }
  defaultTypes = [
    'שדכניות',
    'משודכים - גברים',
    'משודכות - נשים',
    'משתמשים חסומים',
  ];

  unManagedTypes = ['גברים', 'נשים'];

  types: Array<string> = this.defaultTypes;

  usersFilter: UsersFilter = new UsersFilter();
  usersList: Array<User>;
  byList: Array<any>;

  noResults: boolean = false;
  addMsg: boolean = false;
  searched: boolean = false;
  msg: string;
  isAdmin: boolean = false;
  adminStatistics: TotalStatistics;
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true';
    this.getUsersNumbering();
  }

  getBy(id: any) {
    return this.byList.find((val) => val?.[0] === id)?.[1];
  }

  updatePassword(u: User): void {
    this.http
      .put(
        this.globals.apiURL +
          `users/setPassword?id=${u.id}&p=${u.password}&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        {
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        }
      )
      .subscribe(
        (response) => {
          console.log(response.toString());
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  showHidePassword(uId: number): void {
    const input = document.getElementById(
      'psswd_' + uId
    ) as HTMLInputElement | null;
    if (input.type == 'text') {
      input.type = 'password';
      document.getElementById('iconPassword_' + uId).classList.remove('fa-eye');
      document
        .getElementById('iconPassword_' + uId)
        .classList.add('fa-eye-slash');
    } else {
      input.type = 'text';
      document
        .getElementById('iconPassword_' + uId)
        .classList.remove('fa-eye-slash');
      document.getElementById('iconPassword_' + uId).classList.add('fa-eye');
    }
  }

  getUsersNumbering() {
    this.http
      .get<TotalStatistics>(
        this.globals.apiURL +
          `users/getAdminStatistics?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`
      )
      .subscribe((data) => {
        this.adminStatistics = data;
        this.noResults = !(Array.isArray(data) && data.length);
      });
  }

  sort() {
    return (a, b) => {
      let dateA = new Date(a.createdAt);
      let dateB = new Date(b.createdAt);
      return (
        (b?.createdAt ? dateB.getTime() : -Infinity) -
        (a?.createdAt ? dateA.getTime() : -Infinity)
      );
    };
  }

  search() {
    if (!this.usersFilter?.managed) {
      this.searched = true;
      // console.log("this.usersFilter - "+this.usersFilter);
      let params = new HttpParams();
      for (let attr in this.usersFilter)
        params = params.set(attr, this.usersFilter[attr]);
      // console.log("params - " + params);
      this.http
        .get<any>(
          this.globals.apiURL +
            `users/unManaged/freeSearch?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
          { params: params }
        )
        .subscribe((data) => {
          this.usersList = data?.data?.sort(this.sort());
          this.byList = data?.by;
          this.noResults = !(Array.isArray(data?.data) && data?.data?.length);
        }); //add throwError

      return;
    }

    this.searched = true;
    // console.log("this.usersFilter - "+this.usersFilter);
    let params = new HttpParams();
    for (let attr in this.usersFilter)
      params = params.set(attr, this.usersFilter[attr]);
    // console.log("params - " + params);
    this.http
      .get<{ data: Array<User>; by: Array<User> }>(
        this.globals.apiURL +
          `users/freeSearch?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        { params: params }
      )
      .subscribe((data) => {
        this.usersList = data?.data?.sort(this.sort());
        this.byList = data?.by;
        this.noResults = !(Array.isArray(data?.data) && data?.data?.length);
      }); //add throwError
  }

  clear() {
    this.usersFilter = new UsersFilter();
  }

  changeType() {
    this.usersList = [];
    this.usersFilter.type = '';
    if (!this.usersFilter.managed) {
      this.types = this.unManagedTypes;
      this.usersFilter.type = this.types[0];
    } else {
      this.types = this.defaultTypes;
    }
  }

  removeUser(uId: number) {
    if (!confirm('האם אתה בטוח שאתה רוצה למחוק את המשתמש הזה?')) return;
    // console.log(uId);

    if (!this.usersFilter.managed) {
      const gender =
        this.usersFilter.type == this.unManagedTypes[0] ? 'man' : 'woman';

      this.http
        .get<Person>(this.globals.apiURL + `${gender}/delete/${uId}`, {
          responseType: 'json',
        })
        .subscribe(
          (response) => {
            this.usersList = this.usersList.filter((u) => u.id !== uId);
          },
          (err: HttpErrorResponse) => {
            console.log(err);
          }
        );
      return;
    }

    this.http
      .post(
        this.globals.apiURL +
          'users/delete?id=' +
          uId +
          `&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        JSON.stringify(uId),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .subscribe(
        (response) => {
          // console.log(response.toString());
          //  this.msg = u.isLocked ? "המשתמש נמחק" : "המחיקה בוטלה";
          this.addMsg = true;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  lock(uId: number) {
    // console.log("idUser - "+ uId);
    this.http
      .post(
        this.globals.apiURL +
          'users/lockUnLockById?userId=' +
          uId +
          `&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        JSON.stringify(uId),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .subscribe(
        (response) => {
          const first = this.usersList.find((obj) => {
            return obj.id === uId;
          });

          if (response.toString().match('true')) {
            first.isLocked = true;
            this.msg = 'המשתמש נחסם';
          } else {
            first.isLocked = false;
            this.msg = 'החסימה בוטלה';
          }
          this.addMsg = true;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  goToUserFolder(u: User, type: string) {
    this.userService.setLoginFirstName(this.userService.firstName);
    this.userService.setLoginLastName(this.userService.lastName);
    this.userService.setGender(u.gender);
    this.userService.setFirstName(u.firstName);
    this.userService.setLastName(u.lastName);

    if (!this.usersFilter.managed) {
      const gender = this.usersFilter.type == this.unManagedTypes[0] ? 1 : 2;
      this.userService.setGender(gender);
      this.userService.personId = u.id;
      sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
      // console.log(sessionStorage.getItem('currentUser'));
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`user-folder/user-details`])
      );
      window.open('/#' + url, '_blank');
      return;
    }

    // sessionStorage.setItem('isAdmin', 'true');
    let url;
    if (u.isShadchan) {
      this.userService.setId(u.id);
      sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
      //     console.log(sessionStorage.getItem('currentUser'));
      url = this.router.serializeUrl(
        this.router.createUrlTree([`shadchan-folder/add-shadchan/edit`])
      );
      window.open('/#' + url, '_blank');
    } else {
      this.http
        .get<number>(
          this.globals.apiURL +
            `users/getPersonId/${u.id}/${u.gender}?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`
        )
        .subscribe((data) => {
          let personId = data;
          this.userService.personId = personId;
          sessionStorage.setItem(
            'currentUser',
            JSON.stringify(this.userService)
          );
          // console.log(sessionStorage.getItem('currentUser'));
          url = this.router.serializeUrl(
            this.router.createUrlTree([`user-folder/user-details`])
          );
          window.open('/#' + url, '_blank');
        }); //add throwError
    }
  }
}

export class UsersFilter {
  firstName: string;
  lastName: string;
  phone: string;
  type: string;
  gender?: string;
  managed?: boolean;
  constructor() {
    this.managed = true;
    this.firstName = '';
    this.lastName = '';
    this.phone = '';
    this.type = '';
  }
}
