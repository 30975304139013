import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import { User } from '../entities/entities';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css'],
})
export class PasswordComponent implements OnInit {
  action: string;
  myform: UntypedFormGroup;
  showPass: boolean = false;
  done: boolean = false;
  showPrevPass: boolean = false;
  fix: boolean = true;
  errorMsg: string = '';

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.action = this.route.snapshot.params.action;
    this.initForm();
  }

  initForm() {
    this.myform =
      this.action == 'change'
        ? new UntypedFormGroup({
            prevPass: new UntypedFormControl('', [Validators.required]),
            newPass: new UntypedFormControl('', [
              Validators.required,
              Validators.minLength(6),
            ]),
            repeatPass: new UntypedFormControl('', [Validators.required]),
          })
        : new UntypedFormGroup({
            email: new UntypedFormControl('', [
              Validators.required,
              Validators.pattern('[^ @]*@[^ @]*'),
            ]),
          });
  }

  checkValidation() {
    // this.userService=JSON.parse(sessionStorage.getItem('currentUser'));
    let oldP = this.userService.getPassword();
    console.log(oldP);
    if (this.myform.controls['prevPass'].value != oldP) {
      this.errorMsg = 'הסיסמא הקודמת אינה נכונה';
      return false;
    }
    if (
      this.myform.controls['newPass'].value !=
      this.myform.controls['repeatPass'].value
    ) {
      this.errorMsg = 'הסיסמא אינה זהה';
      return false;
    }
    return true;
  }

  submit() {
    this.errorMsg = '';
    if (
      !this.myform.valid ||
      (this.action == 'change' && !this.checkValidation())
    ) {
      this.fix = false;
      return;
    }
    console.log(this.myform);
    if (this.action == 'change') this.setPass();
    else if (this.action == 'reset') this.sendLinkIfExists();
  }

  setPass() {
    this.http
      .put(
        this.globals.apiURL +
          `users/setPassword?userIDRequested=${this.userService.id}-${
            this.userService.loginFirstName
          }-${
            this.userService.loginLastName
          }&id=${this.userService.getId()}&p=${
            this.myform.controls['newPass'].value
          }`,
        {
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        }
      )
      .subscribe(
        (response) => {
          console.log(response.toString());
          this.done = true;
          this.errorMsg = '';
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.errorMsg = 'התרחשה שגיאה, אנא נסו מאוחר יותר';
          this.done = false;
        }
      );
  }

  sendLinkIfExists() {
    console.log(this.myform.controls['email'].value);
    this.http
      .post(
        this.globals.apiURL +
          `users/sendLinkIfExists/${this.myform.controls['email'].value}?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`,
        {
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        }
      )
      .subscribe(
        (response) => {
          console.log(response.toString());
          if (response.toString() == '1') {
            this.errorMsg = 'כעת נשלח אליך בדוא"ל קישור לאיפוס הסיסמא שלך.';
            this.done = true;
          } else {
            this.errorMsg = 'המשתמש אינו קיים במאגר';
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.errorMsg = 'התרחשה שגיאה, אנא נסו מאוחר יותר';
          this.done = false;
        }
      );
  }

  blockPaste(e: any) {
    e.preventDefault();
  }
}
