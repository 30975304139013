<h1 class="page-title outside">{{pageTitle}}</h1>

<div *ngIf="name=='contact'" class="contact">
    <p>ניתן לחייג לשדכניות באופן ישיר</p>
    <p class="bold">
        <fa name="volume-control-phone right-space"></fa>0722-733-744
    </p>
    <p>על כל תקלה או שאלה ניתן לשלוח מייל</p>
    <p class="bold">
        <fa name="envelope right-space"></fa>6093636@gmail.com
    </p>
</div>
<div *ngIf="name=='about'" class="about">
    <pre>
    מערכת "שידוכים כהלכה" הוקמה מבית קו ההלכה הספרדי .
    המערכת הוקמה לזיכוי הרבים, 
    ולתת מענה לציבור החרדי והדתי בהקמת בית נאמן בישראל .
    במערכת זו הושקע ממון רב ומחשבה רבה.
    המערכת הינה על פי ההלכה .
    במערכת זו הכל עובר דרך שדכנית ואין אפשרות להכיר דרך הממשק.
    המערכת הינה ידידותית ונותנת אפשרויות יותר לחיבור בין שדכניות וקהל רחב יותר,
    כך שהסיכוי יותר גדול שיהיה הצעות בתוך המערכת.
    כמו כן המערכת מסייעת באיתור משודך ע"י המשודכים עצמם (כמובן שלא רואים את כל הפרטים)
    אבל ברגע שיש התאמה כללית בין המשודכים, אוטומט זה עובר לשדכנית שתטפל בהצעה ותרים אליכם טלפון להמשך טיפול והעברת פרטים נוספים.

    <b>איך זה עובד ?</b>
    אתם ממלאים את הפרטים שלכם, כשתגמרו למלא הכל, המערכת באופן אוטומטי תביא לכם הצעות שקיימות במערכת שיכולה להתאים בעבורכם לפי הפרטים שמילאתם .
    במידה ויש הצעה שנראית לכם רלוונטית , אתם שולחים את ההצעה לצד השני , הצד השני רואה גם את הפרטים שלכם (כמובן בגדול ללא פרטים מזהים כמו טלפון כתובת מייל וכו')
    במידה והצד השני גם מאשר שנראה לו שזה יכול להתאים , המערכת מעבירה את הפרטים שלכם לשדכנית , שתיצור אתכם קשר להעברת פרטים נוספים ולהתקדמות התהליך 

    <b>האם הכל חסוי ?</b>
    כמובן , כל הפרטים המלאים מוצגים רק לשדכניות בלבד ולא מתפרסמות בשום מקום 

    <b>האם ניתן ליצור גם קשר טלפוני ?</b>
    כמובן , יש לנו גם שדכניות שעונות און ליין בטלפון 0722-733-744, אולם מומלץ מאוד למלאות קודם את כל הפרטים שלכם במערכת ככה זה יחסוך זמן יקר לשדכנית , וגם עדיף לראות קודם האם במערכת יש הצעות מתאימות , כי בסופו של יום השדכנית עובדת גם על אותו התוכנה

    <b>איך אדע אם מישהו שלח לי הצעה ?</b>
    אם מישהו/י שלחו לכם הצעה תקבלו התראה במייל שנשלחה בעבורכם הצעה (שימו לב, יתכן שהמייל יגיע לקידומי מכירות או לספאם לכן מומלץ מאוד כשתקבלו את המייל בפעם הראשונה שנרשמתם תגדירו שיגיע כל הזמן לראשי )

    <b>האם שדכניות יכולות להשתמש גם עם המערכת ?</b>
    כמובן , צרו איתנו קשר במייל  <label class="pink-color ltr">6093636@gmail.com</label>  ואנחנו נפתח לכם יוזר של שדכנית .

    </pre>
</div>
<div *ngIf="name=='how-it-works'" class="how-it-works">
    <pre>
    <b>מה זה שידוכים כהלכה ?</b>
    זו תוכנה חכמה שבאה לעזור לשדכנים ולמשודכים למצוא את זיווגם בקלות ועל פי ההלכה.
    
    <b>מה מיוחד בתוכנה הזאת ?</b>
    א. בתוכנה הזאת גם אם יהיו כמה שדכנים שעובדים על התוכנה, כולם יראו את פרטיכם, ככה שברישום אחד זה כאילו שנרשמתם אצל הרבה שדכניות.
    ב. יש אפשרות לראות את המאגר של המשודכים (כמובן עם פרטים בגדול וללא פרטים מזהים כמו טלפון וכד') 
    ואם יש הצעה שנראית לכם מתאימה, אתם שולחים ישירות מהתוכנה הצעה למשודך שגם רואה את פרטיכם (כמובן ללא זיהוי), ואם הצד השני גם לו נראה הפרטים כמתאים, זה ישר עובר לשדכנית שתרים אליכם טלפון ותנסה לקדם את השידוך. 
    
    <b>האם הרישום עולה כסף ?</b>
    הרישום הוא בחינם 
    אם השדכנית עשתה לי שידוך האם צריך לשלם ?
    3000 לכל צד כפי שמקובל בשידוך רגיל, ובשידוך פרק ב או עם בעיה רפואית 4500 שקל לכל צד.
    
    <b>אז איך מתחילים ?</b>
    דבר ראשון נכנסים ללשונית הרשמה כמו שמסומן בתמונה
    <img src="{{path}}1.PNG" />
    
    לאחר מכן תמלאו את כל הפרטים שמופיעים בטופס 
    מומלץ למלא הכל תקין  שהמערכת תוכל להתאים לכם הצעה מתאימה 
    חשוב מאוד למלא מייל תקין בשביל שאם יהיו הצעות שישלחו לכם תוכלו לקבל התראה למייל 
    <img src="{{path}}2.PNG" />
    
    אחרי שמילאתם את כל הפרטים אתם מגיעים למסך הזה 
    מבואר כאן כל לשונית מה המשמעות שלה
    <img src="{{path}}3.PNG" />
    
    אם יש הצעה שמתאימה לכם לחצו על שלח הצעה 
    <img src="{{path}}4.PNG" />
    
    אם אתם רוצים לערוך את הפרטים או לשנות הגדרות מערכת כנסו לעריכת פרטים 
    <img src="{{path}}5.PNG" />
    
</pre>
</div>
<div *ngIf="name=='contact' || name=='about'" class="logo-kh"></div>