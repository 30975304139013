import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChatService } from '../common/chat.service';
import { UserService } from '../common/user.service';

@Component({
  selector: 'app-shadchan-folder',
  templateUrl: './shadchan-folder.component.html',
  styleUrls: ['./shadchan-folder.component.css'],
  providers: [ChatService],
})
export class ShadchanFolderComponent implements OnInit {
  modalRef: BsModalRef;
  myform: UntypedFormGroup;
  shadchanName: string;
  isSystem: boolean;
  isAdmin: boolean;
  isPrivate: boolean;
  tabMap: Map<string, number>;

  constructor(
    private titleService: Title,
    private modalService: BsModalService,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public chat: ChatService
  ) {}

  ngOnInit(): void {
    this.chat.initData();
    this.isPrivate = this.userService.getPermission() == 1;
    this.tabMap = this.userService.tabMap;
    this.shadchanName = sessionStorage.getItem('shadchanName');
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true';
    this.titleService.setTitle('שידוכים כהלכה');
    if (this.isAdmin) {
      this.shadchanName =
        this.userService.getLastName() + ' ' + this.userService.getFirstName();
      this.titleService.setTitle('שדכנ/ית- ' + this.userService.getFirstName());
    }
    this.isSystem = sessionStorage.getItem('isSystem') === 'true';
    this.myform = new UntypedFormGroup({
      gender: new UntypedFormControl('', Validators.required),
    });
    console.log('userService', this.userService);
  }

  openModal(chooseGender: TemplateRef<any>) {
    this.modalRef = this.modalService.show(chooseGender);
  }

  initGender() {
    this.userService.setGender(parseInt(this.myform.controls.gender.value));
    sessionStorage.setItem('currentUser', JSON.stringify(this.userService));
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['register', 'add'])
    );
    window.open('/#' + url, '_blank');
    this.modalRef.hide();
  }
}
