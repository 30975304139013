import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../common/globals';

@Component({
  selector: 'app-info-pages',
  templateUrl: './info-pages.component.html',
  styleUrls: ['./info-pages.component.css']
})
export class InfoPagesComponent implements OnInit {
  name: string;
  pageTitle: string;
  isMobile = this.globals.isMobile;
  path: string = '/assets/info-pages/';

  constructor(private http: HttpClient, private globals: Globals,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.name = params.name;
        switch (this.name) {
          case 'contact': this.pageTitle = 'צרו קשר';
            break;
          case 'about': this.pageTitle = 'אודותינו';
            break;
          case 'how-it-works': this.pageTitle = 'איך זה עובד';
            break;
        }
      });
  }

}
