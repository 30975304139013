<div class="div-search" (keydown.enter)="search()">
  <div class="row-flex-between">
    <h5
      class="text-info text-center"
      style="margin-top: 3px; margin-bottom: 0px"
    >
      <fa name="search left-space"></fa><label> חיפוש חופשי</label>&nbsp;&nbsp;
      <label *ngIf="!isShadchan"> (לא לפי ההעדפות שהזנת)</label>
      <label *ngIf="!isShadchan" style="font-size: 14px; margin-right: 30px"
        >*ניתן ורצוי לבחור בחירה מרובה</label
      >
    </h5>
    <div
      *ngIf="isShadchanFolder"
      btnRadioGroup
      class="d-flex"
      [(ngModel)]="personFilter['gender']"
    >
      <button
        btnRadio="1"
        (click)="setGender(1)"
        class="btn btn-round btn-male"
        type="button"
      >
        <fa name="male left-space"></fa>גברים</button
      >&nbsp;&nbsp;
      <button
        btnRadio="2"
        (click)="setGender(2)"
        class="btn btn-round btn-female"
        type="button"
      >
        <fa name="female left-space"></fa>נשים
      </button>
    </div>
    <div *ngIf="isShadchan" class="form-check" style="padding-top: 6px">
      <input
        class="left-space"
        type="checkbox"
        [(ngModel)]="displayAll"
      /><label class="text-info">הצג גם לא פעילים ומאורסים</label>&nbsp;&nbsp;
      <input
        *ngIf="permission != 1"
        class="left-space"
        type="checkbox"
        [(ngModel)]="personalRepo"
      /><label *ngIf="permission != 1" class="text-info"
        >חפש במאגר שלי בלבד</label
      >
    </div>
    <div
      *ngIf="!isShadchan || genderChoosen"
      style="display: flex; flex-wrap: wrap"
    >
      <div
        class="w-100"
        style="border-bottom: 1px solid #2699a93d; margin: 8px auto"
      ></div>
      <div
        *ngFor="let ask of asks | advancedSearch : partialAsks; let i = index"
        style="margin: 5px"
      >
        <div *ngIf="ask.name == 'withKids'">
          <ng-select
            [searchable]="false"
            [items]="[
              { value: false, label: 'בלי ילדים' },
              { value: true, label: 'עם ילדים' }
            ]"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [placeholder]="'ילדים'"
            [(ngModel)]="personFilter['withKids']"
          >
          </ng-select>
        </div>
        <div *ngIf="ask.typeInput < 3 && ask.typeInput > -1">
          <ng-select
            [items]="ansMap[ask.id]"
            [bindLabel]="gender"
            [placeholder]="ask[gender]"
            appendTo="body"
            multiple="true"
            [(ngModel)]="personFilter[ask.name]"
            dropdownPosition="bottom"
          >
          </ng-select>
        </div>
        <input
          *ngIf="ask.typeInput >= 3"
          class="form-control"
          [type]="ask.name == 'moneySum' ? 'number' : 'text'"
          [(ngModel)]="personFilter[ask.name]"
          [placeholder]="ask[gender]"
        />
      </div>
      <div *ngIf="isAdvanced" style="margin: 5px; display: flex">
        <input
          type="number"
          class="form-control"
          [(ngModel)]="personFilter['minAge']"
          placeholder="מגיל"
          style="width: 78px"
        />
        <input
          type="number"
          class="form-control"
          [(ngModel)]="personFilter['maxAge']"
          placeholder="עד"
          style="width: 72px"
        />
      </div>
      <div *ngIf="isAdvanced" style="margin: 5px; display: flex">
        <input
          class="form-control"
          [(ngModel)]="personFilter['minHeight']"
          placeholder="מגובה"
          style="width: 78px"
        />
        <input
          class="form-control"
          [(ngModel)]="personFilter['maxHeight']"
          placeholder="עד"
          style="width: 72px"
        />
      </div>
      <div
        *ngIf="isAdvanced && gender == 'man'"
        style="margin: 5px; display: flex"
      >
        <ng-select
          [items]="headCoverList"
          [bindLabel]="'woman'"
          [(ngModel)]="preferedHeadCover"
          [placeholder]="'כיסוי ראש מועדף'"
          multiple="true"
          dropdownPosition="bottom"
        >
        </ng-select>
      </div>
      <input
        class="form-control"
        type="text"
        [(ngModel)]="personFilter['free']"
        placeholder="חיפוש חופשי"
        style="margin: 5px"
      />
      <div style="margin: 7px">
        <button class="btn btn-blue" (click)="search()">
          <!-- סינון<fa name="filter right-space"></fa> -->
          <fa name="search left-space"></fa>חיפוש</button
        >&nbsp;&nbsp;
        <button class="btn btn-blue" (click)="clear()">
          <!-- סינון<fa name="filter right-space"></fa> -->
          <fa name="times left-space"></fa>ניקוי</button
        >&nbsp;&nbsp;
        <button *ngIf="isShadchan" class="btn btn-blue" (click)="advanced()">
          מתקדם<fa
            name="angle-{{ isAdvanced ? 'up' : 'down' }} right-space"
          ></fa>
        </button>
      </div>
    </div>
  </div>
</div>
