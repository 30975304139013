//#region imports
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Globals } from '../common/globals';
import { UserService } from '../common/user.service';
import { Answers, AskAnswer, Asks } from '../entities/AskAnswer';
import { OffersStatus, Person, PersonFilter } from '../entities/entities';
//#endregion

@Component({
  selector: 'app-free-search',
  templateUrl: './free-search.component.html',
  styleUrls: ['./free-search.component.css'],
})
export class FreeSearchComponent implements OnInit {
  asks: Array<Asks>;
  asks1: Array<Asks>;
  partialAsks: Array<Asks>;
  answers: Array<Answers>;
  answers1: Array<Answers>;
  ansMap: Map<number, Array<Answers>>; //instead arr of Answers do arr of strings= gender
  ansMap1: Map<number, Array<Answers>>; //instead arr of Answers do arr of strings= gender
  personList: Array<Person>;
  headCoverList: Array<Answers>;
  meetList: Array<OffersStatus>;
  gender: string;
  otherGender: string;
  Gender: string;
  genderChoosen: boolean = false;
  personalRepo: boolean = false;
  noResults: boolean = false;
  displayAll: boolean = false;
  isAdvanced: boolean;
  isShadchan: boolean;
  isShadchanFolder: boolean;
  permission: number;
  filterAsks: Array<number>;
  personFilter = new PersonFilter();
  @Output() searchClick = new EventEmitter();
  @Output() clearClick = new EventEmitter();
  @Output() setGenderClick = new EventEmitter();
  preferedHeadCover = new Array<Answers>();
  preferedHeadCoverList = new Array<String>();
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.isShadchanFolder = this.router.url.includes('shadchan-folder');
    this.isShadchan =
      this.router.url.includes('shadchan-folder') ||
      this.userService.getIsShadchan() ||
      sessionStorage.getItem('isAdmin') == 'true';

    console.log('function ngOnInit ');
    this.filterAsks = [
      1, 2, 3, 4, 5, 6, 9, 10, 11, 12, 16, 18, 19, 22, 23, 24, 25, 50, 51,
    ].filter((ask) => this.isShadchan || ![3].includes(ask)); //8,16 - age,height
    this.initUserData();
    this.isAdvanced = !this.isShadchan;
    this.preferedHeadCoverList.length = 0;
  }

  setGender(g: number) {
    console.log('function setGender');
    this.genderChoosen = true;
    this.gender = g == 1 ? 'man' : 'woman';
    this.otherGender = g == 2 ? 'man' : 'woman';
    this.userService.setGender(g == 2 ? 1 : 2);
    this.setGenderClick.emit(this.userService.getGender());
    //  console.log('wanted:'+this.gender);
    // console.log('searcher:'+this.userService.getGender());
    this.isAdvanced = false;
    this.getAskAnswer();
    this.clear();
  }

  initUserData() {
    console.log(' function  initUserData()');

    this.permission = this.userService.getPermission();
    if (this.isShadchan) {
      this.setGender(this.userService.getGender() === 2 ? 1 : 2);
      return;
    }
    if (this.userService.getGender() == 1) {
      // opposite!
      this.gender = 'woman';
      this.otherGender = 'man';
      this.Gender = 'Women';
    } else {
      this.gender = 'man';
      this.otherGender = 'woman';
      this.Gender = 'Men';
    }
    this.getAskAnswer();
  }

  getAskAnswer() {
    console.log('function getAskAnswer free-search.component');
    this.http
      .get<AskAnswer>(
        this.globals.apiURL +
          `actions/getAsks` +
          `?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`,
        { responseType: 'json' }
      )
      .subscribe(
        (data) => {
          this.asks = data.askList.concat(data.subAskList);
          this.answers = data.ansList;
          this.initDependentParams(this.asks, this.answers);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  initDependentParams(asks, answers) {
    console.log('  initDependentParams');
    asks = asks.filter(({ id }) => this.filterAsks.includes(id));
    asks = asks.sort((a, b) => (a.orderAll > b.orderAll ? 1 : -1));
    if (!this.isShadchan)
      asks = asks.filter((o) => o.isPublic || o.name == 'moneySum');
    this.asks1 = asks.filter((o) => o['man'] != '0');
    asks = asks.filter((o) => o[this.gender] !== '0');
    this.answers1 = answers.filter((o) => o['woman'] != '0');
    answers = answers.filter((o) => o[this.gender] !== '0');
    this.ansMap = answers.reduce(function (r, a) {
      r[a.idAsk] = r[a.idAsk] || [];
      r[a.idAsk].push(a);
      return r;
    }, Object.create(null));
    this.ansMap1 = this.answers1.reduce(function (r, a) {
      r[a.idAsk] = r[a.idAsk] || [];
      r[a.idAsk].push(a);
      return r;
    }, Object.create(null));

    this.headCoverList =
      this.ansMap[22] == undefined ? this.ansMap1[22] : this.ansMap[22];
    //this.headCoverList.forEach( o => o['man'] = o['woman']);
    //this.personList.forEach(o => o['birthdate']=(new Date().getFullYear()-parseInt(o['birthdate'].split('-')[0]))+"");
    if (asks[asks.findIndex(({ name }) => name == 'moneySum')] != null)
      asks[asks.findIndex(({ name }) => name == 'moneySum')][this.gender] =
        'התחייבות כספית';
    this.partialAsks = this.isShadchan ? asks.filter((o) => o.id <= 8) : asks;
    this.asks = asks.concat({
      id: 0,
      name: 'withKids',
      man: 'ילדים',
      woman: 'ילדים',
      typeInput: -1,
      ordered: 29,
      orderAll: 37,
    });
    this.answers = answers;
  }
  //#endregion

  advanced() {
    console.log('function  advanced');
    this.isAdvanced = !this.isAdvanced;
    this.partialAsks = this.isAdvanced
      ? this.asks
      : this.asks.filter((o) => o.id <= 8);
  }

  search() {
    this.preferedHeadCoverList.length = 0;
    console.log('function   search');
    let tmpArr: Array<string> = [];
    for (let attr in this.personFilter) {
      //transfer ng-selected to my personFilter structure
      if (
        this.personFilter[attr] == null ||
        !this.globals.isDefined(this.personFilter[attr][0]) ||
        typeof this.personFilter[attr][0] == 'string'
      )
        continue;
      tmpArr = [];
      for (let o of this.personFilter[attr]) tmpArr.push(o[this.gender]);
      this.personFilter[attr] = tmpArr;
    }
    let params = new HttpParams();
    var str = '';
    for (let attr in this.personFilter) {
      if (
        !Array.isArray(this.personFilter[attr]) &&
        typeof this.personFilter[attr] == 'string'
      ) {
        if (this.personFilter[attr].indexOf("'") >= 0) {
          str = this.personFilter[attr].replace("'", "''");
        } else {
          if (this.personFilter[attr].indexOf(' '))
            str = this.personFilter[attr].trim();
          else {
            str = this.personFilter[attr];
          }
          this.personFilter[attr] = this.personFilter[attr].trim();
        }
      } else {
        str = this.personFilter[attr];
      }
      params = params.set(attr, str);
    }

    this.preferedHeadCover.forEach((r, i) => {
      if (!this.preferedHeadCoverList.includes(r.woman))
        this.preferedHeadCoverList.push(r.woman);
    });

    let sInfo = {
      isShadchan: this.userService.getIsShadchan(),
      isShadchanFolder: this.isShadchan,
      personalRepo: this.personalRepo,
      idPerson: this.isShadchan ? -1 : this.userService.personId,
      permission: this.userService.getIsShadchan()
        ? this.userService.getPermission()
        : -1, //כי אם שדכן מחפש עבור משודך יכנס הרשאת השדכן ולא המשודך
      idShadchan: this.userService.getIsShadchan()
        ? this.userService.getId()
        : -1,
      displayAll: this.displayAll,
      preferedHeadCoverList: this.preferedHeadCoverList,
    };

    // console.log(this.globals.apiURL + this.otherGender + `/offers/freeSearch?userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`);
    this.http
      .post<Array<Person>>(
        this.globals.apiURL +
          this.otherGender +
          `/offers/freeSearch?userIDRequested=${this.userService.id}-${this.globals.loginFirstName}-${this.globals.loginLastName}`,
        JSON.stringify(sInfo),
        {
          params: params,
        }
      )
      .subscribe(
        (data) => {
          this.personList = data;
          //    console.log(this.personList);
          this.noResults = !(Array.isArray(data) && data.length);
          //     console.log(this.noResults);
          //this.personList.forEach(o => o['birthdate']=(new Date().getFullYear()-parseInt(o['birthdate'].split('-')[0]))+"");
          this.personList = this.personList?.map((o) =>
            JSON.parse(JSON.stringify(o).replace(/;/g, ', '))
          );
          this.searchClick.emit(this.personList);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  clear() {
    console.log('function   clear ');
    this.personFilter = new PersonFilter();
    this.clearClick.emit([]);
  }

  isDevorce(ask: any[]): boolean {
    return (
      ask?.length == 1 && ask?.find((a) => a?.[this.gender]?.includes('גרוש'))
    );
  }
}
