<div class="text-center" style="font-size: 3.05em; padding-top: 30px; color: #565656;">להקים בית בישראל</div>
<div class="text-center" style="font-size: 2.15em; color: #565656;">בסיוע שדכניות מקצועיות</div>
<div class="form-login">
    <form novalidate [formGroup]="myform" autocomplete="off">
        <div class="header">איפוס סיסמא</div>
        <div style="padding: 20px 60px;">
            <!-- <p *ngIf="errorMsg!='' && !done" class="text-center pink-color">{{errorMsg}}</p> -->
                <alert *ngIf="done" type="pink alert-top">
                    <p class="text-center pink-color" style="margin-bottom: 0px;">הסיסמא עודכנה!</p>
                </alert>
        <!-- <div *ngIf="!done;else ShowDone"> -->
            <p *ngIf="errorMsg!='' && !done" class="text-center pink-color">{{errorMsg}}</p>            
            <div>
                <input class="form-control" [type]="showPass ? 'text' : 'password'" placeholder="סיסמא חדשה"
                    autocomplete="new-password" formControlName="newPass" (keydown.enter)="submit()" 
                    [ngClass]="{'invalid': !fix && myform.controls.newPass.invalid}" />
                <fa [name]="showPass ? 'eye-slash' : 'eye'" class="btn-eye" (click)="showPass = !showPass;" (paste)="blockPaste($event)"></fa>
            </div>
            <p class="pink-color" *ngIf="myform.controls.newPass.invalid && myform.controls.newPass.touched">על הסיסמא
                להיות באורך 6 תוים לפחות, ולכלול אות ומספר</p>
            <input class="form-control" type="password" formControlName="repeatPass" (paste)="blockPaste($event)" placeholder="אימות סיסמא"
                (keydown.enter)="submit()" [ngClass]="{'invalid': !fix && myform.controls.repeatPass.invalid}" />
            <button class="btn btn-blue btn-round d-block w-100 mx-auto" (click)="submit()" [disabled]="done">אישור</button>
        </div>
    </form>
</div>