import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../common/user.service';
import {Globals} from '../common/globals';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {Men, Person, Women} from '../entities/entities';
import * as  moment from 'moment-timezone';

@Component({
  selector: 'app-edit-notes-status',
  templateUrl: './edit-notes-status.component.html',
  styleUrls: ['./edit-notes-status.component.css']
})
export class EditNotesStatusComponent implements OnInit {
  statusLbl: Array<string> = ['לא פעיל', 'פעיל', 'התארסתי'];
  gender:string;
  person: Person;
  addMsg:boolean=false;
  isShadchan: boolean;


  constructor(private titleService: Title, private http: HttpClient, private globals: Globals, private router:Router, private userService: UserService) { }

  ngOnInit(): void {
    if (this.userService.getGender() == 1) {
      this.gender = "man";
      this.person=new Men();
    } else {
      this.gender = "woman";
      this.person =new Women();
    }
    this.isShadchan = this.userService.getIsShadchan();
    this.getPerson();
  }

  changeStatus(s: number) {
    if (confirm("האם הנך בטוח/ה שברצונך לשנות סטטוס?"))
      this.person.status = s;
  }

  getPerson(){
    console.log("edit-notes-status.component getPerson");

    this.http.get<Person>(this.globals.apiURL +this.gender+ `/getById/${this.userService.personId}?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, {
      headers: { 'Content-Type': 'application/json' }
    }).subscribe(data => {
        this.person=data;
    });
  }

  save(){
    const shadchanName = sessionStorage.getItem('shadchanName');
    const notesInfo = this.isShadchan && shadchanName ? `נערך על ידי ${shadchanName} ב- ${moment().format('DD/MM/YY HH:mm')}` : ''  ;
    this.http.put(this.globals.apiURL +this.gender+ `/setNotesStatus?status=${this.person.status}&notes=${this.person.notes}&notesInfo=${notesInfo}&id=${this.userService.personId}`+`&userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, {
      headers: { 'Content-Type': 'application/json' }
    }).subscribe(res => {
        console.log();
        this.addMsg=res.toString()=="1";
    });
  }

}
