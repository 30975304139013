//#region imports
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Globals } from '../common/globals';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AskAnswer, Asks, Answers, PrefAsks } from '../entities/AskAnswer';
import { Person, PersonFilter } from '../entities/entities';
import { Routes, RouterModule, Router, ActivatedRoute, Params } from '@angular/router'
import { UserService } from '../common/user.service';
//#endregion

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  asks: Array<Asks>;
  allAsks: Array<Asks>;
  answers: Array<Answers>;
  ansMap: Map<number, Array<Answers>>; //instead arr of Answers do arr of strings= gender
  @Input() personListRoot: Array<Person>;
  personList: Array<Person>;
  gender: string; otherGender: string; Gender: string;
  noResults: boolean = false;
  showFilter: boolean = false;
  filterAsks: Array<number>;
  thisYear: number= new Date().getFullYear();
  personFilter = new PersonFilter();
  @Output() filterClick = new EventEmitter();

  constructor(private http: HttpClient, private globals: Globals, private modalService: BsModalService, private router: Router, private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit() {
    this.filterAsks = [1, 2, 3, 4, 5, 9, 10, 11, 12, 15, 18, 19, 21, 22, 23, 24, 50,51]; //8,16 - age,height
    this.initUserData();
    this.getAskAnswer();
  }

  initUserData() {
    if (this.userService.getGender() == 1) {
      // opposite!
      this.gender = "woman";
      this.otherGender = "man";
      this.Gender = "Women";
    } else {
      this.gender = "man";
      this.otherGender = "woman";
      this.Gender = "Men";
    }
  }

  getAskAnswer() {
    console.log("getAskAnswer filter.component");

    this.http.get<AskAnswer>(this.globals.apiURL + `actions/getAsks?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, { responseType: 'json' })
      .subscribe(data => {
        this.asks = data.askList.concat(data.subAskList);
        this.answers = data.ansList;
        this.initDependentParams(this.asks, this.answers);
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  initDependentParams(asks, answers) {
    asks = asks.filter(o => o[this.gender] !== "0").sort((a, b) => (a.orderAll > b.orderAll) ? 1 : -1);
    this.allAsks = JSON.parse(JSON.stringify(asks.filter(o => o.isPublic || o.name == 'moneySum' || this.userService.getIsShadchan())));
    asks = asks.filter(({ id }) => this.filterAsks.includes(id));
    if(this.userService.getIsShadchan()){
      let a=new Asks();
      a.name="notes";
      this.allAsks.push(a);
    }
    console.log(this.allAsks);
    asks = asks.filter(o => o.isPublic || o.name == 'moneySum');
    if(asks['moneySum'] != undefined)
    asks[asks.findIndex(({ name }) => name == 'moneySum')][this.gender]="התחייבות כספית";
    answers = answers.filter(o => o[this.gender] !== "0");
    this.ansMap = answers.reduce(function (r, a) {
      r[a.idAsk] = r[a.idAsk] || []; r[a.idAsk].push(a); return r;
    }, Object.create(null));
    this.asks = asks;
    this.answers = answers;

  }
  //#endregion

  filter() {
    // this.personList=take from offer
    this.personList = JSON.parse(JSON.stringify(this.personListRoot));
    let personListMulti = null;
    let multiValue: Array<string>;
    let tmpArr: Array<string> = [];
    // Object.keys(this.personFilter).map(k => this.personFilter[k] = this.personFilter[k].trim());
    for (let attr in this.personFilter) { //transfer ng-selected to my personFilter structure
      if(!Array.isArray(this.personFilter[attr]) && typeof this.personFilter[attr]=='string')
        this.personFilter[attr]=this.personFilter[attr].trim();
      //if now its emptied
      if (this.personFilter[attr]!=null && this.globals.isDefined(this.personFilter[attr][0]) && typeof this.personFilter[attr][0] != "string") {
        tmpArr = [];
        for (let o of this.personFilter[attr])
          tmpArr.push(o[this.gender]);
        this.personFilter[attr] = tmpArr;
      }
    }
    let free=this.personFilter['free'];
    for (let attr in this.personFilter) {
      if (attr=="free" || this.personFilter[attr] == ""  || this.personFilter[attr]==null)
        continue;
      if (attr == 'tel1')
        this.personList = this.personList.filter(o => o[attr].includes(this.personFilter[attr]) || o['tel2'].includes(this.personFilter[attr]));
      else if (attr == 'minAge')
        this.personList = this.personList.filter(o => (this.thisYear-parseInt(o['birthdate'].split('-')[0])) >= this.personFilter[attr]);
      else if (attr == 'maxAge')
        this.personList = this.personList.filter(o => (this.thisYear-parseInt(o['birthdate'].split('-')[0])) <= this.personFilter[attr]);
      else if (attr == 'minHeight')
        this.personList = this.personList.filter(o => o['height'] >= this.personFilter[attr]);
      else if (attr == 'maxHeight')
        this.personList = this.personList.filter(o => o['height'] <= this.personFilter[attr]);
      else if (attr == 'moneySum')
        this.personList = this.personList.filter(o => o['moneySum'] >= this.personFilter[attr]);
      else if (typeof this.personFilter[attr] != "string") {
        personListMulti = this.personList.filter(o => o[attr].includes(', '));
        this.personList = this.personList.filter(o => this.personFilter[attr].includes(o[attr]) ||
                            (this.globals.isDefined(free) && o[attr].includes(free))); //free
        if (personListMulti != null) {
          for (let p of personListMulti) {
            multiValue = p[attr].split(', ');
            for (let v of multiValue)
              if (this.personFilter[attr].includes(v) || (this.globals.isDefined(free) && v.includes(free)))//free
                if (this.personList.filter(id => id == p.id).length == 0) // if not exists - add it
                  this.personList.push(p);
          }
        }
      }
      else
        this.personList = this.personList.filter(o => o[attr].includes(this.personFilter[attr] || (this.globals.isDefined(free) && o[attr].includes(free))));
    }
    let found:boolean=false;
    if(this.globals.isDefined(free)){
      for (let p of this.personList) {
        found=false;
        console.log(p);
        for (let a of this.allAsks) {
          console.log(a.name+":  "+p[a.name]);
          if(this.globals.isDefined(p[a.name]) && typeof p[a.name]=="string" && p[a.name].includes(free))
            {found=true; break;}
        }
          if(!found)
            this.personList=this.personList.filter(({id}) => id != p.id);
        }
      }
    this.filterClick.emit(this.personList);
  }

  clear(onlyBar: boolean) {
    this.personFilter = new PersonFilter();
    if (!onlyBar)
      this.filterClick.emit(this.personListRoot);
  }

}
