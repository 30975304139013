import { Component, OnInit } from '@angular/core';
import { Globals } from '../common/globals';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { User, UsersGroups, Groups } from '../entities/entities';
import { UserService } from '../common/user.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {
  selected: number = 0;
  action: string = '';
  groupName: string = '';
  newName: string = '';
  search: string = '';
  msg: string;
  groupId:number;
  noResults: boolean = false;
  addMsg: boolean = false;
  groupList: Array<Groups>;
  shadchanList: Array<User>;
  sInG:Array<ShadchansInGroups>;
  shadchanInGroupList: Array<User> = new Array<User>();
  actionTitle = {'add': 'הוספה', 'edit': 'עריכה','del': 'מחיקה','show': 'צפיה' };


  constructor(private http: HttpClient, private globals: Globals,private userService: UserService) { }

  ngOnInit(): void {
    this.action ='show';
    this.getShadchanInGroups();
  }

  getShadchanList() {
    this.http.get<Array<User>>(this.globals.apiURL + `shadchan/get/listGroups`+`?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, { responseType: 'json' })
      .subscribe(data => {
        this.shadchanList = data;
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  getShadchanInGroups() {
    this.http.get<Array<ShadchansInGroups>>(this.globals.apiURL + `shadchan/get/inGroups`+`?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, { responseType: 'json' })
      .subscribe(data => {
        console.log(data);
        this.sInG=data;
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  init(action:string,removeMsg:boolean) {
    this.action = action;
    if(removeMsg){
      this.addMsg = false;
      this.msg = '';
    }
    if(action =='show')
      {this.getShadchanInGroups(); return;}
    this.getShadchanList();
    if(action!='add'){
      this.getGroupList();
      this.groupName = 'בחר קבוצה';
      this.newName = '';
    }
    else{
      this.groupName ='';
      this.shadchanInGroupList=new Array<User>();
    }
  }

  getGroupList() {
    this.http.get<Array<Groups>>(this.globals.apiURL + `get/groupList?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, { responseType: 'json' })
      .subscribe(data => {
        this.groupList = data;
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  getUsersInGroup(id: number) {
    this.http.get<Array<User>>(this.globals.apiURL + `get/usersByGroup/${id}?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`, { responseType: 'json' })
      .subscribe(data => {
        this.shadchanInGroupList = data;
        this.selected = 0;
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  addShadchan(id: number) {
    if (id != 0) {
      this.shadchanInGroupList.push(this.shadchanList.filter(o => o.id == id)[0]);
      this.selected = 0;
    }
  }

  delShadchan(id: number) {
    this.selected = 0;
    this.shadchanInGroupList = this.shadchanInGroupList.filter(o => o.id != id);
  }

  setGroup(g: Groups) {
    this.selected = 0;
    this.groupName = g.name;
    this.groupId = g.id;
    this.getUsersInGroup(g.id);
    this.addMsg = false;
  }

  submit() {
    let path;
    if (this.action == 'add') {
      if(this.groupName=='')
        return;
      let ids: string = this.shadchanInGroupList.map(o => o.id).toString();
      this.addMsg= ids=='';
      if(ids=='' && !confirm('לא נבחרו שדכניות. האם ברצונך ליצור קבוצה ריקה?'))
         {this.addMsg=false; return;}
      path = `shadchan/set/usersNewGroup?stId=${ids}&gName=${this.groupName}`;
    }
    else if (this.action == 'edit'){
      let ids: string = this.shadchanInGroupList.map(o => o.id).toString();
      path = `shadchan/set/usersGroup?stId=${ids}&idG=${this.groupId}&gName=${this.newName}&userIDRequested=${this.userService.id}-${this.userService.firstName}-${this.userService.lastName}`;
    }
    else if (this.action == 'del'){
      if(!this.globals.isDefined(this.groupId) || !window.confirm("האם למחוק את הקבוצה?"))
        return;
      let ids: string = this.shadchanInGroupList.map(o => o.id).toString();
      path = `shadchan/set/delGroup?idG=${this.groupId}`;
    }
    this.http.post(this.globals.apiURL + path, {
      withCredentials: true
      // headers: { 'Content-Type': 'application/json' }
    }).subscribe(response => {
      console.log(response.toString());
      this.addMsg = true;
      if (response.toString() == "0")
        this.msg = "קיימת כבר קבוצה בשם זה";
      else if (this.action == 'add')
        this.msg = "ההוספה בוצעה בהצלחה!";
      else if (this.action == 'edit')
        this.msg = "העריכה בוצעה בהצלחה!";
      else
        this.msg = "הקבוצה נמחקה!";
      this.init(this.action,false);
    });  //add throwError
  }

}

export class ShadchansInGroups{
  gName:string;
  sNames:string;
}
