import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { firebaseConfig } from '../firebase/environment';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

import {
  AtomSpinnerModule,
  FulfillingBouncingCircleSpinnerModule,
  HalfCircleSpinnerModule,
  OrbitSpinnerModule,
} from 'angular-epic-spinners';
import { Cloudinary } from 'cloudinary-core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { heLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FontAwesomeModule } from 'ngx-icons';
import { AddImgComponent } from './add-img/add-img.component';
import { AddManStepsComponent } from './add-man-steps/add-man-steps.component';
import { AddManComponent } from './add-man/add-man.component';
import { AddMultiUserMeshudachComponent } from './add-multi-user-meshudach-folder/add-multi-user-meshudach.component';
import { AddShadchanComponent } from './add-shadchan/add-shadchan.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AdminFolderComponent } from './admin-folder/admin-folder.component';
import { AlertsUserComponent } from './alerts-user/alerts-user.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './common/auth-guard';
import { CanDeactivateGuard } from './common/can-deactivate-guard';
import { Globals } from './common/globals';
import { LoaderInterceptor } from './common/loader.interceptor';
import { LoaderService } from './common/loader.service';
import { DateWithHebComponent } from './date-with-heb/date-with-heb.component';
import { DbManageComponent } from './db-manage/db-manage.component';
import { DelShadchanComponent } from './del-shadchan/del-shadchan.component';
import { EditNotesStatusComponent } from './edit-notes-status/edit-notes-status.component';
import { FamilyStatusArrs } from './entities/familyStatusAsks';
import { FamilyStatusAsksComponent } from './family-status-asks/family-status-asks.component';
import { FilesComponent } from './files/files.component';
import { FilterPairComponent } from './filter-pair/filter-pair.component';
import { FilterComponent } from './filter/filter.component';
import { FreeSearchComponent } from './free-search/free-search.component';
import { GroupsComponent } from './groups/groups.component';
import { InfoPagesComponent } from './info-pages/info-pages.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginComponent } from './login/login.component';
import { LogoMenuMobileComponent } from './logo-menu-mobile/logo-menu-mobile.component';
import { LogoMenuComponent } from './logo-menu/logo-menu.component';
import { NotesComponent } from './notes/notes.component';
import { OfferPairComponent } from './offer-pair/offer-pair.component';
import { OfferComponent } from './offer/offer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PasswordComponent } from './password/password.component';
import { AdvancedSearchPipe } from './pipes/advanced-search.pipe';
import { FilterSortPipe } from './pipes/filter-sort.pipe';
import { MyOfferFilterPipe } from './pipes/my-offer-filter.pipe';
import { TblFreeSearchPipe } from './pipes/tbl-free-search.pipe';
import { RemoveMeComponent } from './remove-me/remove-me.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SettingComponent } from './setting/setting.component';
import { ShadchanFolderComponent } from './shadchan-folder/shadchan-folder.component';
import { SystemAlertsComponent } from './system-alerts/system-alerts.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserFolderComponent } from './user-folder/user-folder.component';
import { UserSidebarMobileComponent } from './user-sidebar-mobile/user-sidebar-mobile.component';
import { UserUnavailableComponent } from './user-unavailable/user-unavailable.component';
import { UsersManageComponent } from './users-manage/users-manage.component';
import { UsersReportsComponent } from './users-reports/users-reports.component';
import { ShadchanNotesComponent } from './shadchan-notes/shadchan-notes.component';
import { ChatComponent } from './chat/chat.component';
import { ShadchanListComponent } from './shadchan-list/shadchan-list.component';
import { ImageService } from './common/img.service';
import { DndDirective } from './chat/dnd.directive';
import { ShdchanReportComponent } from './shdchan-report/shdchan-report.component';
import { CallbackPipe } from './pipes/callback.pipe';
defineLocale('he', heLocale);

@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
    AddManStepsComponent,
    FamilyStatusAsksComponent,
    FamilyStatusAsksComponent,
    AddManComponent,
    AddImgComponent,
    LoginComponent,
    LoaderComponent,
    AddUserComponent,
    UserDetailsComponent,
    UserFolderComponent,
    ShadchanFolderComponent,
    PageNotFoundComponent,
    ShadchanNotesComponent,
    PasswordComponent,
    OfferComponent,
    NotesComponent,
    OfferPairComponent,
    LogoMenuComponent,
    FilterComponent,
    MyOfferFilterPipe,
    FreeSearchComponent,
    AdvancedSearchPipe,
    CallbackPipe,
    AdminFolderComponent,
    SettingComponent,
    AddShadchanComponent,
    GroupsComponent,
    UsersManageComponent,
    FilterSortPipe,
    UserUnavailableComponent,
    SystemAlertsComponent,
    FilterPairComponent,
    TblFreeSearchPipe,
    AlertsComponent,
    AlertsUserComponent,
    DbManageComponent,
    ResetPasswordComponent,
    DelShadchanComponent,
    EditNotesStatusComponent,
    RemoveMeComponent,
    DateWithHebComponent,
    LogoMenuMobileComponent,
    UserSidebarMobileComponent,
    InfoPagesComponent,
    FilesComponent,
    AddMultiUserMeshudachComponent,
    UsersReportsComponent,
    ShadchanListComponent,
    DndDirective,
    ShdchanReportComponent,
  ],
  imports: [
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideFirestore(() => getFirestore()),
    NgbDatepickerModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    OrbitSpinnerModule,
    AtomSpinnerModule,
    HalfCircleSpinnerModule,
    FulfillingBouncingCircleSpinnerModule,
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    ButtonsModule.forRoot(),
    TabsModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    AccordionModule.forRoot(),
    NgSelectModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    CloudinaryModule.forRoot(
      { Cloudinary },
      { cloud_name: 'hgchqoeaz', upload_preset: 'my_preset' }
    ),
  ],
  providers: [
    Globals,
    FamilyStatusArrs,
    Title,
    CanDeactivateGuard,
    AuthGuard,
    LoaderService,
    LoaderService,
    ImageService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
