import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, ViewChild } from '@angular/core';

import { inject } from '@angular/core';
import {
  CollectionReference,
  Firestore,
  collection,
  collectionChanges,
  query,
} from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import {
  DocumentChange,
  Timestamp,
  addDoc,
  deleteDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import * as moment from 'moment-timezone';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, concat, map, of, switchMap } from 'rxjs';
import { Globals } from 'src/app/common/globals';
import { UserService } from 'src/app/common/user.service';
import { ChatService } from '../common/chat.service';
import { User } from '../entities/entities';
import { Chat, ChatType, Message } from './interface';
import { ImageService } from '../common/img.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnDestroy {
  selectedImage: string = '';
  selectedMessage: Message;

  onFileDropped($event) {
    if (this.selectedChat) {
      this.addImg({ target: { files: $event } });
    }
  }

  allRead(message: Message) {
    return !message?.notRead.filter((nr) => nr !== this.me)?.length;
  }
  ngOnDestroy() {
    if (this.msgSubscription) {
      this.msgSubscription.unsubscribe();
    }
    if (this.chatSubscription) {
      this.chatSubscription.unsubscribe();
    }
  }

  isAdmin: boolean;
  msgSubscription: Subscription;
  chatSubscription: Subscription;

  loginUser: User;

  @ViewChild('input') input;

  modalRef: BsModalRef;
  @ViewChild('createChat') createChat;

  @ViewChild('viewImage') viewImage;

  shadchanList: Array<User>;
  selectedTo: number[];

  messages$: Message[] = [];
  chats$: Chat[] = [];

  selectedChat: Chat;
  chatsMembers: Map<number, User> = new Map();

  firestore: Firestore = inject(Firestore);
  messageCollation: CollectionReference<Message>;
  chatCollection: CollectionReference<Chat>;
  isPersonChat = false;
  chatWith: number;
  constructor(
    public userService: UserService,
    public chatService: ChatService,
    private modalService: BsModalService,
    private http: HttpClient,
    private globals: Globals,
    private config: NgSelectConfig,
    private router: Router,
    private route: ActivatedRoute,
    public imgService: ImageService
  ) {
    this.isPersonChat = this.route.snapshot.params?.isPersonChat;

    this.chatWith = this.route.snapshot.queryParams.chatWith;

    this.messageCollation = collection(
      this.firestore,
      'messages'
    ) as CollectionReference<Message>;

    this.chatCollection = collection(
      this.firestore,
      'chats'
    ) as CollectionReference<Chat>;

    this.loginUser = userService.loginUser;

    this.config.notFoundText = 'לא נמצא בשם הזה';
    this.chatsMembers.set(this.loginUser.id, this.loginUser);
    if (this.chatWith) {
      this.onCreateChat([+this.chatWith], ChatType.USER_SHADCHAN);
    }
  }

  addImg($event: any) {
    this.imgService.addImg($event).then((fileName: string) => {
      this.onSend(fileName);
    });
  }

  get sortChats() {
    return this.chats$.sort((a, b) => {
      return (
        ((b?.dateModify || b?.dateCreated) as Timestamp)?.toMillis() -
        ((a?.dateModify || a?.dateCreated) as Timestamp)?.toMillis()
      );
    });
  }

  getShadchanList(updateChatMembers = false) {
    if (!!this?.shadchanList) {
      return;
    }
    this.http
      .get<Array<User>>(this.globals.apiURL + `shadchan/get/allShadchan`, {
        responseType: 'json',
      })
      .subscribe(
        (data) => {
          this.shadchanList = data;
          if (updateChatMembers) this.updateChatMembers();
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  isMe(message: any) {
    return message.sentBy === this.loginUser.id;
  }

  public get getShadchanMap(): any {
    return this.shadchanList.map((s) => ({
      value: s.id,
      label: s.firstName + ' ' + s.lastName,
    }));
  }

  toTimeStr(timestemp: Timestamp) {
    return moment(timestemp.toDate()).format('DD/MM/YY HH:mm');
  }

  public get me() {
    return this.loginUser.id;
  }

  getMemberName(id: number) {
    const member = this?.chatsMembers?.get(id);
    if (!member) {
      return this?.chatsMembers?.size ? 'משתמש שנמחק' : '';
    }
    return (
      member.firstName +
      ' ' +
      member.lastName +
      ' ' +
      this.getMemberTitle(member)
    );
  }

  getMemberTitle(member: User) {
    if (member.id === 123) {
      return '(מנהל)';
    }
    if (member.id === this.me) {
      return '(אני)';
    } else if (member.isShadchan) {
      if (member.gender === 1) {
        return '(שדכן)';
      } else {
        return '(שדכנית)';
      }
    } else {
      if (member.gender === 2) {
        return '(משודכת)';
      } else {
        return '(משודך)';
      }
    }
  }

  filter() {
    return (m: number) => m !== this.me;
  }

  getChatMembers(chat: Chat) {
    return chat.members.filter((m: number) => m !== this.me);
  }

  getChatUsers(chat: Chat) {
    const members = this.getChatMembers(chat);
    return [...this.chatsMembers.values()].filter((cm, i) =>
      members.includes(cm.id)
    );
  }

  updateChatMembers() {
    const allMembers = this.chats$
      .reduce((c, s) => c.concat(s.members), [])
      .filter((m) => !this.chatsMembers.has(m));

    of(allMembers)
      .pipe(
        switchMap((members) => {
          const inShadchans = this.shadchanList.filter((s) =>
            members.includes(s.id)
          );
          const notInShadchans = members.filter(
            (m) => !inShadchans.map((u) => u.id).includes(m)
          );

          if (notInShadchans.length) {
            const sub = this.http.post<Array<User>>(
              this.globals.apiURL + `users/getPersons`,
              {
                ids: notInShadchans,
              }
            );
            return concat(of(inShadchans), sub);
          }
          return of(inShadchans);
        })
      )
      .subscribe((users) => {
        users.forEach((user) => {
          //@ts-ignore
          const id = user?.idUser || user?.id;
          this.chatsMembers.set(id, user);
        });
      });
  }

  ngOnInit(): void {
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true';

    this.getShadchanList();
    this.chatSubscription = collectionChanges<Chat>(
      query<Chat>(
        this.chatCollection,
        where('members', 'array-contains', this.me)
      )
    )
      .pipe(
        map((s) => {
          return this.parseDoc(s);
        })
      )
      .subscribe({
        next: (data) => {
          this.chats$ = this.replaceOld(data, this.chats$);
          if (!this?.shadchanList) {
            this.getShadchanList(true);
          } else {
            this.updateChatMembers();
          }
        },
        error: () => {
          if (!this?.shadchanList) {
            this.getShadchanList(true);
          }
        },
      });
  }

  parseDoc(s: DocumentChange[]) {
    return s.map((c) => {
      const data = c.doc.data();
      const obj = {
        type: c.type,
        id: c.doc.id,
        ref: c.doc.ref,
        ...data,
      };

      if (c.type === 'added' && c.doc.ref.path.startsWith('messages/')) {
        if (this.messages$.length) {
          this.scrollBottom();
        } else {
          this.placeInBottom();
        }
      }

      return obj;
    });
  }

  replaceOld(newData: any[], oldData: any[]) {
    return oldData
      .filter((om) => !newData.map((nm) => nm.id).includes(om.id))
      .filter(
        (om) => !newData.find((d) => d.type === 'removed' && d.id == om.id)
      )
      .concat(newData.filter((d) => d.type !== 'removed'))
      .sort((a, b) => {
        return a?.dateCreated?.toMillis() - b?.dateCreated?.toMillis();
      });
  }

  scrollToMessage(id: string) {
    const element = document?.getElementById(id);
    element.classList.remove('replay-container--blink');
    if (element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      element.classList.add('replay-container--blink');
    }
  }

  placeInBottom() {
    var element = document.querySelector('.message_container');
    element.classList.add('message_container-hide');
    setTimeout(() => {
      element.scrollTo({ top: element.scrollHeight, behavior: 'auto' });
      element.classList.remove('message_container-hide');
    }, 300);
  }

  scrollBottom() {
    setTimeout(() => {
      var element = document.querySelector('.message_container');
      element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
    }, 200);
  }

  onChat(chatRef: Chat): void {
    this.onCloseReplay();
    if (this.msgSubscription) {
      this.msgSubscription.unsubscribe();
    }
    this.messages$ = [];
    this.selectedChat = chatRef;
    this.msgSubscription = collectionChanges<Message>(
      query<Message>(this.messageCollation, where('chat', '==', chatRef.ref))
    )
      .pipe(map((s) => this.parseDoc(s)))
      .subscribe((data) => {
        this.messages$ = this.replaceOld(data, this.messages$);
        const batch = writeBatch(this.firestore);
        this.messages$
          .filter((m) => {
            return !!m.notRead.find((m) => m === this.me);
          })
          .forEach((m) => {
            batch.update(m.ref, {
              notRead: m.notRead.filter((m) => m !== this.me),
            });
          });
        batch.commit();
        this.messages$ = this.messages$.map((m) => {
          return {
            ...m,
            replayMessage: this.messages$.find(
              (rm) => rm.id === m?.replayMessage?.id
            ),
          };
        });
      });
  }

  onRemoveMessage(message: Message) {
    deleteDoc(message.ref).then(() => {
      if (message.uri) {
        return this.imgService.dell(message.uri);
      }
    });
  }

  onRemoveChat(chat: Chat) {
    deleteDoc(chat.ref).then(() => {
      this.selectedChat = undefined;
      collectionChanges<Message>(
        query<Message>(this.messageCollation, where('chat', '==', chat.ref))
      ).subscribe({
        next: (docs) => {
          const batch = writeBatch(this.firestore);
          docs.forEach((doc) => {
            batch.delete(doc.doc.ref);
          });
          batch.commit();
        },
      });
    });
  }

  onSend(uri?: string) {
    const body = uri || this.input.nativeElement?.value;
    if (!body) {
      return;
    }
    const to = this.selectedChat.members.filter((m) => m !== this.me);
    addDoc(
      this.messageCollation,
      Object.assign(
        {
          dateCreated: new Date(),
          //@ts-ignore
          [uri ? 'uri' : 'body']: body,
          chat: this.selectedChat.ref,
          sentBy: this.me,
          notRead: to,
        },
        this.selectedMessage && { replayMessage: this.selectedMessage?.ref }
      )
    )
      .then(() => {
        this.input.nativeElement.value = '';
        this.onCloseReplay();
        return updateDoc(this.selectedChat.ref, { dateModify: new Date() });
      })
      .then(() => {
        const from = this.chatsMembers?.get?.(this.me);

        [...this.chatsMembers.values()]
          .filter((m) => to.includes(m.id))
          .forEach((user) => {
            const lastMessage = this.messages$?.[this.messages$.length - 2];
            if (!lastMessage || !lastMessage?.notRead?.includes?.(user.id)) {
              this.userService.sendEmail(
                user,
                'שידוכים כהלכה: הודעה חדשה מחכה לכם',
                '<p>הודעת צ׳אט חדשה מחכה לכם</p>' +
                  `<p> הודאה מ: ${from?.firstName} ${from?.lastName}</p>` +
                  (!user.isShadchan && user.id !== 123
                    ? '<p>ההודאות נמצאות תחת הלשונית ׳צאט עם שדכנ/ית׳</p>'
                    : '') +
                  '<p>לינק למערכת [link]</p>'
              );
            }
          });
      });
  }

  onViewImage(url) {
    this.selectedImage = url;
    this.modalRef = this.modalService.show(this.viewImage, {
      class: 'modal-lg',
      backdrop: 'static',
    });
  }

  onHideViewImage() {
    this?.modalRef?.hide();
    this.selectedImage = '';
  }

  openModal() {
    this.selectedTo = [];
    this.modalRef = this.modalService.show(this.createChat, {
      class: 'modal-lg',
      backdrop: 'static',
    });
  }

  onCreateChat(members: number[], chatType = ChatType.SHADCHANIM) {
    this?.modalRef?.hide();
    addDoc(this.chatCollection, {
      dateCreated: new Date(),
      chatType: ChatType.SHADCHANIM,
      subject: '',
      createBy: this.me,
      members: [...members, this.me],
    }).then((doc) => {
      this.messages$ = [];
      setTimeout(() => {
        this.selectedChat = this.chats$.find((c) => c.id === doc.id);
        this.onChat(this.selectedChat);
      }, 500);
    });
  }

  openLink(userId: number) {
    const user = this.chatsMembers?.get(userId);
    let url;
    if (user.id === 123) {
      return;
    }
    if (user?.isShadchan) {
      if (this.userService.isShadchan) {
        this.router.navigate(['shadchan-folder/shadchan-list'], {
          queryParams: { id: userId },
        });
      }
    } else {
      this.userService.setLoginFirstName(this.userService.firstName);
      this.userService.setLoginLastName(this.userService.lastName);
      this.userService.setGender(user.gender);
      this.userService.setFirstName(user.firstName);
      this.userService.setLastName(user.lastName);

      this.http
        .get<number>(
          this.globals.apiURL +
            `users/getPersonId/${user.id}/${user.gender}?userIDRequested=${this.userService.id}-${this.userService.loginFirstName}-${this.userService.loginLastName}`
        )
        .subscribe((data) => {
          let personId = data;
          this.userService.personId = personId;

          sessionStorage.setItem(
            'currentUser',
            JSON.stringify(this.userService)
          );
          // console.log(sessionStorage.getItem('currentUser'));
          url = this.router.serializeUrl(
            this.router.createUrlTree([`user-folder/user-details`])
          );
          window.open('/#' + url, '_blank');
        }); //add throwError
    }
  }

  term: string = '';

  selectShadchanList() {
    return this.shadchanList?.filter?.((u) => u.id !== this.me);
  }

  changed(term: string, item: any) {
    this.term = term?.length > 2 ? term : '';
    const show =
      this.shadchanList
        ?.filter((s) => s.id === item)
        .filter((s) => (s.firstName + ' ' + s.lastName).includes(term)).length >
      0;
    return term?.length > 2 && show;
  }

  onClose() {
    this.term = '';
    this.modalRef.hide();
  }

  add() {
    this.term = '';
  }

  onReplay(message: Message) {
    this.selectedMessage = message;
  }
  onCloseReplay() {
    this.selectedMessage = undefined;
  }
}
