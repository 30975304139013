<div class="d-flex flex-column container">
  <div class="text-info">התוצאות ינתנו ביחס לתאריכים*</div>
  <div
    class="d-flex align-items-center justify-content-between px-5 py-3 mb-2 pt-0"
  >
    התחלה:
    <input
      class="form-control mr-1 ml-5"
      placeholder="התאריך התחלה"
      bsDatepicker
      #d
      [bsConfig]="{
        containerClass: 'theme-blue',
        startView: 'year',
        dateInputFormat: 'DD/MM/YYYY',
        showWeekNumbers: false
      }"
      [bsValue]="startValue"
      (bsValueChange)="selectStartDate($event)"
    />
    סיום:
    <input
      class="form-control mr-1"
      placeholder="תאריך סיום"
      bsDatepicker
      #d
      [bsConfig]="{
        containerClass: 'theme-blue',
        startView: 'year',
        dateInputFormat: 'DD/MM/YYYY',
        showWeekNumbers: false
      }"
      [bsValue]="endValue"
      (bsValueChange)="selectEndDate($event)"
    />
    <button class="btn btn-blue mr-5" (click)="search()">
      <!-- סינון<fa name="filter right-space"></fa> -->
      <fa name="search"></fa>חיפוש
    </button>
  </div>
  <div class="d-flex flex-column">
    <table *ngIf="adminStatistics" class="table">
      <thead>
        <tr>
          <th>מספר שהצטרפו</th>
          <th>גברים שהצטרפו</th>
          <th>נשים שהצטרפו</th>
          <th>שהצטרפו לבד</th>
          <th>שלא סיימו הרשמה</th>
          <th>שעברו ללא פעיל</th>
          <th>שעברו להתארסו</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="link" (click)="getPersonStatistics('totalAdded')">
              {{ adminStatistics?.totalAdded }}
            </div>
          </td>
          <td>
            <div class="link" (click)="getPersonStatistics('manAdded')">
              {{ adminStatistics?.manAdded }}
            </div>
          </td>
          <td>
            <div class="link" (click)="getPersonStatistics('womanAdded')">
              {{ adminStatistics?.womanAdded }}
            </div>
          </td>
          <td>
            <div class="link" (click)="getPersonStatistics('addedAlone')">
              {{ adminStatistics?.addedAlone }}
            </div>
          </td>
          <td>
            <div class="link" (click)="getPersonStatistics('addedNotEnd')">
              {{ adminStatistics?.addedNotEnd }}
            </div>
          </td>
          <td>
            <div class="link" (click)="getPersonStatistics('movedToNonActive')">
              {{ adminStatistics?.movedToNonActive }}
            </div>
          </td>
          <td>
            <div class="link" (click)="getPersonStatistics('movedToErusin')">
              {{ adminStatistics?.movedToErusin }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="tableFixHead">
      <table
        *ngIf="shadchanStatistics.length"
        style="margin-bottom: 100"
        class="table"
      >
        <thead>
          <tr>
            <th>
              <div class="d-flex">
                <div>השדכני/ת</div>
                <input [(ngModel)]="shadchanFilter" class="w-100 mr-5" />
              </div>
            </th>
            <th>משודכים שיצר/ה</th>
            <th>שידוכים בטיפולי</th>
            <th>עברו לבירורים</th>
            <th>עברו לפגישות</th>
            <th>לא התאימו</th>
          </tr>
        </thead>
        <tbody class="statistics-tbody">
          <tr
            *ngFor="
              let statistics of shadchanStatistics
                | callback : filterUser.bind(this)
            "
          >
            <td>
              {{ statistics?.user.firstName }} {{ statistics?.user.lastName }}
            </td>
            <td>
              <div class="link" (click)="personOfShadchan(statistics?.user.id)">
                {{ statistics?.myPersons }}
              </div>
            </td>
            <td>
              <div
                class="link"
                (click)="getOffers('shadchanUsers', statistics?.user.id)"
              >
                {{ statistics?.myCare }}
              </div>
            </td>
            <td>
              <div
                class="link"
                (click)="getOffers('check', statistics?.user.id)"
              >
                {{ statistics?.movedCheck }}
              </div>
            </td>
            <td>
              <div
                class="link"
                (click)="getOffers('meet', statistics?.user.id)"
              >
                {{ statistics?.movedMeet }}
              </div>
            </td>
            <td>
              <div
                class="link"
                (click)="getOffers('finallyNo', statistics?.user.id)"
              >
                {{ statistics?.movedNotMatch }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #offersModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <button
        type="button"
        class="close pull-left"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div
        class="offer-box"
        id="p{{ person.id }}"
        *ngFor="let person of personList"
      >
        <div
          *ngIf="isMobile && offersType == 'sent' && person['forMe']"
          class="reaction"
        ></div>
        <div [ngClass]="{ 'd-flex': !isMobile }">
          <div class="profile-container" *ngIf="!isMobile">
            <div
              [class]="person.zakan !== undefined ? 'male-img' : 'female-img'"
            >
              <app-add-img
                *ngIf="person.img != null"
                [onlyShow]="true"
                [userImg]="person.img"
              ></app-add-img>
            </div>
            <button
              class="btn btn-round btn-blue"
              (click)="goToPersonFolder(person, 'user-details')"
            >
              לפרופיל המלא<fa name="address-card right-space"></fa>
            </button>
            <p class="p-name">{{ person.firstName }} {{ person.lastName }}</p>
            <p class="p-name pink-color">{{ person["statusDesc"] }}</p>
            <p
              *ngIf="person.status != 1 && !person['statusDesc']"
              class="pink-color text-center isActive"
            >
              ({{ person.status == 0 ? "לא פעיל" : "התארס/ה" }})
            </p>
          </div>
          <div>
            <div class="inner-container" [ngClass]="{ 'row-flex': !isMobile }">
              <!-- <div *ngFor="let ask of asks; let i=index" class="d-flex"  [ngClass]="isMobile?'w-100':'w-50'"> -->
              <div
                [ngClass]="{ 'w-50': !isMobile }"
                [ngStyle]="{ margin: isMobile ? 'auto' : '0' }"
              >
                <label class="key-label">תאריך הצטרפות:</label>
                <label class="value-label">{{ person.joinDate }}</label>
              </div>
              <div
                *ngFor="let ask of asks; let i = index"
                class="d-flex"
                [ngClass]="{ 'w-50': !isMobile }"
                [ngStyle]="{ margin: isMobile ? 'auto' : '0' }"
              >
                <div *ngIf="ask.name != 'israelID'">
                  <label class="key-label"
                    >{{ ask[person.zakan !== undefined ? "man" : "woman"]
                    }}{{
                      ask[person.zakan !== undefined ? "man" : "woman"].slice(
                        -1
                      ) == "?"
                        ? ""
                        : ":"
                    }}</label
                  >
                  <label
                    *ngIf="ask.typeInput != 5"
                    class="value-label label-{{ ask.name }}"
                    >{{ person[ask.name] }}</label
                  >
                  <label
                    *ngIf="ask.typeInput == 5"
                    class="value-label label-{{ ask.name }}"
                    >{{ person[ask.name] == 1 ? "כן" : "לא" }}</label
                  >
                </div>
              </div>
              <div
                *ngIf="
                  person.familyStatus == 'גרוש' ||
                  person.familyStatus == 'גרושה' ||
                  person.familyStatus == 'אלמן' ||
                  person.familyStatus == 'אלמנה'
                "
                [ngClass]="{ 'w-50': !isMobile }"
                [ngStyle]="{ margin: isMobile ? 'auto' : '0' }"
              >
                <label class="key-label">מספר ילדים:</label>
                <label class="value-label">{{
                  person["numKids"] == -1 || person["numKids"] == null
                    ? "0"
                    : person["numKids"]
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="offer-box" *ngFor="let pair of pairsList">
        <div
          class="d-flex p-container"
          *ngIf="pair.man != null && pair.woman != null"
        >
          <div class="man-container w-50 d-flex">
            <div class="profile-container">
              <div class="male-img">
                <app-add-img
                  *ngIf="pair.man.img != null"
                  [onlyShow]="true"
                  [userImg]="pair.man.img"
                ></app-add-img>
              </div>
              <p>{{ pair.man.tel1 }}</p>
              <p style="margin-top: -12px">{{ pair.man.tel2 }}&nbsp;</p>
              <button
                (click)="goToUserFolder(pair.man, 1)"
                class="btn btn-round btn-blue d-block mx-auto"
              >
                לפרופיל המלא
              </button>
            </div>
            <div class="inner-container">
              <div class="profile-title">
                {{ pair.man.firstName }} {{ pair.man.lastName }},
                {{ pair.man.age }}<br />
                {{ pair.man.religiousLevel }} | {{ pair.man.familyStatus
                }}<br />{{ pair.man.city }}
                <span
                  *ngIf="pair.man.status != 1 && offersType != 'mzlt'"
                  class="pink-color isActive"
                  >( {{ pair.man.status == 0 ? "לא פעיל" : "התארס" }})
                </span>
              </div>
              <div *ngFor="let ask of asksMen; let i = index" class="d-flex">
                <label class="key-label"
                  >{{ ask.man }}{{ ask.man.slice(-1) == "?" ? "" : ":" }}</label
                >
                <label
                  *ngIf="ask.typeInput != 5"
                  class="value-label label-{{ ask.name }}"
                  >{{ pair.man[ask.name] }}</label
                >
                <label
                  *ngIf="ask.typeInput == 5"
                  class="value-label label-{{ ask.name }}"
                  >{{ pair.man[ask.name] == 1 ? "כן" : "לא" }}</label
                >
              </div>
              <div class="w-100"></div>
            </div>
          </div>
          <div class="border-left" style="margin-top: 25px"></div>
          <div class="woman-container w-50 d-flex">
            <div class="profile-container">
              <div class="female-img">
                <app-add-img
                  *ngIf="pair.woman.img != null"
                  [onlyShow]="true"
                  [userImg]="pair.woman.img"
                ></app-add-img>
              </div>
              <p>{{ pair.woman.tel1 }}</p>
              <p style="margin-top: -12px">{{ pair.woman.tel2 }}&nbsp;</p>
              <button
                (click)="goToUserFolder(pair.woman, 2)"
                class="btn btn-round btn-blue d-block mx-auto"
              >
                לפרופיל המלא
              </button>
            </div>
            <div class="inner-container">
              <div class="profile-title">
                {{ pair.woman.firstName }} {{ pair.woman.lastName }},
                {{ pair.woman.age }}<br />
                {{ pair.woman.religiousLevel }} | {{ pair.woman.familyStatus
                }}<br />{{ pair.woman.city }}
                <span
                  *ngIf="pair.woman.status != 1 && offersType != 'mzlt'"
                  class="pink-color isActive"
                  >({{ pair.woman.status == 0 ? "לא פעיל" : "התארסה" }})</span
                >
              </div>
              <div *ngFor="let ask of asksWomen; let i = index" class="d-flex">
                <label class="key-label"
                  >{{ ask.woman
                  }}{{ ask.woman.slice(-1) == "?" ? "" : ":" }}</label
                >
                <label
                  *ngIf="ask.typeInput != 5"
                  class="value-label label-{{ ask.name }}"
                  >{{ pair.woman[ask.name] }}</label
                >
                <label
                  *ngIf="ask.typeInput == 5"
                  class="value-label label-{{ ask.name }}"
                  >{{ pair.woman[ask.name] == 1 ? "כן" : "לא" }}</label
                >
              </div>
              <div class="w-100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modalRef.hide()"
      >
        סגירה
      </button>
    </div>
  </div>
</ng-template>
