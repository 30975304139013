<div *ngFor="let ask of asks">
  <div *ngIf="!ask.idParent || (asks[ask.idParent-1].answer=='1'
    && (!asks[ask.idParent-1].idParent || asks[asks[ask.idParent-1].idParent-1].answer=='1'))">
    <!-- prev line for only contact times -->
    <div >
      <div class="row-form" [ngClass]="{'invalid': !validFlag && ask.required && ask.answer==''}">
  <fa *ngIf="ask.name!='hasKids' && ask.name!='numKids'" name="eye-slash pink-color left-space"></fa>
  <label class="label-{{ask.name}} ml-15">{{ask[person]}}</label>
  <!-- <label *ngIf="ask.name=='hasKids'" class="text-center note">פרט זה בלבד יוצג באתר</label>   -->
  <!-- <p *ngIf="requiredMsg" class="note">*זהו שדה חובה</p>     -->
<div *ngIf="ask.typeInput==1">   
    <div class="btn-my-group" btnRadioGroup [(ngModel)]="ask.answer">
        <label class="btn btn-light" btnRadio="1" tabindex="0" role="button">כן</label>
        <label class="btn btn-light" btnRadio="0" tabindex="0" role="button">לא</label>
        <label class="btn btn-light" btnRadio="2" tabindex="0" role="button" >לפעמים</label>
      </div>
</div> 
  <input *ngIf="ask.typeInput==3" autofocus #currentInput class="form-control {{ask.name}}" type="text" [(ngModel)]="ask.answer"/>
<div *ngIf="ask.typeInput==5">
  <div class="btn-my-group" btnRadioGroup [(ngModel)]="ask.answer">
    <label class="btn btn-light" btnRadio="1" tabindex="0" role="button">כן</label>
    <label class="btn btn-light" btnRadio="0" tabindex="0" role="button">לא</label>
  </div>
  <!-- <label *ngIf="ask.name=='hasKids'" class="text-center note">פרט זה בלבד יוצג באתר</label>   -->
</div>
</div>
</div>
</div>
</div>
<!-- <p>{{ getFamilyStatusDetails()}}</p> -->